import { BuildClass, Do, Maybe } from '../../../universal'
import { React } from '../../lib'
import { CJSX, Focusable } from './meta-types'
import { Modal } from './modal'
import { stubInput } from './stubs'

// TODO - look at using some of the colours from the Tailwind palette or something similar
// https://tailwindcss.com/docs/customizing-colors
const default_palette = [
	// Grid of colours - 4x4
	['#4444FF', '#FF6666', '#F61C1C', '#CCCC00'],
	['#00CC33', '#EC407A', '#AB47BC', '#7E57C2'],
	['#5C6BC0', '#FF7043', '#29B6F6', '#26A69A'],
	['#66BB6A', '#D4E157', '#FFA726', '#8D6E63'],
]

type ColorPickerProps = {
	value: string | null
	onUpdate: (v: string | null) => void
	colors?: string[][]
	autoFocus?: boolean
	readOnly?: boolean
	onFocus?: () => void
	onBlur?: () => void
}

const ColorPickerComponent = (
	props: ColorPickerProps,
	ref: React.ForwardedRef<Focusable<HTMLInputElement>>,
) => {
	// Refs and state
	const element = React.useRef<HTMLInputElement>(stubInput)
	const [focused, setFocused] = React.useState(false)
	const [closed, setClosed] = React.useState(false)

	// Instance
	React.useImperativeHandle(ref, () => ({
		getElement: () => element.current,
		focus: () => {
			element.current.focus()
		},
		select: () => {
			element.current.select()
		},
	}))

	// Render
	return (
		<div
			className={BuildClass({
				'ui5-color-picker': true,
				focused: focused && !closed,
			})}
		>
			<input
				ref={element}
				readOnly={true}
				autoFocus={props.autoFocus}
				value={props.value ?? ''}
				style={{ backgroundColor: getColorValue(props.value) }}
				onFocus={() => {
					if (!props.readOnly) {
						setFocused(true)
						setClosed(false)
					}
				}}
				onClick={() => {
					if (!props.readOnly) {
						setClosed(false)
					}
				}}
				onBlur={() => {
					setFocused(false)
				}}
			/>
			<Modal>
				<div
					className={BuildClass({
						'ui5-color-picker-modal': true,
						closed: closed || !focused,
					})}
					style={Do(() => {
						const B = element.current?.getBoundingClientRect() ?? {
							left: 0,
							right: 0,
							bottom: 0,
						}
						const x = (B.left + B.right) / 2
						const y = B.bottom
						return {
							left: `${x - 134}px`,
							top: `${y + 1}px`,
						}
					})}
				>
					<CJSX cond={focused && !closed}>
						{(props.colors ?? default_palette).map((x, p) => (
							<div className="col-row" key={p}>
								{x.map((x, q) => (
									<div
										className="cell"
										key={q}
										style={{ background: x }}
										onMouseDown={e => {
											e.stopPropagation()
											e.preventDefault()
										}}
										onClick={e => {
											e.stopPropagation()
											e.preventDefault()
											props.onUpdate?.(stripHash(x))
											setClosed(true)
										}}
									/>
								))}
							</div>
						))}
					</CJSX>
				</div>
			</Modal>
		</div>
	)
}

const stripHash = (c: string) => {
	if (!c) {
		return null
	}
	if (c.startsWith('#')) {
		return c.substring(1)
	}
	return c
}

const getColorValue = (value: Maybe<string>): string | undefined => {
	let col = value ?? ''
	if (col && !col.startsWith('#')) {
		col = `#${col}`
	}
	if (col && col.length !== 7) {
		console.warn(`Invalid colour: '${col}'`)
	}
	return col || undefined
}

export const ColorPicker = React.forwardRef(ColorPickerComponent)
