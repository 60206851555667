import {
	Maybe,
	ReportDataCache,
	ReportDataCacheVersioned,
	ReportJob,
	ReportJobReport,
} from '../../../../universal'
import { WindowManagerWindow } from '../../window-manager'
import { ListInstance } from '../list'
import { RSInstance, RSInstanceD } from '../meta-types'
import { Report, ReportComponent, ReportGroup } from '../report-frame'
import { Payload } from './actions'

/** The list doesn't like it being null but negatives are used for new/unsave report IDs */
export const GLOBAL_PARAM_ID = -999999

export type ReducerStateD = RSInstanceD<RJMProps, RJMState, RJMRefs, Payload>
export type ReducerState = RSInstance<RJMProps, RJMState, RJMRefs>

export type RJMSerialisedState = {
	selectedReportJob: Maybe<number>
	selectedReport: Maybe<number>
	searchText: string
	compactView: boolean
	widthsOuter: Maybe<number>[] | null
	widthsInner: Maybe<number>[] | null
}

export type RJMProps = {
	/** A list of report jobs for the application's data model */
	reportJobs: Record<number, ReportJob>
	/** Index of all report types - also passed to the reports window */
	reportIndex: { [key: string]: Report }
	/** Index of all report types, grouped by category - also passed to the reports window */
	reportGroups: ReportGroup[]
	/** Supply the window view state - start with null and pair with `onUpdate` */
	view: Partial<RJMSerialisedState>
	/** Serialised parameter update event - used to store window state */
	onUpdate: (values: RJMSerialisedState) => void
	/** Loads the param/export cache data (by engine version) */
	loadData: {
		v3?: () => Promise<ReportDataCache>
		v4?: () => Promise<ReportDataCache>
	}
	/** Same function passed to report window to get an option component */
	getReportComponent: (type: string) => ReportComponent
	/** Opens the report job in the reports window so it can be run */
	openReportWindow: (rj: number | null) => void
	/** Generally passed in by the window manager automatically */
	window?: WindowManagerWindow
	/** Runs when the user adds a new report job
	 * Callback returns number (new RJ ID = good) or string (error message, bad)
	 */
	onAdd: (name: string, callback: (goodOrError: number | string) => void) => void
	/**
	 * Runs when the user confirms that they want to delete a report job
	 * Callback returns `true` (good) or string (error message, bad)
	 */
	onDelete: (rj: number, callback: (goodOrError: true | string) => void) => void
	/**
	 * Runs when saving changes to an existing report job
	 * Callback returns `true` (good) or string (error message, bad)
	 */
	onSave: (
		reportJob: ReportJob,
		deletedReports: number[],
		callback: (goodOrError: true | string) => void,
	) => void
}

export type RJMState = {
	selectedReportJob: Maybe<number>
	selectedReport: Maybe<number>
	searchText: string
	compactView: boolean
	reportJobs: Record<number, ReportJobInternal>
	widthsOuter: Maybe<number>[] | null
	widthsInner: Maybe<number>[] | null
	isLoading: Record<number, boolean>
	errorMessage: Record<number, Maybe<string>>
	cache: Maybe<ReportDataCacheVersioned>
}

export type RJMRefs = {
	list: React.RefObject<ListInstance<number, false>>
}

export type ReportJobInternal = Omit<ReportJob, 'Reports'> & {
	Reports: Record<number, ReportJobReportPending>
}

export type ReportJobReportPending = Omit<ReportJobReport, 'Type'> & {
	Type: Maybe<string>
}
