import { Maybe } from '../../../../universal'
import { React } from '../../../lib'
import { ReducerStateD } from './types'
import { StubMessage } from './ui-options'

export const ReportJobGlobalParams = (props: {
	rs: ReducerStateD
	rj: Maybe<number>
}) => {
	if (false as boolean) {
		console.log(props)
	}
	return <StubMessage msg="Coming soon" />
}
