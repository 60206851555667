import { Do, Maybe, guid, timer } from '../../universal'
import { $, _ } from '../lib'
import { IRIS } from './component-iris'
import { j2h } from './component-j2h'
import { newSystemFlyout } from './flyouts'

// Resusable J2H components
export var Components = {
	// Dropdown
	/**
	 * @deprecated This is only for use with old JQuery layouts
	 */
	Dropdown: (obj: any = {}) => {
		const value = obj.value ?? null
		const model = obj.model ?? null
		const multiple = obj.multiple ?? false
		const options = obj.options ?? null
		const evChange = obj.change ?? _.noop
		const className = obj.class ?? 'tb2'
		const disabled = obj.disabled ?? false
		// Create the select element with preset value
		const M = {
			tag: 'select',
			class: className,
			value,
			model: null,
			attr: {} as any,
			children: options.map(opt => {
				if (opt.constructor === Array) {
					return {
						tag: 'option',
						value: opt[0],
						text: opt[1],
					}
				}
				return {
					tag: 'optgroup',
					attr: { label: opt.label },
					children: opt.children.map(o => ({
						tag: 'option',
						value: o[0],
						text: o[1],
					})),
				}
			}),
			ev: {
				init: elem => {
					if (value != null) {
						elem.value = value
					}
					;(obj.init ?? _.noop)(elem)
				},
				change: evChange,
			},
		}
		if (multiple) {
			M.attr.multiple = null
			M.class += ' multiple'
		}
		if (model != null) {
			M.model = model
		}
		if (disabled) {
			M.attr.disabled = true
		}
		// Return the object
		return M
	},

	// Save button
	/**
	 * @deprecated This is only for use with old JQuery layouts
	 */
	Button: (lbl, class_, onClick) => {
		if (onClick == null) {
			onClick = _.noop
		}
		return {
			tag: 'input',
			class: class_,
			value: lbl,
			attr: { type: 'button' },
			click: (e, $elem) => onClick(e, $elem),
		}
	},
}

// Reusable Alerts
export var Alerts = {
	Base: obj => {
		// Normalise inputs
		if (obj.title == null) {
			obj.title = 'No title given'
		}
		if (obj.msg == null) {
			obj.msg = 'No message given'
		}
		if (obj.buttons == null) {
			obj.buttons = [['OK', 'btnSubmit2', _.noop, true]]
		}
		if (obj.load == null) {
			obj.load = _.noop
		}
		if (obj.size == null) {
			obj.size = [360, 200]
		}
		if (obj.cl == null) {
			obj.cl = ''
		}

		// Create the fly-out
		const flyout = newSystemFlyout({
			size: obj.size,
			cl: obj.cl,
			closeCallback: obj.no ?? _.noop,
		})
		const $form = $(flyout.Element)
		$form.append(
			j2h([
				{
					class: 'flyout_header',
					children: [
						{
							tag: 'h1',
							attr: { style: 'display: inline;' },
							text: obj.title,
						},
					],
				},
				{
					class: 'floater_body toDialog',
					children: [
						{ tag: 'p', html: obj.msg },
						{
							class: 'buttons cntr',
							children: _.map(obj.buttons, btn => {
								const [lbl, class_, onclick, isFocused] = btn
								const M = Components.Button(lbl, class_, () => {
									flyout.Close()
									onclick?.($form)
								}) as any
								if (isFocused) {
									if (M.ev == null) {
										M.ev = {}
									}
									M.ev.init = elem => timer(() => elem.focus())
								}
								return M
							}),
						},
					],
				},
			]),
		)

		// Run the on-load function event
		obj.load()
		return $form.find('.floater_body')
	},

	Confirm: obj => {
		if (obj.title == null) {
			obj.title = 'Are you sure?'
		}
		if (obj.msg == null) {
			obj.msg = 'Are you sure you want to perform this action?'
		}
		if (obj.yes == null) {
			obj.yes = _.noop
		}
		if (obj.no == null) {
			obj.no = _.noop
		}
		if (obj.buttons == null) {
			obj.buttons = [
				['OK', 'btnSubmit2', obj.yes, true],
				['Cancel', 'btnStandard2', obj.no, false],
			]
		}
		return Alerts.Base(obj)
	},

	ConfirmWithOption: obj => {
		// Input validation
		if (obj.label == null) {
			console.error('Must define `label` to `ConfirmWithOption`')
		}
		const onYesClick = obj.yes ?? _.noop

		// Build the custom HTML form with the checkbox
		const thisGUID = guid()
		obj.msg = Do(() => {
			const dom = {
				children: [
					{
						tag: 'p',
						text: obj.msg ?? 'Are you sure you want to perform this action?',
					},
					{
						tag: 'label',
						class: 'label noselect',
						children: [
							{
								tag: 'input',
								attr: {
									type: 'checkbox',
									id: thisGUID,
								},
							},
							String(obj.label ?? 'No label defined'),
						],
					},
				],
			}
			return j2h(dom).outerHTML
		})

		// Wrap the yes handler with some logic to get the checkbox state
		obj.yes = $form => {
			const is_checked = $form.find(`[id='${thisGUID}']`)[0].checked
			onYesClick(is_checked)
		}

		// Pass the custom options with fallbacks
		if (obj.title == null) {
			obj.title = 'Are you sure?'
		}
		if (obj.no == null) {
			obj.no = _.noop
		}
		if (obj.buttons == null) {
			obj.buttons = [
				['OK', 'btnSubmit2', obj.yes, true],
				['Cancel', 'btnStandard2', obj.no, false],
			]
		}
		return Alerts.Base(obj)
	},

	Alert: obj => {
		if (obj.title == null) {
			obj.title = 'Message'
		}
		if (obj.size == null) {
			obj.size = [300, 190]
		}
		obj.buttons = [['OK', 'btnSubmit2', obj.yes, true]]
		return Alerts.Base(obj)
	},
}

// Helper - Zero padding to 4 characters
export const leftpad = (text: string | number, len: number, char: string = '0') => {
	let string = String(text) // Ensure it is a string
	const gap = len - string.length
	if (gap > 0) {
		// Skip if already padded
		for (
			let __ = 1, end = gap, asc = end >= 1;
			asc ? __ <= end : __ >= end;
			asc ? __++ : __--
		) {
			string = char + string
		}
	}
	return string
}

// Helper - Sanitizes time strings
export const validateTime = (string: Maybe<string>) => {
	// Sanitize to only digits in the input - only the first 4 chars
	if (!string) {
		return ''
	}
	string = string.trim()

	// If it's a full time string - drop the seconds first
	if (string.split(':').length == 3) {
		string = string.split(':').slice(0, 2).join(':')
	}

	// Check if there's anything here
	string = string.replace(/\D/g, '')
	if (string.length === 0) {
		return ''
	}

	// Add trailing zeroes if only two chars entered
	// Pad the string to 4 characters
	if (string.length <= 2) {
		string += '00'
	}
	string = leftpad(string, 4)

	// Get the hours and minutes separately
	let h = +string.substring(0, 2)
	let m = +string.substring(2, 4)

	// Ensure that the hours and minutes are in range
	if (!(h >= 0 && h <= 24)) {
		h = 0
	}
	if (!(m >= 0 && m <= 60)) {
		m = 0
	}

	// Add a colon in the middle of the 4 digits
	return `${leftpad(String(h), 2)}:${leftpad(String(m), 2)}`
}

// Runs the event listener to report any client side errors to the server
export const sendErrorsToTelegram = () => {
	window.onerror = (message, url, line, _col, err) => {
		IRIS.Send({
			data: {
				progID: 0,
				funcID: 7,
				browser: navigator?.userAgent ?? 'Unknown',
				message,
				stack: err?.stack ?? null,
				url,
				line,
				location: location.href,
			},
		})
	}
}

// Helper - enables/disables a button and all siblings
/**
 * @deprecated This is only for use with old JQuery layouts
 */
export const enableButtons = $btn => {
	$btn = $btn.parent().children('input, button')
	$btn.removeClass('disabled').prop('disabled', false)
}
/**
 * @deprecated This is only for use with old JQuery layouts
 */
export const disableButtons = $btn => {
	$btn = $btn.parent().children('input, button')
	$btn.addClass('disabled').prop('disabled', true)
}

// Fetches the width of scrollbars and caches it for future calls
let scrollbarWidthCache: number = null
export const getScrollbarWidth = () => {
	// Return the cached value, if one exists
	if (scrollbarWidthCache != null) {
		return scrollbarWidthCache
	}

	// Create the DOM to test the scrollbar width
	const div: HTMLDivElement = j2h({
		attr: {
			style: 'width:50px;height:50px;position:absolute;left:-50px;top:-50px;overflow:auto;',
		},
		children: [{ attr: { style: 'width:1px;height:100px;' } }],
	})

	// Quickly append to DOM, check the scrollbar side, and remove again
	document.body.appendChild(div)
	scrollbarWidthCache = div.offsetWidth - div.clientWidth
	div.remove()

	// Create a dynamic style on the page for the class `allButScrollbar` that
	// gives a calculated width of the amount without the scrollbar. Used for
	// fixed header divs above scrollable panes
	$('head').append(
		$(`<style>.allButScrollbar{width:calc(100% - ${scrollbarWidthCache}px)}`),
	)

	// Return the now-cached width
	return scrollbarWidthCache
}
export const clearScrollbarWidthCache = () => {
	scrollbarWidthCache = null
}

// A basic draggable API that uses transforms instead of top/left
export const makeDraggable = options => {
	// Helper function to get the elements
	const getElement = v => {
		if (v == null) {
			v = null
		}
		if (typeof v === 'function') {
			v = v()
		}
		return v
	}

	// Normalise the parameters
	const normaliseParameters = () => {
		// Normalise
		if (options == null) {
			options = {}
		}
		options = {
			// The two elements - taken as JQuery elements
			$element: getElement(options.$element), // Thing you move
			$handle: getElement(options.$handle), // Thing you drag

			// The three events - start, during, stop
			// Each takes (data) - see data parameter below - event nested underneath
			onStart: options.onStart ?? _.noop,
			onDuring: options.onDuring ?? _.noop,
			onStop:
				options.onStop ??
				(data => {
					// Get the old position and update the style
					const pos = options.$element.position()
					options.$element.css({
						left: pos.left + data.pos.delta.x,
						top: pos.top + data.pos.delta.y,
						transform: '',
					})
				}),

			// Parameters
			polling: options.polling ?? 30, // The debounce
			grid: options.grid ?? 1, // Round the position to this
			allowHorizontal: options.allowHorizontal ?? true,
			allowVertical: options.allowVertical ?? true,
			stopProp: options.stopProp ?? true,

			// Arbitrary data carried between handlers
			data: options.data ?? {},
		}

		// Add the options object to the data
		options.data.options = options
	}

	// Helper function to get the new delta position, rounded to the grid size
	const getDelta = (o, n) => options.grid * Math.floor((o - n) / options.grid)

	// Function handler for moving the transform
	const dragMove = e => {
		// Get the position of the coordinates - touch or no touch
		const cx = e.pageX ?? e.originalEvent?.touches[0]?.pageX
		const cy = e.pageY ?? e.originalEvent?.touches[0]?.pageY

		// Get the change in X and Y coordinates since the initial drag
		let dx = getDelta(cx, options.data.pos.init.x)
		let dy = getDelta(cy, options.data.pos.init.y)

		// Get the amount to transform - dependent on whether dimensions allowed
		dx = options.allowHorizontal ? dx : 0
		dy = options.allowVertical ? dy : 0

		// Update the current and delta positions
		options.data.pos.delta = { x: dx, y: dy }
		options.data.pos.current = {
			x: options.data.pos.init.x + dx,
			y: options.data.pos.init.y + dy,
		}

		// Apply the transformation to the element
		options.$element.css({ transform: `translate(${dx}px, ${dy}px)` })

		// Run the custom function during dragging
		options.data.event = e
		options.onDuring(options.data)
	}

	// A throttled version of the dragMove function to limit the number of calls
	const dragMoveThrottled = _.throttle(dragMove, 30)

	// Called when the function runs
	const onMouseMoveThis = e => {
		dragMoveThrottled(e)
	}

	// Called when the mouse button RISES
	const onMouseUpThis = e => {
		// Terminate any events and outstanding throttled handlers
		options.data.$window.off('mousemove touchmove', onMouseMoveThis)
		options.data.$window.off('mouseup touchend', onMouseUpThis)
		dragMoveThrottled.cancel()

		// Run the custom function for when dragging stops
		options.data.event = e
		options.onStop(options.data)
	}

	// Function to run on mouse down - starts the drag
	const startDrag = e => {
		// Skip propagation, if requested
		if (options.stopProp) {
			e.stopPropagation()
			e.preventDefault()
		}

		// Normalise the parameters
		normaliseParameters()

		// Store the JQuery window object - used to add global events for
		// the dragging to end, once it starts
		options.data.$window = $(window)

		// Get the position of the coordinates - touch or no touch
		const cx = e.pageX ?? e.originalEvent?.touches[0]?.pageX
		const cy = e.pageY ?? e.originalEvent?.touches[0]?.pageY

		// Get the coordinates of the mouse when dragging starts
		// Add the posDelta flag - holds the current distance from start
		// Add the posCurrent flag - holds the current position
		options.data.pos = {
			init: { x: cx, y: cy },
			delta: { x: 0, y: 0 },
			current: { x: cx, y: cy },
		}

		// Start the two events too look for dragging on this item
		options.data.$window.on('mousemove touchmove', onMouseMoveThis)
		options.data.$window.on('mouseup touchend', onMouseUpThis)

		// Run the custom function when dragging starts
		options.data.event = e
		options.onStart(options.data)
	}

	// Remove any existing dragging handler and add this new one
	return getElement(options.$handle)
		.off('mousedown touchstart', startDrag)
		.on('mousedown touchstart', startDrag)
}

// A default-dict-like object for JavaScript, using ES6 maps
export class DefMap<K, V, T = V | V[] | Set<V>> {
	fn: () => T
	map: Map<K, T>

	// Constructor
	constructor(fn: () => T) {
		this.fn = fn
		this.map = new Map()
	}

	// Gets a value from the map - sets to the default if undefined
	get(key: K) {
		let val = this.map.get(key)
		if (val === undefined) {
			val = this.fn()
			this.map.set(key, val)
		}
		return val
	}

	// Sets a value in the map
	set(key: K, val: T) {
		return this.map.set(key, val)
	}

	// Add to the set (only works if default value is an array)
	push(key: K, newVal: V) {
		const valObj = this.get(key)
		if (Array.isArray(valObj)) {
			valObj.push(newVal)
		}
		if (valObj.constructor !== Array) {
			throw Error('Cannot use append for non-array values')
		}
	}

	// Add to the set (only works if default value is a set)
	add(key: K, newVal: V) {
		const valObj = this.get(key)
		if (valObj instanceof Set) {
			valObj.add(newVal)
		} else {
			throw Error('Cannot use append for non-set values')
		}
	}

	// Grabs the native map object
	toMap() {
		return this.map
	}
}

// Search highlighting
export const applySearchHighlight = (text, search) =>
	// Turns a text string into an array of spans with matching parts highlighted
	_.map(getSearchMatchingSections(text, search), (match, i) => ({
		tag: 'span',
		key: i,
		cl: match.match ? 'highlight' : undefined,
		text: match.text,
	}))

var getSearchMatchingSections = (text, search) => {
	// This function takes in some text and a search string (both case insensitive)
	// Divides the text into an array of matching and non-matching parts
	// Used to work out which parts of the string require highlight
	// To use as a drop-in for a React element, use `applySearchHighlight`

	// If no search given, return the text as the only node - no match
	if (text == null) {
		text = ''
	}
	if (!search || search.length === 0) {
		return [{ match: false, text }]
	}

	// Convert to lowercase
	let haystack = text.toLowerCase()
	search = search.toLowerCase()

	// Get the start index of each search match
	const result_indices = Do(() => {
		const indices = []
		let index_offset = 0
		while (haystack.indexOf(search) !== -1) {
			const new_index = haystack.indexOf(search) + index_offset
			indices.push(new_index)
			const index_offset_delta = haystack.indexOf(search) + search.length
			index_offset += index_offset_delta
			haystack = haystack.substring(index_offset_delta)
		}
		return indices
	})

	// Divide the string up into matching parts and non-matching parts
	// Start with the bit before the first match
	const sections = [
		{
			match: false,
			text: text.substring(0, result_indices[0] ?? text.length + 1),
		},
	]

	// Each match has a corresponding section afterwards that doesn't match
	result_indices.forEach((index, n) => {
		// Section for the match
		const match_start = index
		const match_end = index + search.length
		sections.push({
			match: true,
			text: text.substring(match_start, match_end),
		})

		// Section for the subsequent non-matching part
		const next_match_start = result_indices[n + 1] ?? text.length + 1
		sections.push({
			match: false,
			text: text.substring(match_end, next_match_start),
		})
	})

	// Return the sections, filtering out any section with no text (redundant)
	return sections.filter(x => x.text.length > 0)
}

export const objDiff = (objA, objB) => {
	objA = _.cloneDeep(objA)
	const changes = (objA, objB) =>
		_.transform(objA, (result, value, key) => {
			if (!_.isEqual(value, objB[key])) {
				result[key] = Do(() => {
					if (_.isObject(value) && _.isObject(objB[key])) {
						return changes(value, objB[key])
					}
					return value
				})
			}
		})

	return changes(objA, objB)
}
