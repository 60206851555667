import { Maybe } from '../../../universal'
import { React, _ } from '../../lib'

/**
 * Returns the second parameter (executing if it's a function), if the condition is true
 * @param cond The condition for whether to return anything
 * @param obj The value (or function returning that value) to return
 * @returns Either `obj` or `undefined`
 */
export const ConditionalObject = <T,>(
	cond: boolean,
	obj: T | (() => T),
): T | undefined => {
	if (!cond) {
		return undefined
	}
	if (obj instanceof Function) {
		return obj()
	}
	return obj
}

/** Conditionally renders a JSX child based on a condition */
export const CJSX = (props: {
	cond: boolean
	children: React.JSX.Element | React.JSX.Element[]
}): React.JSX.Element => (props.cond ? <>{props.children}</> : <></>)

/**
 * Copies a React state object so it can be edited imperatively. Useful for nested object
 * updates without resorting to a lot of `_.assign` uses
 */
export const CopyState = <T,>(val: Readonly<T>): T => _.clone(val)

/**
 * Ensures that class methods are bound to the class so they can be run with the correct
 * value for `this` even if run as a direct event instead of an anonymous function
 * @param obj class to bind to
 * @param methods array of methods
 */
export const Bindings = (obj: any, methods: Function[]) => {
	_.forEach(methods, method => {
		if (method.name.startsWith('bound ')) {
			console.warn('Function is already bound', method)
		} else {
			obj[method.name] = method.bind(obj)
		}
	})
}

/**
 * Interface that says a component is externally-focusable.
 * Necessary for components going into editable grids.
 */
export type Focusable<E extends Element> = {
	focus: () => void
	select: () => void
	getElement: () => E
}

/**
 * Code copied from https://fettblog.eu/typescript-react-generic-forward-refs/
 * Note that this only changes the types, and does not change the library at all!
 * Makes `forwardRef` retain generic types properly. Apparently it's only not in the base
 * library because it breaks defaultProps and other legacy features we don't use
 */
declare module 'react' {
	function forwardRef<T, P = {}>(
		render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
	): (
		props: P & React.RefAttributes<T>,
	) => (React.ReactElement & { displayName: string }) | null
}

/**
 * Substitute for `React.useMemo` that takes in a compare function instead of array
 * Adapted from https://usehooks.com/useMemoCompare/ to store values/compare separately
 * @param valueFn Function returning the memoised value
 * @param nextCompare The compare object/array - same as `React.useMemo`
 * @param compareFn Takes in the previous and next compare values and decides if equal
 * @returns Unwrapped value, either a cached older one, or a new one
 */
export const useMemoCompare = <T, S>(
	valueFn: () => T,
	nextCompare: S,
	compareFn: (prev: S, next: S) => boolean = (p, n) => _.isEqualWith(p, n, _.isEqual),
): T => {
	// Ref for storing previous value
	// Ref for storing previous compare value
	const previousRefValue = React.useRef<Maybe<{ value: T }>>()
	const previousRefCompare = React.useRef<Maybe<{ value: S }>>()
	const hasRunOnce = React.useRef<boolean>(false)

	// If it's the first time, don't bother running a comparison
	if (!hasRunOnce.current) {
		const valueUnpacked = valueFn()
		previousRefCompare.current = { value: nextCompare }
		previousRefValue.current = { value: valueUnpacked }
		hasRunOnce.current = true
		return valueUnpacked
	}

	// Pass prev/next values to compare function to check if considered equal
	const isEqual = previousRefCompare.current
		? compareFn(previousRefCompare.current.value, nextCompare)
		: false

	// If not equal, evaluate/unpack the new function's value
	// Return the new value. Update references to the new compare/value
	if (!isEqual || !previousRefValue.current) {
		const valueUnpacked = valueFn()
		previousRefCompare.current = { value: nextCompare }
		previousRefValue.current = { value: valueUnpacked }
		return valueUnpacked
	}

	// The previous values continue to be used
	return previousRefValue.current.value
}

export const useEffectCompare = <T extends any>(
	effect: () => void,
	nextCompare: T,
	compareFn: (prev: T, next: T) => boolean = (p, n) => _.isEqualWith(p, n, _.isEqual),
) => {
	// Keep track of a counter. Update the counter each time we want the effect to run
	const counter = React.useRef<number>(1)

	// Test the change of value with the deep compare memo function
	useMemoCompare<1, T>(
		() => {
			counter.current++
			return 1
		},
		nextCompare,
		compareFn,
	)

	// Run the effect with the counter - if it increment it will refresh
	// If the useMemoCompare did not execute the memo function, it will cache
	React.useEffect(effect, [counter.current])
}

/** Substitute for `React.useState` that returns an object ready to splat into a ui5 obj */
export const useStateObj = <T extends any>(
	defVal: T | (() => T),
): {
	value: T
	onUpdate: (value: T) => void
} => {
	const [value, onUpdate] = React.useState<T>(defVal)
	return { value, onUpdate }
}

/**
 * Gets a state value that is synchronised to a prop value and a state value
 * Custom hook with 3 sub-hooks. One tracks whether state/props have changed this render
 * One updates the state when props change, the other updates props when state changes
 * A custom comparison function can be used - defaults to a very deep compare
 * This saves some boilerplate and ensures no double-update render loops
 */
export const useStateSync = <T, U>(args: {
	stateVal: U
	propVal: T
	setState: (val: T) => void
	setProp: (val: U) => void
	compareFn?: (prop: T, state: U) => boolean
	disabled?: boolean // You cannot if statement this away because of hook count
	debug?: boolean
}): void => {
	// Track whether the state has updated on this render
	// This prevents getting stuck in a loop where the prop/state are flipping
	const hasUpdatedOnRender = React.useRef<boolean>(false)

	// Default the compare function to the deepest compare
	const fallback = React.useMemo(
		() => (a: T, b: U) => _.isEqualWith(a, b, _.isEqual),
		[],
	)
	const compareFn = args.compareFn ?? fallback

	// Update value to match props
	useEffectCompare(() => {
		if (args.debug) {
			console.log('Change prop effect')
		}
		if (!compareFn(args.propVal, args.stateVal)) {
			if (args.debug) {
				console.log('Prop changed', { prop: args.propVal, state: args.stateVal })
			}
			hasUpdatedOnRender.current = true
			if (!args.disabled) {
				args.setState(args.propVal)
			}
		}
	}, args.propVal)

	// Update the prop to match state
	useEffectCompare(() => {
		if (hasUpdatedOnRender.current) {
			return
		}
		if (args.debug) {
			console.log('Change state effect')
		}
		if (!compareFn(args.propVal, args.stateVal)) {
			if (args.debug) {
				console.log('State changed', { prop: args.propVal, state: args.stateVal })
			}
			if (!args.disabled) {
				args.setProp(args.stateVal)
			}
		}
	}, args.stateVal)

	// Set back to false at the end of the render
	React.useEffect(() => {
		hasUpdatedOnRender.current = false
	})
}

export const useStateSimple = <T extends any>(
	propVal: T,
	setProp: (newVal: T) => void,
): [T, (newVal: T) => void] => {
	const [value, setValue] = React.useState<T>(propVal)
	useStateSync<T, T>({
		stateVal: value,
		propVal: propVal,
		setState: setValue,
		setProp: setProp,
		compareFn: (p, s) => p === s,
	})
	return [value, setValue]
}

/** A reducer state instance (no dispatch function) */
export type RSInstance<
	P extends object,
	S extends object,
	R extends { [key: string]: React.RefObject<any> },
> = {
	props: P
	state: S
	refs: R
}

/** A reducer state instance with the dispatch function */
export type RSInstanceD<
	P extends object,
	S extends object,
	R extends { [key: string]: React.RefObject<any> },
	PL extends object,
> = RSInstance<P, S, R> & {
	/** The more structured way to make state updates using a dispatch payload */
	dispatch: (payload: PL) => void
	/** Updates the state object with a plain delta without worrying about dispatch events */
	updateState: (delta: Partial<S> | ((currentState: S) => Partial<S>)) => void
}

/**
 * Creates a reducer state instance that can be passed around functional components like
 * the `this` keyword in a class component. Stores props/state/refs (i.e. all context),
 * and allows changes to state via the dispatch function.
 */
export const useRSInstance = <
	P extends object,
	S extends object,
	R extends { [key: string]: React.RefObject<any> },
	PL extends object,
>(input: {
	/** Passthrough props for the component */
	props: P
	/**
	 * Initial state based on props. Only called once - can be relative expensive.
	 * Wrap in `React.useCallback` make static (i.e. not inline-defined)
	 */
	defaultState: (props: P) => S
	/** Passthrough ref objects - define above with `React.useRef`. Should NOT change */
	refs: R
	/**
	 * Static function that turns current rs instance and payload into partial state delta
	 * Wrap in `React.useCallback` make static (i.e. not inline-defined)
	 * Payload is generally an enum of actions w/ input data for action type
	 */
	actionToDelta: Maybe<
		(rs: Readonly<RSInstance<P, S, R>>, payload: PL) => Maybe<Partial<S>>
	>
}): Readonly<RSInstanceD<P, S, R, PL>> => {
	// Create a second object with the original input functions to check against
	const refCheck = React.useRef({
		refs: input.refs,
		defaultState: input.defaultState,
		actionToDelta: input.actionToDelta,
	})

	// Confirm the function inputs never change - they must be static
	if (refCheck.current.defaultState !== input.defaultState) {
		console.warn('Function `defaultState` changed between renders')
	}
	if (refCheck.current.actionToDelta !== input.actionToDelta) {
		console.warn('Function `actionToDelta` changed between renders')
	}

	// Check that the reference objects are all the same. The values behind the refs can
	// change but the reference objects themselves should not. This should work fine if
	// you're passing through values generated from `React.useRef` directly without edits
	const uniqueKeys = new Set([
		...Object.keys(refCheck.current.refs),
		...Object.keys(input.refs),
	])
	uniqueKeys.forEach(key => {
		if (refCheck.current.refs[key] !== input.refs[key]) {
			console.warn(`Ref object '${key}' changed between renders`)
		}
	})

	// Create a dummy useState so we can force a re-render when dispatch changes something
	const [, setIncrement] = React.useState<number>(0)

	// Create the main object once so all changes are mutated to the same object
	const initialValue = {
		// Passthrough props
		props: input.props,
		// Get default state from props - run once and could be expensive
		// eslint-disable-next-line react-hooks/exhaustive-deps
		state: React.useMemo(() => input.defaultState(input.props), []),
		// Passthrough refs
		refs: input.refs,
		// Create the dispatch function to modify the state
		dispatch: (payload: PL): void => {
			if (!input.actionToDelta) {
				console.warn('No action to delta function provided for dispatch')
				return
			}
			const delta = input.actionToDelta?.(refInstance.current, payload)
			if (delta) {
				refInstance.current.state = {
					...refInstance.current.state,
					...delta,
				}
				setIncrement(v => v + 1) // Force re-render
			}
		},
		// Updates the state directly without worrying about dispatching
		updateState: (delta: Partial<S> | ((currentState: S) => Partial<S>)): void => {
			refInstance.current.state = {
				...refInstance.current.state,
				...(_.isFunction(delta) ? delta(refInstance.current.state) : delta),
			}
			setIncrement(v => v + 1) // Force re-render
		},
	}

	// Get the reference with the reducer state object
	// This object is only ever mutated so references to it will keep up-to-date
	// This avoids potential messy race conditions when updating state
	const refInstance = React.useRef<RSInstanceD<P, S, R, PL>>(initialValue)

	// Mutate the object to update the props so it's always up-to-date
	// Refs in theory shouldn't update but update those too just in case
	refInstance.current.props = input.props
	refInstance.current.refs = input.refs

	// Return the reference
	return refInstance.current
}

/**
 * Conditional rendering of JSX elements in an if/else setup
 * Conditions are evaluated in order and the first one that is true will be rendered
 * If none are true, an empty string is returned
 */
export const MatchRender = (props: {
	check: {
		when: boolean
		then: React.JSX.Element
	}[]
}): React.JSX.Element => {
	const condition = props.check.find(c => c.when)
	return condition?.then ?? <></>
}
