export const marquee = () => {
	document.querySelectorAll('span').forEach(el => {
		replaceTag(el, 'marquee')
	})
}

const replaceTag = (originalElement: Element, newTag: string) => {
	const newElement = document.createElement(newTag)
	// Copy the children
	while (originalElement.firstChild) {
		newElement.appendChild(originalElement.firstChild) // *Moves* the child
	}
	// Copy the attributes
	for (let index = originalElement.attributes.length - 1; index >= 0; --index) {
		newElement.attributes.setNamedItem(
			originalElement.attributes[index].cloneNode() as any,
		)
	}
	// Replace it
	originalElement.parentNode.replaceChild(newElement, originalElement)
}
