import { $, _, hljs, showdown } from '../lib'
import { j2h } from './component-j2h'

// All next-gen markdown code should be in this file
// This powers the KB, TMS notes, audit notes, static KB pages, and change notes in billing

export var markdownToHTMLProxy = (md, heading_lvl = 2) => {
	// Create the converter
	const this_converter = new showdown.Converter({
		headerLevelStart: heading_lvl,
		ghMentionsLink: '#mention',
	})
	this_converter.setFlavor('github')
	this_converter.setOption('parseImgDimensions', true)
	this_converter.setOption('smoothLivePreview', true)

	// Replace any remaining ' -> ' or ' --> ' with a '&rarr;'
	// Similarly with any left arrow
	md = md.replace(/\s-+>\s/g, ' → ')
	md = md.replace(/\s<-+\s/g, ' ← ')

	// Replace < and > with special chars
	// Get markdown HTML and put in place
	md = md.replace(/<(.*?)>/g, '&lt;$1&gt;')
	let html = this_converter.makeHtml(md)

	// Fix bug with showdown
	const re = /<a href="<a href=".*?">.*?">.*?>(.*?)<\/a><\/a>/g
	html = html.replace(re, '<code>$1</code>')

	// Parse into off-screen JQuery element
	const $elem = $('<div/>')
	$elem.html(html)

	// Parse all wikitable blocks
	$elem.find('code.wikitable').each((_i, el) => {
		const $block = $(el)
		const $parent = $block.parent()
		const $el = $(convertWikiTable($block.html()))
		$el.addClass('wikitable')
		$parent.replaceWith($el)
	})

	// Run all cells through the markdown generator
	// Cells that start with `>` are multi-line - first line ignored, and tabs are
	// removed from the start of the lines until at least one line has none
	$elem.find('table.wikitable td, table.wikitable th').each((_i, el) => {
		md = el.innerText
		if (md[0].trim() === '>') {
			let lines = md.trim().substring(1).split('\n').slice(1)
			while (lines.length > 0 && _.every(_.map(lines, x => x[0] === ' ' || !x))) {
				lines = lines.map(x => x.substring(1))
			}
			md = lines.join('\n')
		}
		el.innerHTML = markdownToHTMLProxy(md)
		// Unwrap top-level element if it's singular and a `p` tag
		if (el.children.length === 1 && el.children[0].tagName.toUpperCase() === 'P') {
			el.innerHTML = el.children[0].innerHTML
		}
	})

	// Find all code blocks
	$elem.find('code').each((_i, block) => {
		// Explicitly ensure inline code remains inline and is highlighted only as text
		const $block = $(block)
		const $parent = $block.parent()
		if ($parent.is('p') || $parent.is('li') || $parent.is('td')) {
			$block.css({ display: 'inline' }).addClass('hljs')
		} else {
			hljs.highlightElement(block)
		}

		// If this is plain-text, keep the core hljs style class
		if ($block.hasClass('text')) {
			$block.addClass('hljs')
		}

		// Replace &lt; with &gt; in code blocks
		html = $block.html()
		html = html.replace(/&amp;gt;/g, '>')
		html = html.replace(/&amp;lt;/g, '<')
		$block.html(html)
	})

	// Convert the named blockquotes into blockquotes with those specific classes
	const keys = {
		'[grey]': 'grey',
		'[red]': 'red',
		'[yellow]': 'yellow',
		'[green]': 'green',
	}
	$elem.find('blockquote').each((_i, bq) => {
		let src
		_.forEach(keys, (v, k) => {
			if (bq.innerText.trim().startsWith(k)) {
				bq.classList.add('box')
				bq.classList.add(v)
				// Remove the prefix from the first non-empty text node found
				let node = bq as any
				while (node?.nodeType !== 3) {
					node = getFirstNonEmptyChild(node)
				}
				node.data = node.data.substring(k.length)
			}
		})

		// Convert the named blockquote [audio] into an inline audio clip
		if (bq.innerText.trim().startsWith('[audio]')) {
			bq.classList.add('media-container')
			src = bq.innerText.trim().substring(7).trim()
			bq.innerText = ''
			bq.appendChild(
				j2h({
					tag: 'audio',
					attr: { controls: '' },
					children: [
						{
							tag: 'source',
							attr: {
								src,
								type: 'audio/wav',
							},
						},
						{
							tag: 'span',
							text: 'Audio clip - browser not supported',
						},
					],
				}),
			)
		}

		// Convert the named blockquote [youtube] into an embeded YouTube clip
		if (bq.innerText.trim().startsWith('[youtube]')) {
			bq.classList.add('media-container')
			src = bq.innerText.trim().substring(9).trim()
			bq.innerText = ''
			bq.appendChild(
				j2h({
					tag: 'iframe',
					attr: {
						width: 560,
						height: 315,
						src: `https://www.youtube-nocookie.com/embed/${src}`,
						frameborder: '0',
						allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
						allowfullscreen: '',
					},
				}),
			)
		}

		// Convert named blockquote [video] into a standard video embed
		if (bq.innerText.trim().startsWith('[video]')) {
			bq.classList.add('media-container')
			src = bq.innerText.trim().substring(7).trim()
			bq.innerText = ''
			bq.appendChild(
				j2h({
					tag: 'video',
					attr: {
						controls: '',
						width: 560,
					},
					children: [
						{
							tag: 'source',
							attr: {
								src,
								type: 'video/mp4',
							},
						},
						{
							tag: 'span',
							text: 'Video clip - browser not supported',
						},
					],
				}),
			)
		}

		// Convert named blockquote [gif] into a video embed with no controls, loop, and autoplay
		if (bq.innerText.trim().startsWith('[gif]')) {
			bq.classList.add('media-container')
			src = bq.innerText.trim().substring(5).trim()
			bq.innerText = ''
			bq.appendChild(
				j2h({
					tag: 'video',
					attr: {
						loop: '',
						autoplay: '',
						playsinline: '',
						disablepictureinpicture: '',
						width: 560,
					},
					children: [
						{
							tag: 'source',
							attr: {
								src,
								type: 'video/mp4',
							},
						},
						{
							tag: 'span',
							text: 'Video clip - browser not supported',
						},
					],
				}),
			)
		}

		// Convert named blockquote [tmsnote] into a TMS note embed/link
		if (bq.innerText.trim().startsWith('[tmsnote]')) {
			bq.classList.add('tms-note-embed-link')
			bq.classList.add('box')
			bq.classList.add('grey')
			bq.setAttribute('data-key', bq.innerText.trim().substring(9).trim())
			bq.innerText = 'Loading TMS Note...'
		}
	})

	// Any images with an alt tag of the format `123x456` (both sides optional)
	// Set the maximum dimensions of the image as these
	const pattern = RegExp(/^\d*?x\d*$/)
	$elem.find('img').each((_i, el) => {
		if ((el.alt ?? '').match(pattern)) {
			const [w, h] = el.alt.split('x')
			el.style.maxWidth = `min(100%, ${w}px)`
			el.style.maxHeight = `${h}px`
			el.alt = undefined
		}
	})

	// Make all links to a new tab
	$elem.find('a').attr({ target: '_blank' })

	// Convert links to #mention to a highlight
	$elem.find('a[href="#mention"]').each((_i, el: any) => {
		el.target = ''
		el.classList.add('mention')
		el.addEventListener('click', () => false)
		const text = el.innerText
		el.innerText = ''
		el.appendChild(
			j2h({
				tag: 'span',
				class: 'at',
				text: '@',
			}),
		)
		el.appendChild(
			j2h({
				tag: 'span',
				class: 'name',
				text: text.substring(1),
			}),
		)
	})

	// Checked checkboxes in lists should flag the parent list item. This can
	// be used for making the font gray to visually distinguish from completed
	$elem.find('input[type="checkbox"]:checked').parent().addClass('lb_checked')

	// Return the sanitized HTML
	return $elem.html()
}

export const markdownToHTML = ($elem, text, heading_level?) => {
	// Return the formatted text (for the editor)
	if ($elem === undefined || $elem === null) {
		return text
	}

	// Convert to HTML
	const html = markdownToHTMLProxy(text, heading_level)

	// Ensure that the container element has the correct class assignment
	// This ensures document styles from the universal sheet are applied
	$elem.addClass('to_document')

	// Write to DOM
	$elem.html(html)
}

var getFirstNonEmptyChild = el => {
	let found = null
	_.forEach(el.childNodes, n => {
		if (found) {
			return
		}
		if (n.textContent.trim() !== '') {
			found = n
		}
	})
	return found
}

export const getClientSuitableMarkdown = (md, references?, heading_level?) => {
	// Default to empty string
	if (references == null) {
		references = {}
	}
	if (md == null) {
		md = ''
	}

	// Replace any KB links
	const pattern = /\bKB#([0-9]+)\b/g
	md.match(pattern)?.forEach(x => {
		const ID = +x.substring(3)
		const title = references[ID] ?? 'Unknown Article'
		md = md.replace(x, `[KB: ${title}](/kb/${ID}/)`)
	})

	// Obfuscate TMS links
	// - TMSS#xxx - hidden
	// - TMS#xxx - shown as non-link code block
	const p1 = new RegExp('\\[TMSS(#[0-9a-f]+)\\]', 'gi')
	const p2 = new RegExp('\\bTMS+(#[0-9a-f]+)', 'gi')
	md = md.replace(p1, '')
	md = md.replace(p2, '`$1`')

	// Return the HTML representation of the markdown
	return markdownToHTMLProxy(md, heading_level)
}

var convertWikiTable = value => {
	// Note this was taken from the following source
	// I'm fairly sure I understand how it works, but some parts may be redundant
	// > https://stackoverflow.com/questions/38593898/how-to-parse-wikitext-tables-html

	// Variables
	let attrs = ''
	let headers = ''
	let rows = ''

	// Build the table
	const renderTables = wiki => {
		wiki.replace(findTable, parseTable)
		let HTML = `<table ${attrs ?? ''}>`
		if (headers) {
			HTML += `<thead>${headers}</thead>`
		}
		if (rows) {
			HTML += `<tbody>${rows}</tbody>`
		}
		HTML += '</table>'
		attrs = headers = rows = ''
		return HTML
	}

	// Find and build - table
	var findTable = new RegExp(/\{\|\s*(.*?)\s*\|\}/, 'gs')
	var parseTable = (_match, content) => {
		Array.prototype.forEach.call(content.split(newRow), renderRow)
	}

	// Find and build - row
	var newRow = new RegExp(/\s*\|-\s*(?:\|\s*)?/, 'gs')
	var renderRow = content => {
		// console.log("tr", content, arguments)
		if (content.indexOf('=') !== -1) {
			// console.log("attrs")
			attrs += content
		} else if (content[0] === '!') {
			// console.log("th")
			headers += `<tr>${content.replace(findHeader, renderHeader)}</tr>`
		} else {
			// console.log("td")
			rows += `<tr>${content.replace(findCol, renderCol)}</tr>`
		}
	}

	// Find and replace - header
	var findHeader = new RegExp(/\s*!\s*([^!]+?)(?=\s*!|$)/, 'gs')
	var renderHeader = '<th>$1</th>'

	// Find and replace - column
	var findCol = new RegExp(/(?:^\s*|\s*\|\s*)([^\|]+?)(?=\s*\||$)/, 'gs')
	var renderCol = '<td>$1</td>'

	// Process
	return renderTables(value)
}
