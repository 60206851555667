import { timer } from '../../universal'
import { React, _ } from '../lib'
import { J2rButton } from './component-buttons'
import { j2r, reactFlyoutNew } from './component-react'
import { trionlineAjax } from './component-trionline'
import { NewSystemFlyoutInstance } from './flyouts'
import { Bindings } from './ui5'

// TODO - turn the service worker functions into a client side service worker class
// This would have instance variables and methods of the SW-specific stuff

let swRegistration: ServiceWorkerRegistration = undefined
let applicationServerKey: string = undefined
let pushSubscription: PushSubscription = undefined
let pushSubUploadRequired = false
let swReady = false

const swlog = (s, warning?) => {
	let css1, css2
	if (warning) {
		css1 = 'color:#88f'
		css2 = 'color:#f00'
	} else {
		css1 = 'color:#88f'
		css2 = 'color:#888'
	}
	console.log(`%c[SW] %c${s}`, css1, css2)
}

export const registerServiceWorker = async () => {
	// Exit early if serviceworkers are not supported
	if (navigator.serviceWorker == null) {
		console.warn('Service workers not available')
		return undefined
	}

	// Warn (but do not exit) if push messaging isn't supported
	if (window.PushManager == null) {
		console.warn('Push messaging is not supported')
	}

	// Create the service-worker and register for push notifications
	const url = '/sw-trionline' // ?_t=' + String(new Date().getTime())
	const options = { scope: '/' }

	// Register the service worker
	const sw = await navigator.serviceWorker.register(url, options)

	// Wait for the service worker to be ready
	swlog('Service worker registered, waiting...')
	swRegistration = sw // Store in global

	// Start a timer - if it's not ready within 2 seconds, it might
	// require the user to close all TriOnline tabs and open it again
	timer(2000, () => {
		if (!swReady) {
			console.warn(
				`There seems to be an issue connecting to the service worker for TriOnline. Some features may not work properly until this is resolved. Please try closing all open TriOnline tabs and re-opening TriOnline.`,
			)
		}
	})

	// Wait for it to be ready
	await navigator.serviceWorker.ready
	swReady = true
	if (navigator.serviceWorker.controller != null) {
		swlog('Service worker is ready!')
	} else {
		swlog('Service worker DISABLED - probably by a browser hard refresh')
	}
	await askPermissionNotifications()

	const prmGetSub = getSubscription()
	const prmGetAppKey = getApplicationServerKey()
	await Promise.all([prmGetSub, prmGetAppKey])
	pushSubscription = await prmGetSub
	applicationServerKey = await prmGetAppKey

	// If no permissions for notifications, warn the user
	if (Notification.permission === 'denied') {
		const txt = 'Warning: notification permission denied - push disabled'
		swlog(txt, Notification.permission === 'denied')
	} else {
		swlog('Fetched. Parsing existing subscription keys...')
	}

	// Get the application server keys of the existing and the subscriptions
	// This will allow us to work out if an unsub/resub is needed
	const existing_key = pushSubscription?.options?.applicationServerKey ?? null
	const new_key = urlBase64ToUint8Array(applicationServerKey).buffer
	const applicationServerKeys = await Promise.all([
		convertToBase64(existing_key),
		convertToBase64(new_key),
	])

	// Unsubscribe from existing push subscription if the application server
	// key has been modified - allows a new one to be made
	if (pushSubscription != null && _.uniq(applicationServerKeys).length > 1) {
		swlog('Unsubscribing from current push notifications...')
		const unsub = pushSubscription.unsubscribe()
		pushSubscription = null
		await unsub
	}

	let sub: typeof pushSubscription = null
	if (pushSubscription) {
		swlog('Fetching canonical subscription...')
		sub = await swRegistration.pushManager?.getSubscription()
	}

	// Subscribe to push notifications
	swlog('Subscribing to push notifications...')
	pushSubUploadRequired = true
	if (!sub) {
		sub = await swRegistration.pushManager?.subscribe({
			userVisibleOnly: true,
			applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
		})
	}
	pushSubscription = sub

	// Upload the push subscription details to the server, if required
	if (Notification.permission !== 'granted') {
		await emptyPromise()
	} else if (pushSubUploadRequired) {
		await uploadPushSubscription()
	} else {
		await checkPushSubscription()
	}

	if (Notification.permission === 'granted') {
		swlog('TriOnline Installation Complete')
	} else {
		swlog('TriOnline Installation Complete - No Notifications')
	}
}

const uploadPushSubscription = async () =>
	new Promise<void>(resolve => {
		swlog('Registering push subscription with server...')
		trionlineAjax({
			url: '/api/push/register/',
			method: 'post',
			data: {
				data: JSON.stringify(pushSubscription.toJSON()),
				UA: navigator?.userAgent ?? null,
			},
			yes: () => {
				resolve()
			},
			no: () => {
				console.error('Failed to upload push subscription')
				pushSubscription.unsubscribe()
			},
		})
	})

const askPermissionNotifications = async () => {
	// Check if permissions are granted and if push is enabled for this user
	const is_already_granted = Notification.permission === 'granted'
	const is_push_enabled = window.rootData?.PushEnabled ?? false

	// If it's not granted and push is disabled, just exit early
	if (!is_already_granted && !is_push_enabled) {
		return 'denied'
	}

	// If it's already granted, all good
	if (Notification.permission === 'granted') {
		return 'granted'
	}

	// If it's explicitly denied, don't call the ask API
	if (Notification.permission === 'denied') {
		return 'denied'
	}

	// Actually ask the user to choose "granted"
	return askUserForNotificationPermission()
}

// Request the response (two different APIs)
const askUserForNotificationPermission = async () => {
	swlog('Requesting push notification permission...')
	return new Promise<NotificationPermission | string>((res, rej) =>
		// Present the user with a flyout asking them to turn notifications on
		reactFlyoutNew(null, [380, 220], {
			tag: RequestNotificationForm,
			resolvePromise: res,
			rejectPromise: rej,
		}),
	)
}

const getSubscription = async (): Promise<PushSubscription | null> => {
	if (Notification.permission !== 'granted') {
		return null
	}
	swlog('Requesting push subscription information...')
	return swRegistration?.pushManager?.getSubscription()
}

const getApplicationServerKey = async () =>
	new Promise<string>((resolve, reject) =>
		trionlineAjax({
			url: '/vapid/',
			yes: app_key => {
				resolve(app_key)
			},
			no: e => {
				reject(e)
			},
		}),
	)

const urlBase64ToUint8Array = (base64String: string) => {
	// Correct inconsistent base64 formats
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
	const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

	// Decode to original bytes and convert to Uint8Array
	const rawData = window.atob(base64)
	const outputArray = new Uint8Array(rawData.length)

	// Place the characters into the array
	_.range(0, rawData.length).forEach(i => {
		outputArray[i] = rawData.charCodeAt(i)
	})
	return outputArray
}

// TODO - look into the input type here - incompatible with Uint8Array?
const convertToBase64 = async (buffer: Iterable<number> | ArrayBufferLike | any) =>
	new Promise<string | ArrayBuffer | null>(resolve => {
		// Handle null case
		if (buffer == null) {
			resolve(null)
		}

		// Create a reader that will resolve the promise once decoded
		const reader = new FileReader()
		reader.addEventListener('loadend', e => {
			const text = e.target.result
			resolve(text)
		})

		// Read the blob
		reader.readAsText(new Blob([new Uint8Array(buffer)]))
	})

export const clearServiceWorkerCache = async () => {
	const x = await sendServiceWorkerMessage({ type: 'clear-cache' })
	swlog('Sent', x)
}
export const cacheFileServiceWorker = async (
	url: string,
	blob64: string,
	mime: string,
	force_dl: boolean,
	cb: () => void,
) => {
	const args = {
		type: 'add-to-cache',
		url,
		blob64,
		mime,
		force_dl,
	}
	try {
		await sendServiceWorkerMessage(args)
		cb()
		return
	} catch (x) {
		console.error('Failed', x)
		return
	}
}

export const cacheFileServiceWorkerPromise = async (
	url: string,
	blob64: string,
	mime: string,
	force_dl: boolean,
) =>
	new Promise<void>(resolve => {
		cacheFileServiceWorker(url, blob64, mime, force_dl, () => {
			resolve()
		})
	})

export const viewCachedFiles = async () =>
	new Promise(async resolve => {
		const x: any = await sendServiceWorkerMessage({ type: 'view-cache' })
		resolve(JSON.parse(x))
	})

export const viewCachedFilesTable = async () => {
	const data: any = await viewCachedFiles()
	console.table(_.sortBy(data, (x: any) => x.size))
	swlog(_.sumBy(data, 'size'))
}

const sendServiceWorkerMessage = async msg =>
	new Promise((resolve, reject) => {
		const msgChan = new MessageChannel()
		msgChan.port1.onmessage = ev => {
			if (ev.data.error) {
				reject(ev.data.error)
				return
			}
			resolve(ev.data)
		}
		return navigator.serviceWorker.controller?.postMessage(msg, [msgChan.port2])
	})

export const unsubscribe = async () => {
	const sub = await getSubscription()
	if (sub) {
		const success = await sub.unsubscribe()
		if (success) {
			swlog('Unsubscribed from push notifications')
		} else {
			swlog('Error unsubscribing from push notifications')
		}
	}
}

const checkPushSubscription = async (): Promise<void> => {
	// Checks the current subscription against the server to see if it's active
	// If it's not, then requests a new one
	const endpoints = await getExistingPushEndpoints()
	if (endpoints == null) {
		swlog('Push subscriptions disabled - unavailable')
	} else if (endpoints.indexOf(pushSubscription?.endpoint) === -1) {
		swlog('Requesting a new subscription')
		await requestNewPushSubscription()
	}
}

const getExistingPushEndpoints = async () =>
	new Promise<string[] | null>((resolve, reject) => {
		// If it's a 2018 frame, it's already here, just return that
		const existing = window.rootData?.PushEndpoints
		if (existing != null) {
			resolve(existing)
			return
		}

		// Otherwise we need to do a separate GET call to retrieve them
		trionlineAjax({
			url: '/api/push/get/',
			no: x => {
				reject(x)
			},
			yes: x => {
				try {
					const endpoints = JSON.parse(x) as string[]
					resolve(endpoints)
				} catch (error) {
					console.warn('No push registrations available - server unreachable')
					resolve(null)
				}
			},
		})
	})

const requestNewPushSubscription = async () => {
	const sub = await getSubscription()
	await sub?.unsubscribe()
	await swRegistration.pushManager?.subscribe({
		userVisibleOnly: true,
		applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
	})
	pushSubscription = sub
	await uploadPushSubscription()
}

var emptyPromise = async () => new Promise((x: Function) => x())

// TODO: fix state type
type requestNotificationFormProps = {
	resolvePromise: (result: NotificationPermission | string) => void
	rejectPromise: (reason: any) => void
	flyout: NewSystemFlyoutInstance
}
type requestNotificationFormState = {
	accepting: boolean
}
class RequestNotificationForm extends React.Component<
	requestNotificationFormProps,
	requestNotificationFormState
> {
	constructor(props) {
		super(props)
		Bindings(this, [this.accept, this.reject])
		this.state = { accepting: false }
	}

	override render() {
		return j2r(() => {
			if (this.state.accepting) {
				return this.buildDirectionalPrompt()
			}
			return this.buildSalesPitch()
		})
	}

	buildSalesPitch() {
		return {
			children: [
				{
					tag: 'p',
					key: 'desc',
					text: `\
We'd like to send you notifications about updates to your roster. \
Some urgent notifications may be sent via SMS instead. If you do \
not wish to receive push notifications from TriOnline, you will need \
to check this app periodically for updates instead.\
`,
				},
				{
					cl: 'buttons cntr',
					key: 'buttons',
					children: [
						{
							tag: J2rButton,
							key: 'agree',
							label: 'Agree',
							type: 'submit',
							onClick: this.accept,
						},
						{
							tag: J2rButton,
							key: 'disagree',
							label: 'Disagree',
							type: 'standard',
							onClick: this.reject,
						},
					],
				},
			],
		}
	}

	buildDirectionalPrompt() {
		return {
			tag: 'p',
			key: 'ty-text',
			text: `\
Thank you. Your browser / device should now be prompting you to accept. \
Click or tap on "Allow" to complete this one-time process.\
`,
		}
	}

	async accept() {
		swlog('Presenting browser confirmation prompt for push notifications')
		await this.askViaBrowser()
		this.setState({ accepting: true })
	}

	reject() {
		swlog('Rejecting push notifications for now')
		this.props.resolvePromise('denied')
		this.props.flyout.Close()
	}

	async askViaBrowser() {
		const permissionResult = await Notification.requestPermission()
		this.props.resolvePromise(permissionResult)
		this.props.flyout.Close()
	}
}
