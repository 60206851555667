/**
 * This wraps the `moment` function so that it can also take a date object (luxon).
 * This is just for backward compatibility
 */
import { DateObj, DateTimeObj } from '../../universal'
import { _, momentJSRaw } from '../lib'

/** @deprecated Use DateObj or DateTimeObj */
export function moment(
	inp?: momentJSRaw.MomentInput,
	strict?: boolean,
): momentJSRaw.Moment

/** @deprecated Use DateObj or DateTimeObj */
export function moment(
	inp?: momentJSRaw.MomentInput,
	format?: momentJSRaw.MomentFormatSpecification,
	strict?: boolean,
): momentJSRaw.Moment

/** @deprecated Use DateObj or DateTimeObj */
export function moment(
	inp?: momentJSRaw.MomentInput,
	format?: momentJSRaw.MomentFormatSpecification,
	language?: string,
	strict?: boolean,
): momentJSRaw.Moment

/** @deprecated Use DateObj or DateTimeObj */
export function moment(inp: DateObj | DateTimeObj): momentJSRaw.Moment

/** @deprecated Use DateObj or DateTimeObj */
export function moment(
	inp?: momentJSRaw.MomentInput | DateObj | DateTimeObj,
	formatOrStrict?: momentJSRaw.MomentFormatSpecification | boolean,
	languageOrStrict?: string | boolean,
	strict?: boolean,
): momentJSRaw.Moment {
	if (inp instanceof DateObj || inp instanceof DateTimeObj) {
		return momentJSRaw(inp.raw)
	}
	if (_.isBoolean(formatOrStrict)) {
		return momentJSRaw(inp, formatOrStrict)
	}
	if (_.isBoolean(languageOrStrict)) {
		return momentJSRaw(inp, formatOrStrict, languageOrStrict)
	}
	return momentJSRaw(inp, formatOrStrict, languageOrStrict, strict)
}
