import { Do, Maybe, clamp } from '../../universal'
import { $, React, _ } from '../lib'
import { J2rButton } from './component-buttons'
import { J2rComboBox } from './component-combobox'
import { j2h } from './component-j2h'
import { j2r } from './component-react'
import { cacheFileServiceWorkerPromise } from './component-sw-client'
import { moment } from './moment-wrapper'
import { Bindings, ConditionalObject } from './ui5'

declare let pdfjsLib: any
declare let pdfjsViewer: any

export const cachePDFFile = async (
	hashCode: string,
	output: string,
	name: Maybe<string>,
	cb: (url: string) => void,
) => cacheFile(hashCode, output, 'pdf', 'application/pdf', name, cb)

export const cacheXLSXFile = async (
	hashCode: string,
	output: string,
	name: Maybe<string>,
	cb: (url: string) => void,
) => {
	const mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	return cacheFile(hashCode, output, 'xlsx', mime, name, cb)
}

export const cacheCSVFile = async (
	hashCode: string,
	output: string,
	name: Maybe<string>,
	cb: (url: string) => void,
) => cacheFile(hashCode, output, 'csv', 'text/csv', name, cb)

export const cache7ZFile = async (
	hashCode: string,
	output: string,
	name: Maybe<string>,
	cb: (url: string) => void,
) => cacheFile(hashCode, output, '7z', 'application/x-7z-compressed', name, cb)

export const cacheZipFile = async (
	hashCode: string,
	output: string,
	name: Maybe<string>,
	cb: (url: string) => void,
) => cacheFile(hashCode, output, 'zip', 'application/zip', name, cb)

export var cacheFile = async (
	hashCode: string,
	output: string,
	extension: string,
	mimetype: string,
	name: string,
	cb: (url: string) => void,
) => {
	// Save the PDF data to the service-worker cache
	// The URL will 404 if it goes to the server, but the SW will intercept
	// all calls to this specific URL (based on file hash code) and return
	// the pre-downloaded PDF report

	// Get the timestamp to make unique URLs
	// const ts = Math.floor(moment().valueOf() / 1000)

	// A friendly timestamp to prepend the actual filename
	const tsFriendly = moment().format('YYYYMMDD-HHmmss')
	let basename = `${tsFriendly}.${extension}`
	if (name != null) {
		basename = `${name}-${basename}`
	}

	// Create the URLs for both the inline and attachment versions
	const url_inline = `/rptcache/inline/${hashCode}/${basename}`
	const url_attach = `/rptcache/attach/${hashCode}/${basename}`

	// Create the promises to cache both files
	const promises = Promise.all([
		cacheFileServiceWorkerPromise(url_inline, output, mimetype, false),
		cacheFileServiceWorkerPromise(url_attach, output, mimetype, true),
	])

	// When both files are cached, return the callback with the inline URL
	return promises
		.then(() => {
			cb(url_inline)
		})
		.catch(err => {
			console.error(err)
		})
}

// TODO: fix state type
export class CustomPDFContainer extends React.Component<{ url?: string }, any> {
	pdfRenderer: React.RefObject<CustomPDFRenderer>

	constructor(props) {
		super(props)
		Bindings(this, [this.resize, this.updatePages])
		this.pdfRenderer = React.createRef()
		this.state = {
			url: this.props.url ?? null,
			zoom: 'auto',
			zoomText: 'Automatic Zoom',
			showNewComponent: Do(() => {
				// Read the local storage variable
				const useOld = +localStorage.loadNativePDFViewer || 0
				return useOld === 0
			}),
		}
	}

	override componentDidUpdate() {
		// If the URL has changed, then it will need to be rebuild
		if (this.state.url !== this.props.url) {
			this.setState({ url: this.props.url })
		}
	}

	// Builders #

	override render() {
		return j2r({
			cl: 'pdfjs-container-full',
			children: [
				this.buildToolbar(),
				this.state.showNewComponent
					? {
							tag: CustomPDFRenderer,
							key: 'pdf',
							ref: this.pdfRenderer,
							url: this.state.url,
							resize: this.resize,
							updatePages: this.updatePages,
						}
					: {
							tag: 'iframe',
							key: 'old-pdf',
							src: this.state.url,
						},
			],
		})
	}

	buildToolbar() {
		return {
			key: 'toolbar',
			cl: 'toolbar',
			children: [
				// Zoom buttons only show for new component
				this.state.showNewComponent ? this.buildZoom() : undefined,

				// Pop-out button only shows if looking at the old component
				ConditionalObject(!this.state.showNewComponent, {
					tag: J2rButton,
					key: 'print',
					label: '❏ Pop-out',
					title: 'Opens the PDF in another window - generally so it can be printed',
					onClick: () => window.open(this.state.url, '_blank'),
				}),

				// Download button always shows
				{
					tag: J2rButton,
					key: 'download',
					label: '🠇 Download',
					title: 'Download a copy of the PDF to your local machine',
					onClick: () => {
						const url = this.state.url.replace(/inline/, 'attach')
						// window.open(url, '_self')
						window.open(url, '_blank')
					},
				},

				// Toggle view button always showw
				{
					tag: J2rButton,
					key: 'toggle-view',
					label: Do(() => {
						if (this.state.showNewComponent) {
							return '⎗ Print View'
						}
						return '⎗ Display View'
					}),
					title: `Toggles the inline PDF viewer between the built-in browser one and the TriOnline one. Only the built-in browser one can print.`,
					onClick: () => {
						const newVal = !this.state.showNewComponent
						localStorage.loadNativePDFViewer = newVal ? 0 : 1
						this.setState({ showNewComponent: newVal })
					},
				},

				// Pages only show for new component
				this.state.showNewComponent ? this.buildPages() : undefined,

				// Printing information only shows for old component
				ConditionalObject(!this.state.showNewComponent, {
					key: 'print-msg',
					cl: 'print-msg',
					text: 'Use browser PDF viewer to print',
				}),
			],
		}
	}

	buildZoom() {
		return {
			cl: 'zoom',
			key: 'zoom',
			children: [
				{
					tag: J2rButton,
					cl: 'zoom',
					key: 'zoom-out',
					label: '‒',
					title: 'Zooms out - makes the PDF smaller',
					onClick: () => {
						this.updateZoom(this.pdfRenderer.current.changeZoomLevelDelta(-1))
					},
				},
				{
					tag: J2rButton,
					cl: 'zoom',
					key: 'zoom-in',
					label: '🞡',
					title: 'Zooms in - makes the PDF bigger',
					onClick: () => {
						this.updateZoom(this.pdfRenderer.current.changeZoomLevelDelta(+1))
					},
				},
				{
					tag: J2rComboBox,
					key: 'preset-zoom-selector',
					value: this.state.zoom,
					textView: () => this.state.zoomText,
					options: this.zoomOptions().map(x => ({
						value: x[0],
						text: x[1],
					})),
					onUpdate: v => {
						this.updateZoom(this.pdfRenderer.current.changeZoomLevel(v.value))
					},
				},
			],
		}
	}

	buildPages() {
		return {
			cl: 'pages',
			key: 'pages',
			children: [
				{
					tag: 'span',
					key: 'pageCount',
					text: this.state.page ?? '-',
				},
				{
					tag: 'span',
					key: 'separator',
					cl: 'separator',
					text: '/',
				},
				{
					tag: 'span',
					key: 'pageTotal',
					text: this.state.pageTotal ?? '-',
				},
			],
		}
	}

	// Events and helpers #

	updatePages(page, pageTotal) {
		// Update the total number of pages
		if (this.state.pageTotal !== pageTotal) {
			this.setState({ pageTotal })
		}

		// Update the current page
		if (this.state.page !== page) {
			this.setState({ page })
		}
	}

	resize() {
		const currZoom = this.state.zoom
		const cmpt = this.pdfRenderer.current
		cmpt?.changeZoomLevel(1)
		cmpt?.changeZoomLevel(currZoom)
	}

	zoomOptions() {
		return [
			['auto', 'Automatic Zoom'],
			['page-actual', 'Actual Size'],
			['page-fit', 'Page Fit'],
			['page-width', 'Page Width'],
			['0.5', '50%'],
			['0.75', '75%'],
			['1', '100%'],
			['1.25', '125%'],
			['1.5', '150%'],
			['2', '200%'],
			['3', '300%'],
			['4', '400%'],
		]
	}

	updateZoom(v) {
		// Get the zoom options as an object/dictionary
		const Z1 = this.zoomOptions()
		const Z2 = _.keyBy(Z1, x => x[0])
		const Z3 = _.mapValues(Z2, x => x[1])

		// Get the text to show in the dropdown for this zoom level
		const title = Do(() => {
			let needle
			if (((needle = v), _.keys(Z3).includes(needle))) {
				// If the new value is in the preset options, set the text to its value
				return Z3[v]
			} else if (isNaN(+v)) {
				// It's an unknown non-numeric value
				return `Unknown: ${v}`
			}
			// Show it as a zoom percentage
			return `${Math.round(+v * 100)}%`
		})

		// Set the value and the text
		this.setState({
			zoom: v,
			zoomText: title,
		})
	}
}

class CustomPDFRenderer extends React.Component<
	{
		url?: string
		resize?: Function
		updatePages?: Function
	},
	any
> {
	containerparent: React.RefObject<HTMLDivElement>
	mounted: boolean

	constructor(props) {
		super(props)
		Bindings(this, [this.updatePagePosition])
		this.containerparent = React.createRef()
		this.state = {
			zoom: 'auto',
			pdfViewer: null,
			pdfDocument: null,
		}
	}

	// Component state management #

	override componentDidMount() {
		this.mounted = true
		const { zoom } = this.state
		this.changeZoomLevel(1)
		this.changeZoomLevel(zoom)
		this.buildNewReport()
	}

	override componentWillUnmount() {
		this.mounted = false
		if (this.state.pdfViewer && this.state.pdfDocument) {
			this.state.pdfDocument.destroy()
		}
	}

	override componentDidUpdate(prevProps, prevState) {
		// Skip if it's not mounted anymore
		if (!this.mounted) {
			return
		}

		// If the URL has changed then it will need to be rebuilt
		if (prevProps.url !== this.props.url) {
			this.setState({ pdfViewer: null }, () => {
				this.buildNewReport()
			})
			return
		}

		// Change the zoom state
		if (prevState.zoom !== this.state.zoom) {
			if (this.state.pdfViewer != null) {
				this.state.pdfViewer.currentScaleValue = this.state.zoom
			}
			return
		}
	}

	override render() {
		return j2r({
			cl: 'pdfjs-container canselect',
			ref: this.containerparent,
			onScroll: _.debounce(this.updatePagePosition, 200),
		})
	}

	// Building report and updating state #

	buildNewReport() {
		// This builds a new report but ensures that the old one is disposed first

		// Skip if dismounted
		if (!this.mounted) {
			return
		}

		// If nothing exists yet, just run it synchronously
		if (this.state.pdfDocument == null) {
			this.buildNewReportInner()
			return
		}

		// Destroy and then build once completed
		this.state.pdfDocument.destroy().then(() => {
			this.buildNewReportInner()
		})
	}

	buildNewReportInner() {
		// If there's no URL or reference state, skip
		// If the component isn't mounted, also cancel
		if (!this.props.url || this.containerparent.current == null) {
			return
		}
		if (!this.mounted) {
			return
		}

		// Pre-prepare the container
		// Get the container and its dimensions
		const container = $(this.containerparent.current).empty()[0]
		$(container).append(j2h({ class: 'pdfjs-container-inner' }))

		// Ensure that the worker location is set
		const path =
			'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.min.js'
		pdfjsLib.GlobalWorkerOptions.workerSrc = path

		// (Optionally) enable hyperlinks within PDF files
		// (Optionally) enable find controller
		const pdfLinkService = new pdfjsViewer.PDFLinkService()
		const pdfFindController = new pdfjsViewer.PDFFindController({
			linkService: pdfLinkService,
		})

		// Build the viewer
		const pdfViewer = new pdfjsViewer.PDFViewer({
			container,
			linkService: pdfLinkService,
			findController: pdfFindController,
		})
		pdfLinkService.setViewer(pdfViewer)

		// Event handler for the widget loading
		pdfViewer.eventBus.on('pagesinit', () => {
			// We can use pdfViewer now, e.g. let's change default scale.
			if (this.mounted) {
				this.setState({ pdfViewer }, () => {
					this.onInit()
				})
			}
		})

		// Read the document
		pdfjsLib
			.getDocument(this.props.url)
			.then(pdfDocument => {
				// Exit early if the component dismounted
				if (!this.mounted) {
					return
				}

				// Store the doc on the state and update the page counters
				this.setState({ pdfDocument })
				this.props.updatePages(1, pdfDocument.numPages)

				// Loaded, specifying document for the viewer and (optional) linkService
				pdfViewer.setDocument(pdfDocument)
				pdfLinkService.setDocument(pdfDocument, null)
			})
			.catch(err => {
				console.error(err)
			})
	}

	onInit() {
		// Set the initial zoom
		if (this.mounted) {
			try {
				this.props.resize()
				this.state.pdfViewer.currentScaleValue = this.state.zoom
			} catch (error) {}
		}
	}

	changeZoomLevel(amt) {
		if (this.mounted) {
			amt = amt || this.state.zoom
			if (!isNaN(+amt)) {
				amt = String(clamp(+amt, 0.1, 4.0))
			}
			this.setState({ zoom: amt })
		}
		return amt
	}

	changeZoomLevelDelta(delta) {
		// Get the current percentage (rounded to whole percentage point)
		const currPercentage = Math.round(this.state.pdfViewer.currentScale * 100)

		// Determine the amount to modify per delta tick
		const modifier = Math.floor(currPercentage / 100) * 10 + 10

		// Calculate the new zoom level and convert to decimal form to one decimal place
		const new_zoom = Math.round(currPercentage + modifier * delta)
		let new_zoom_rounded = Math.floor(new_zoom / 10) / 10

		// Clamp the zoom value between the min and max allowable
		new_zoom_rounded = clamp(new_zoom_rounded, 0.1, 4.0)

		// Update the zoom level and return the value for other component use
		this.setState({ zoom: new_zoom_rounded })
		return new_zoom_rounded
	}

	updatePagePosition() {
		const pageNumber = this.state.pdfViewer?.currentPageNumber
		const pageTotal = this.state.pdfDocument.numPages
		this.props.updatePages(pageNumber, pageTotal)
	}
}
