import { Maybe, ReportJob, fsmData } from '../../../../universal'
import { _ } from '../../../lib'
import { Alerts } from '../../component-main'
import { reactForceAllUpdate } from '../../component-react'
import { FormType, openFlyoutForm } from '../form'
import {
	RJMProps,
	RJMSerialisedState,
	RJMState,
	ReducerState,
	ReducerStateD,
	ReportJobInternal,
	ReportJobReportPending,
} from './types'

export enum Action {
	UpdateReportJob,
	UpdateOuterWidths,
	UpdateInnerWidths,
	AddReport,
	DeleteReport,
	UpdateReport,
}

export type Payload =
	| [
			Action.UpdateReportJob,
			Maybe<number>,
			(rj: ReportJobInternal) => Partial<ReportJobInternal>,
	  ]
	| [Action.UpdateOuterWidths, Maybe<number>[] | null]
	| [Action.UpdateInnerWidths, Maybe<number>[] | null]
	| [Action.AddReport, { rj: number }]
	| [Action.DeleteReport, { rj: number; report: number }]
	| [
			Action.UpdateReport,
			{
				rj: number
				rpt: number
				update: (s: ReportJobReportPending) => Partial<ReportJobReportPending>
			},
	  ]

export const getDefaultState = (props: RJMProps): RJMState => ({
	selectedReportJob: props.view?.selectedReportJob,
	selectedReport: props.view?.selectedReport,
	searchText: props.view?.searchText ?? '',
	compactView: props.view?.compactView ?? false,
	reportJobs: props.reportJobs,
	widthsOuter: props.view?.widthsOuter ?? null,
	widthsInner: props.view?.widthsInner ?? null,
	isLoading: {},
	errorMessage: {},
	cache: null,
})

export const getPartialStateFromAction = (
	rs: ReducerState,
	p: Payload,
): Maybe<Partial<RJMState>> => {
	switch (p[0]) {
		// Update the raw state of a report job - abstracts the splat nesting
		case Action.UpdateReportJob:
			const rj_key = p[1]
			const curr = rs.state.reportJobs[rj_key ?? -1]
			if (!rj_key || !curr) {
				return {}
			}
			return {
				reportJobs: {
					...rs.state.reportJobs,
					[rj_key]: {
						...curr,
						...p[2](curr),
					},
				},
			}

		// Update resize splitter widths
		case Action.UpdateOuterWidths:
			return { widthsOuter: p[1] }
		case Action.UpdateInnerWidths:
			return { widthsInner: p[1] }

		// Adding a new report to the report job
		case Action.AddReport:
			const rjID = p[1].rj
			const rj = rs.state.reportJobs[rjID]
			if (!rj) {
				return {}
			}
			const lowestNewID = fsmData(rj.Reports, {
				filter: x => x.ID < 0,
				sort: x => x.ID,
				map: x => x?.ID ?? 0,
				takeFirst: true,
			})
			const newID = lowestNewID - 1
			return {
				selectedReportJob: rjID,
				selectedReport: newID,
				reportJobs: {
					...rs.state.reportJobs,
					[rjID]: {
						...rj,
						Reports: {
							...rj.Reports,
							[newID]: {
								ID: newID,
								ReportJob: rjID,
								ExportType: 1,
								Name: '',
								Type: null,
								Params: {},
								OverriddenParams: [],
							},
						},
					},
				},
			}

		// Remove a report from the report job
		case Action.DeleteReport:
			const rj2 = rs.state.reportJobs[p[1].rj]
			if (!rj2) {
				return {}
			}
			return {
				selectedReport: null,
				reportJobs: {
					...rs.state.reportJobs,
					[rj2.ID]: {
						...rj2,
						Reports: _.omit(rj2.Reports, p[1].report),
					},
				},
			}

		// Update details about a report
		case Action.UpdateReport:
			const rj3 = rs.state.reportJobs[p[1].rj]
			const report = rj3?.Reports[p[1].rpt ?? 0]
			if (!rj3 || !report) {
				return {}
			}
			return {
				reportJobs: {
					...rs.state.reportJobs,
					[rj3.ID]: {
						...rj3,
						Reports: {
							...rj3.Reports,
							[p[1].rpt]: {
								...report,
								...p[1].update(report),
							},
						},
					},
				},
			}
	}
}

export const sendOnUpdate = (
	rs: ReducerState,
	delta?: Partial<RJMSerialisedState>,
): void => {
	requestAnimationFrame(() => {
		rs.props.onUpdate({
			selectedReportJob: rs.state.selectedReportJob,
			selectedReport: rs.state.selectedReport,
			searchText: rs.state.searchText,
			compactView: rs.state.compactView,
			widthsOuter: rs.state.widthsOuter ?? null,
			widthsInner: rs.state.widthsInner ?? null,
			...delta,
		})
	})
}

export const actionAddReportJob = (rs: ReducerStateD) => {
	openFlyoutForm({
		title: 'Add Report Job',
		size: [320, null],
		fields: {
			name: FormType.Text({
				lbl: 'Name',
				doc: 'The name of the report job',
				autoCapitalize: true,
				minLength: 5,
				maxLength: 30,
			}),
		},
		formPrompt: 'The user is adding a new report job',
		lblWidth: 60,
		onSave: (model, callback) => {
			rs.props.onAdd(model.name, IDorError => {
				if (_.isString(IDorError)) {
					callback(IDorError)
					return
				}
				reactForceAllUpdate()
				callback(true)
				updateStateWhenDefined(
					() => rs.props.reportJobs[IDorError],
					rj => {
						rs.updateState(s => ({
							selectedReportJob: IDorError,
							selectedReport: null,
							reportJobs: {
								...s.reportJobs,
								[IDorError]: rj,
							},
						}))
					},
				)
			})
		},
	})
}

const updateStateWhenDefined = <T,>(
	getDefinedValue: () => Maybe<T>,
	update: (item: T) => void,
	count?: number,
) => {
	const item = getDefinedValue()
	if (!item) {
		if ((count ?? 0) > 10) {
			console.error(
				'Took more than 10 animation frames to get defined value - aborting.',
			)
			return
		}
		requestAnimationFrame(() => {
			updateStateWhenDefined(getDefinedValue, update, (count ?? 0) + 1)
		})
		return
	}
	update(item)
}

export const actionAttemptDelete = (rs: ReducerStateD, rj: number): void => {
	Alerts.Confirm({
		msg: 'Are you sure you want to delete this report job?',
		yes: () => {
			// Set as loading and clear the error message
			rs.updateState(() => ({
				isLoading: {
					...rs.state.isLoading,
					[rj]: true,
				},
				errorMessage: {
					...rs.state.errorMessage,
					[rj]: '',
				},
			}))

			// Run application-defined delete function
			rs.props.onDelete(rj, (goodOrError: true | string) => {
				// No longer loading
				rs.updateState(() => ({
					isLoading: {
						...rs.state.isLoading,
						[rj]: false,
					},
				}))

				// Display an error message if one supplied
				if (goodOrError !== true) {
					rs.updateState(() => ({
						errorMessage: {
							...rs.state.errorMessage,
							[rj]: goodOrError,
						},
					}))
					return
				}

				// Reset the state and clear the selected report job
				reactForceAllUpdate()
				rs.updateState(s => ({
					selectedReportJob: null,
					selectedReport: null,
					reportJobs: _.omitBy(s.reportJobs, x => x.ID == rj),
				}))
			})
		},
	})
}

export const actionAttemptSave = (rs: ReducerStateD, rj: number): void => {
	// Get the original and current report objects
	const rj_original = rs.props.reportJobs[rj ?? -1]
	const rj_internal = rs.state.reportJobs[rj ?? -1]
	if (!rj_internal || !rj_original) {
		return
	}

	// Determine which reports have been deleted
	const deletedReports = _.difference(
		_.map(rj_original.Reports, x => x.ID),
		_.map(rj_internal.Reports, x => x.ID),
	)

	// Confirm that there are no duplicate {name, exportType} pairs
	const duplicate = _.chain(rj_internal.Reports)
		.map(x => getFilename(x))
		.groupBy()
		.map((v, k) => [k, v.length] as const)
		.filter(x => x[1] > 1)
		.map(x => x[0])
		.first()
		.value()
	if (duplicate != null) {
		rs.updateState(s => ({
			errorMessage: {
				...s.errorMessage,
				[rj ?? -1]: `Duplicate filename "${duplicate}"`,
			},
		}))
		return
	}

	// The difference between a `ReportJob` and `ReportJobInternal`
	// is that the latter as nullable report `Type` and includes `Deleted`
	// Verfiy that the report types are defined so we can convert types
	const reportJob: ReportJob = {
		...rj_internal,
		Reports: _.mapValues(rj_internal.Reports, rpt => ({
			...rpt,
			Type: rpt.Type ?? '',
		})),
	}

	// Set loading spinner and clear error message
	rs.updateState(s => ({
		isLoading: {
			...s.isLoading,
			[rj ?? -1]: true,
		},
		errorMessage: {
			...s.errorMessage,
			[rj ?? -1]: '',
		},
	}))

	// Run the application-level save function
	rs.props.onSave(reportJob, deletedReports, goodOrError => {
		// No longer loading
		rs.updateState(s => ({
			isLoading: {
				...s.isLoading,
				[rj ?? -1]: false,
			},
		}))

		// Display an error message if one supplied
		if (goodOrError !== true) {
			rs.updateState(s => ({
				errorMessage: {
					...s.errorMessage,
					[rj ?? -1]: goodOrError,
				},
			}))
			return
		}

		// Reset the state for the selected report job to match props
		reactForceAllUpdate()
		requestAnimationFrame(() => {
			updateStateWhenDefined(
				() => rs.props.reportJobs[rj ?? -1],
				rjObj => {
					rs.updateState(s => ({
						selectedReport:
							!s.selectedReport || s.selectedReport < 0
								? null
								: s.selectedReport,
						reportJobs: {
							...s.reportJobs,
							[rj ?? -1]: rjObj,
						},
					}))
				},
			)
		})
	})
}

const getFilename = (rj: ReportJobReportPending): string => {
	const name = rj.Name.replace(/[^a-zA-Z0-9-]/g, '-')
	const ext_map = { 1: 'pdf', 2: 'xlsx', 3: 'csv' }
	const ext = ext_map[rj.ExportType]
	return `${name}.${ext}`
}
