import { BuildClass, Maybe } from '../../../universal'
import { React, _ } from '../../lib'
import { validateTime } from '../component-main'
import { Focusable } from './meta-types'
import { stubFocusable, stubInput, stubTextarea } from './stubs'

/** Valid parameters for HTML input elements */
type inputBaseProps = Omit<
	React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	>,
	'ref' | 'onChange'
>

/** List of valid border types - defaults to underlined */
type textboxBorderTypes = Maybe<'underlined' | 'boxed' | 'borderless'>

type textboxProps = {
	value: string
	onUpdate: (value: string) => void
	border?: textboxBorderTypes
	fixOnBlur?: (value: string) => string
	serialize?: (value: string) => string
	deserialize?: (valueString: string) => string
	selectOnClick?: boolean
}

export type extraInputProps = Omit<inputBaseProps, keyof textboxProps>

/**
 * Basic textbox with a string value
 * @param value - The value of the textbox
 * @param onUpdate - Event when the text is mutated
 * @param fixOnBlur - State value mapping function when textbox loses focus
 * @param serialize - Optional override of mapping prop to state
 * @param deserialize - Optional override of mapping state back to prop
 * @prop Also allows any prop that is valid on a HTML `input` element
 */
export const TextboxComponent = (
	props: textboxProps & extraInputProps,
	ref: React.ForwardedRef<Focusable<HTMLInputElement>>,
) => {
	// Ref forward
	const refInput = React.useRef<Focusable<HTMLInputElement>>(stubFocusable(stubInput))
	React.useImperativeHandle(ref, () => refInput.current)

	// Render
	return (
		<TextboxGeneric<string>
			ref={refInput}
			value={props.value ?? ''}
			onUpdate={props.onUpdate}
			border={props.border}
			fixOnBlur={props.fixOnBlur ?? _.identity}
			serialize={props.serialize ?? String}
			deserialize={props.deserialize ?? String}
			selectOnClick={props.selectOnClick ?? false}
			{..._.omit(props, [
				'value',
				'onUpdate',
				'border',
				'fixOnBlur',
				'serialize',
				'deserialize',
				'selectOnClick',
				'ref',
			])}
		/>
	)
}
export const Textbox = React.forwardRef(TextboxComponent)

type textboxNumericProps = {
	value: number
	onUpdate: (value: number) => void
	border?: textboxBorderTypes
	fixOnBlur?: (value: number) => string
	serialize?: (value: number) => string
	deserialize?: (valueString: string) => number
	selectOnClick?: boolean
}

/**
 * Basic textbox with a numeric prop value.
 * Identical to `Textbox` but the default serializer convert to a 2DP number
 * @param value - The value of the textbox
 * @param onUpdate - Event when the text is mutated
 * @param fixOnBlur - State value mapping function when textbox loses focus
 * @param serialize - Optional override of mapping prop to state
 * @param deserialize - Optional override of mapping state back to prop
 * @prop Also allows any prop that is valid on a HTML `input` element
 */
export const TextboxNumeric = (
	props: textboxNumericProps & Omit<inputBaseProps, keyof textboxNumericProps>,
) => (
	<TextboxGeneric<number>
		value={props.value}
		onUpdate={props.onUpdate}
		border={props.border}
		fixOnBlur={props.fixOnBlur ?? (v => v.toFixed(2))}
		serialize={props.serialize ?? (v => String(v))}
		deserialize={props.deserialize ?? (s => +s)}
		selectOnClick={props.selectOnClick ?? false}
		{..._.omit(props, [
			'value',
			'onUpdate',
			'border',
			'fixOnBlur',
			'serialize',
			'deserialize',
			'selectOnClick',
			'ref',
		])}
	/>
)

type textboxTimeProps = {
	value: string
	onUpdate: (value: string) => void
	border?: textboxBorderTypes
	fixOnBlur?: (value: string) => string
	serialize?: (value: string) => string
	deserialize?: (valueString: string) => string
	selectOnClick?: boolean
}

/**
 * Basic textbox that validates output as a time on blur
 * @param value - The value of the textbox
 * @param onUpdate - Event when the text is mutated
 * @param fixOnBlur - State value mapping function when textbox loses focus
 * @param serialize - Optional override of mapping prop to state
 * @param deserialize - Optional override of mapping state back to prop
 * @prop Also allows any prop that is valid on a HTML `input` element
 */
export const TextboxTime = React.forwardRef(
	(
		props: textboxTimeProps & Omit<inputBaseProps, keyof textboxTimeProps>,
		ref: React.ForwardedRef<Focusable<HTMLInputElement>>,
	) => (
		<TextboxGeneric<string>
			ref={ref}
			value={props.value}
			onUpdate={props.onUpdate}
			border={props.border}
			fixOnBlur={props.fixOnBlur ?? validateTime}
			serialize={props.serialize ?? _.identity}
			deserialize={props.deserialize ?? _.identity}
			placeholder={props.placeholder ?? 'hh:mm'}
			selectOnClick={props.selectOnClick ?? false}
			{..._.omit(props, [
				'value',
				'onUpdate',
				'border',
				'fixOnBlur',
				'serialize',
				'deserialize',
				'placeholder',
				'selectOnClick',
				'ref',
			])}
		/>
	),
)

/**
 * Generic, base textbox component requiring custom serialization
 * @param value - The value of the textbox
 * @param onUpdate - Event when the text is mutated
 * @param fixOnBlur - State value mapping function when textbox loses focus
 * @param serialize - Optional override of mapping prop to state
 * @param deserialize - Optional override of mapping state back to prop
 * @prop Also allows any prop that is valid on a HTML `input` element
 */
const TextboxGenericComponent = <T extends unknown>(
	props: {
		value: T
		onUpdate: (value: T) => void
		border?: textboxBorderTypes
		fixOnBlur: (value: T) => string
		serialize: (value: T) => string
		deserialize: (valueString: string) => T
		selectOnClick: boolean
	} & inputBaseProps,
	ref: React.ForwardedRef<Focusable<HTMLInputElement>>,
) => {
	// Default the serialization functions
	const serialize = props.serialize ?? _.identity
	const deserialize = props.deserialize ?? _.identity

	// Ref
	const refInput = React.useRef<HTMLInputElement>(stubInput)

	// Cache the change function - runs the `onUpdate` event
	const onChange = React.useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>) => {
			const nVal = deserialize(ev.target.value)
			const origValue = deserialize(serialize(props.value))
			if (nVal != origValue) {
				props.onUpdate(nVal)
			}
		},
		[props.value, props.onUpdate, deserialize],
	)

	// Cache the blur function - runs the `fixOnBlur` transform
	const onBlur = React.useCallback(
		(ev: React.FocusEvent<HTMLInputElement>) => {
			props.onBlur?.(ev)
			const nVal = deserialize(props.fixOnBlur(deserialize(ev.target.value)))
			if (props.value != nVal) {
				props.onUpdate(nVal)
			}
		},
		[props.onBlur, props.fixOnBlur, deserialize, props.value],
	)

	// Build the on click event
	const onClick = React.useCallback(
		(e: React.MouseEvent<HTMLInputElement>) => {
			if (props.selectOnClick) {
				refInput.current.select()
			}
			props.onClick?.(e)
		},
		[props.onClick, props.selectOnClick],
	)

	// Reference
	React.useImperativeHandle(ref, () => ({
		focus: () => {
			refInput.current.focus()
		},
		select: () => {
			refInput.current.select()
		},
		getElement: () => refInput.current,
	}))

	// Build the HTML input element with the custom class
	// Straight map the value state string in/out
	return (
		<input
			ref={refInput}
			type={props.type ?? 'text'}
			className={BuildClass({
				'ui5 ui5-textbox': true,
				[props.className ?? '']: true,
				underlined: props.border == 'underlined' || props.border == null,
				boxed: props.border == 'boxed',
				borderless: props.border == 'borderless',
			})}
			value={serialize(props.value)}
			onChange={onChange}
			onBlur={onBlur}
			onClick={onClick}
			{..._.omit(props, [
				'value',
				'onUpdate',
				'border',
				'fixOnBlur',
				'serialize',
				'deserialize',
				'selectOnClick',
				'ref',
				'type',
				'className',
				'onChange',
				'onBlur',
				'onClick',
			])}
		/>
	)
}
export const TextboxGeneric = React.forwardRef(TextboxGenericComponent)

/**
 * Basic multi-line textbox component
 * @param value - The value of the textbox
 * @param onUpdate - Event when the text is mutated
 * @param fixOnBlur - State value mapping function when textbox loses focus
 * @param serialize - Optional override of mapping prop to state
 * @param deserialize - Optional override of mapping state back to prop
 * @prop Also allows any prop that is valid on a HTML `textarea` element
 */
const TextareaComponent = (
	props: {
		value: string
		onUpdate: (value: string) => void
		fixOnBlur?: (value: string) => string
		serialize?: (value: string) => string
		deserialize?: (valueString: string) => string
		selectOnClick?: boolean
		autoExpand?: boolean
		removeBaseClass?: boolean
	} & React.DetailedHTMLProps<
		React.TextareaHTMLAttributes<HTMLTextAreaElement>,
		HTMLTextAreaElement
	>,
	ref: React.ForwardedRef<HTMLTextAreaElement>,
) => {
	// Default the serialization functions
	const serialize = props.serialize ?? (x => x)
	const deserialize = props.deserialize ?? (x => x)
	const fixOnBlur = props.fixOnBlur ?? ((x: string) => x)

	// Ref
	const refInput = React.useRef<HTMLTextAreaElement>(stubTextarea)

	// Cache the change function - runs the `onUpdate` event
	const onChange = React.useCallback(
		(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
			// Update the value
			const nVal = deserialize(ev.target.value)
			if (nVal != props.value) {
				props.onUpdate(nVal)
			}

			// Auto-expand
		},
		[props.value, props.onUpdate, deserialize],
	)

	// Auto-expanding adjusts the textarea height based on content
	React.useEffect(() => {
		if (props.autoExpand && refInput.current) {
			refInput.current.style.height = 'auto'
			refInput.current.style.height = `${refInput.current.scrollHeight}px`
		}
	}, [props.value])

	// Cache the blur function - runs the `fixOnBlur` transform
	const onBlur = React.useCallback(
		(ev: React.FocusEvent<HTMLTextAreaElement, Element>) => {
			props.onBlur?.(ev)
			const nVal = deserialize(fixOnBlur(deserialize(ev.target.value)))
			if (props.value != nVal) {
				props.onUpdate(nVal)
			}
		},
		[props.onBlur, props.fixOnBlur, deserialize, props.value],
	)

	// Build the on click event
	const onClick = React.useCallback(
		(e: React.MouseEvent<HTMLTextAreaElement>) => {
			if (props.selectOnClick) {
				refInput.current.select()
			}
			props.onClick?.(e)
		},
		[props.onClick, props.selectOnClick],
	)

	// Reference
	React.useImperativeHandle(ref, () => refInput.current)

	// Build the HTML input element with the custom class
	// Straight map the value state string in/out
	return (
		<textarea
			ref={refInput}
			className={BuildClass({
				'ui5 ui5-textarea': !(props.removeBaseClass ?? false),
				[props.className ?? '']: true,
			})}
			value={serialize(props.value)}
			onChange={onChange}
			onBlur={onBlur}
			onClick={onClick}
			{..._.omit(props, [
				'value',
				'onUpdate',
				'fixOnBlur',
				'serialize',
				'deserialize',
				'ref',
				'className',
				'onChange',
				'onBlur',
				'onClick',
				'selectOnClick',
				'removeBaseClass',
			])}
		/>
	)
}

export const Textarea = React.forwardRef(TextareaComponent)

/**
 * A textbox with some search defaults. Optionally reports keyboard up/down arrow events
 * for things like moving a list selection up/down more conveniently
 */
export const SearchBox = React.forwardRef(
	(
		props: {
			className?: string
			value: string
			onUpdate: (value: string) => void
			/** The placeholder text to display in the search box. Default "Search..." */
			placeholder?: string
			title?: string
			style?: React.CSSProperties
			/** When the user press up/down or page up/down, a delta row count is passed for changing list selection */
			onArrowDelta?: (delta: number, shiftKey: boolean) => void
			/** When the user presses enter - can be used to activate a list selection from `onArrowDelta` */
			onEnter?: () => void
		},
		ref: React.ForwardedRef<Focusable<HTMLInputElement>>,
	): React.JSX.Element => (
		<Textbox
			ref={ref}
			type="search"
			className={props.className}
			placeholder={props.placeholder ?? 'Search...'}
			title={props.title}
			value={props.value}
			style={props.style}
			onUpdate={props.onUpdate}
			onKeyDown={e => {
				// Check for an arrow delta
				if (props.onArrowDelta) {
					switch (e.which) {
						// Arrow down
						case 40:
							e.preventDefault()
							props.onArrowDelta(+1, e.shiftKey)
							break
						// Arrow up
						case 38:
							e.preventDefault()
							props.onArrowDelta(-1, e.shiftKey)
							break
						// Page down
						case 34:
							e.preventDefault()
							props.onArrowDelta(+12, e.shiftKey)
							break
						// Page up
						case 33:
							e.preventDefault()
							props.onArrowDelta(-12, e.shiftKey)
							break
						case 13:
							// Enter
							props.onEnter?.()
							break
					}
				}

				// Check for an enter keypress
				if (e.which == 13) {
					e.preventDefault()
					props.onEnter?.()
				}
			}}
		/>
	),
)
