import { _ } from '../../lib'
import { ListInstance } from './list'
import { ListGridInstance } from './list-grid'
import { ModalAnchoredInstance } from './modal'

/** Useful to ensure that a `HTMLTextAreaElement` reference is never null */
export const stubTextarea = document.createElement('textarea')

/** Useful to ensure that a `HTMLDivElement` reference is never null */
export const stubDiv = document.createElement('div')

/** Useful to ensure that a `HTMLFormElement` reference is never null */
export const stubForm = document.createElement('form')

/** Useful to ensure that a `HTMLSelectElement` reference is never null */
export const stubSelect = document.createElement('select')

/** Useful to ensure that a `HTMLInputElement` reference is never null */
export const stubInput = document.createElement('input')

/** Useful to ensure that a `HTMLButtonElement` reference is never null */
export const stubButton = document.createElement('button')

/** Useful to ensure that a `HTMLImageElement` reference is never null */
export const stubImage = document.createElement('img')

/** Useful to ensure that a `ModalAnchoredInstance` reference is never null */
export const stubModal: ModalAnchoredInstance = { getElement: () => stubDiv }

/** Useful to ensure that a `ListInstance` reference is never null */
export const stubListInstance: ListInstance<any, any> = {
	focus: _.noop,
	blur: _.noop,
	selectAll: _.noop,
	selectNone: _.noop,
	setSelected: _.noop,
	moveSelection: _.noop,
	updateScrollPosition: _.noop,
	refreshScrollJack: _.noop,
	rs: null,
	renderedRows: [],
	getKeyDownEventDelta: () => [],
	forwardEventScroll: _.noop,
	forwardEventMouseDown: _.noop,
	forwardEventMouseClick: _.noop,
	forwardEventKeyDown: _.noop,
	noop: _.noop,
	getElement: () => stubDiv,
	getRowValues: () => [],
}

/** Useful to ensure that a `ListGridInstance` reference is never null */
export const stubListGrid: ListGridInstance<any, any> = {
	getElement: () => stubDiv,
	getListElement: () => stubDiv,
	getFullTable: () => [],
	getFilteredRecords: () => [],
	getHiddenFields: () => [],
	getList: () => stubListInstance,
	focus: () => void 0,
}

export const stubFocusable = <El extends Element>(el: El) => ({
	focus: _.noop,
	select: _.noop,
	getElement: () => el,
})

export const stubResizeSplitterInstance = {
	getElement: () => stubDiv,
	reset: () => {},
}

export const stubTreeListInstance = {
	focus: _.noop,
	select: _.noop,
	getElement: () => stubDiv,
	getList: () => stubListGrid,
}

export const stubCanvas = document.createElement('canvas')
