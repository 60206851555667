import { BuildClass } from '../../universal'
import { React, _ } from '../lib'
import { J2rObject, isJSX, j2r } from './component-react'
import { Bindings } from './ui5'

/**
 * A 42px high toolbar component split into left-hand and right-hand sides
 * @deprecated Use `Toolbar` in ui5
 */
export class J2rToolbar extends React.Component<
	{
		cl?: string
		lhs?: J2rObject[]
		rhs?: J2rObject[]
		widthLHS?: number
		widthRHS?: number
	},
	any // TODO: fix state type
> {
	constructor(props) {
		super(props)
		Bindings(this, [this.eval])

		// Log a warning if widhts are defined for both sides - not really allowed
		if (this.props.widthLHS != null && this.props.widthRHS != null) {
			console.warn('Not supposed to have widths defined for both sides', this)
		}
		if (this.props.widthLHS == null && this.props.widthRHS == null) {
			console.warn(
				'J2rToolbar should have a defined width for either LHS or RHS',
				this,
			)
		}
	}

	override render() {
		return (
			<div
				className={BuildClass({
					[this.props.cl]: true,
					'j2r-toolbar': true,
				})}
			>
				<div className="lhs side" style={{ width: this.getWidthLHS() }}>
					{_.compact(_.map(this.props.lhs, this.eval))}
				</div>

				<div className="rhs side" style={{ width: this.getWidthRHS() }}>
					{_.compact(_.map(this.props.rhs, this.eval))}
				</div>
			</div>
		)
	}

	getWidthLHS() {
		if (this.props.widthLHS != null) {
			return `${this.props.widthLHS}px`
		} else if (this.props.widthRHS != null) {
			return `calc(100% - ${this.props.widthRHS}px)`
		}
		return undefined
	}

	getWidthRHS() {
		if (this.props.widthRHS != null) {
			return `${this.props.widthRHS}px`
		} else if (this.props.widthLHS != null) {
			return `calc(100% - ${this.props.widthLHS}px)`
		}
		return undefined
	}

	eval(cmpt, index): JSX.Element {
		// Null returns null
		if (cmpt == null) {
			return null
		}

		// If it's already a JSX element, modify the class directly
		if (isJSX(cmpt)) {
			return React.cloneElement(cmpt, {
				key: String(index),
				className: BuildClass({
					[cmpt.props.cl]: true,
					'toolbar-item': true,
				}),
			})
		}

		// Evalulate lazy rendering
		if (_.isFunction(cmpt)) {
			cmpt = cmpt()
		}

		// Add a class and a default key
		// Return the component
		return j2r(
			_.assign({}, cmpt, {
				cl: BuildClass({
					[cmpt.cl]: true,
					'toolbar-item': true,
				}),
				key: cmpt.key ?? index,
			}),
		)
	}
}
