import { Maybe, ReportVersion } from '../../../../universal'
import { IRISResponseProgress } from '../../component-iris'
import { cacheCSVFile, cachePDFFile, cacheXLSXFile } from '../../component-pdf-viewer'
import {
	ReducerState,
	ReducerStateD,
	ReportFrameSerialisedState,
	ReportFrameState,
	RunReportFunction,
} from './types'

export enum Action {
	UpdateState,
	UpdateOptions,
	UpdateFormState,
	ResetOptions,
}

export type Payload =
	| [Action.UpdateState, (state: ReportFrameState) => Partial<ReportFrameState>]
	| [Action.UpdateOptions, { [paramID: string]: unknown }]
	| [
			Action.UpdateFormState,
			{
				reportKey: string
				isLoading?: boolean
				loadProg?: Maybe<IRISResponseProgress>
				errorMessage?: Maybe<string>
				pdfURL?: Maybe<string>
				iframeSrc?: Maybe<string>
			},
	  ]
	| [Action.ResetOptions, { reportKey: string }]

export const getPartialStateFromAction = (
	rs: ReducerState,
	p: Payload,
): Maybe<Partial<ReportFrameState>> => {
	switch (p[0]) {
		case Action.UpdateState:
			return p[1](rs.state)
		case Action.UpdateOptions:
			const rpt = rs.props.reportIndex[rs.state.selectedReport ?? -1]?.Key
			if (!rpt) {
				return {}
			}
			return {
				options: {
					...rs.state.options,
					[rpt]: {
						...rs.state.options[rpt],
						...p[1],
					},
				},
			}
		case Action.UpdateFormState:
			const v = p[1].reportKey
			if (!v) {
				return {}
			}
			return {
				isLoading: {
					...rs.state.isLoading,
					[v]: p[1].isLoading ?? rs.state.isLoading[v] ?? false,
				},
				errorMessage: {
					...rs.state.errorMessage,
					[v]:
						p[1].errorMessage === undefined
							? rs.state.errorMessage[v]
							: p[1].errorMessage,
				},
				loadProg: {
					...rs.state.loadProg,
					[v]:
						p[1].loadProg === undefined
							? rs.state.loadProg[v]
							: p[1].loadProg,
				},
				pdfURL: {
					...rs.state.pdfURL,
					[v]: p[1].pdfURL === undefined ? rs.state.pdfURL[v] : p[1].pdfURL,
				},
				iframeSrc: {
					...rs.state.iframeSrc,
					[v]:
						p[1].iframeSrc === undefined
							? rs.state.iframeSrc[v]
							: p[1].iframeSrc,
				},
			}
		case Action.ResetOptions:
			return {
				options: {
					...rs.state.options,
					[p[1].reportKey]: rs.state.defaultOptions[p[1].reportKey] ?? {},
				},
			}
	}
}

export const buildReport = (rs: ReducerStateD, export_type: number): void => {
	const rpt = rs.state.selectedReport
	if (!rpt) {
		return
	}

	// Form is now loading, and prior report is trashed
	rs.dispatch([
		Action.UpdateFormState,
		{
			reportKey: rpt,
			isLoading: true,
			loadProg: null,
			iframeSrc: null,
			pdfURL: null,
			errorMessage: '',
		},
	])

	// Get the report key
	const report_key = rs.props.reportIndex[rpt ?? -1]?.Key
	if (!report_key) {
		rs.dispatch([
			Action.UpdateFormState,
			{
				reportKey: rpt,
				errorMessage: 'Invalid report',
				isLoading: false,
				loadProg: null,
			},
		])
		return
	}
	if (rs.state.cache == null) {
		rs.dispatch([
			Action.UpdateFormState,
			{
				reportKey: rpt,
				errorMessage: 'Still loading',
				isLoading: false,
				loadProg: null,
			},
		])
		return
	}
	let rptFunc: Maybe<RunReportFunction> = null
	switch (rs.state.cache.version[report_key]) {
		case ReportVersion.V3:
			rptFunc = rs.props.runReport.v3
			break
		case ReportVersion.V4:
			rptFunc = rs.props.runReport.v4
			break
		default:
			rptFunc = () => undefined
			break
	}

	// Send the request via the application-defined function
	const anyResp = () => {
		rs.dispatch([
			Action.UpdateFormState,
			{
				reportKey: rpt,
				isLoading: false,
				loadProg: null,
			},
		])
	}
	rptFunc?.({
		resolve: data => {
			anyResp()
			// If it's an XLSX file, just pump it into the frame to be downloaded
			// If it's a CSV file, just pump it into the frame to be downloaded
			// If it's a PDF file, cache it and set the URL to display
			if (export_type === 2) {
				cacheXLSXFile(data.hashCode, data.output, null, url => {
					rs.dispatch([
						Action.UpdateFormState,
						{ reportKey: rpt, iframeSrc: url },
					])
				}).catch(err => {
					console.error(err)
				})
			} else if (export_type === 3) {
				cacheCSVFile(data.hashCode, data.output, null, url => {
					rs.dispatch([
						Action.UpdateFormState,
						{ reportKey: rpt, iframeSrc: url },
					])
				}).catch(err => {
					console.error(err)
				})
			} else {
				cachePDFFile(data.hashCode, data.output, null, url => {
					rs.dispatch([Action.UpdateFormState, { reportKey: rpt, pdfURL: url }])
					sendOnUpdate(rs)
				}).catch(err => {
					console.error(err)
				})
			}
		},
		reject: data => {
			anyResp()
			console.error(data)
			rs.dispatch([
				Action.UpdateFormState,
				{ reportKey: rpt, errorMessage: data.message },
			])
		},
		onProg: data => {
			rs.dispatch([Action.UpdateFormState, { reportKey: rpt, loadProg: data }])
		},
		key: report_key,
		params: rs.state.options[report_key] ?? {},
		export_type: export_type,
	})
}

export const sendOnUpdate = (
	rs: ReducerState,
	delta?: Partial<ReportFrameSerialisedState>,
): void => {
	requestAnimationFrame(() => {
		rs.props.onUpdate({
			selectedReport: String(rs.state.selectedReport),
			expanded: rs.state.expanded,
			options: rs.state.options,
			pdfs: rs.state.pdfURL,
			widths: rs.state.widths ?? null,
			searchText: rs.state.searchText,
			...delta,
		})
	})
}
