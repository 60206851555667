import { BuildClass, Do, timer } from '../../universal'
import { React, _ } from '../lib'
import { openNavLinkMaybe } from './component-frame'
import { IRIS } from './component-iris'
import { CJSX, FormButton, FormButtonSet, LoadingSpinnerLarge, Textbox } from './ui5'

declare let AccountPage: any

export const openMFALoginForm = () => ({
	cappedWidth: true,
	content: () => ({
		tag: MFALoginForm,
		redirect: Do(() => {
			const p = new URLSearchParams(window.location.search)
			return p.get('rd')
		}),
	}),
})

export const MFALoginForm = (props: {
	flyout?: any
	onCloseFailed?: Function
	redirect?: string
	onComplete?: Function
}) => {
	// State
	const [token, setToken] = React.useState('')
	const [error, setError] = React.useState('')
	const [isLoading, setIsLoading] = React.useState(false)
	const [successful, setSuccessful] = React.useState<boolean | null>(null)

	// Save action - send server request to elevate session
	const onSave = (this_token: string) => {
		setError('')
		setIsLoading(true)
		IRIS.Send({
			data: {
				progID: 0,
				funcID: 33,
				token: this_token,
			},
			no: data => {
				setIsLoading(false)
				setError(data.message)
			},
			yes: () => {
				onComplete()
			},
		})
	}

	// Final action when completed
	const onComplete = () => {
		// If it's the standalone page, refresh
		if (props.flyout == null) {
			openNavLinkMaybe(props.redirect, true)
			return
		}
		// Run the callback (re-run the function)
		props.onComplete?.()
		// Close the form - we're successful so no dismount fail pass-through
		setSuccessful(true)
		props.flyout?.Close()
	}

	// On mount/dismount
	React.useEffect(() => {
		// If the session is already elevated on the standalone page
		// The process is already done and can be skipped
		if (props.flyout == null && window.rootData.Page.isElevated) {
			setSuccessful(true)
			timer(() => {
				onComplete()
			})
		} else {
			setSuccessful(false)
		}

		// When unmounting
		return () => {
			// If the form is being closed before a successful elevation,
			// display the original error message that prompted this form
			if (!successful) {
				;(props.onCloseFailed ?? _.noop)()
			}
		}
	}, [])

	// Render loading spinner
	if (successful || successful == null) {
		return <LoadingSpinnerLarge />
	}

	// Render main form
	const isFlyout = props.flyout != null
	return (
		<form
			className="tailwind-wrapper mfa-login-page"
			onSubmit={e => {
				e.preventDefault()
				onSave(token)
			}}
		>
			{/* Title and pre-amble decription */}
			{/* Not needed if this is in a flyout */}
			<CJSX cond={props.flyout == null}>
				<h1 className="text-5xl font-condensed font-extralight">
					MFA Elevation Required
				</h1>
			</CJSX>
			<div
				className={BuildClass({
					'font-condensed text-[#333] my-[5px] mx-0': isFlyout,
				})}
			>
				You are trying to access a TriOnline feature that requires the use of
				multi-factor authentication. Please enter the 6-digit MFA token from your
				device below to elevate your session. Note that if you are inactive for 15
				minutes you will need to re-enter the token.
			</div>

			<div
				className={BuildClass({
					'text-center max-w-[480px]': true,
					'py-[10px]': isFlyout,
					'rounded-lg py-8 my-8 mx-auto': !isFlyout,
					'shadow-[0_1.3px_5.1px_rgba(0,0,0,0.096),0_4.2px_17.2px_rgba(0,0,0,0.116),0_19px_77px_rgba(0,0,0,0.23)]':
						!isFlyout,
				})}
			>
				{/* Input - with icon */}
				<div className="mb-2">
					<img
						className="inline w-12 align-middle"
						src="/static/img/svg/login-mfa.svg"
					/>
					<Textbox
						value={token}
						onUpdate={v => {
							setToken(v)
							// Auto-save once all 6 chars are in there
							if (v.length == 6) {
								onSave(v)
							}
						}}
						className={BuildClass({
							'w-[230px] h-12 tracking-[10px] text-center align-middle font-condensed text-4xl my-0 mx-[10px]':
								true,
							'placeholder:tracking-normal placeholder:text-[28px]': true,
						})}
						autoFocus={true}
						maxLength={6}
						placeholder="MFA Token"
					/>
				</div>

				{/* Help */}
				<div className="mb-2">
					<a
						className="text-[#03f] hover:text[#f30]"
						target="_blank"
						href="https://trionline.com.au/kb/690/"
					>
						What is this?
					</a>
				</div>

				{/* Buttons */}
				<FormButtonSet loading={isLoading} msg={error} good={successful}>
					<FormButton
						lbl="Authorise"
						onClick={_.noop}
						type="submit"
						classNameSpan={BuildClass({
							'text-2xl px-5 py-2': !isFlyout,
							'text-[#333] font-condensed': true,
						})}
					/>
				</FormButtonSet>

				{/* Link to add an MFA token */}
				{/* Don't do this if one exists */}
				<CJSX cond={!window.rootData.Page.hasRegistered}>
					<a
						className={BuildClass({
							'register-mfa': true,
							'text-[#77f] font-condensed': true,
							'hover:text-[#00f]': true,
						})}
						onClick={e => {
							e.preventDefault()
							AccountPage.openMFARegistration()
						}}
					>
						Don't have an MFA registration?
					</a>
				</CJSX>
			</div>
		</form>
	)
}
