import {
	createPhantomTypeSchema,
	DateObj,
	DateObjT,
	Maybe,
	MaybeT,
	t,
} from '../../../../universal'
import { _, React } from '../../../lib'
import { Checkbox as CheckboxInner } from '../checkbox'
import { DateBox, DateboxZoomLevel } from '../date'
import { TextareaWithVoice } from '../text-with-voice'
import { Textbox } from '../textbox'
import { FormTypeAddress, FormTypeContact } from './fields-address-contact'
import {
	FormTypeDropdown,
	FormTypeDropdownMulti,
	FormTypeDropdownMultiStringKey,
	FormTypeDropdownStringKey,
} from './fields-dropdown'
import { FormTypeFileMultiple, FormTypeFileSingle } from './fields-file'
import { FormTypeNumeric } from './fields-numeric'
import { FormField, FormFieldJSX } from './types'

/** Basic textbox */
export const Text = (
	settings: FormField<string, string> & {
		/** Placeholder text to show when the textbox is empty */
		placeholder?: string
		/** Easy way to add a min length validator */
		minLength?: number
		/** Easy way to add a max length validator */
		maxLength: number
		/** If set to true, the min length is ignored if the length is 0 - default FALSE */
		nullable?: boolean
		/** Automatically capitalises the first character - default TRUE */
		autoCapitalize?: boolean
		/** Trims preceding/trailing whitespace - default TRUE */
		autoTrim?: boolean
	},
): FormFieldJSX<string, string> => ({
	...settings,
	valueDefaults: {
		def: () => '',
		fixerImmediate: x =>
			(settings.autoCapitalize ?? true)
				? x.charAt(0).toUpperCase() + x.slice(1)
				: x,
		fixer: x => ((settings.autoTrim ?? true) ? x?.trim() : x) ?? '',
		validators: [
			{
				req: x => {
					const len = settings.minLength
					if ((settings.nullable ?? false) && !x) {
						return true
					}
					return Boolean(len == null || (x?.length ?? 0) >= len)
				},
				msg: `must be at least ${settings.minLength} chars`,
			},
			{
				req: x => {
					const len = settings.maxLength
					return Boolean(len == null || (x?.length ?? 0) <= len)
				},
				msg: `cannot exceed ${settings.maxLength} chars`,
			},
		],
	},
	height: 36,
	typeMap: {
		schemaPublic: createPhantomTypeSchema<string>(t.String()),
		schemaRaw: createPhantomTypeSchema<string>(t.String()),
		toPublic: (x: string) => x,
		toInternal: (x: string) => x,
	},
	llmInfo: {
		stringifiedType: 'text',
		description: settings.doc ?? settings.lbl,
		reversal: x => String(x ?? ''),
	},
	jsx: props => (
		<Textbox
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			placeholder={settings.placeholder}
			readOnly={props.readOnly}
			disabled={props.disabled}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
			data-1p-ignore={true}
		/>
	),
})

/** Textarea box - multi-line textbox */
export const TextMultiline = (
	settings: FormField<string, string> & {
		/** Uses the full form width by removing the label. Default: FALSE */
		fullWidth?: boolean
		/** Placeholder text to show when the textbox is empty */
		placeholder?: string
		/** Easy way to add a min length validator */
		minLength?: number
		/** Easy way to add a max length validator */
		maxLength: number
		/** Automatically capitalises the first character - default TRUE */
		autoCapitalize?: boolean
		/** Trims preceding/trailing whitespace - default TRUE */
		autoTrim?: boolean
		/** Are newlines disallowed? Default: FALSE */
		disableNewlines?: boolean
		/** Disable voice input? Default: FALSE */
		disableVoiceInput?: boolean
		/** Passed to whisper for transcription when doing a direct voice input */
		voicePrompt?: string
		/** How many lines of text should be visible before having to scroll */
		rows: number
	},
): FormFieldJSX<string, string> => ({
	...settings,
	valueDefaults: {
		def: () => '',
		fixerImmediate: x => {
			let s = x
			if (settings.autoCapitalize ?? true) {
				s = s.charAt(0).toUpperCase() + s.slice(1)
			}
			if (settings.disableNewlines ?? false) {
				s = s.replace(/\n/g, ' ')
			}
			return s
		},
		fixer: x => ((settings.autoTrim ?? true) ? x?.trim() : x) ?? '',
		validators: [
			{
				req: x => {
					const len = settings.minLength
					return Boolean(len == null || (x?.length ?? 0) >= len)
				},
				msg: `must be at least ${settings.minLength} chars`,
			},
			{
				req: x => {
					const len = settings.maxLength
					return Boolean(len == null || (x?.length ?? 0) <= len)
				},
				msg: `cannot exceed ${settings.maxLength} chars`,
			},
		],
	},
	emptyLabel: settings.fullWidth ?? false,
	removeLabel: settings.fullWidth ?? false,
	height: (_.max([settings.rows, 1]) ?? 1) * 20 + 38,
	typeMap: {
		schemaPublic: createPhantomTypeSchema<string>(t.String()),
		schemaRaw: createPhantomTypeSchema<string>(t.String()),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: {
		stringifiedType: 'text',
		description: settings.doc ?? settings.lbl,
		reversal: x => String(x ?? ''),
	},
	jsx: props => (
		<TextareaWithVoice
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			rows={settings.rows < 1 ? 1 : settings.rows}
			placeholder={settings.placeholder}
			readOnly={props.readOnly}
			disabled={props.disabled}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
			data-1p-ignore={true}
			prompt={settings.voicePrompt ?? settings.doc ?? ''}
			maxLength={settings.maxLength}
			disableVoiceInput={settings.disableVoiceInput ?? false}
		/>
	),
})

/** Date box */
export const Date = (
	settings: FormField<Maybe<DateObj>, Maybe<DateObj>> & {
		/** Placeholder text to show when the datebox is empty. Defaults to the date format */
		placeholder?: string
		/** Format string - defaults to dd/mm/yyyy */
		format?: string
		/** Default zoom level */
		defaultZoom?: DateboxZoomLevel
		/** Earliest allowable date */
		minDate?: DateObj
		/** Latest allowable date */
		maxDate?: DateObj
	},
): FormFieldJSX<Maybe<DateObj>, Maybe<DateObj>> => ({
	...settings,
	valueDefaults: {
		def: () => null,
		validators: [
			settings.minDate && {
				req: x => {
					const d = DateObj.parse(x)
					const minDate = settings.minDate
					if (!minDate) {
						return true
					}
					return Boolean(d && d >= minDate)
				},
				msg: `must be after ${settings.minDate?.fmtDMY()}`,
			},
			settings.maxDate && {
				req: x => {
					const d = DateObj.parse(x)
					const maxDate = settings.maxDate
					if (!maxDate) {
						return true
					}
					return Boolean(d && d <= maxDate)
				},
				msg: `must be before ${settings.maxDate.fmtDMY()}`,
			},
		],
	},
	height: 36,
	typeMap: {
		schemaPublic: createPhantomTypeSchema<Maybe<DateObj>>(MaybeT(DateObjT())),
		schemaRaw: createPhantomTypeSchema<Maybe<DateObj>>(MaybeT(DateObjT())),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: {
		stringifiedType: 'date',
		description: `Return in YYYY-MM-DD format. ${settings.doc ?? settings.lbl}`,
		reversal: x => (Boolean(x) ? DateObj.parse(String(x)) : null),
	},
	jsx: props => (
		<DateBox
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			placeholder={settings.placeholder}
			defaultZoom={settings.defaultZoom}
			fmt={settings.format}
			readOnly={props.readOnly}
			disabled={props.disabled}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
			data-1p-ignore={true}
		/>
	),
})

/** Checkbox */
export const Checkbox = (
	settings: FormField<boolean, boolean>,
): FormFieldJSX<boolean, boolean> => ({
	...settings,
	valueDefaults: {
		def: () => false,
		validators: [],
	},
	height: 36,
	emptyLabel: true,
	typeMap: {
		schemaPublic: createPhantomTypeSchema<boolean>(t.Boolean()),
		schemaRaw: createPhantomTypeSchema<boolean>(t.Boolean()),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: {
		stringifiedType: 'boolean',
		description: settings.doc ?? settings.lbl,
		reversal: x => Boolean(x),
	},
	jsx: props => (
		<CheckboxInner
			lbl={props.lbl}
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			readOnly={props.readOnly}
			disabled={props.disabled}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		/>
	),
})

/** Namespace for storing form field types. Basically `GridCell` but for forms */
export const FormType = {
	Text: Text,
	Textarea: TextMultiline,
	Numeric: FormTypeNumeric,
	Date: Date,
	Checkbox: Checkbox,
	Dropdown: FormTypeDropdown,
	DropdownStringKey: FormTypeDropdownStringKey,
	DropdownMulti: FormTypeDropdownMulti,
	DropdownMultiStringKey: FormTypeDropdownMultiStringKey,
	Address: FormTypeAddress,
	Contact: FormTypeContact,
	FileUploadSingle: FormTypeFileSingle,
	FileUploadMultiple: FormTypeFileMultiple,
}
