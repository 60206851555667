/* eslint-disable no-loops/no-loops */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BuildClass, Maybe, fsmData } from '../../../universal'
import { React, _ } from '../../lib'
import { setContextMenu } from './context-menu'
import { CJSX, useStateSync } from './meta-types'
import { stubDiv } from './stubs'

type PaneBase = {
	/** Once dragged sufficiently below min width, can this pane collapse into nothing? */
	collapsible: boolean
	/** Content within this pane */
	content: React.JSX.Element
}

type PaneFlexi = {
	/** The amount of width to give/take proportionally when outer container resized */
	flexWidth: number
	/** Default amount of width allocated to this pane */
	defaultWidth: number | null
	/** Pane cannot take up less width than this */
	minWidth: number | null
	/** Pane cannot take up more width than this */
	maxWidth: number | null
}
type PaneNonFlexi = {
	/** The amount of width to give/take proportionally when outer container resized */
	flexWidth: null
	/** Default amount of width allocated to this pane */
	defaultWidth: number
	/** Pane cannot take up less width than this */
	minWidth: number
	/** Pane cannot take up more width than this */
	maxWidth: number | null
}
type Pane = PaneBase & (PaneFlexi | PaneNonFlexi)

type ResizeSplitterProps = {
	className?: string
	value: maybeNumbers
	onUpdate: (widths: maybeNumbers) => void
	borderColor?: string
	panes: Pane[]
}

type minMaxIndex = {
	index: number
	minWidth: number
	maxWidth: number
}

export type ResizeSplitterInstance = {
	getElement: () => HTMLDivElement
	reset: () => void
}

type maybeNumbers = Maybe<Maybe<number>[]>

export const ResizeSplitter = React.forwardRef(
	(props: ResizeSplitterProps, ref: React.ForwardedRef<ResizeSplitterInstance>) => {
		// Track the container dimensions and update internal widths as required
		// When the dimesnsions of the container update, update the inner widths
		const elContainer = React.useRef<HTMLDivElement>(stubDiv)
		const cacheRect = React.useRef<DOMRect>(stubDiv.getBoundingClientRect())
		React.useEffect(() => {
			const runObserverEvent = () => {
				cacheRect.current =
					elContainer.current?.getBoundingClientRect() ?? cacheRect.current
				updateWidths(widths, null, cacheRect.current.width)
			}
			const observer = new ResizeObserver(runObserverEvent)
			observer.observe(elContainer.current)
			runObserverEvent()
			return () => {
				observer.disconnect()
			}
		}, [elContainer.current])

		// Track the widths of each pane
		const [widths, setWidths] = React.useState<maybeNumbers>(props.value ?? null)
		useStateSync({
			propVal: props.value ?? null,
			setProp: props.onUpdate,
			stateVal: widths,
			setState: setWidths,
			compareFn: (p, s) => _.isEqual(p, s),
		})

		// Cache a "real" min/max based on what is available
		const [minMaxWidths, setMinMaxWidths] = React.useState<minMaxIndex[]>(() =>
			getMinMaxWidths(widths, cacheRect.current.width, props.panes),
		)

		// When the container resizes or when the props change, cache the current resizeable range
		// This is used to prevent the user from dragging the divider beyond the min/max widths
		// Also ensure that all widths are within acceptable bounds
		React.useEffect(() => {
			// Get the container width but skip if it's zero (not yet ready to calculate)
			const totalWidth = cacheRect.current.width
			if (!widths) {
				return
			}

			// First ensure each pane's width is within acceptable bounds
			const newWidths = props.panes.map((pane, index) => {
				const minWidth = pane.minWidth ?? 0
				const maxWidth = pane.maxWidth ?? totalWidth
				let width = widths[index] ?? -1
				if (!(pane.collapsible && width == 0) && width < minWidth) {
					width = Math.max(width, minWidth)
				}
				return Math.min(width, maxWidth)
			})
			if (!_.isEqual(newWidths, widths)) {
				updateWidths(newWidths)
			}

			// Set the "real" (based on available) min/max width cache for each pane
			const newMinMaxWidths = getMinMaxWidths(
				newWidths,
				cacheRect.current.width,
				props.panes,
			)
			if (!_.isEqual(newMinMaxWidths, minMaxWidths)) {
				setMinMaxWidths(newMinMaxWidths)
			}
		}, [cacheRect.current.width, widths, props.panes, props.value])

		// Helper function to update the width state after sanitising
		// Doesn't create a new array (invaliding cache) if values are unchanged
		const updateWidths = (
			newWidths: maybeNumbers,
			index?: Maybe<number>,
			containerWidth?: number,
		) => {
			const newWidthsSanitised = calculateNewWidths(
				newWidths,
				containerWidth ?? cacheRect.current.width,
				props.panes,
				index ?? null,
			)
			// Apply the new widths
			if (!_.isEqual(widths, newWidthsSanitised)) {
				setWidths(newWidthsSanitised)
			}
		}

		// When the container resizes, update the widths
		// Width changes should be contained to the flex panes first where possible
		// Once max/mins of flexes are hit, proportionally impact the rest
		React.useEffect(() => {
			updateWidths(widths)
		}, [
			cacheRect.current.width,
			elContainer.current,
			widths,
			props.panes,
			props.value,
		])

		// Track what handle is being dragged and where it is relative to its original offset
		const [dragging, setDragging] = React.useState<number | null>(null)

		// Build the instance reference
		React.useImperativeHandle(ref, () => ({
			getElement: () => elContainer.current,
			reset: () => {
				updateWidths(null)
			},
		}))

		// Stub render just the container until it's loaded and we know the widths
		if (!minMaxWidths || !widths) {
			return (
				<div
					ref={elContainer}
					className={BuildClass({
						'ui5-resize-splitter': true,
						[props.className ?? '']: true,
						loading: true,
					})}
				/>
			)
		}

		// Render
		return (
			<div
				ref={elContainer}
				className={BuildClass({
					'ui5-resize-splitter': true,
					[props.className ?? '']: true,
				})}
			>
				<div
					className={BuildClass({
						'resize-splitter-backdrop': true,
						active: dragging != null,
					})}
				/>
				{props.panes.map((pane, index) => (
					<React.Fragment key={index}>
						<div
							className="ui5-resize-splitter-pane"
							style={{
								width: `${widths[index]}px`,
							}}
						>
							{pane.content}
						</div>
						<CJSX cond={index < props.panes.length - 1}>
							<ResizeSplitterHandle
								borderColor={props.borderColor}
								pane={pane}
								isDragging={dragging == index}
								width={widths[index]!}
								minMaxWidth={minMaxWidths[index]!}
								isCollapsed={widths[index] == 0 && pane.collapsible}
								onDragStart={() => {
									setDragging(index)
								}}
								onDragEnd={newWidth => {
									setDragging(null)
									// Update the width for this one
									const newWidths = [...widths]
									newWidths[index] = newWidth
									updateWidths(newWidths, index)
								}}
								onUncollapse={() => {
									const newWidths: (number | null)[] = [...widths]
									newWidths[index] = pane.defaultWidth
									updateWidths(newWidths)
								}}
								onResetWidths={() => {
									updateWidths(null)
								}}
							/>
						</CJSX>
					</React.Fragment>
				))}
			</div>
		)
	},
)

const ResizeSplitterHandle = (props: {
	borderColor?: string
	pane: Pane
	isDragging: boolean
	width: number
	minMaxWidth: {
		minWidth: number
		maxWidth: number
	}
	isCollapsed: boolean
	onDragStart: () => void
	onDragEnd: (newWidth: number) => void
	onUncollapse: () => void
	onResetWidths: () => void
}) => (
	<div className="ui5-resize-splitter-divider-wrapper">
		<div
			className="splitter-border"
			style={{
				backgroundColor:
					props.borderColor ??
					// Default depends on theme
					((window.rootUI?.state?.theme ?? window.rootData?.Theme) === 'black'
						? 'hsl(0, 0%, 28%)'
						: '#aaaaaa'),
			}}
		/>
		<div
			className={BuildClass({
				'ui5-resize-splitter-collapsed-handle': true,
				hidden: !props.isCollapsed,
			})}
			title="Click to reset the collapsed pane to its default width"
			onClick={() => {
				// Reset the width for this one
				props.onUncollapse()
			}}
		>
			<img
				className="arrow-img"
				alt="Reset pane widths"
				src="/static/img/svg/cb-arrow.svg"
			/>
		</div>
		<div
			className={BuildClass({
				'ui5-resize-splitter-divider': true,
				active: props.isDragging,
			})}
			onMouseDown={e => {
				const element = e.currentTarget
				const offset = e.clientX
				props.onDragStart()

				// Stop allowing highlighting of text while dragging
				document.body.style.userSelect = 'none'

				// Calculate the min/max offset
				const minMax = props.minMaxWidth
				const minOffset = minMax.minWidth - props.width
				const maxOffset = minMax.maxWidth - props.width
				const collapsible = props.pane.collapsible ?? false
				const collapse_threshold = (minOffset - props.width) / 2

				// Add global events for mouse move and mouse up
				// This stops issues if the mouse is released away from the element
				const mouseMoveHandler = (e1: MouseEvent) => {
					let transX = e1.clientX - offset
					if (collapsible && transX < collapse_threshold) {
						transX = -props.width
					} else if (transX < minOffset) {
						transX = minOffset
					} else if (transX > maxOffset) {
						transX = maxOffset
					}
					element.style.transform = `translate(${transX}px, 0px)`
				}
				const mouseUpHandler = (e1: MouseEvent) => {
					let transX = e1.clientX - offset
					let collapsed = false
					if (collapsible && transX < collapse_threshold) {
						transX = -props.width
						collapsed = true
					} else if (transX < minOffset) {
						transX = minOffset
					} else if (transX > maxOffset) {
						transX = maxOffset
					}
					element.style.transform = null

					// Remove handlers and allow highlighting again
					document.body.style.userSelect = undefined
					document.removeEventListener('mousemove', mouseMoveHandler)
					document.removeEventListener('mouseup', mouseUpHandler)

					// Get the new width
					const newWidth = collapsed ? 0 : props.width + transX
					props.onDragEnd(newWidth)
				}

				// Set up handlers
				document.addEventListener('mousemove', mouseMoveHandler)
				document.addEventListener('mouseup', mouseUpHandler)
			}}
			onContextMenu={e => {
				e.preventDefault()
				e.stopPropagation()
				setContextMenu({
					position: {
						x: e.clientX,
						y: e.clientY,
					},
					items: [
						{
							label: 'Reset Pane Widths',
							onClick: () => {
								props.onResetWidths()
							},
						},
					],
				})
			}}
		/>
	</div>
)

// Helper functions

// Calculate a "real" min/max based on what is available. Each pane can only expand/contract
// based on what the other panes will allow for their own size
const getMinMaxWidths = (
	widths: maybeNumbers,
	containerWidth: number,
	panes: Pane[],
): Maybe<minMaxIndex[]> => {
	// If there are no widths, return null
	if (!widths) {
		return null
	}

	// For each pane, calculate how much smaller/larger it's allowed to be
	// This informs how much the other panes can expand/contract, taking from others
	const ranges = panes.map((pane, index) => {
		const minWidth = pane.minWidth ?? 0
		const maxWidth = pane.maxWidth ?? containerWidth
		const width = widths[index] ?? -1
		return {
			index: index,
			contract: width - minWidth,
			expand: maxWidth - width,
		}
	})

	// Restrict the min/max for each individual pane taking into account what space is available
	// i.e. A max of 500px is not possible if only 300px of remaining flexi width is available
	const realMinMax = panes.map((_pane, index) => {
		const thisRange = ranges[index] ?? { index: -1, contract: -1, expand: -1 }
		const availableContract = _.sum(
			fsmData(ranges, {
				filter: x => x.index != index,
				map: x => x.expand,
			}),
		)
		const availableExpand = _.sum(
			fsmData(ranges, {
				filter: x => x.index != index,
				map: x => x.contract,
			}),
		)
		const width = widths[index] ?? -1
		return {
			index: index,
			minWidth: width - Math.min(thisRange.contract, availableContract),
			maxWidth: width + Math.min(thisRange.expand, availableExpand),
		}
	})
	return realMinMax
}

const calculateDefaultWidths = (
	containerWidth: number,
	panes: Pane[],
): Maybe<(number | null)[]> => {
	// Give all non-flex boxes their default width to start and see what's left
	// Cap at zero: flexi panes get nothing and full calc will resolve min/max
	const nonFlexiWidth = _.sum(
		fsmData(panes, {
			filter: x => x.flexWidth == null,
			map: x => x.defaultWidth,
		}),
	)
	const remainingWidth = Math.max(containerWidth - nonFlexiWidth, 0)

	// Distribute the remaining width proportionally to the flexi panes
	const flexiSum = _.sum(
		fsmData(panes, {
			filter: x => x.flexWidth != null,
			map: x => x.flexWidth,
		}),
	)
	const widthPerFlex = remainingWidth / flexiSum

	// Calculate all pane widths and pass to the full calc to resolve min/max issues
	return calculateNewWidths(
		panes.map(pane => {
			if (pane.flexWidth != null) {
				return Math.floor(widthPerFlex * pane.flexWidth)
			}
			return pane.defaultWidth
		}),
		containerWidth,
		panes,
		null,
	)
}

// When the user or container resizes, update the widths
// Width changes should be contained to the flex panes first where possible
// Once max/mins of flexes are hit, proportionally impact the rest
// Note that if the user resized a flex, resize that as a last resort
const calculateNewWidths = (
	widths: maybeNumbers,
	containerWidth: number,
	panes: Pane[],
	lastResizedIndex: number | null,
	recursiveCounter = 0,
): Maybe<(number | null)[]> => {
	// If the widths are null and the containerWidth is zero, return null
	// We still need to wait for the container box to render before we can proceed
	if (!widths && containerWidth == 0) {
		return null
	}

	// If there are no widths, get the default
	widths ??= calculateDefaultWidths(containerWidth, panes)

	// Check if we need to expand or shrink
	// +ve = expand, -ve = shrink
	const currentTotalWidth = _.sum(widths.map(x => x ?? 0))
	const deficit = containerWidth - currentTotalWidth

	// If the container width is zero, just return the current widths
	// This stops issues with a race condition causing it to all collapse down to minimum
	if (containerWidth == 0) {
		return widths
	}

	// Each flex width need to take its proportion of the deficit that is possible
	// Shadow `widths` so the next check uses up-to-date state
	// Only take the largest proportion that doesn't exhaust a flexi-width pane, and loop
	// until all flexis are exhausted, or the deficit is resolved
	const panesWithIndex = _.map(panes, (x, i) => ({ ...x, index: i }))
	let deficitRemaining = deficit
	let remainingFlexiPanes = panesWithIndex.filter(
		x => x.flexWidth != null && x.index != lastResizedIndex,
	)
	const newWidths = widths.map(x => x ?? 0)
	let passes = 0
	while (deficitRemaining != 0 && remainingFlexiPanes.length > 0 && passes < 20) {
		// Get total flex width of remaining flexi panes
		const totalFlexWidth = _.sum(remainingFlexiPanes.map(x => x.flexWidth ?? 0))

		// Get the most amount of deficit that can be handled in this pass
		const proportions = remainingFlexiPanes.map(pane => {
			const width = widths[pane.index]!
			const this_pane_proportion = (pane.flexWidth ?? 0) / totalFlexWidth
			const fullDiff = deficitRemaining * this_pane_proportion
			const minWidth = pane.minWidth ?? 0
			const maxWidth = pane.maxWidth ?? containerWidth
			if (deficitRemaining > 0) {
				return Math.min((maxWidth - width) / fullDiff, 1)
			}
			if (deficitRemaining < 0) {
				return Math.min((minWidth - width) / fullDiff, 1)
			}
			return 1 // It must have been a zero deficit
		})

		// Perform the proportional change on the lowest proportion
		const proportion = _.min(proportions) ?? 0
		remainingFlexiPanes.forEach(pane => {
			const width = widths[pane.index]!
			const this_pane_proportion = (pane.flexWidth ?? 0) / totalFlexWidth
			const diff = deficitRemaining * proportion * this_pane_proportion
			const newWidth = Math.round(width + diff)
			deficitRemaining -= newWidth - width
			newWidths[pane.index] = newWidth
		})

		// Flexi panes that were equal to the proportion are removed from remaining
		remainingFlexiPanes = remainingFlexiPanes.filter(
			pane => proportions[pane.index] != proportion,
		)
		passes++
	}

	// If there is any remaining deficit, we need to start affecting the non-flexi panes
	// This will be done proportionally between all of them as if they are all flex: 1
	// Min/max still needs to be respected
	let remainingNonFlexiPanes = panesWithIndex.filter(x => x.flexWidth == null)
	passes = 0
	while (deficitRemaining != 0 && remainingNonFlexiPanes.length > 0 && passes < 20) {
		// Get the most amount of deficit that can be handled in this pass
		const proportions = remainingNonFlexiPanes.map(pane => {
			const width = widths[pane.index]!
			const this_pane_proportion = 1 / remainingNonFlexiPanes.length
			const fullDiff = deficitRemaining * this_pane_proportion
			const minWidth = pane.minWidth ?? 0
			const maxWidth = pane.maxWidth ?? containerWidth
			if (deficitRemaining > 0) {
				return Math.min((maxWidth - width) / fullDiff, 1)
			}
			if (deficitRemaining < 0) {
				return Math.min((minWidth - width) / fullDiff, 1)
			}
			return 1 // It must have been a zero deficit
		})

		// Perform the proportional change on the lowest proportion
		const proportion = _.min(proportions) ?? 0
		remainingNonFlexiPanes.forEach(pane => {
			const width = widths[pane.index]!
			const this_pane_proportion = 1 / remainingNonFlexiPanes.length
			const diff = deficitRemaining * proportion * this_pane_proportion
			const newWidth = Math.floor(width + diff)
			deficitRemaining -= newWidth - width
			newWidths[pane.index] = newWidth
		})

		// Flexi panes that were equal to the proportion are removed from remaining
		remainingNonFlexiPanes = remainingNonFlexiPanes.filter(
			pane => proportions[pane.index] != proportion,
		)
		passes++
	}

	// If there's anything left, apply it directly to the last resized pane
	const lastResizedPane = panes[lastResizedIndex ?? -1]
	if (deficitRemaining && lastResizedIndex && lastResizedPane?.flexWidth) {
		const width = widths[lastResizedIndex] ?? -1
		const minWidth = lastResizedPane.minWidth ?? 0
		const maxWidth = lastResizedPane.maxWidth ?? containerWidth
		const newWidth = Math.min(Math.max(width + deficitRemaining, minWidth), maxWidth)
		newWidths[lastResizedIndex] = newWidth
		deficitRemaining -= newWidth - width
	}

	// No remaining deficit? Excellent - send it
	if (deficitRemaining == 0) {
		return newWidths
	}

	// Run it through another pass - up to 5 times
	// This is a lazy workaround for the fact that the above algorithm doesn't always work
	if (recursiveCounter < 5) {
		return calculateNewWidths(
			newWidths,
			containerWidth,
			panes,
			lastResizedIndex,
			recursiveCounter + 1,
		)
	}

	// Return what we have but warn
	console.warn(`ResizeSplitter deficit: ${deficitRemaining}`)
	return newWidths
}
