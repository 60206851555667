import { BuildClass } from '../../../universal'
import { React } from '../../lib'

export const Toolbar = (props: {
	className?: string
	lhs?: React.JSX.Element
	rhs?: React.JSX.Element
	widthLHS?: number
	widthRHS?: number
	/** Prevents adding the "tailwind-wrapper" class */
	omitTailwindWrapper?: boolean
}) => {
	// Exactly one width should be defined
	if ((props.widthLHS == null) == (props.widthRHS == null)) {
		console.trace('Exactly one width should be defined')
	}

	// Either the LHS or RHS should be defined
	if (!props.lhs && !props.rhs) {
		console.trace('No LHS/RHS defined for the toolbar')
	}

	// Get widths
	const widths =
		props.widthLHS != null
			? { lhs: `${props.widthLHS}px`, rhs: `calc(100% - ${props.widthLHS}px)` }
			: { lhs: `calc(100% - ${props.widthRHS}px)`, rhs: `${props.widthRHS}px` }

	// Render
	return (
		<div
			className={BuildClass({
				[props.className ?? '']: true,
				'ui5-toolbar': true,
				'tailwind-wrapper': !(props.omitTailwindWrapper ?? false),
			})}
		>
			<div className="lhs side" style={{ width: widths.lhs }}>
				{props.lhs}
			</div>

			<div className="rhs side" style={{ width: widths.rhs }}>
				{props.rhs}
			</div>
		</div>
	)
}
