import { BuildClass, Do, timer } from '../../../universal'
import { React, _ } from '../../lib'
import { LoadingSpinnerSmall } from './loading'
import { CJSX, ConditionalObject } from './meta-types'

export type ui5ButtonType =
	| 'ui5-borderless-24'
	| 'std-tailwind'
	| 'borderless'
	| 'submit'
	| 'delete'
	| 'green'
	| 'standard'
	| 'button'
	| 'trans-red'
	| 'trans-blue'

// Define the two sets of button props
type buttonBaseProps = {
	img?: string
	className?: string
	classNameImg?: string
	classNameSpan?: string
	lbl?: string
	onClick?: (ev: React.MouseEvent) => void
	type?: ui5ButtonType
	hidden?: boolean
	disabled?: boolean
	width?: number
	tailwind?: boolean
	// Confirmation click
	doubleClickNeeded?: boolean
	timeoutConfirmingMs?: number
}
type buttonElementProps = Omit<
	React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	>,
	keyof buttonBaseProps
>

/**
 * A button component with TriOnline styles
 * @param img Optional image to show left of the children
 * @param className Custom class string
 * @param lbl Label to show inside the button (overridden by `children`)
 * @param children Content to show inside the button
 * @param title Hover tooltip text
 * @param onClick Event to run when button is activated
 * @param type Dictates the button style, and `submit` makes it a submit button
 * @param hidden Hides the button
 * @param disabled Disables the button
 * @param doubleClickNeeded Requires a confirmation click
 * @param timeoutConfirmingMs Timeout (ms) for confirmation click
 * @prop Also allows any valid <button /> prop
 */
export const ButtonTW = (
	props: Omit<buttonBaseProps, 'tailwind'> & buttonElementProps,
	// eslint-disable-next-line deprecation/deprecation
): React.JSX.Element => <Button {...props} tailwind={true} />

/**
 * A button component with TriOnline styles
 * @param img Optional image to show left of the children
 * @param className Custom class string
 * @param lbl Label to show inside the button (overridden by `children`)
 * @param children Content to show inside the button
 * @param title Hover tooltip text
 * @param onClick Event to run when button is activated
 * @param type Dictates the button style, and `submit` makes it a submit button
 * @param hidden Hides the button
 * @param disabled Disables the button
 * @param doubleClickNeeded Requires a confirmation click
 * @param timeoutConfirmingMs Timeout (ms) for confirmation click
 * @param tailwind Removes the CSS style classes to force using tailwind instead
 * @prop Also allows any valid <button /> prop
 * @deprecated Use `ButtonTW` instead until all buttons are converted
 */
export const Button = (
	props: buttonBaseProps & buttonElementProps,
): React.JSX.Element => {
	// Timer and state for confirmation buttons
	const confirmingTimer = React.useRef<number>()
	const [confirming, setConfirming] = React.useState(false)

	// Default type is standard
	const type = props.type ?? 'standard'

	// Get the style object
	const style = Do(() => {
		const hidden = props.hidden ? { display: 'none' } : {}
		const all = { ...props.style, ...hidden }
		if (props.width) {
			all.minWidth = props.width
			all.maxWidth = props.width
			all.width = props.width
		}
		return _.size(all) > 0 ? all : undefined
	})

	// const a = <div className="active:shadow-none" />

	// Get the button style classes
	const className = BuildClass({
		'group/ui5btn': true, // Added Tailwind class for hover group
		[BuildClass({
			'ui5-btn': ['ui5-borderless-24'].includes(type),
			btnStdBorderless: type === 'borderless',
			ui5Borderless24: type === 'ui5-borderless-24',
			btnSubmit2: type === 'submit',
			btnDelete2: type === 'delete',
			btnStandard2: ['standard', 'button'].includes(type),
			btnGreenColor: type === 'green',
			btnTrans: ['trans-red', 'trans-blue'].includes(type),
			red: type === 'trans-red',
			blue: type === 'trans-blue',
			disabled: props.disabled ?? false,
			confirming: confirming,
		})]: !props.tailwind,
		// Tailwind equivalent of `btnStandard2`
		[BuildClass({
			'bg-[#ddd] hover:bg-[#b9b9b9]': true,
			'border-[#aaa] hover:border-[#aaa]': true,
			'focus:border-[#333] active:border-[#333]': true,
			'border border-solid outline-0': true,
			'px-[10px] py-[5px] mx-[6px] my-[0]': true,
			'text-base leading-[19px]': true,
			'rounded-[3px] shadow-button': true,
			'active:translate-y-[2px] active:shadow-none': true,
		})]: type == 'std-tailwind',
		// Equivalent of ui5Borderless24
		[BuildClass({
			'px-3 py-0 cursor-pointer rounded-[3px] bg-transparent': true,
			'border border-solid border-transparent': true,
			'opacity-50 cursor-default': props.disabled ?? false,
			'hover:bg-[#ffc] hover:border-[#aaa]': !props.disabled,
			'focus:border-[#ccc]': !props.disabled,
			'hover:shadow-[0_8px_5px_-5px_rgba(0,0,0,0.25),0_1px_2px_rgba(0,0,0,0.15),0_0_20px_rgba(0,0,0,0.05)_inset]':
				!props.disabled,
		})]: type == 'ui5-borderless-24',
		// Coloured buttons
		[BuildClass({
			'border border-solid outline-none rounded-[3px] appearance-none': true,
			'py-[5px] px-[10px] mx-[6px] my-0 shadow-button noncondensed': true,
			'active:shadow-none active:translate-y-[2px]': !props.disabled,
			'shadow-none translate-y-[1px] text-[#666]': props.disabled ?? false,
		})]: ['standard', 'submit', 'delete', 'green', 'button'].includes(type),
		[BuildClass({
			'bg-[#bdf] border-[#99f]': true,
			'hover:bg-[#8bf] hover:border-[#aaf]': !props.disabled,
			'focus:border-[#22f]': !props.disabled,
			'active:bg-[#88f] active:border-[#03f]': !props.disabled,
		})]: type === 'submit',
		[BuildClass({
			'bg-[#ddd] border-[#aaa]': true,
			'hover:bg-[#b9b9b9] hover:border-[#aaa]': !props.disabled,
			'focus:border-[#333]': !props.disabled,
			'active:bg-[#999] active:border-[#333]': !props.disabled,
		})]: ['standard', 'button'].includes(type),
		[BuildClass({
			'bg-[#fcc] border-[#f99]': true,
			'hover:bg-[#faa] hover:border-[#faa]': !props.disabled,
			'focus:border-[#f00]': !props.disabled,
			'active:bg-[#f88] active:border-[#f30]': !props.disabled,
		})]: type === 'delete',
		[BuildClass({
			'bg-[hsl(120,70%,80%)] border-[hsl(120,40%,55%)]': true,
			'hover:bg-[hsl(120,80%,70%)] hover:border-[hsl(120,50%,40%)]':
				!props.disabled,
			'focus:border-[hsl(120,100%,20%)]': !props.disabled,
			'active:bg-[#88f] active:border-[#03f]': !props.disabled,
		})]: type === 'green',
		// Button transparent styles
		[BuildClass({
			'outline-none border border-solid border-[#aaa] rounded-[3px]': true,
			'font-condensed text-[14px] uppercase tracking-[-0.2px]': true,
			'cursor-pointer transition-[border-color_background_color] duration-250':
				true,
		})]: ['trans-red', 'trans-blue'].includes(type),
		[BuildClass({
			'bg-[#ffe5e5] border-[#fa9b9b] text-[#222]': true,
			'hover:bg-[#ff3325] hover:border-[#ff0a07] hover:text-[#fff]':
				!props.disabled,
		})]: type === 'trans-red',
		[BuildClass({
			'bg-[#e5e5ff] border-[#a0a0ff]': true,
			'hover:bg-[#4040ee] hover:border-[#4040ee] hover:text-[#fff]':
				!props.disabled,
		})]: type === 'trans-blue',
		// Hover/active effect
		'active:translate-y-[2px] active:shadow-none': !props.disabled,
		// Custom classes can override at the end
		[props.className ?? '']: true,
	})

	// Cache the click event
	const clickEvent = React.useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			// If double-click is needed, deal with that first
			// If it's not already confirming, set it to the confirming state
			// If it is confirming, turn off that flag and allow through
			if (props.doubleClickNeeded) {
				if (!confirming) {
					setConfirming(true)
					confirmingTimer.current = timer(
						props.timeoutConfirmingMs ?? 2000,
						() => {
							setConfirming(false)
						},
					)
					return
				}
				setConfirming(false)
				if (confirmingTimer.current) {
					clearTimeout(confirmingTimer.current)
				}
			}

			// Run the click handler
			;(props.onClick ?? _.noop)(e)
		},
		[props.onClick, props.doubleClickNeeded, props.timeoutConfirmingMs, confirming],
	)

	// Render
	return (
		<button
			className={className}
			title={props.title ?? props.lbl}
			type={type === 'submit' ? 'submit' : 'button'}
			style={style}
			onClick={clickEvent}
			{..._.omit(props, [
				'className',
				'classNameImg',
				'classNameSpan',
				'img',
				'lbl',
				'title',
				'type',
				'style',
				'onClick',
			])}
		>
			{ConditionalObject(
				props.img != null,
				<img
					className={BuildClass({
						[props.classNameImg ?? '']: true,
						'inline align-baseline': type != 'ui5-borderless-24',
						'inline-block align-top my-[2px] mr-[3px] ml-0 h-6':
							type == 'ui5-borderless-24',
						'group-hover/ui5btn:opacity-100':
							!props.disabled && type == 'ui5-borderless-24',
					})}
					src={props.img}
				/>,
			)}
			<span
				className={BuildClass({
					[props.classNameSpan ?? '']: true,
					'inline-block align-top text-base leading-[30px]':
						type == 'ui5-borderless-24',
					'font-condensed  text-[#333] uppercase': type == 'ui5-borderless-24',
					'group-hover/ui5btn:text-[#000] group-hover/ui5btn:[text-shadow:0_0_1px_#000]':
						!props.disabled && type == 'ui5-borderless-24',
				})}
			>
				{confirming ? 'Confirm?' : (props.lbl ?? props.children)}
			</span>
		</button>
	)
}

/**
 * Shows some buttons and links to loading and message state.
 * While loading, buttons are disabled and a loading spinner is shown.
 * When there is a message, is shows where the spinner was under the buttons
 * @param isLoading Disables the buttons and shows the loading spinner
 * @param message Shows some text where the loading spinner goes
 * @param tick Show a tick where the text/spinner goes instead
 * @param children An array of `FormButton` components
 */
type FormButtonSetInputBase = {
	loading: boolean
	msg: string | string[]
	good?: boolean
	tick?: boolean // Show a tick where the response goes
	neutral?: boolean
	widths?: number
	className?: string
	/** Whether to hide the line under the buttons with the loading spinner, message, etc */
	hideFeedbackLine?: boolean
}
export const FormButtonSet = (
	props:
		| (FormButtonSetInputBase & {
				buttons?: null
				children: React.JSX.Element | React.JSX.Element[]
		  })
		| (FormButtonSetInputBase & { buttons: FormButtonInput[]; children?: null }),
): React.JSX.Element => (
	<div
		className={BuildClass({
			'ui5-form-button-response': true,
			'tailwind-wrapper': true, // TODO - can be removed once everything is in a tailwind wrapper
			[props.className ?? '']: true,
		})}
	>
		<div className="buttons text-center my-[10px] p-0">
			{props.buttons &&
				props.buttons.map(btn => (
					<FormButton
						key={btn.lbl}
						{...{
							width: props.widths,
							...btn,
							disabled: btn.disabled || props.loading,
						}}
					/>
				))}
			{props.children &&
				React.Children.map(props.children, x => {
					// If the element is null then we can just return it, React will ignore it
					// when rendering
					if (x == null) {
						return x
					}

					// Check if it's the right type
					if (x.type != FormButton) {
						throw new Error('Invalid child type to FormButtons')
					}
					// Add the disabled  flag
					return React.cloneElement(x, {
						width: x.props.width ?? props.widths,
						disabled: (x.props.disabled as boolean) || props.loading,
					})
				})}
		</div>
		<CJSX cond={!props.hideFeedbackLine}>
			<div
				className={BuildClass({
					lblResponse: true,
					'text-center min-h-[20px] leading-[20px]': true,
					'text-[#060]': Boolean(props.good || props.tick) && !props.neutral,
					'text-[#f00]': !props.good && !props.tick && !props.neutral,
					'text-[#666]': Boolean(props.neutral) && !props.good,
				})}
			>
				{Do(() => {
					if (props.tick) {
						return <img src="/static/img/tick.png" alt="Success!" />
					} else if (props.loading) {
						return <LoadingSpinnerSmall />
					} else if (_.isArray(props.msg)) {
						return (
							<div>
								{props.msg.map((v, k) => (
									<div key={k}>{v}</div>
								))}
							</div>
						)
					} else if (!props.msg) {
						return <div />
					}
					return <div>{props.msg}</div>
				})}
			</div>
		</CJSX>
	</div>
)

/**
 * A subset of `Button` that is suitable for use in a `FormButtonSet`
 * @param lbl The label text to show on the button
 * @param className Custom class string
 * @param disabled Whether the button is disabled
 * @param hidden: Whether to hide the button (default: false)
 * @param onClick Function to run when the button is activated
 * @param type Submit, standard or delete (standard, but coloured red)
 */
type FormButtonInput = {
	lbl: string
	className?: string
	classNameSpan?: string
	disabled?: boolean
	hidden?: boolean // Whether to hide the button
	onClick: (ev: React.MouseEvent) => void
	style?: React.CSSProperties
	title?: string
	type?: formButtonTypeSubset
	width?: number
}
export const FormButton = (props: FormButtonInput): React.JSX.Element => {
	if (props.hidden ?? false) {
		return <></>
	}
	return (
		<Button
			className={BuildClass({
				'min-w-[120px]': props.width == null,
				[props.className ?? '']: true,
			})}
			classNameSpan={props.classNameSpan}
			width={props.width}
			type={props.type ?? guessButtonTypeFromLabel(props.lbl.toLowerCase())}
			disabled={props.disabled}
			onClick={props.onClick}
			title={props.title}
			lbl={props.lbl}
			style={props.style}
		/>
	)
}

export type formButtonTypeSubset = 'submit' | 'delete' | 'standard' | 'green'
const submits = ['save', 'submit', 'go', 'login', 'sign in', 'add', 'verify', 'send']
const standards = ['reset', 'cancel', 'undo', 'reorder']
const deletes = ['delete', 'ignore', 'remove']
const guessButtonTypeFromLabel = (lbl: string): formButtonTypeSubset => {
	if (submits.includes(lbl)) {
		return 'submit'
	} else if (standards.includes(lbl)) {
		return 'standard'
	} else if (deletes.includes(lbl)) {
		return 'delete'
	}
	return 'standard'
}

/** A wrapper for an add button - automatically adds the default icon/type */
export const AddButton = (props: {
	className?: string
	title?: string
	/** Event to run when the button is activated */
	onClick: (ev: React.MouseEvent) => void
	/** Label of the buttton - defaults to "Add" */
	lbl?: string
	/** Icon for the button - defaults to the new SVG one*/
	icon?: string
	/** Type for the button - defaults to borderless with 24px icon */
	type?: ui5ButtonType
	/** Whether the button is disabled. Default "false" */
	disabled?: boolean
}): React.JSX.Element => (
	<Button
		lbl={props.lbl ?? 'Add'}
		title={props.title}
		type={props.type ?? 'ui5-borderless-24'}
		img={props.icon ?? '/static/img/i8/w11-add.svg'}
		onClick={props.onClick}
		className={props.className}
	/>
)
