import { React } from '../../lib'

/**  A small loading spinner with spinning dots. */
export const LoadingSpinnerSmall = () => (
	<img
		className="loading-spinner loading-spinner-new"
		src="/static/img/svg/ajax-small.svg"
		title="Loading..."
	/>
)

/** A large loading spinner with multi-coloured circles. */
export const LoadingSpinnerLarge = () => (
	<div className="loadingSpinnerLarge">
		<div className="circle blue" />
		<div className="circle red" />
		<div className="circle yellow" />
		<div className="circle green" />
	</div>
)
