import { BuildClass, Do, Maybe, clamp, fsmData, timer } from '../../universal'
import { React, _ } from '../lib'
import { J2rModalPortal } from './component-react'
import { J2rListComponent } from './component-react-list'
import { Bindings, ConditionalObject } from './ui5'

// Magic value for the internal representation of the null / all value selections
// All is only used when the combobox has the `multiple` property added
// Null is only used when the combobox has the `nullable` property added
const j2rcball = '****all****'
const j2rcbnull = '****null****'

type optionValue = string | number
type optionSingle = {
	value: optionValue
	text: string
	cl?: string
	title?: string
	visible?: boolean
}
type optionGroup = {
	text: string
	options: optionSingle[]
	prefix?: string // Used instead of text for full names of children
	cl?: string
	title?: string
	style?: { [key: string]: string }
}
type optionInternal = {
	cl?: string
	fullText: string
	indent: number
	selectable: boolean
	style?: { [key: string]: string }
	text: string
	title?: string
	value?: optionValue
	visible?: boolean
}
export type J2rComboBoxOption = optionSingle | optionGroup

type J2rComboBoxProps = {
	autoFocus?: boolean
	autoPickOnly?: boolean // If there's only one option, select it automatically
	cl?: string
	disabled?: boolean
	enforce?: boolean
	multiple?: boolean
	noSelectAll?: boolean
	nullable?: string
	onUpdate?: (response: {
		value: any
		valueLast: any
		text: any
		isNull: boolean
	}) => void
	onFocus?: (ev: FocusEvent) => void
	options: J2rComboBoxOption[]
	placeholder?: string
	tabIndex?: number
	textView?: (value: any | any[], allSelected: boolean) => string
	title?: string
	value?: any
	fixedWidth?: Maybe<number>
	selected?: boolean
}
type J2rComboBoxState = {
	canSendUpdates: boolean
	isActive: boolean
	isEnteringMode: boolean
	isOpen: boolean
	isInvalid: boolean
	backspacePressed: boolean
	optionsPos: {
		top: number
		left: number
		height: number
		width: number
		minWidth: number
		isDown: boolean
	}
	fixedWidth: Maybe<number>
	selected: any
	value: any | any[]
	valueLast: any | any[]
	valueLastBroadcast: any | any[]
	valueRaw: any
	valueEntered: string
}

/**
 * Combobox - advanced dropdown
 * @deprecated Use `Combobox` in ui5
 */
export class J2rComboBox extends React.Component<J2rComboBoxProps, J2rComboBoxState> {
	// The magic numbers used in the component
	static globs = {
		flipHeightThreshold: 6 * 21 + 2, // 128px - holds 6 rows exactly
		maxOptionsHeight: 19 * 21 + 2, // 401px - holds up to 19 rows exactly
		optionRowHeight: 21,
		closeArrowHeight: 22,
	}
	static defaultProps = {
		autoPickOnly: false,
		cl: '',
		disabled: false,
		enforce: true,
		multiple: false,
		noSelectAll: false,
		textView: null,
		value: null,
	}

	mounted: boolean
	appFocusing: boolean
	txt: React.RefObject<HTMLInputElement>
	opt: React.RefObject<J2rListComponent>
	root: React.RefObject<HTMLDivElement>
	lastPosBox: Maybe<{ top: number; bottom: number; left: number; width: number }> = null

	// Constructor
	constructor(props: J2rComboBoxProps) {
		super(props)
		Bindings(this, [
			this.closeBox,
			this.evBlur,
			this.evChange,
			this.evClick,
			this.evFocus,
			this.getOptions,
			this.getValueRaw,
			this.moveSelection,
			this.onKeyPress,
			this.selectValue,
			this.setOptionPosition,
			this.toggleSelected,
			this.updateText,
			this.validateValue,
		])
		this.mounted = false
		this.appFocusing = false
		this.txt = React.createRef()
		this.opt = React.createRef()
		this.root = React.createRef()
		this.state = {
			canSendUpdates: false,
			isActive: false,
			isEnteringMode: false,
			isOpen: false,
			isInvalid: null,
			backspacePressed: false,
			optionsPos: {
				// Default to underneath at full height
				left: 0,
				top: 28.8,
				height: 400,
				width: 300,
				minWidth: 0,
				isDown: false,
			},
			fixedWidth: props.fixedWidth,
			selected: null,
			value: props.value ?? (props.multiple ? [] : j2rcbnull),
			valueLast: props.value ?? (props.multiple ? [] : j2rcbnull),
			valueLastBroadcast: props.value ?? (props.multiple ? [] : j2rcbnull),
			valueRaw: this.getValueRaw(props.value),
			valueEntered: '',
		}
	}

	// Render function - define the view for the component
	override render() {
		return (
			<div
				ref={this.root}
				title={this.props.title}
				className={BuildClass({
					j2rcombobox: true,
					[this.props.cl ?? '']: true,
					isActive: this.state.isActive,
					isOpen: this.state.isOpen,
					isInvalid: this.state.isInvalid,
					disabled: this.props.disabled ?? false,
					multiple: this.props.multiple ?? false,
				})}
			>
				{this.buildInput()}
				{/*
					Another text input element to display the string representation
					This is only needed for multi-selects that need a separate text
					box to enter filters to the display text
				*/}
				{this.props.multiple ? this.buildMultiInput() : undefined}
				{/* Arrow to the right of the textbox to show the menu */}
				{this.buildArrow()}
				{/* Options list - only shows when textbox focused */}
				{this.buildOptionListModal()}
			</div>
		)
	}

	buildInput() {
		// Text input element - include reference for managing blur
		return (
			<input
				key="input"
				className="txt"
				ref={this.txt}
				// Set the initial properties based on input
				disabled={this.props.disabled ? true : undefined}
				placeholder={this.props.placeholder}
				tabIndex={this.props.tabIndex}
				autoFocus={this.props.autoFocus}
				autoComplete="new-password" // Attempt to work around new versions
				data-lpignore={true}
				value={Do(() => {
					const val = this.props.multiple
						? this.state.valueEntered
						: this.state.valueRaw
					return val ?? ''
				})}
				// Controlled state - update based on new text value
				onChange={this.evChange}
				// Catch key strokes for custom actions
				onKeyDown={this.onKeyPress}
				// Clicking it will open it if it's not already
				onClick={this.evClick}
				// Use the focus/blur state of the input box to determine
				// whether it's active and/or open
				onFocus={this.evFocus}
				onBlur={this.evBlur}
			/>
		)
	}

	evChange(e: React.ChangeEvent<HTMLInputElement>) {
		this.updateText(e.target.value)
	}

	evClick() {
		if (this.props.disabled) {
			return
		}
		this.setState({ isOpen: true })
	}

	evFocus(e: React.FocusEvent<HTMLInputElement, Element>) {
		if (this.props.disabled) {
			return
		}
		this.setState(
			{
				isActive: true,
				isOpen: !this.appFocusing,
			},
			() => {
				;(this.props.onFocus ?? _.noop)(e)
			},
		)
		this.appFocusing = false
	}

	evBlur() {
		const delta = (s: J2rComboBoxState) => ({
			isActive: false,
			isOpen: false,
			isEnteringMode: false,
			valueEntered: '',
			selected: null,
			valueRaw: this.getValueRaw(s.value),
		})

		this.setState(delta, () => {
			this.validateValue()
		})
	}

	buildMultiInput() {
		return (
			<input
				key="input-readonly"
				className="txt txtReadonly"
				readOnly={true}
				placeholder={this.props.placeholder}
				value={this.state.valueRaw}
				tabIndex={-1}
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					if (this.props.disabled) {
						return
					}
					timer(() => this.txt.current?.focus())
				}}
			/>
		)
	}

	buildArrow() {
		return (
			<span
				key="arrow"
				className="arrow noselect"
				// Used to stop clicking out of the text box (onto the arrow)
				// from de-focusing the textbox and flashing the options
				onMouseDown={e => {
					e.preventDefault()
					e.stopPropagation()
				}}
				// Clicking on the arrow toggles the isOpen state via the text
				// box focus state
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					if (this.props.disabled) {
						return
					}
					if (this.state.isOpen) {
						this.closeBox(false)
					} else {
						this.txt.current?.focus()
						this.setState({ isOpen: true })
					}
				}}
			>
				<img
					key="arrow-img"
					className="arrow-img"
					src="/static/img/svg/cb-arrow.svg"
				/>
			</span>
		)
	}

	buildOptionListModal() {
		return (
			<J2rModalPortal key="modal-options-outer">
				{this.buildOptionListWrapper()}
			</J2rModalPortal>
		)
	}

	buildOptionListWrapper() {
		return (
			<div
				key="options-wrapper"
				className={BuildClass({
					'j2rcombobox-options': true,
					hidden: !this.state.isOpen,
					isFlippedUp: !this.state.optionsPos.isDown,
					multi: this.props.multiple ?? false,
				})}
				// Define the position of the dropdown
				style={{
					top: `${this.state.optionsPos.top}px`,
					maxHeight: `${this.state.optionsPos.height}px`,
					left: `${this.state.optionsPos.left}px`,
					minWidth: `${this.state.optionsPos.minWidth}px`,
					width: ConditionalObject(
						this.state.fixedWidth != null,
						`${this.state.fixedWidth}px`,
					),
				}}
			>
				{/* Build the list */}
				{ConditionalObject(this.state.isOpen, () => this.buildOptionList())}
			</div>
		)
	}

	buildOptionList() {
		// Cache at render-time for usage below
		// If not open, don't bother calculating them
		const options = this.getOptions()

		// Return the element
		return (
			<J2rListComponent
				key="options"
				ref={this.opt}
				// Lazy rendering
				lazyRenderHeight={21}
				lazyRenderViewportHeightOverride={400} // Since it animates out from 0 height
				// Add a heading row (doesn't scroll) to close the dropdown if it's a multi-select
				headingRow={ConditionalObject(Boolean(this.props.multiple), () => ({
					cl: 'item close-dropdown',
					key: 'close-dropdown',
					title: 'Close dropdown',
					children: [
						{
							tag: 'img',
							key: 'arrow-img',
							cl: 'arrow-img',
							src: '/static/img/svg/cb-arrow.svg',
						},
					],
					onMouseDown: (e: React.MouseEvent) => {
						e.preventDefault()
						e.stopPropagation()
					},
					onClick: (e: React.MouseEvent) => {
						e.stopPropagation()
						e.preventDefault()
						this.closeBox(true)
					},
				}))}
				// Build the dropdown items
				items={fsmData(options ?? [], {
					filter: opt => opt.visible ?? true,
					map: (opt, index) => this.buildOption(opt, options, index),
				})}
				// Scrollbar clicking shouldn't hide the menu
				// onMouseDown={e => {
				// 	e.preventDefault()
				// 	e.stopPropagation()
				// }}
			/>
		)
	}

	buildOption(opt: optionInternal, options: optionInternal[], index: number) {
		return {
			tag: 'span',
			cl: BuildClass({
				[opt.cl ?? '']: true,
				selected: opt.value === this.state.value,
				highlight: opt.value != null && opt.value === this.state.selected,
				'no-select': !opt.selectable,
				showAll: opt.value === j2rcball,
				[`indent-${opt.indent}`]: !this.state.isEnteringMode,
			}),
			value: opt.value ?? `__no_val_index-${index}`,
			title: opt.title ?? opt.text,
			style: opt.style,

			// Stop click propagation in a label
			onMouseDown: (e: React.MouseEvent) => {
				e.preventDefault()
				e.stopPropagation()
			},

			// Generate the child items
			children: [
				// Checkbox (if it's a multi-select)
				ConditionalObject(Boolean(this.props.multiple), {
					tag: 'input',
					key: 'check',
					type: 'checkbox',
					tabIndex: -1,
					readOnly: true,
					checked: Do(() => {
						// If it's in the set of values that are selected
						if (_.includes(this.state.value, opt.value)) {
							return true

							// Check that all items in the filtered list are selected
						} else if (opt.value === j2rcball) {
							const options_filtered = fsmData(options, {
								filter: v => v.value !== j2rcball && v.selectable,
								map: v => v.value,
							})
							if (
								_.intersection(options_filtered, this.state.value)
									.length === options_filtered.length
							) {
								return true
							}
							return false

							// Nope, not selected
						}
						return false
					}),

					// Clicking updates the selection
					onClick: (e: React.MouseEvent) => {
						e.stopPropagation()
						e.preventDefault()

						// If holding the shift key and multiple selection
						if (
							this.props.multiple &&
							e.shiftKey &&
							this.state.value.length > 0
						) {
							// Get the previously selected value
							const prevValue: any = _.last(this.state.value)

							// Get all the options
							const options = this.getRawOptionsFlat()

							// Get the first and second index
							const firstIndex = _.findIndex(
								options,
								x =>
									x.value ===
									(typeof prevValue === 'object'
										? prevValue.value
										: prevValue),
							)
							const secondIndex = _.findIndex(
								options,
								x => x.value === opt.value,
							)

							// Get all the intermediary values
							let values: optionInternal[] = []
							values =
								firstIndex < secondIndex
									? _.slice(options, firstIndex, secondIndex + 1)
									: _.slice(options, secondIndex, firstIndex + 1)
							timer(() => {
								this.selectValue(_.map(values, v => v.value))
							})
						} else {
							timer(() => {
								this.selectValue(opt.value)
							})
						}
						return false
					},
				}),

				// Text span showing the label of the item
				{
					tag: 'span',
					key: 'text',
					text: !this.state.isEnteringMode ? opt.text : undefined,
					title: !this.state.isEnteringMode
						? (opt.title ?? opt.fullText)
						: undefined,
					dangerouslySetInnerHTML: this.state.isEnteringMode
						? this.getOptionText(opt.fullText)
						: undefined,
				},
			],
			// Updates the selection
			onClick: (e: React.MouseEvent) => {
				e.preventDefault()
				e.stopPropagation()
				if (opt.selectable) {
					// If holding the shift key and multiple selection
					if (
						this.props.multiple &&
						e.shiftKey &&
						this.state.value.length > 0
					) {
						// Get the previously selected value
						const prevValue: any = _.last(this.state.value)

						// Get all the options
						const options = this.getRawOptionsFlat()

						// Get the first and second index
						const firstIndex = _.findIndex(
							options,
							x =>
								x.value ===
								(typeof prevValue === 'object'
									? prevValue.value
									: prevValue),
						)
						const secondIndex = _.findIndex(
							options,
							x => x.value === opt.value,
						)

						// Get all the intermediary values
						let values: typeof options = []
						values =
							firstIndex < secondIndex
								? _.slice(options, firstIndex, secondIndex + 1)
								: _.slice(options, secondIndex, firstIndex + 1)
						this.selectValue(_.map(values, v => v.value))
					} else {
						this.selectValue(opt.value)
					}
				}
			},
		}
	}

	// Whenever the component wants to update, and just after it's mounted, we
	// need to look at the position/size of the component in the viewport and
	// adjust the option div Y coord and height accordingly
	override componentWillUnmount() {
		this.mounted = false
		this.setState({ canSendUpdates: false })
	}

	override componentDidMount() {
		this.mounted = true
		this.setOptionPosition()
		this.validateValue()
		timer(() => {
			if (this.mounted) {
				this.setState({ canSendUpdates: true }, () => {
					// If the flag is true and there is only one option available, pre-select it
					if (
						this.props.autoPickOnly &&
						this.getRawOptionsFlat().length === 1
					) {
						const v = this.getRawOptionsFlat()[0]?.value
						this.setState(
							{
								value: v,
								valueRaw: this.getValueRaw(v),
							},
							() => {
								this.validateValue()
							},
						)
					}
				})
			}
		})
	}

	override componentDidUpdate(
		prevProps: J2rComboBoxProps,
		prevState: J2rComboBoxState,
	) {
		// If the options changed, work out what to do about fixed widths
		if (prevState.isOpen !== this.state.isOpen) {
			timer(100, () => {
				if (this.mounted) {
					this.setState(s => ({
						fixedWidth:
							this.props.fixedWidth ??
							Do(() => {
								if (s.isOpen) {
									return (
										(this.getListElement()?.getBoundingClientRect()
											.width ?? 0) + 2
									)
								}
								return null
							}),
					}))
				}
			})
		}

		// Which which types of properties have changed
		const changedValueMetrics = [
			!_.isEqual(prevProps.value, this.props.value),
			prevProps.textView !== this.props.textView,
		]
		const changedBoxMetrics = [
			prevProps.multiple !== this.props.multiple,
			!_.isEqual(prevProps.options, this.props.options),
		]
		const changedValue = _.some(changedValueMetrics)
		const changedBox = _.some(changedBoxMetrics)

		// Determine the delta based on which prope sets have changed
		const delta = {}
		if (changedBox) {
			_.assign(delta, {
				isEnteringMode: false,
				isOpen: false,
				valueRaw: this.getValueRaw(),
			})
		}
		if (changedValue) {
			const emptyVal = this.props.multiple ? [] : j2rcbnull
			_.assign(delta, {
				value: this.props.value ?? emptyVal,
				valueLastBroadcast: this.props.value ?? emptyVal,
				valueRaw: this.getValueRaw(this.props.value, this.props),
				valueEntered: '',
				valueLast: this.state.value,
			})
		}
		if (_.size(delta) > 0) {
			this.setState(delta, () => {
				this.setOptionPosition()
			})
			return
		}

		// Update the scroll position
		this.setOptionPosition()
	}

	setOptionPosition() {
		// Shorthand alias for the static globals for this component
		let height, is_down, top
		const glob = J2rComboBox.globs

		// Get the dimensions and position of the component
		// Don't bother if it's closed - do an estimate
		const posBox = (this.state.isOpen
			? this.root.current?.getBoundingClientRect()
			: this.lastPosBox) ?? {
			top: 0,
			bottom: 0,
			left: 0,
			width: 200,
		}

		this.lastPosBox = posBox

		// Get the bounding box details
		const boundingBox = {
			left: 0,
			top: 0,
			x: 0,
			y: 0,
			bottom: window.innerHeight,
			height: window.innerHeight,
			right: window.innerWidth,
			width: window.innerWidth,
		}

		// Check the maximum amount of height required - 400 or the size without
		// scrollbars - 21px per option line
		const max_height = Do(() => {
			const max_height_needed = Do(() => {
				let c = this.getRawOptionsFlat().length * glob.optionRowHeight + 2
				if (this.props.nullable != null) {
					c += glob.optionRowHeight
				}
				if (this.props.multiple && !this.props.noSelectAll) {
					c += glob.optionRowHeight + 1
				}
				if (this.props.multiple) {
					c += glob.closeArrowHeight
				}
				return c
			})
			return clamp(max_height_needed, 0, glob.maxOptionsHeight)
		})

		// Check how much height we'd get going up and down respectively
		// Clamp each value so it's a maximum of 400px
		const height_down = clamp(boundingBox.bottom - posBox.bottom, 0, max_height)
		const height_up = clamp(posBox.top - boundingBox.top, 0, max_height)

		// Check whether going up will yield more on screen than going down
		if (height_down < glob.flipHeightThreshold && height_up > height_down) {
			top = posBox.top - height_up
			height = height_up
			is_down = false
		} else {
			top = posBox.bottom - 1
			height = height_down
			is_down = true
		}

		// Update the state if it has changed
		const newObj = {
			isDown: is_down,
			left: posBox.left,
			top: top,
			minWidth: posBox.width,
			width: this.state.optionsPos.width ?? posBox.width,
			height: height,
		}
		if (!_.isEqual(newObj, this.state.optionsPos)) {
			this.setState({ optionsPos: newObj })
		}
	}

	// When a key is pressed
	onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
		// Each new keystroke assumes not backspace unless it is (set below)
		this.setState({ backspacePressed: false })

		// Check the code of the key that was pressed
		switch (e.which) {
			// ESC to close, no endorsement of auto-fill result
			case 27:
				this.closeBox(false)
				e.preventDefault()
				break

			// Enter to also close, but endorsing the result
			// If it's a multi-select, also toggle the selection
			case 13:
				if (this.props.multiple) {
					// If there's only one item in the filter with no selected index, select that one
					const opts = this.getOptions()
					if (
						this.state.selected === null &&
						opts.length === 1 &&
						opts[0]?.value !== j2rcball
					) {
						this.selectValue(opts[0]?.value)
						// Otherwise toggle the current highlight
					} else {
						this.toggleSelected(e)
					}
				}
				// And then close
				this.closeBox(true)
				e.preventDefault()
				break

			// Tab is an endorsement (like ENTER)
			case 9:
				this.updateText(this.state.valueRaw ?? '', false)
				break

			// Space - toggle selection (pass-through for non-multi)
			case 32:
				if (this.props.multiple && this.props.selected != null) {
					e.preventDefault()
					e.stopPropagation()
				}
				this.toggleSelected(e)
				break

			// Down arrow - move selection down the (filtered) list
			// Page down - move by more
			case 40:
				this.moveSelection(+1)
				e.preventDefault()
				break
			case 34:
				this.moveSelection(+12)
				e.preventDefault()
				break

			// Up arrow - move selection up the (filtered) list
			// Page up - move by more
			case 38:
				this.moveSelection(-1)
				e.preventDefault()
				break
			case 33:
				this.moveSelection(-12)
				e.preventDefault()
				break

			// Right arrow - valueEntered is now valueRaw
			case 39:
				this.setState(prev => ({
					valueEntered: prev.valueRaw ?? '',
				}))
				break

			// Backspace/delete need to be stored to know to not highlight
			// Otherwise it'd be really annoying for the user
			case 8:
			case 46:
				this.setState({ backspacePressed: true })
				break
		}
	}

	// When clicking on a value to select it
	selectValue(val) {
		// Exit early if not mounted (this method is sometimes called after a timeout)
		let value, valueRaw
		if (!this.mounted) {
			return
		}

		// Single selection mode
		if (!this.props.multiple) {
			this.setState({ isOpen: false })

			// Only change the value if it's actually changing
			valueRaw = this.getValueRaw(val)
			if (this.state.value === val && this.state.valueRaw === valueRaw) {
				return
			}
			this.setState(
				{
					value: val,
					valueRaw,
					valueLast: this.state.value,
				},
				() => {
					this.validateValue()
				},
			)
			return
		}

		// If this is a select-all, toggle all accordingly
		if (val === j2rcball) {
			// Only toggle the items in the filter
			const options = fsmData(this.getOptions(), {
				filter: v => v.value !== j2rcball && v.selectable,
				map: v => v.value,
			})

			// Check if the items are already toggled
			if (_.intersection(options, this.state.value).length === options.length) {
				// If so, filter out all items in the filtered set
				value = this.state.value.filter(x => !_.includes(options, x))
			} else {
				// Otherwise, add all of the items and de-duplicate
				value = _.uniq(this.state.value.concat(options))
			}

			// Clear the value entered
			this.setState({
				valueEntered: '',
			})

			// Standard multiple-selection - toggle a single item
		}

		// Check if this is an array of items being sent through
		else if (Array.isArray(val)) {
			value = _.uniq(this.state.value.concat(val))
		} else {
			value = Do(() => {
				if (this.state.value.indexOf(val) !== -1) {
					return this.state.value.filter(x => x !== val)
				}
				return this.state.value.concat(val)
			})

			// If multiple. clear the value entered and set the selection
			this.setState({
				valueEntered: '',
				selected: val,
			})
		}

		// Update the state - only if it's actually changing
		valueRaw = this.getValueRaw(value)
		if (this.state.value === value && this.state.valueRaw === valueRaw) {
			return
		}
		this.setState(
			{
				value,
				valueRaw,
				valueLast: this.state.value,
			},
			() => {
				this.validateValue()
			},
		)
	}

	// Toggles selection of the currently highlighted option
	toggleSelected(e) {
		// Do nothing in non-multiple mode, since it's already selected
		if (!this.props.multiple) {
			return
		}

		// If the selection key is there, cancel the default event action
		// Set the value as though we've clicked it, but keep the selected index
		if (this.state.selected) {
			this.selectValue(this.state.selected)
			e.preventDefault()
		}
	}

	// Gets the text to put in the box based on an explicit value
	getValueRaw(val = this.state.value, props = this.props) {
		// This is used when the props are refreshed so we need to alias the props
		let text

		// Get the length of all selectable options
		const optLength = _.size(
			_.filter(this.getRawOptionsFlat(props), x => x.selectable),
		)

		// If a custom display function is given, use that above all else
		// If the custom function returns null, fallback to the default
		// Second parameter is whether all items are selected
		if (props.textView != null) {
			text = props.textView(val, (val?.length ?? 0) === optLength)
			if (text != null) {
				return text
			}
		}

		// Default to the null value if the prop is null
		if (val == null) {
			val = j2rcbnull
		}

		// Single selection mode just shows the text of the option with the selected value
		if (!props.multiple) {
			return fsmData(_.concat(this.getOptions(props)), {
				filter: o => o.value === val,
				map: o => o?.text ?? '',
				takeFirst: true,
			})
		}

		// Multiple selection is just a comma separated list of text
		// If there are more than 2 of them, just show the count
		const matches = fsmData(this.getRawOptionsFlat(props), {
			filter: o => _.includes(val, o.value),
			map: o => o.text,
		})
		if (matches.length === optLength) {
			return 'All Selected'
		}
		if (matches.length > 2) {
			return `${matches.length} Selected`
		}
		return matches.join(', ')
	}

	// Gets the options - potentially filtered and sorted based on the current entered text
	getOptions(props = this.props) {
		// Cache this since it has some ugly null checks (state not available on first run)
		const isEnteringMode = this.state?.isEnteringMode ?? false

		// Flatten the option groups and flag which ones are non-selectable
		let options = this.getRawOptionsFlat(props)

		// Get the filtered/sorted version of the options
		options = fsmData(options, {
			// If in editing mode, filter to only those containing the text
			filter: opt => {
				if (!isEnteringMode) {
					return true
				}
				if (!opt.selectable) {
					return false
				}
				const tl = (s: string) => s.toLowerCase()
				return tl(opt.fullText).indexOf(tl(this.state.valueEntered)) !== -1
			},

			// Divide into two groups - prefix matches followed by inside matches
			sort: opt => {
				// No custom sort if not editing
				if (!isEnteringMode) {
					return 1
				}
				// Helper function to convert to lowercase
				// Check whether it's a prefix match
				const tl = (s: string) => s.toLowerCase()
				const prefix_match = tl(opt.text).startsWith(tl(this.state.valueEntered))
				return prefix_match ? 0 : 1
			},
		})

		// Check if it's filtered
		// const isFiltered = options.length !== this.getRawOptionsFlat(props).length

		// Add the nullable item at the top - if not filtered
		if (props.nullable != null && !isEnteringMode) {
			options.unshift({
				value: j2rcbnull,
				selectable: true,
				text: props.nullable,
				fullText: '',
				indent: 0,
			})
		}

		// Add a show all toggle at the top, if this is a multi-select and the
		// flag to prevent this hasn't been set
		if (props.multiple && !props.noSelectAll && options.length !== 1) {
			const lbl = Do(() => {
				if (options.length === 0) {
					return 'No options meet this filter'
				} else if (options.length !== this.getRawOptionsFlat(props).length) {
					return 'Select Filtered'
				}
				return 'Select All'
			})
			options.unshift({
				value: j2rcball,
				selectable: true,
				fullText: lbl,
				text: lbl,
				indent: 0,
			})
		}

		// Return the options array
		return options
	}

	getRawOptionsFlat(props = this.props): optionInternal[] {
		const options: optionInternal[] = []

		// Flatten the option groups and flag which ones are non-selectable
		_.forEach(props.options, opt => {
			// It's an individual
			if (!('options' in opt)) {
				options.push(
					_.assign({}, opt, {
						selectable: true,
						fullText: opt.text,
						indent: 0,
					}),
				)
				return
			}

			// It's a group
			options.push({
				selectable: false,
				text: opt.text,
				cl: BuildClass({
					[opt.cl ?? '']: true,
					'group-heading': true,
				}),
				title: opt.title,
				fullText: opt.text,
				indent: 0,
			})

			// Add each child item, too
			_.forEach(opt.options, o =>
				options.push(
					_.assign({}, o, {
						selectable: true,
						fullText: `${opt.prefix ?? opt.text} → ${o.text}`,
						indent: 1,
					}),
				),
			)
		})

		//  Return the full array
		return options
	}

	// Get the options list element (DOM)
	getListElement() {
		return this.opt.current?.listElement.current
	}

	// Gets the option text - used to add highlights based on `valueEntered`
	getOptionText(s: string) {
		// Just pass the string through if there's no current search
		if (!this.state || !this.state.isEnteringMode) {
			return { __html: s }
		}

		// Sub in the `em` tags around the search matches
		// Sanitise the input string
		const val = this.state.valueEntered.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
		const q = new RegExp(`(${val})`, 'gi')
		s = s.replace(q, '<em>$1</em>')
		return { __html: s }
	}

	// Moves the selection up or down - generally via the arrow keys
	moveSelection(delta: number) {
		// Get the value of the current selection
		// Multiple select uses its own separate value for this
		const selectedVal = Do(() => {
			if (this.props.multiple) {
				return this.state.selected
			}
			return this.state.value
		})

		// Get the current option list, potentially filtered
		const options = this.getOptions()

		// Get the current selection index
		let index = -1
		_.forEach(options, (opt, i) => {
			if (opt.value != null && opt.value === selectedVal) {
				index = i
				return false
			}
			return undefined
		})

		// Modify the selection index
		index += delta

		// While it's non-selectable, keep travelling one in the same direction until it's not
		while (options[index]?.selectable === false) {
			index += delta / Math.abs(delta)
		}

		// While it doesn't exist (out-of-bounds), backtrack one at a time until selectable
		while (options[index]?.selectable !== true) {
			index -= delta / Math.abs(delta)
		}

		// Move the scroll position of the options pane such that the newly-selected
		// index is visible. Only move it so that it's barely in view if it was
		// not previously
		const listEl = this.getListElement()
		if (listEl != null) {
			const opt_height = listEl.clientHeight
			const top = listEl.scrollTop
			const bot = top + opt_height
			const offset_top = index * J2rComboBox.globs.optionRowHeight
			const offset_bot = offset_top + J2rComboBox.globs.optionRowHeight
			if (offset_top < top) {
				listEl.scrollTo(0, offset_top)
			} else if (offset_bot > bot) {
				listEl.scrollTo(0, offset_bot - opt_height)
			}
		}

		// Set the new selection
		if (this.props.multiple) {
			this.setState({ selected: options[index]?.value })
		} else {
			this.setState({
				value: options[index]?.value,
				valueRaw: this.getValueRaw(options[index]?.value),
				valueLast: this.state.value,
			})
		}
	}

	// Closes the options box - param for whether to keep or discard half-state
	closeBox(keep_state_change: boolean) {
		// Close the box
		this.setState({ isOpen: false })

		// Throwing away state changes - ESC pressed
		if (!keep_state_change && !this.props.multiple) {
			this.setState(s => ({
				isEnteringMode: false,
				valueRaw: this.getValueRaw(s.value),
				valueEntered: '',
			}))
		}

		// Keep the state - ENTER pressed
		if (keep_state_change) {
			this.updateText(this.state.valueRaw, false)
			this.setState({ isOpen: false })
		}
	}

	// Event handler that looks at the raw text and checks if it matches a value
	updateText(text: string, enteredManually = true) {
		if (this.props.disabled) {
			return
		}

		// If it wasn't already open, it is now
		this.setState({ isOpen: true })

		// If it's a multi-select, just set the entered value and leave
		if (this.props.multiple) {
			this.setState({
				valueEntered: text ?? '',
				isEnteringMode: enteredManually && text.length > 0,
			})
			return
		}

		// Set the raw text - may be impacted by other state changes below
		this.setState({ valueRaw: text })

		// Get options without a filter
		const options = this.getRawOptionsFlat()

		// Check if this string matches any of the existing values
		const matches = options.filter(
			opt => opt.text.toLowerCase() === text.toLowerCase(),
		)
		if (matches.length > 0) {
			this.setState(
				{
					value: matches[0]?.value,
					valueRaw: this.getValueRaw(matches[0]?.value),
					isEnteringMode: enteredManually,
					valueLast: this.state.value,
				},
				() => {
					this.validateValue()
				},
			)
			return
		}

		// Start partial matching the options

		// Check if the cursor is at the end - if not we don't bother with
		// doing the auto-fill highlight
		const selectionAtEnd = Do(() => {
			const [selStart, selEnd] = [
				this.txt.current?.selectionStart,
				this.txt.current?.selectionEnd,
			]
			return selStart === selEnd && selStart === text.length
		})

		// Add a highlighted suffix to the text input to auto-complete based on
		// the results that have the same prefix as the entered text
		// Don't do this if the user just pressed backspace/delete or it'll be annoying
		// Also don't do this if the user's current selection isn't at the tail end
		let index = 0
		let suffix = ''
		if (!this.state.backspacePressed && selectionAtEnd) {
			// Find all with the same prefix
			const matches = fsmData(options, {
				filter: o => o.text.toLowerCase().startsWith(text.toLowerCase()),
				map: o => o.text,
			})

			// Check how many characters in their prefixes are common
			while (index < _.min(matches.map(t => t.length))) {
				const diffs = _.uniq(matches.map(t => t[index].toLowerCase()))
				if (diffs.length > 1) {
					break
				}
				index += 1
			}
			suffix = matches[0]?.slice(text.length, index) ?? ''

			// Suffix can be converted to lowercase if it isn't the remainder
			// of a single match result
			if (matches.length > 1) {
				suffix = suffix.toLowerCase()
			}
		}

		// Update the states
		// On callback, adjust the input selection as required
		this.setState(
			{
				value: null,
				valueEntered: text ?? '',
				valueRaw: text + suffix,
				isEnteringMode: enteredManually && text.length > 0,
				valueLast: this.state.value,
			},
			() => {
				if (suffix) {
					this.txt.current?.setSelectionRange(text.length, index)
				}
			},
		)
	}

	// This runs when the input is confirmed in some form
	// The value is then passed back to the consumer via the `onUpdate` property
	validateValue() {
		// Assume that it's no longer invalid. If the tests fail, it'll be reset
		// to true at the end of this function
		this.setState({ isInvalid: false })

		// Create the record to be passed back (if valid)
		const record = {
			value: Do(() => {
				if (this.state.value === j2rcbnull) {
					return null
				}
				return this.state.value || null
			}),
			valueLast: Do(() => {
				if (this.state.valueLast === j2rcbnull) {
					return null
				}
				return this.state.valueLast || null
			}),
			text: this.state.valueRaw,
			isNull: this.state.value === j2rcbnull,
		}

		// Check whether the current value is valid. One of the following:
		// - We're not enforcing anything
		// - It's null and this component is nullable
		// - There is a value
		const bools = [!this.props.enforce, record.isNull, Boolean(record.value)]

		// If it's valid, broadcast the update event
		// Only broadcast if can broadcast is true
		if (_.sum(bools) > 0) {
			if (
				this.state.canSendUpdates &&
				!_.isEqual(record.value, this.state.valueLastBroadcast)
			) {
				this.setState({ valueLastBroadcast: record.value })
				;(this.props.onUpdate ?? _.noop)(record)
			}
			return
		}

		// Otherwise, set the value to null to highlight that it's not correct
		// Only do this if this is actually going to be a change
		const value = null
		const valueRaw = this.getValueRaw(null)
		if (value !== this.state.value || valueRaw !== this.state.valueRaw) {
			this.setState(
				{
					value,
					valueRaw,
				},
				() => {
					this.validateValue()
				},
			)
		}
	}

	Focus(open_as_well = false) {
		if (!open_as_well) {
			this.appFocusing = true
		}
		this.txt.current?.focus()
	}
}
