import { BuildClass, Do, fsmData } from '../../universal'
import { React, _ } from '../lib'
import { J2rModalPortal, J2rText, j2r } from './component-react'
import { ConditionalObject } from './ui5'

// This component is a textbox that allows you to auto-complete based on preset options
// The best example is entering a suburb name and pulling from the list of known postcodes
const MAX_AUTOCOMPLETE_SHOWN = 20

export class J2rTextAutoComplete extends React.Component<
	{
		value?: any
		onUpdate?: Function
		cl?: string
		placeholder?: string
		// If true, `searchExtra` will match auto-complete on the "extra" text
		searchExtra?: boolean
		// Returns an array of options with the shape - IS A PROMISE
		// - `key`: PropTypes.number - passed back to the parent component when selected
		// - `text`: PropTypes.number.isRequired - is what is put in the text box
		// - `extra`: PropTypes.string - extra text to help differentiate options with same name
		getOptions?: (resolve, reject) => void
	},
	any // TODO: fix state type
> {
	element: React.RefObject<J2rText>
	callback: (options: any) => any
	fetching: any

	constructor(props) {
		super(props)
		this.element = React.createRef()
		this.state = {
			value: this.props.value ?? '',
			focused: false,
			closed: false,
			highlightedIndex: -1,
			cachedMatches: [],
		}
	}

	override componentDidMount() {
		this.getMatchingOptions(x => {
			this.setState({ cachedMatches: x })
		})
	}

	override componentDidUpdate(prevProps, prevState) {
		// If the props have changed or the value/focus has changed, refresh the matches
		const cause_for_change = _.some([
			!_.isEqual(prevProps, this.props),
			this.state.value !== prevState.value,
		])
		if (cause_for_change) {
			this.getMatchingOptions(x => {
				this.setState({ cachedMatches: x })
			})
		}
	}

	override render() {
		return j2r({
			cl: 'j2rautocomplete',
			children: [this.buildInput(), this.buildOptions()],
		})
	}

	buildInput() {
		return {
			tag: J2rText,
			key: 'input',
			ref: this.element,
			autoComplete: 'off',
			value: this.state.value,
			onKeyDown: e => {
				switch (e.which) {
					case 27: // Escape
						e.stopPropagation()
						e.preventDefault()
						this.setState({ closed: true })
						break
					case 38: // Arrow up
						this.moveSelection(e, -1)
						break
					case 40: // Arrow down
						this.moveSelection(e, 1)
						break
					case 13: // Enter
						this.confirmSelection(true)
						break
					case 9: // Tab
						this.confirmSelection(false)
						break
				}
			},
			onFocus: () => {
				this.setState({
					focused: true,
					closed: false,
				})
			},
			onBlur: () => {
				this.setState({
					focused: false,
					closed: false,
					highlightedIndex: -1,
				})
			},
			onUpdate: v => {
				this.setState(
					{
						value: v,
						closed: false,
						highlightedIndex: -1,
					},
					() => {
						this.updateValue(v, null)
					},
				)
			},
		}
	}

	willShowOptions() {
		return _.every([
			this.state.focused,
			!this.state.closed,
			this.state.cachedMatches.length > 0,
		])
	}

	buildOptions() {
		return {
			tag: J2rModalPortal,
			key: 'modal-options-outer',
			children: [
				{
					key: 'options',
					cl: BuildClass({
						'autocomplete-options': true,
						closed: !this.willShowOptions(),
					}),
					style: Do(() => {
						const el = this.element.current?.element.current
						const box = el?.getBoundingClientRect() ?? {
							bottom: 0,
							left: 0,
							width: 0,
						}
						return {
							top: `${box.bottom - 1}px`,
							left: `${box.left}px`,
							minWidth: `${box.width}px`,
						}
					}),
					children: Do(() => {
						// If the box isn't focused, don't display anything
						// Same if it has been explicitly closed or has no items
						if (!this.willShowOptions()) {
							return []
						}

						// Build the rows
						// Cap the results to only show the first N results
						const items_to_show = this.state.cachedMatches.slice(
							0,
							MAX_AUTOCOMPLETE_SHOWN,
						)
						const items = items_to_show.map((option, index) => ({
							key: option.key,
							cl: BuildClass({
								option: true,
								highlighted: this.state.highlightedIndex === index,
							}),
							children: [
								{
									tag: 'span',
									cl: 'text',
									key: 'text',
									text: option.text,
								},
								ConditionalObject(option.extra, {
									tag: 'span',
									cl: 'extra',
									key: 'extra',
									text: option.extra ?? '',
								}),
							],
							// This stops the input box from losing focus, closing the options
							onMouseDown: e => {
								e.stopPropagation()
								e.preventDefault()
							},
							// Explicitly select this item
							onClick: e => {
								e.stopPropagation()
								e.preventDefault()
								this.confirmSelection(true, option)
							},
						}))

						// If there were more than what is shown, show the number in excess
						var excess = Do(() => {
							if (
								this.state.cachedMatches.length > MAX_AUTOCOMPLETE_SHOWN
							) {
								return {
									cl: 'excess-count',
									key: 'excess-count',
									text: Do(() => {
										excess =
											this.state.cachedMatches.length -
											MAX_AUTOCOMPLETE_SHOWN
										return `${excess} more found`
									}),
								}
							}
							return undefined
						})

						// Return the items with the excess at the end
						return _.flatten([items, excess])
					}),
				},
			],
		}
	}

	getMatchingOptions(cb) {
		// If the box isn't focused or if it's empty, nothing matches
		if (this.state.value.length === 0) {
			cb([])
			return
		}

		// Differentiate between a prefix match, needle match, and fuzzy match
		// Prefix match - best kind, you're starting to write that string
		// Needle match - it's within the string without any gaps
		// Fuzzy match - if you squint you can see it - skips some characters to work
		const value = this.state.value.toLowerCase().trim()
		const fuzzyValue = new RegExp(Array.from(value).join('.*?'))
		const prefixMatch = s => s.startsWith(value)
		const needleMatch = s => s.indexOf(value) !== -1
		const fuzzyMatch = s => s.match(fuzzyValue) != null
		const searchExtra = Boolean(this.props.searchExtra)

		// Get the options that match for each
		this.callback = options => {
			const matches = _.compact(
				_.map(options, option => {
					// Get the match type for this option
					const txt = option.text.toLowerCase().trim()
					const extra = option.extra.toLowerCase().trim()
					const match_type = Do(() => {
						if (prefixMatch(txt)) {
							return 1
						} else if (needleMatch(txt)) {
							return 2
						} else if (fuzzyMatch(txt)) {
							return 3
						} else if (searchExtra && prefixMatch(extra)) {
							return 4
						} else if (searchExtra && needleMatch(extra)) {
							return 5
						} else if (searchExtra && fuzzyMatch(extra)) {
							return 6
						}
						return -1
					})

					// If it's -1, return null - effectively filters it out
					if (match_type === -1) {
						return null
					}

					// Otherwise, return the option with its match type for supplemental sorting
					return { option, match_type }
				}),
			)

			// Sort such that the better matches are up top
			return cb(
				fsmData(matches, {
					sort: match =>
						`${match.match_type}-${match.option.text}-${
							match.option.extra ?? ''
						}`,
					map: match => match.option,
				}),
			)
		}

		// Fetch the options - callback is executed once done
		this.fetchOptions()
	}

	fetchOptions() {
		if (this.fetching) {
			return
		}
		this.fetching = true
		const fn = this.props.getOptions ?? Promise.resolve
		new Promise(fn).then(x => {
			this.callback(x)
			this.fetching = false
		})
	}

	moveSelection(ev, delta) {
		// Cancel existing event behaviour for the arrow keys
		ev.preventDefault()
		ev.stopPropagation()

		// Get and modify the index
		this.setState(s => {
			let index = s.highlightedIndex ?? -1
			index += delta
			const maximum = Math.min(s.cachedMatches.length, MAX_AUTOCOMPLETE_SHOWN) - 1
			if (index < 0) {
				index = 0
			}
			if (index > maximum) {
				index = maximum
			}
			return { highlightedIndex: index }
		})
	}

	confirmSelection(is_explicit, selected_item?) {
		// Confirms the selection that has been made
		// The `key` param defaults to the currently-highlighted index's key if not supplied
		// The `is_explicit` flag denotes whether we assume a single match is used if no
		// highlight (arrow key) is made

		// Get the key that has been selected - null if no selection has been made
		// A selection can either be from having it highlighted (arrow keys) or from
		// clicking on the option explicitly
		if (selected_item == null) {
			selected_item = this.state.cachedMatches[this.state.highlightedIndex]
		}

		// If this is explicit, we can use the sole option if there was one
		if (is_explicit && this.state.cachedMatches.length === 1) {
			selected_item = this.state.cachedMatches[0]
		}

		// At this point if there's no selection, nothing can be done
		if (selected_item == null) {
			return
		}

		// At this pont, we have a selection that can be made
		this.updateValue(selected_item.text, selected_item.key)

		// If this was explicit, explicitly close while holding focus
		if (is_explicit) {
			this.setState({ closed: true })
		}
	}

	updateValue(text, key) {
		this.setState({ value: text })
		this.props.onUpdate(text, key)
	}
}
