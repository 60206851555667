import { Alerts } from './component-main'
import { trionlineAjax } from './component-trionline'

/**
 * @deprecated Use trionlineAjax instead
 */
export const DoAjax = input => {
	let { url } = input
	let { data } = input
	let { success } = input
	let { icon } = input
	let { method } = input
	let { cache } = input
	let { dataType } = input
	let { error } = input

	// Default to text data type
	if (!dataType) {
		dataType = 'html'
	}

	// Default to cache
	if (!cache) {
		cache = true
	}

	// Whether we're using the loading icon
	icon = icon ? $(icon) : false

	// Default method to GET
	if (!method) {
		method = 'GET'
	}

	// Default success function to empty
	if (!success) {
		success = data => {
			if (data) {
				Alerts.Alert({ msg: 'Unexpected Error' })
				console.log(data)
			}
		}
	}

	// Default data to empty
	if (!data) {
		data = {}
	}
	if (!error) {
		error = data => {
			console.log('AJAX ERROR')
			console.log(data)
		}
	}

	if (icon) {
		icon.html("<img class='ajax_ico' src='/static/img/ajax-loader.gif' alt='...' />")
	}

	// Fix directory for Python scripts if required
	if (url.slice(-3) === '.py' && url.indexOf('/') === -1) {
		url = `/py/${url}`
		url = url.replace('/py//py', '/py/')
	}

	// Add default data arguments
	try {
		data.sid = $('#sid').val()
	} catch (error1) {}
	// Do nothing

	// Encode all Data as URI components
	for (const key in data) {
		if (data.hasOwnProperty(key)) {
			data[key] = encodeURIComponent(data[key])
		}
	}

	// Perform AJAX request
	trionlineAjax({
		url,
		data,
		method,
		yes: success,
		no: error,
	})
}
