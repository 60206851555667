import { BuildClass, Maybe } from '../../../../universal'
import { React, _ } from '../../../lib'
import { startGlobalVoiceRecording } from '../voice-recorder'
import { FormRow } from '../wrappers'
import { runLLMFormFill } from './llm-fill'
import {
	FormFieldJSX,
	FormFixer,
	FormPropsFields,
	InferValueFromFieldsPublic,
	InferValueFromFieldsRaw,
} from './types'

export enum FormSaveOutcome {
	Good = 1,
	Neutral = 2,
	Error = 3,
}
type FormSaveResponse = {
	status: FormSaveOutcome
	message: string
}

const isDarkTheme = (): boolean => window.rootUI.state.theme == 'black'

const theme = {
	bgChangedGood: () => (isDarkTheme() ? '!bg-[#040]' : '!bg-[#afa]'),
	bgChangedBad: () => (isDarkTheme() ? '!bg-[#500]' : '!bg-[#faa]'),
}

/**
 * Manages the state and fields of a form.
 * This powers the simple FormComponent renderer, but can be used to do more complex things.
 */
export class FormManager<F extends FormPropsFields> {
	public readonly fields: F
	private readonly formFixer: Maybe<FormFixer<F>>
	public readonly disableFormFill: boolean
	public readonly defaultState: InferValueFromFieldsRaw<F>

	public constructor(input: {
		fields: F
		formFixer?: FormFixer<F>
		disableFormFill?: boolean
	}) {
		this.fields = input.fields
		this.formFixer = input.formFixer
		this.defaultState = this.getDefaultState()
		const realUserID = window.rootData.Session.RealUser
		this.disableFormFill =
			input.disableFormFill ?? (realUserID == null || realUserID > 100000)
	}

	/** Get the default values based on the components */
	private getDefaultState(): InferValueFromFieldsRaw<F> {
		return _.fromPairs(
			_.map(this.fields, (x, k) => {
				const toInternalFn = x.typeMap?.toInternal ?? (a => a)
				const defFn = x.def ?? x.valueDefaults.def
				const v: unknown = runFixerFull(x, toInternalFn(defFn()))
				return [k, v]
			}),
		) as InferValueFromFieldsRaw<F>
	}

	/** Helper function to convert the internal state into a public one */
	public getPublicState(
		internalState: InferValueFromFieldsRaw<F>,
	): InferValueFromFieldsPublic<F> {
		return _.fromPairs(
			_.map(this.fields, (F, k) => {
				const fn = F.typeMap?.toPublic ?? (x => x)
				return [k, fn(internalState[k])]
			}),
		) as InferValueFromFieldsPublic<F>
	}

	/** Gets the validation errors */
	public getValidationErrors(currentState: InferValueFromFieldsRaw<F>): string[] {
		return _.compact(
			_.flatMap(this.fields, (_x, k) =>
				this.getValidationError(
					k,
					currentState[k] as InferValueFromFieldsRaw<F>[string],
				),
			),
		)
	}

	public getValidationError<K extends keyof F>(
		field: K,
		value: InferValueFromFieldsRaw<F>[K],
	): string[] {
		const x = this.fields[field]
		if (!x) {
			return []
		}
		return _.compact(
			[...(x.validators ?? []), ...(x.valueDefaults.validators ?? [])].map(v => {
				if (!v) {
					return null
				}
				if (!v.req(value)) {
					return `Invalid ${x.lbl}: ${v.msg}`
				}
				return null
			}),
		)
	}

	/** Update the value of a field */
	public updateValue<K extends keyof F>(input: {
		currentState: InferValueFromFieldsRaw<F>
		key: K
		value: F[K]
		fullFieldFix?: boolean
	}): InferValueFromFieldsRaw<F> {
		const field = this.fields[input.key]
		const fieldFixer = input.fullFieldFix ? runFixerFull : runFixerImmediate
		const new_state = {
			...input.currentState,
			[input.key]: fieldFixer(field, input.value),
		}
		return {
			...new_state,
			...(this.formFixer?.(input.currentState, new_state, input.key) ?? {}),
		}
	}

	/** Renders the JSX of a field component in a `FormRow` */
	public renderComponentFormRow<K extends keyof F>(input: {
		currentState: InferValueFromFieldsRaw<F>
		key: K
		lblWidth: number
		highlightChanges: boolean
		onUpdate: (
			fn: (currentState: InferValueFromFieldsRaw<F>) => InferValueFromFieldsRaw<F>,
		) => void
		onFocus?: () => void
		onBlur?: () => void
	}): React.JSX.Element {
		// Get the field
		const field = this.fields[input.key] as FormFieldJSX<unknown>

		// Default values
		const removeLabel = field.removeLabel ?? false

		// See if it's changed and get the validation errors
		const hasChanged = !_.isEqual(
			this.defaultState[input.key],
			input.currentState[input.key],
		)
		const errors = this.getValidationError(input.key, input.currentState[input.key])

		// Render
		return (
			<FormRow
				lbl={field.emptyLabel ? '' : field.lbl}
				className={BuildClass({
					[field.className ?? '']: true,
					[theme.bgChangedGood()]:
						input.highlightChanges && hasChanged && errors.length == 0,
					[theme.bgChangedBad()]: hasChanged && errors.length > 0,
				})}
				lblWidth={removeLabel ? 0 : input.lblWidth}
				title={_.compact([field.tooltip, errors.join(', ')]).join(' - ')}
				showHelp={field.helpIcon}
				key={input.key as string}
			>
				{this.renderComponent({
					...input,
					highlightChanges: false,
					highlight: false,
					errorTooltips: false,
				})}
			</FormRow>
		)
	}

	/** Renders the JSX of a field component without the `FormRow` */
	public renderComponent<K extends keyof F>(input: {
		currentState: InferValueFromFieldsRaw<F>
		key: K
		onUpdate: (
			fn: (currentState: InferValueFromFieldsRaw<F>) => InferValueFromFieldsRaw<F>,
		) => void
		onFocus?: () => void
		onBlur?: () => void
		/** Whether changes should be highlighted in green. Makes sense when editing but not adding */
		highlightChanges: boolean
		/** Whether to add highlights. Default: TRUE */
		highlight?: boolean
		/** Whether to show tooltips on the field if there are validation errors. Default: TRUE */
		errorTooltips?: boolean
	}): React.JSX.Element {
		// Alias for key and field
		const key = input.key
		const field = this.fields[key] as FormFieldJSX<unknown>

		// Default values for params
		const highlighting = input.highlight ?? true
		const showErrorTooltips = input.errorTooltips ?? true

		// Check whether it's changed and get the validation errors
		const hasChanged =
			highlighting &&
			!_.isEqual(this.defaultState[input.key], input.currentState[input.key])
		const errors = highlighting
			? this.getValidationError(input.key, input.currentState[input.key])
			: []

		// Render
		return field.jsx({
			lbl: field.lbl,
			value: input.currentState[key],
			className: BuildClass({
				[theme.bgChangedGood()]:
					input.highlightChanges && hasChanged && errors.length == 0,
				[theme.bgChangedBad()]: hasChanged && errors.length > 0,
			}),
			onUpdate: v => {
				input.onUpdate(s =>
					this.updateValue({
						currentState: s,
						key: key,
						value: v as F[typeof key],
						fullFieldFix: false,
					}),
				)
			},
			disabled: field.disabled ?? false,
			readOnly: field.readOnly ?? false,
			title: showErrorTooltips ? errors.join(', ') : null,
			onFocus: () => {
				input.onFocus?.()
			},
			onBlur: () => {
				input.onUpdate(s =>
					this.updateValue({
						currentState: s,
						key: key,
						value: s[key] as F[typeof key],
						fullFieldFix: true,
					}),
				)
				input.onBlur?.()
			},
		})
	}

	/**
	 * Wraps a user-defined save handler, doing validation checks on the form.
	 * If nothing has changed, it is returned as a neutral type of error
	 */
	public async trySaving(input: {
		currentState: InferValueFromFieldsRaw<F>
		setState: (newState: InferValueFromFieldsRaw<F>) => void
		onSave: (
			model: InferValueFromFieldsPublic<F>,
			callback: (result: true | string) => void,
		) => void
	}): Promise<FormSaveResponse> {
		return new Promise<FormSaveResponse>(resolve => {
			// Run the value fixers on the state and update before saving
			const newState = _.fromPairs(
				_.map(this.fields, (x, k) => {
					const v = runFixerFull<unknown>(x, input.currentState[k])
					return [k, v]
				}),
			) as InferValueFromFieldsRaw<F>
			input.setState(newState)

			// Ensure something has changed before saving
			// Needs to be calculated again because the state may have just changed
			if (_.isEqual(this.defaultState, newState)) {
				resolve({
					status: FormSaveOutcome.Neutral,
					message: 'Nothing has changed',
				})
				return
			}

			// Ensure there are no errors
			const errors = this.getValidationErrors(newState)
			if (errors.length > 0) {
				resolve({
					status: FormSaveOutcome.Error,
					message: errors.join('\n'),
				})
				return
			}

			// Run the user-defined save function and wait for the callback response
			const publicState = this.getPublicState(newState)
			input.onSave(publicState, result => {
				// User-defined save function complete
				// If it wasn't successful, show the supplied error message
				// This error usually comes from the linked server-side request
				if (result !== true) {
					resolve({
						status: FormSaveOutcome.Error,
						message: result,
					})
					return
				}

				// Request completed successfully
				resolve({
					status: FormSaveOutcome.Good,
					message: '',
				})
			})
		})
	}

	/** Start a transcription form-fill, beginning with starting the microphone */
	public startTranscriptionFormFill(input: {
		currentState: InferValueFromFieldsRaw<F>
		editBeforeSending: boolean
		voicePrompt?: Maybe<string>
		formPrompt: Maybe<string>
		setState: (newState: InferValueFromFieldsRaw<F>) => void
		containingElement: HTMLElement
	}): void {
		// Check if form filling is disabled
		if (this.disableFormFill) {
			console.log('Form fill is disabled')
			return
		}
		// Start the voice recording - the recording is transcribed
		startGlobalVoiceRecording({
			editBeforeSending: input.editBeforeSending,
			prompt: input.voicePrompt,
			originatingFormElement: input.containingElement,
			onTranscribed: (requestText, callback) => {
				console.log(requestText)

				// We have the text!
				// Make the request to form-fill to the LLM
				runLLMFormFill({
					currentState: input.currentState,
					manager: this,
					description: input.formPrompt,
					request: requestText,
					yes: values => {
						console.log({ values })
						input.setState({
							...input.currentState,
							..._.mapValues(values, (v, k) => {
								const old_val = input.currentState[k]
								const reversal_fn = this.fields[k]?.llmInfo?.reversal
								const new_val = reversal_fn ? reversal_fn(v, old_val) : v
								return runFixerFull<unknown>(this.fields[k], new_val)
							}),
						})

						// Close the audio transcription window with the loading spinner
						callback(true)
					},
				})
			},
		})
	}

	/**
	 * Handles the `onKeyDown` event for a hotkey.
	 * Pass in the event - it may not do anything unless specific keys are pressed
	 */
	public hotkeyPress(
		event: React.KeyboardEvent,
		input: {
			currentState: InferValueFromFieldsRaw<F>
			setState: (newState: InferValueFromFieldsRaw<F>) => void
			/** Passed to Whisper to slightly improve transcription of special non-phonetic words */
			voicePrompt?: Maybe<string>
			/** Passed to the LLM to explain what the form is for */
			formPrompt: Maybe<string>
			/**
			 * Define the containing element so we can draw a box around it while listening
			 * This ensures that the user knows the scope of the current form
			 */
			containingElement: HTMLElement
		},
	): void {
		// Check if the right ctrl key has been pressed
		if (event.code == 'ControlRight' && !event.repeat && !this.disableFormFill) {
			console.log('Starting voice input')
			event.stopPropagation()
			this.startTranscriptionFormFill({
				currentState: input.currentState,
				editBeforeSending: false,
				formPrompt: input.formPrompt,
				voicePrompt: input.voicePrompt,
				setState: input.setState,
				containingElement: input.containingElement,
			})
		}
	}

	/** Handle the `onKeyUp` event */
	public hotkeyRelease(event: React.KeyboardEvent): void {
		// Check if the right ctrl key has been released
		if (event.code == 'ControlRight') {
			event.stopPropagation()
		}
	}
}

export const runFixerFull = <T,>(field: Maybe<FormFieldJSX<T>>, value: T): T => {
	// Run the immediate one before and again after doing the full fixer
	// Sometimes they compound (e.g. trim whitespace and capitalise first letter)
	if (!field) {
		return value
	}
	const v1 = runFixerImmediate(field, value)
	const v2 = field.fixer?.(v1) ?? v1
	const v3 = field.valueDefaults.fixer?.(v2) ?? v2
	return runFixerImmediate(field, v3)
}

export const runFixerImmediate = <T,>(field: Maybe<FormFieldJSX<T>>, value: T): T => {
	if (!field) {
		return value
	}
	const v1 = field.fixerImmediate?.(value) ?? value
	return field.valueDefaults.fixerImmediate?.(v1) ?? v1
}
