import { BuildClass, TW_OVERFLOW_ELLIPSIS } from '../../../universal'
import { React, _ } from '../../lib'
import { HelpIcon } from './help'
import { LoadingSpinnerLarge, LoadingSpinnerSmall } from './loading'
import { CJSX, ConditionalObject } from './meta-types'

/**
 * `FormRow` - wraps a component with a label, suitable for a form
 * @param lbl The label text to show on the form row.
 * @param title The hover text to show on the form row.
 * @param showHelp Shows a help icon on the RHS with the title (default: false)
 * @param children Component element to show to the right of the label.
 * @param cl Class to apply to label
 */
export const FormRow = (props: {
	lbl: string
	title?: string
	showHelp?: boolean
	children: React.JSX.Element | React.JSX.Element[] | string | number
	className?: string
	lblClass?: string
	lblWidth?: number
	onLabelClick?: (ev: React.MouseEvent<HTMLSpanElement>) => void
}): React.JSX.Element => (
	<label
		className={BuildClass({
			'ui5-form-row': true, // Needed for old CSS links
			'tailwind-wrapper flex': true, // Redundant - done with direct style, only works if inside another tailwind wrapper
			[props.className ?? '']: true,
		})}
		style={{ display: 'flex' }} // TODO - remove once everything uses a tailwind wrapper
	>
		<span
			className={BuildClass({
				lbl: true,
				'flex-1 max-w-[100px] leading-9 h-9 pl-1 select-none': true,
				[TW_OVERFLOW_ELLIPSIS]: true,
				'max-w-[100px]': props.lblWidth != null,
				hidden: props.lblWidth === 0,
				[props.lblClass ?? '']: true,
			})}
			tabIndex={-1}
			style={{
				maxWidth: props.lblWidth ?? undefined,
				minWidth: props.lblWidth ?? undefined,
			}}
			title={props.title ?? props.lbl}
			onClick={props.onLabelClick}
		>
			{props.lbl}
		</span>
		<span
			className={BuildClass({
				cmpt: true,
				'flex-1 leading-7 min-h-[36px] p-1': true,
				[TW_OVERFLOW_ELLIPSIS]: true,
			})}
		>
			{React.Children.map(props.children, x => {
				if (_.isString(x) || _.isNumber(x)) {
					return x
				}
				return React.cloneElement(x, {
					className: BuildClass({
						'w-full align-top': true,
						[x.props.className ?? '']: true,
					}),
				})
			})}
		</span>
		{ConditionalObject(
			(props.showHelp ?? false) && Boolean(props.title),
			<span
				className={BuildClass({
					'help-icon': true,
					'flex-1 max-w-[20px] min-w-[20px] text-center': true,
				})}
			>
				<HelpIcon
					className="inline-block w-full pt-[9px] pb-[7px] px-0"
					title={props.title ?? ''}
				/>
			</span>,
		)}
	</label>
)

/**
 * Wraps a component in a `FormRow` and adds boilerplate `value` and `onUpdate` props.
 * @param obj The component holding the `state` object to be changed.
 * @param key The key on the `state` object to be used as boilerplate.
 * @param lbl The label text to show on the form row.
 * @param title The hover text to show on the form row.
 * @param showHelp Shows a help icon on the RHS with the title (default: false)
 * @param children The inner component to be mutated and rendered.
 */
export const FormCmpt = <T, S extends object>(props: {
	obj: React.Component<T, S>
	field: keyof S
	lbl: string
	lblWidth?: number
	showHelp?: boolean
	className?: string
	title?: string
	children: React.JSX.Element
}): React.JSX.Element => (
	<FormRow
		lbl={props.lbl}
		lblWidth={props.lblWidth}
		className={props.className}
		title={props.title}
		showHelp={props.showHelp}
	>
		{makeCmpt(props.obj, props.field, props.children)}
	</FormRow>
)

/**
 * Adds boilerplate `value` and `onUpdate` props.
 * @param parentComponent The component holding the `state` object to be changed.
 * @param stateKey The key on the `state` object to be used as boilerplate.
 * @param cmpt The inner component to be mutated and rendered.
 */
export const makeCmpt = <T, S extends object>(
	parentComponent: React.Component<T, S>,
	stateKey: keyof S,
	cmpt: React.JSX.Element,
): React.JSX.Element =>
	React.cloneElement(cmpt, {
		value: parentComponent.state[stateKey],
		onUpdate: (v: any) => {
			const delta = { [stateKey]: v } as Pick<S, keyof S>
			parentComponent.setState(delta)
		},
	})

/** Used to add a dummy `value` and `onUpdate` to component wrapped by `FormCmpt` */
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const dummyFP = { value: null as any, onUpdate: (): void => undefined }

/** Helper function for a span element with a tooltip */
export const txt = (text: string | number): React.JSX.Element => (
	<span title={String(text)}>{text}</span>
)

/**
 * Wrapper renderer for an async context loader
 * Shows loading spinners while context data is fetching.
 * Child components can run `React.useContext` on given provider
 */
export const AsyncContextLoader = <D extends object>(props: {
	context: AsyncContextLoaderContext<D, any>
	children: React.JSX.Element | React.JSX.Element[]
	large?: boolean
}): React.JSX.Element => {
	// Load the data when the component first mounts
	const [ctxData, setCtxData] = React.useState(null)
	const [hasLoaded, setHasLoaded] = React.useState(false)
	React.useEffect(() => {
		console.log('Loading')
		if (!hasLoaded) {
			props.context
				.loader()
				.then(data => {
					console.log('Loaded', data)
					setCtxData(data)
					setHasLoaded(true)
				})
				.catch(err => {
					console.error('Error loading context', err)
				})
		}
	}, [])
	console.log('ctxData', ctxData)

	// Render
	return (
		<>
			{/* Loading spinners while we wait */}
			<CJSX cond={ctxData == null}>
				<CJSX cond={props.large !== false}>
					<LoadingSpinnerLarge />
				</CJSX>
				<CJSX cond={props.large === false}>
					<LoadingSpinnerSmall />
				</CJSX>
			</CJSX>
			{/* Show the inner component now that it's loaded */}
			<CJSX cond={ctxData != null}>
				<props.context.context.Provider value={ctxData}>
					{props.children}
				</props.context.context.Provider>
			</CJSX>
		</>
	)
}

/** The output type of `CreateDataModelSubsetContext` **/
export type AsyncContextLoaderContext<D extends object, K extends (keyof D)[]> = {
	read: () => Pick<D, K[number]>
	context: React.Context<Pick<D, K[number]>>
	loader: () => Promise<Pick<D, K[number]>>
	getter: () => Pick<D, K[number]>
	_data: D
	_keys: K
}

/** Creates a HR data model subset context, derived from `loadHRDataModelNodes` function */
export const CreateDataModelSubsetContext = <D extends object, K extends (keyof D)[]>(
	data: D,
	keys: K,
	loader: (data: D, keys: K) => Promise<Pick<D, K[number]>>,
): AsyncContextLoaderContext<Pick<D, K[number]>, K> => {
	// Create the React context and wrap the loader, reader, and raw context
	const ctx = React.createContext<Pick<D, K[number]>>(_.pick(data, keys))
	return {
		read: () => React.useContext(ctx),
		context: ctx,
		loader: async () => loader(data, keys),
		getter: () => _.pick(data, keys),
		_data: data,
		_keys: keys,
	}
}

/**
 * Wraps a functional React component with a dynamic context. Renders a loading spinner
 * until the data loader promise has resolved, then renders the component with the given
 * props forwarded. The context object is available on all recursive children
 */
export const LoadWithDataSubset = <
	D extends object,
	K extends (keyof D)[],
	P extends object,
>(
	ctx: AsyncContextLoaderContext<Pick<D, K[number]>, K>,
	cmpt: (props: P) => React.JSX.Element,
	props: React.PropsWithChildren<P>,
): React.JSX.Element => {
	console.log('Rendering with data subset')
	return (
		<AsyncContextLoader context={ctx}>
			{React.createElement(cmpt, props)}
		</AsyncContextLoader>
	)
}
