import { BuildClass, Maybe, clamp } from '../../../universal'
import { React, ReactDOM, _ } from '../../lib'
import { ConditionalObject } from './meta-types'
import { stubDiv } from './stubs'

export const Modal = (props: {
	className?: string
	children: React.JSX.Element | React.JSX.Element[]
}) => {
	// References
	const mounted = React.useRef<boolean>(false)
	const element = React.useRef<HTMLDivElement>(stubDiv)
	const [__, setEl] = React.useState<HTMLDivElement>()

	// Mount and dismount functions
	React.useEffect(() => {
		mounted.current = true

		// Get the modal container
		const container = document.querySelector('#modal-container')
		if (!container) {
			console.error('No #modal-container defined')
			return _.noop
		}

		// Find an unused element and claim it
		let el: Maybe<Element> = container.querySelector('.available')
		if (el) {
			element.current = el as HTMLDivElement
			setEl(el as HTMLDivElement)
			return _.noop
		}

		// Create a new element
		el = document.createElement('div')
		container.appendChild(el)
		element.current = el as HTMLDivElement
		setEl(el as HTMLDivElement)

		// Run on dismount
		return () => {
			if (element.current) {
				element.current.className = 'ui5-modal available'
			}
			mounted.current = false
		}
	}, [])

	// When the class changes
	React.useEffect(() => {
		if (element.current) {
			element.current.className = BuildClass({
				'ui5-modal': true,
				[props.className ?? '']: true,
			})
		}
	}, [props.className, element.current])

	// Render
	if (!element.current) {
		return <></>
	}
	return ReactDOM.createPortal(props.children, element.current)
}

export type ModalAnchoredInstance = {
	getElement: () => HTMLDivElement
}

const ModalAnchoredComponent = (
	props: {
		className?: string
		children: React.JSX.Element | React.JSX.Element[]
		mountElement: HTMLElement
		hidden: boolean
		width: Maybe<number>
		maxHeight: number
		flipHeightThreshold?: number
		alignX?: 'left' | 'right' | 'center' // Default left
		flipByDefault?: boolean
	},
	ref: React.ForwardedRef<ModalAnchoredInstance>,
) => {
	// Refs
	const element = React.useRef<HTMLDivElement>(stubDiv)

	// Get the alignment setting
	const alignX = props.alignX ?? 'left'

	// Determine basic position style for the pop-out
	const rect = props.mountElement.getBoundingClientRect()

	// Start with the X coordinate - much easier
	const width = props.width ?? rect.width
	const leftMap = {
		left: rect.left,
		right: rect.right - width,
		center: (rect.left + rect.right - width) / 2,
	}
	const left = clamp(leftMap[alignX], 0, window.innerWidth - width)

	// Check how much height we have available going up vs down
	// If it's better to flip up above the textbox, do that instead
	const flipThreshold = props.flipHeightThreshold ?? props.maxHeight
	const height_down = clamp(window.innerHeight - rect.bottom, 0, props.maxHeight)
	const height_up = clamp(rect.top, 0, props.maxHeight)
	const isFlipped =
		(props.flipByDefault ?? false)
			? !(height_up < flipThreshold && height_down > height_up)
			: height_down < flipThreshold && height_up > height_down
	const height = isFlipped ? height_up : height_down
	const top = isFlipped ? rect.top - height_up - 1 : rect.bottom - 1

	// Instance reference
	React.useImperativeHandle(ref, () => ({
		getElement: () => element.current,
	}))

	// Render
	return (
		<Modal>
			<div
				ref={element}
				className={BuildClass({
					'ui5-modal-inner': true,
					[props.className ?? '']: true,
					hidden: props.hidden,
					flipped: isFlipped,
				})}
				style={{
					left: `${left}px`,
					top: `${top}px`,
					width: `${width}px`,
					maxHeight: `${height}px`,
					minWidth: `${width}px`,
				}}
				onMouseDown={e => {
					e.stopPropagation()
					e.preventDefault()
				}}
			>
				{ConditionalObject(!props.hidden, props.children)}
			</div>
		</Modal>
	)
}

/**
 * A modal window with an inner div of specific dimensions, anchored to a given element.
 * Adjusts position if there is no space on-screen to show the full box
 * @param className Optional class for inner element
 * @param children JSX children
 * @param mountElement The HTML element to mount to
 * @param hidden Whether the box should be hidden or not
 * @param width An override width for the box - defaults to the width of `mountElement`
 * @param maxHeight The maximum height of the box
 * @param flipHeightThreshold Optional height at which it flips up if unavailable
 */
export const ModalAnchored = React.forwardRef(ModalAnchoredComponent)
