import { BuildClass, Do, timer } from '../../universal'
import { React, _ } from '../lib'
import { J2rObject, j2r } from './component-react'

type J2rButtonProps = {
	img?: string
	label?: string
	cl?: string
	title?: string
	onClick?: Function
	type:
		| 'borderless'
		| 'submit'
		| 'delete'
		| 'standard'
		| 'button'
		| 'trans-red'
		| 'trans-blue'
	hidden?: boolean
	disabled?: boolean
	inner?: any[]
	doubleClickNeeded?: boolean
}

/**
 * Basic button component
 * @deprecated Use ui5 `Button`
 */
export class J2rButton extends React.Component<
	J2rButtonProps,
	{
		confirming: boolean
	}
> {
	static timeoutConfirmingMs = 2000
	confirmingTimer: number | undefined

	constructor(props: J2rButtonProps) {
		super(props)
		this.state = { confirming: false }
		this.confirmingTimer = undefined
	}

	override render() {
		return j2r({
			tag: 'button',
			cl: BuildClass({
				[this.props.cl ?? '']: true,
				btnStdBorderless: this.props.type === 'borderless',
				btnSubmit2: this.props.type === 'submit',
				btnDelete2: this.props.type === 'delete',
				btnStandard2: ['standard', 'button'].includes(this.props.type),
				btnTrans: ['trans-red', 'trans-blue'].includes(this.props.type),
				red: this.props.type === 'trans-red',
				blue: this.props.type === 'trans-blue',
				disabled: this.props.disabled ?? false,
				confirming: this.state.confirming,
			}),
			title: this.props.title ?? this.props.label,
			type: this.props.type === 'submit' ? 'submit' : 'button',
			disabled: this.props.disabled ? true : undefined,
			style: this.props.hidden ? { display: 'none' } : undefined,
			children: Do(() => {
				// If the button is awaiting a second click confirmation
				if (this.state.confirming) {
					return ['Confirm?']
				}

				// Custom inner elements for the button
				if (this.props.inner != null) {
					return this.props.inner
				}

				// Build the span to go inside the button
				// If it has an image defined, add an image tag before the label
				let el: J2rObject[] = []
				if (this.props.img != null) {
					el = [
						{
							tag: 'img',
							key: 'img',
							src: this.props.img,
						},
					]
				}
				el.push({
					tag: 'span',
					key: 'label',
					text: this.props.label,
				})
				return el
			}),

			// Click handler
			onClick: (e: React.MouseEvent) => {
				// If double-click is needed, deal with that first
				// If it's not already confirming, set it to the confirming state
				// If it is confirming, turn off that flag and allow through
				if (this.props.doubleClickNeeded) {
					if (!this.state.confirming) {
						this.setState({ confirming: true })
						this.confirmingTimer = timer(
							J2rButton.timeoutConfirmingMs,
							() => {
								this.setState({ confirming: false })
							},
						)
						return
					}
					this.setState({ confirming: false })
					clearTimeout(this.confirmingTimer)
				}

				// Run the click handler
				;(this.props.onClick ?? _.noop)(e)
			},
		})
	}
}
