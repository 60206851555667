import { Do, guid, timer } from '../../universal'
import { _ } from '../lib'
import { j2h } from './component-j2h'
import { unmountReactOnElement } from './component-react'
import { TOGLOB } from './component-trionline'

/**
 * Creates a new fly-out with simpler options than systemFlyout
 * @deprecated This is for pre-React layouts
 */
export var getNewFlyout = (title: string, size: number[], locked?: boolean) => {
	const $D = systemFlyout({
		title,
		size,
		content: '',
		force: !!locked,
	})
	$D.find('.flyout_buttons').remove()
	return $D.find('.floater_body').empty()
}

// Flyouts

/**
 * @deprecated This is for pre-React layouts
 */
export const systemFlyout = (I): JQuery<HTMLElement> => {
	// Sanitize the data object
	let pos_left
	const obj = (obj => {
		// Ensure object is at least an empty dictionary for default vals
		if (obj === undefined) {
			obj = {}
		}

		// Default body text
		if (obj.body === undefined) {
			obj.body = '<p>Content goes here</p>'
		}

		// If body is not html, wrap it in a paragraph tag
		if (obj.body.indexOf('<') === -1) {
			obj.body = `<p>${obj.body}</p>`
		}

		// Default size to 640 x 360
		if (!obj.size) {
			obj.size = [640, 360]
		}

		// Default ID is the GUID with a prefix
		if (!obj.ID) {
			obj.ID = `tofloat_${guid()}`
		}

		// Default function is empty callable
		if (!$.isFunction(obj.callback)) {
			obj.callback = _.noop
		}

		// Default x and y coords to null if none given
		if (!obj.coords) {
			obj.coords = [null, null]
		}

		// Buttons is an empty string
		if (!obj.buttons) {
			obj.buttons = ''
		}

		// If explicit HTML not given, default to title and body - most common
		if (obj.html === undefined) {
			obj.html = ''
			if (obj.title != null) {
				obj.html += `<div class="flyout_header"><h1>${obj.title}</h1></div>`
			}
			obj.html += `<div class="floater_body">${obj.body}</div>`
		}

		// If inline frame defined, use this in place of the body
		if (obj.iframe) {
			obj.html = ''
			if (obj.title != null) {
				obj.html += `<div class="flyout_header"><h1>${obj.title}</h1></div>`
			}
			obj.html += `<iframe src="${obj.iframe}"></iframe>`
		}

		// If tabs are defined, use this in place of body and append the buttons
		if (obj.tabs) {
			const append = Do(() => {
				let tab_html = ''
				let body = ''
				_.each(obj.tabs, (value, key) => {
					tab_html += `<span class="floating_tab_link" data-key>${key}</span>`
					key = key.toLowerCase().replace(/\s/g, '')
					body += `<div class="floater_body tab tab_${key}">${value}</div>`
				})
				return [tab_html, body]
			})
			obj.buttons += append[0]
			obj.body = append[1]
			obj.html = ''
			if (obj.title != null) {
				obj.html += `<div class="flyout_header"><h1>${obj.title}</h1></div>`
			}
			obj.html += obj.body
		}

		// Ensure that "force" is a bool
		obj.force = !!obj.force

		// Auto-tab defaults to the first tab
		if (!obj.autoTab) {
			obj.autoTab = 0
		}

		// If multiple sizes are specified, set the initial size to the first
		// Also ensure the length of the sizes matches the length of the tabs
		if (obj.sizes) {
			obj.size = obj.sizes[obj.autoTab] || [640, 480]
			if (obj.sizes.length !== _.keys(obj.tabs).length) {
				throw new Error('Sizes length mismatched with tab quantity')
			}
		}

		// Return the sanitized object
		return obj
	})(I)

	// Get the initial height and the height gap required
	const $window = $(window)
	let height_gap = ($window.height() - obj.size[1]) / 4
	height_gap = -(20 + height_gap + obj.size[1])
	const initial_height = -20 - obj.size[1]

	// Cap dimensionns based on screen size
	const max_width = $window.width() - 20
	const max_height = $window.height() - 20
	if (obj.size[0] > max_width) {
		obj.size[0] = max_width
	}
	if (obj.size[1] > max_height) {
		obj.size[1] = max_height
	}

	// Determine the left position based on width of flyout
	pos_left = !obj.coords[0]
		? `calc((100% - ${obj.size[0]}px) / 2)`
		: `${obj.coords[0]}px`

	// Create the div object
	const $div = $(
		j2h({
			ID: obj.ID,
			class: `to_floater ${obj.ID}`,
			html: obj.html,
		}),
	)
	$div.css({
		top: `${Math.round(initial_height)}px`,
		left: pos_left,
		width: `${obj.size[0]}px`,
		height: `${obj.size[1]}px`,
		opacity: '1',
	})

	// Remove transition
	if (!TOGLOB.showFlyoutAnimation) {
		$div.css({ transition: null })
	}

	// Tab height
	$div.find('.floater_body.tab').css({ height: 'calc(100% - 42px)' })

	// Give the responsive class name to the system flyout
	if ($('#hub_body').length > 0) {
		$div.addClass($('#hub_body').attr('class'))
	}
	$('body').append($div)

	// Flyout Buttons
	const $h1 = $div.find('h1')
	const $buttons = $(
		j2h({
			class: 'flyout_buttons toolbar_buttons noselect',
			html: obj.buttons,
		}),
	)
	$buttons.find('button').each((_i, elem) => {
		const $button = $(elem)
		const img = $button.attr('data-img')
		if (!img) {
			return
		}
		const label = $button.text()
		const html = `<img src='${img}' /><span>${label}</span>`
		$button.html(html)
	})

	// Check the sizing with the buttons
	$buttons.insertAfter($h1)
	$h1.css({ display: 'inline' })
	const heading_width = $h1.width() + 5
	$buttons.css({ width: `calc(100% - ${heading_width}px)` })

	// Write the sizes for each tab onto the tabs
	if (obj.sizes) {
		$div.find('.floating_tab_link').each((i, elem) => {
			const size = obj.sizes[i]
			$(elem).attr({
				'data-width': size[0],
				'data-height': size[1],
			})
		})
	}

	// Slide down
	let new_height = ($(window).height() - obj.size[1]) / 4
	if (obj.coords[1] !== null) {
		new_height = obj.coords[1]
	}
	const translation = new_height - initial_height
	$div.css('transform', `translate(0px, ${translation}px)`)
	$div.find('iframe').css({ height: `${obj.size[1] - 64}px` })

	// Handlers for tabs
	$div.find('span.floating_tab_link').click(e => {
		// Determine which tab was clicked and find its linked content pane
		const $tab = $(e.currentTarget)
		const $tabs = $tab.parent().children('span.floating_tab_link')
		const $tab_contents = $div.find('div.floater_body.tab')
		const key = $tab.text().toLowerCase().replace(/\s/g, '')

		// All all tabs and then show the selected one
		$tab_contents.hide()
		$tabs.removeClass('selected')
		$tab.addClass('selected')
		return $tab_contents.filter(`.tab_${key}`).each((_i, elem) => {
			const $elem = $(elem)
			if ($elem.hasClass('vertical')) {
				$elem.css({ display: 'inline-block' })
			} else {
				$elem.show()
			}
		})
	})

	// Execute once sliding down animation is complete
	const duration = TOGLOB.showFlyoutAnimation ? 500 : 0
	timer(duration, () => {
		const existing_top = parseInt($div.css('top'))
		const new_top = Math.round(existing_top + translation)
		const css = {
			transition: 'height 0.4s, width 0.4s, opacity 0.2s, left 0.4s',
			transform: 'translate(0px, 0px)',
			top: `${new_top}px`,
		}
		if (!TOGLOB.showFlyoutAnimation) {
			delete css.transition
		}
		$div.css(css)

		// Handlers for tabs
		$div.find('span.floating_tab_link').click(e => {
			// Get the requested width and height for this tab
			const $tab = $(e.currentTarget)
			const width = +$tab.attr('data-width')
			const height = +$tab.attr('data-height')
			if (isNaN(width)) {
				return
			}
			flyoutUpdateSize($div, width, height)
		})

		// Execute callback
		if ($.isFunction(obj.callback)) {
			obj.callback()
		}
	})

	// Convert tabs to being vertical if necessary
	if (obj.tabsVertical) {
		const $vertical = $(j2h({ class: 'verticalTabPane noselect' }))
		$div.find('.flyout_buttons')
			.find('span.floating_tab_link')
			.each((_i, elem) => {
				$(elem).appendTo($vertical)
			})
		$div.find('.flyout_header').after($vertical)
		$div.find('.floater_body.tab').addClass('vertical')
		$div.find('.flyout_header').find('h1').css({ width: '' })
	}

	// Open the default tab
	$div.find('span.floating_tab_link').eq(obj.autoTab).trigger('click')

	// Lock the page. Skip if already locked and there are no other fly-outs
	if (TOGLOB.lockLevel === 0 || $('.to_floater:not(.leaving)').length > 1) {
		to_page_lock(obj.force)
	}

	// Add the "nested_2" class if it is a second-level fly-out
	// Repeat for additional nesting levels
	$div.addClass(`nested_${TOGLOB.lockLevel}`)

	// Return the fly-out object
	return $div
}
/**
 * @deprecated This is for pre-React layouts
 */
export var flyoutUpdateSize = ($flyout, width, height) => {
	// Calculate the width and height - capped at what the window has
	const $w = $(window)
	const wHeight = $w.height()
	width = Math.min(width, $w.width() - 50)
	height = Math.min(height, wHeight - 50)
	const top = (wHeight - height) / 4

	// Get the duration
	const duration = TOGLOB.showFlyoutAnimation ? 500 : 0
	const d2 = duration === 0 ? 0 : 0.4
	const d1 = duration === 0 ? 0 : 0.2

	// Style objects for the transition changing
	const t1 = `height ${d2}s, width ${d2}s, opacity ${d1}s, left ${d2}s, top ${d2}s`
	const t2 = `height ${d2}s, width ${d2}s, opacity ${d1}s, left ${d2}s`
	const style1 = { transition: t1, '-webkit-transition': t1 }
	const style2 = { transition: t2, '-webkit-transition': t2 }

	// Add the transitions
	$flyout.css(style1)

	// Change the size and position
	$flyout.css({
		width: `${width}px`,
		height: `${height}px`,
		left: `calc((100% - ${width}px) / 2)`,
		top: `${top}px`,
	})

	// After the transition is complete, reset the transition style
	timer(duration, () => $flyout.css(style2))
}
/**
 * @deprecated This is for pre-React layouts
 */
export const closeMessage = (ID?) => {
	// Get window elements to close
	const ID_string = ID === undefined ? '' : `#${ID}`
	let $flyouts = $(`.to_floater${ID_string}`)

	// Filter to those above the current nesting level
	const { lockLevel } = TOGLOB
	if (lockLevel >= 2) {
		const classes = _.range(1, lockLevel).map(x => `nested_${x}`)
		$flyouts = $flyouts.filter((_i, elem) => {
			let skip = false
			const $flyout = $(elem)
			classes.forEach(c => {
				if ($flyout.hasClass(c)) {
					skip = true
				}
			})
			return !skip
		})
	}

	// If we're closing this message - terminate all event handlers
	// This prevents accidental clicks on a message that's leaving
	// TODO: This hack is in here because of the scheduled leave flyout for ATCC
	// This should be redone in React
	try {
		$flyouts.find('*').off()
	} catch (error) {}

	// Transition the object to fly away
	$flyouts.each((_i, elem) => {
		const $this = $(elem)
		$this.off('*')
		$this.addClass('leaving')

		// Unmount any React nodes mounted here
		try {
			unmountReactOnElement($this.find('.floater_body')[0])
			unmountReactOnElement($this[0])
		} catch (error1) {}

		// Skip animation altogether and just destroy if required
		if (!TOGLOB.showFlyoutAnimation) {
			$this.remove()
			return
		}
		const top_offset = -($this.height() + 20)
		const current_top = parseInt($this.css('top'))
		const translate = current_top - top_offset
		$this.css({
			top: `${top_offset}px`,
			transform: `translate(0px, ${translate}px)`,
		})
		timer(50, () => {
			$this.css({
				transition:
					'transform 0.2s, height 0.4s, width 0.4s, opacity 0.2s, left 0.4s',
			})
		})
		timer(55, () => {
			$this.css({
				transform: 'translate(0px, 0px)',
				opacity: '0',
			})
		})
		timer(400, () => {
			$this.remove()
		})
	})
}

// Page lock functions

/**
 * @deprecated This is for pre-React layouts
 */
var to_page_lock = force => {
	// Skip if >= 5
	if (TOGLOB.lockLevel >= 5) {
		return
	}

	// Get the current lock level and therefore the lock element
	const $lock = TOGLOB.$lock[TOGLOB.lockLevel]

	// Remove the transition style if no animations
	if (!TOGLOB.showFlyoutAnimation) {
		$lock.css({ transition: 'none' })
	}

	// Transform to the new position
	$lock.css({
		transform: 'translate(0px, 0px)',
		opacity: '0.5',
	})

	// Add the click handler for the lock pane
	$lock.on('click', e => {
		e.stopImmediatePropagation()
		e.preventDefault()
		to_page_unlock()
	})

	// Add a class to prevent close if forced/locked
	if (force !== false) {
		$lock.addClass('locked')
	}

	// Prevent body scrolling while the lock is active
	$('body').css({ 'overflow-y': 'hidden' })

	// Increment lock counter
	to_page_lock_level_change(+1)
}
/**
 * @deprecated This is for pre-React layouts
 */
export var to_page_unlock = (force?) => {
	// Skip if <= 0
	if (TOGLOB.lockLevel <= 0) {
		return
	}

	// Get the locker that needs to close
	const $lock = TOGLOB.$lock[TOGLOB.lockLevel - 1]

	// Remove the transition if we aren't animating
	if (!TOGLOB.showFlyoutAnimation) {
		$lock.css({ transition: 'none' })
	}

	// Check if this is a forced unlock
	if (force === undefined) {
		force = false
	}

	// Skip if an unforced unlock meets a forced lock
	if ($lock.hasClass('locked') && force === false) {
		return
	}

	// Close fly-outs - flag if we're only closing nesting level 2 fly-outs
	closeMessage(undefined)

	// Transition the page lock element
	{
		const [d1, d2] = TOGLOB.showFlyoutAnimation ? [100, 400] : [0, 0]
		$lock.off('click')
		timer(d1, () => {
			$lock.css({ opacity: 0 }).removeClass('locked')
			timer(d2, () => {
				const p = '-10000px'
				$lock.css({ transform: `translate(${p}, ${p})` })
			})
		})
	}

	// Increment lock counter
	to_page_lock_level_change(-1)

	// Return scrolling ability to the page if we've reached zero
	// Also ensure all fly-outs are gone
	if (TOGLOB.lockLevel === 0) {
		$('body').css({ 'overflow-y': 'auto' })
	}
	// closeMessage()

	// Check and see if there's a handler event for this registered
	if ($.isFunction(TOGLOB.onFlyoutsClose)) {
		TOGLOB.onFlyoutsClose()
	}
}
/**
 * @deprecated This is for pre-React layouts
 */
export var to_is_locked = () => +TOGLOB.$lock[0].css('opacity') !== 0
/**
 * @deprecated This is for pre-React layouts
 */
var to_page_lock_level_change = delta => {
	TOGLOB.lockLevel += delta
	if (TOGLOB.lockLevel > 5) {
		TOGLOB.lockLevel = 5
	} else if (TOGLOB.lockLevel < 0) {
		TOGLOB.lockLevel = 0
	}
}
