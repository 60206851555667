import {
	Do,
	ReportDataCache,
	ReportDataCacheVersioned,
	ReportVersion,
	fsmData,
} from '../../../../universal'
import { React, _ } from '../../../lib'
import { CustomPDFContainer } from '../../component-pdf-viewer'
import { LoadingSpinnerProgress } from '../../component-progress'
import { Button } from '../buttons'
import { CJSX, ConditionalObject, useRSInstance } from '../meta-types'
import { ResizeSplitter } from '../resize-split'
import { SearchBox } from '../textbox'
import { Toolbar } from '../toolbar'
import { TreeList, TreeListInstance } from '../tree-list'
import { Action, Payload, getPartialStateFromAction, sendOnUpdate } from './actions'
import { ReportOptionPane, getComponentType } from './options'
import {
	ReducerStateD,
	ReportFrameInstance,
	ReportFrameProps,
	ReportFrameRefs,
	ReportFrameState,
} from './types'

const getDefaultState = (props: ReportFrameProps): ReportFrameState => ({
	widths: props.view?.widths,
	selectedReport: props.view?.selectedReport,
	expanded: props.view?.expanded ?? [],
	options: props.view?.options ?? {},
	defaultOptions: props.defaultOptions ?? {},
	searchText: props.view?.searchText ?? '',
	pdfURL: props.view?.pdfs ?? {},
	cache: null,
	isLoading: {},
	loadProg: {},
	errorMessage: {},
	iframeSrc: {},
})

export const ReportFrameComponent = (
	props: ReportFrameProps,
	ref: React.ForwardedRef<ReportFrameInstance>,
): React.JSX.Element => {
	// Refs
	const pdfContainer = React.useRef<CustomPDFContainer>(null)
	const treeList = React.useRef<TreeListInstance<string>>(null)

	// Reducer
	const rs = useRSInstance<
		ReportFrameProps,
		ReportFrameState,
		ReportFrameRefs,
		Payload
	>({
		props: props,
		refs: { pdfContainer, treeList },
		defaultState: getDefaultState,
		actionToDelta: getPartialStateFromAction,
	})

	// Handle loading the parameters
	React.useEffect(() => {
		loadParameters(rs).catch(err => {
			console.error(err)
		})
	}, [props.loadData.v3, props.loadData.v4, rs])

	// Instance
	React.useImperativeHandle(ref, () => ({
		rs: rs,
		pdfContainer: pdfContainer,
	}))

	// Get the selected report sub-state
	const rpt = rs.state.selectedReport ?? ''
	const isLoading = rs.state.isLoading[rpt]
	const loadProg = rs.state.loadProg[rpt]
	const errorMessage = rs.state.errorMessage[rpt]
	const pdfURL = rs.state.pdfURL[rpt]
	const iframeSrc = rs.state.iframeSrc[rpt]

	// Render
	return (
		<div className="tailwind-wrapper" style={{ width: '100%', height: '100%' }}>
			<ResizeSplitter
				className="flex w-full h-full"
				value={rs.state.widths}
				onUpdate={v => {
					rs.dispatch([Action.UpdateState, () => ({ widths: v })])
					sendOnUpdate(rs, { widths: v })
				}}
				panes={[
					{
						defaultWidth: 200,
						minWidth: 160,
						maxWidth: 400,
						flexWidth: null,
						collapsible: true,
						content: (
							<>
								<ReportToolbar rs={rs} />
								<ReportList rs={rs} />
							</>
						),
					},
					{
						defaultWidth: 290,
						minWidth: 200,
						maxWidth: 400,
						flexWidth: null,
						collapsible: true,
						content: (
							<div className="w-full h-full overflow-y-auto border-l border-l-[#ccc]">
								<ReportOptionPane
									rs={rs}
									report={rs.state.selectedReport ?? null}
								/>
							</div>
						),
					},
					{
						defaultWidth: null,
						minWidth: null,
						maxWidth: null,
						flexWidth: 1,
						collapsible: true,
						content: (
							<div className="w-full h-full bg-[#ddd] border-l border-l-[#ccc] overflow-y-hidden">
								{Do(() => {
									if (isLoading) {
										return (
											<LoadingSpinnerProgress
												type="full"
												report={loadProg ?? undefined}
											/>
										)
									} else if (errorMessage) {
										return (
											<div className="inline-block absolute top-1/3 left-1/2 text-[#e00] font-light text-xl -translate-x-1/2 -translate-y-1/2">
												{errorMessage}
											</div>
										)
									} else if (pdfURL != null) {
										return (
											<CustomPDFContainer
												ref={pdfContainer}
												url={pdfURL}
											/>
										)
									} else if (iframeSrc != null) {
										return (
											<div className="w-full h-full">
												<iframe
													src={iframeSrc}
													className="w-full h-full overflow-hidden border-none absolute pointer-events-none opacity-[0.01]"
												/>
												<div className="absolute w-full top-1/3 -translate-y-1/2 text-center text-xl font-light text-[#444]">
													Complete. Your browser should download
													the report automatically.
												</div>
											</div>
										)
									}
									return (
										<div className="block font-light font-condensed text-xl mt-20 text-center text-[#888]">
											Build a report
										</div>
									)
								})}
							</div>
						),
					},
				]}
			/>
		</div>
	)
}

const ReportToolbar = (props: { rs: ReducerStateD }) => (
	<Toolbar
		widthRHS={90}
		lhs={
			<SearchBox
				className="w-full"
				value={props.rs.state.searchText}
				onUpdate={v => {
					props.rs.dispatch([Action.UpdateState, () => ({ searchText: v })])
					sendOnUpdate(props.rs, { searchText: v })
				}}
				onArrowDelta={(delta, shiftKey) => {
					const list = props.rs.refs.treeList.current?.getList()
					list?.moveSelection(delta, shiftKey)
				}}
			/>
		}
		rhs={
			<CJSX cond={props.rs.props.reportJobs != null}>
				<Button
					type="ui5-borderless-24"
					img="/static/img/i8/office-report.svg"
					lbl="Jobs"
					title="Open the report job manager"
					onClick={() => {
						props.rs.props.reportJobs?.openWindow?.(null)
					}}
				/>
			</CJSX>
		}
	/>
)

const ReportList = (props: { rs: ReducerStateD }) => (
	<TreeList<string>
		className="w-full bg-[#eee]"
		style={{ height: 'calc(100% - 40px)' }}
		value={props.rs.state.selectedReport ?? null}
		ref={props.rs.refs.treeList}
		selectableGroups={true}
		onUpdate={v => {
			props.rs.dispatch([
				Action.UpdateState,
				() => ({
					selectedReport: v,
				}),
			])
			sendOnUpdate(props.rs, { selectedReport: String(v) })
		}}
		searchText={props.rs.state.searchText}
		expanded={props.rs.state.expanded}
		onUpdateExpanded={v => {
			props.rs.dispatch([
				Action.UpdateState,
				() => ({
					expanded: v.map(String),
				}),
			])
			sendOnUpdate(props.rs, { expanded: v.map(String) })
		}}
		items={[
			{
				value: 'report-jobs',
				label: 'Report Jobs',
				items: ConditionalObject(
					props.rs.props.reportJobs != null &&
						_.size(props.rs.props.reportJobs?.items) > 0,
					fsmData(props.rs.props.reportJobs?.items, {
						sort: x => x.Name,
						map: x => ({
							value: String(-x.ID),
							label: x.Name,
						}),
					}),
				),
			},
			..._.map(props.rs.props.reportGroups, grp =>
				grp.Items.length > 1
					? {
							value: grp.ID,
							label: grp.Name,
							items: fsmData(grp.Items, {
								filter: R => !R.Unavailable,
								sort: R => R.ID,
								map: R => ({
									value: String(R.ID),
									label: R.Title,
								}),
							}),
						}
					: {
							value: String(grp.Items[0]?.ID),
							label: grp.Items[0]?.Title ?? 'Unknown group',
						},
			),
		]}
	/>
)

export const loadParametersGeneric = async (loadData: {
	v3?: () => Promise<ReportDataCache>
	v4?: () => Promise<ReportDataCache>
}) => {
	// Helper function to produce an empty `ReportDataCacheVersioned`
	const emptyCache: ReportDataCache = {
		params: {},
		exports: {},
	}
	const emptyCacheFn = async () => Promise.resolve(emptyCache)

	// Make two network calls to get the caches for v3 (Python) and v4 (TypeScript) reports
	const optionsByVersion: [ReportVersion, () => Promise<ReportDataCache>][] = [
		[ReportVersion.V3, loadData.v3 ?? emptyCacheFn],
		[ReportVersion.V4, loadData.v4 ?? emptyCacheFn],
	]
	const caches = await Promise.all(
		optionsByVersion.map(async ([version, fn]) => {
			const cache = await fn()
			return [version, cache] as const
		}),
	)

	// Combine into a single cache
	const cachesVersioned: ReportDataCacheVersioned[] = caches.map(cachePair => {
		const [version, cache] = cachePair
		return cache
			? {
					...cache,
					version: _.fromPairs(_.keys(cache.params).map(k => [k, version])),
				}
			: { version: {}, ...emptyCache }
	})
	const cacheCombined: ReportDataCacheVersioned = _.merge(
		{ version: {} },
		emptyCache,
		...cachesVersioned,
	)
	return cacheCombined
}

const loadParameters = async (rs: ReducerStateD) => {
	const cacheCombined = await loadParametersGeneric(rs.props.loadData)

	// Build the form data for each report
	type opt = Record<string, Record<string, unknown>>
	const options: opt = {}
	const defaultOptions: opt = {}
	_.forEach(rs.props.reportIndex, x => {
		_.map(cacheCombined.params[x.Key] ?? [], param => {
			const preset_option = rs.state.options?.[x.Key]?.[param.Key] as unknown
			const vDefault: unknown =
				param.Default ?? getComponentType(rs, param.Type).defVal?.() ?? null
			const v: unknown = preset_option ?? vDefault
			if (options[x.Key] == null) {
				options[x.Key] = {}
			}
			if (defaultOptions[x.Key] == null) {
				defaultOptions[x.Key] = {}
			}
			const optRecord = options[x.Key]
			if (optRecord) {
				optRecord[param.Key] = v
			}
			const optDefRecord = defaultOptions[x.Key]
			if (optDefRecord) {
				optDefRecord[param.Key] = vDefault
			}
		})
	})

	// Set the cache and default options
	rs.dispatch([
		Action.UpdateState,
		() => ({
			cache: cacheCombined,
			options: options,
			defaultOptions: defaultOptions,
		}),
	])
}
