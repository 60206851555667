import { createPhantomTypeSchema, Maybe, MaybeT, t } from '../../../../universal'
import { _, React } from '../../../lib'
import { FileUpload, FileUploadItem } from '../file-upload'
import { FormField, FormFieldJSX } from './types'

/** File upload - single */
export const FormTypeFileSingle = (
	settings: FormField<Maybe<string>, Maybe<string>> & {
		/** How much height does the drag zone take up? */
		height: number
		/** Uses the full form width by removing the label. Default: FALSE */
		fullWidth?: boolean
		/** Override the text to show instead of "Click or drag files here" */
		dragText?: string
	},
): FormFieldJSX<Maybe<string>, Maybe<string>> =>
	({
		...settings,
		valueDefaults: {
			def: () => null,
			validators: [],
		},
		emptyLabel: settings.fullWidth ?? false,
		removeLabel: settings.fullWidth ?? false,
		height: _.max([36, (settings.height ?? 0) + 8]) ?? 36,
		typeMap: {
			schemaRaw: createPhantomTypeSchema<Maybe<string>>(MaybeT(t.String())),
			schemaPublic: createPhantomTypeSchema<Maybe<string>>(MaybeT(t.String())),
			toPublic: x => x,
			toInternal: x => x,
		},
		llmInfo: null,
		jsx: props => (
			<UploadFileFormFieldSingle
				value={props.value}
				onUpdate={props.onUpdate}
				className={props.className ?? ''}
				height={settings.height}
			/>
		),
	}) as FormFieldJSX<Maybe<string>, Maybe<string>>

/** File upload - multiple */
export const FormTypeFileMultiple = (
	settings: FormField<string[], string[]> & {
		/** How much height does the drag zone take up? */
		height: number
		/** Uses the full form width by removing the label. Default: FALSE */
		fullWidth?: boolean
		/** Override the text to show instead of "Click or drag files here" */
		dragText?: string
	},
): FormFieldJSX<string[], string[]> =>
	({
		...settings,
		valueDefaults: {
			def: () => [],
			validators: [],
		},
		emptyLabel: settings.fullWidth ?? false,
		removeLabel: settings.fullWidth ?? false,
		height: _.max([36, (settings.height ?? 0) + 8]) ?? 36,
		typeMap: {
			schemaRaw: createPhantomTypeSchema<string[]>(t.Array(t.String())),
			schemaPublic: createPhantomTypeSchema<string[]>(t.Array(t.String())),
			toPublic: x => x,
			toInternal: x => x,
		},
		llmInfo: null,
		jsx: props => (
			<UploadFileFormFieldMultiple
				value={props.value}
				onUpdate={props.onUpdate}
				className={props.className ?? ''}
				height={settings.height}
			/>
		),
	}) as FormFieldJSX<string[], string[]>

const UploadFileFormFieldSingle = (props: {
	className?: string
	height?: number
	dragText?: string
	value: Maybe<string>
	onUpdate: (v: Maybe<string>) => void
}) => {
	const [value, setValue] = React.useState<FileUploadItem[]>(
		props.value ? [{ Filename: '', Checksum: props.value }] : [],
	)
	return (
		<FileUpload
			multiple={false}
			value={value}
			onUpdate={v => {
				const f = v[0]
				setValue(f ? [f] : [])
				props.onUpdate(f?.Checksum ?? null)
			}}
			className={props.className}
			height={props.height}
			placeholder={props.dragText}
		/>
	)
}

const UploadFileFormFieldMultiple = (props: {
	className?: string
	height?: number
	dragText?: string
	value: string[]
	onUpdate: (v: string[]) => void
}) => {
	const init = React.useRef(false)

	// Track the value state - call `onUpdate` when it changes
	const [value, setValue] = React.useState<FileUploadItem[]>(
		props.value.map(x => ({
			Filename: '',
			Checksum: x,
		})),
	)
	React.useEffect(() => {
		if (init.current) {
			console.log('Updating', value)
			props.onUpdate(_.compact(value.map(x => x.Checksum)))
		}
	}, [value])

	// Track whether a first render effect has happened
	// This is to ensure we don't immediately call `onUpdate` above
	React.useEffect(() => {
		init.current = true
	}, [])

	// Render
	return (
		<FileUpload
			multiple={true}
			value={value}
			onUpdate={v => {
				setValue(v)
				props.onUpdate(_.compact(v.map(x => x.Checksum)))
			}}
			className={props.className}
			height={props.height}
			placeholder={props.dragText}
		/>
	)
}
