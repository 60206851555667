import { PouchDB, _ } from '../lib'

type cacheRecord = {
	_id: string
	_rev: string
	value: object
}
type cacheData = { [key: string]: cacheRecord }
export class EncryptedCache {
	db: PouchDB.Database<any>
	data: cacheData
	index: number
	__name: string
	__key: string

	async connect(obj?: { key: string; name: string }): Promise<void> {
		// Save params for reconnecting later
		this.__name = obj?.name ?? this.__name
		this.__key = obj?.key ?? this.__key

		// Remove any existing connection
		await this.close()

		// Create a new connection
		this.db = new PouchDB(this.__name)
		await (this.db as any).crypto(this.__key) // TODO - fix crypto-pouch types
		this.data = {}

		await this.__read('index__')
	}

	private async __read(key: string): Promise<cacheRecord> {
		// Read some data from the key
		let data: any

		try {
			// Try to reach from PouchDB
			data = await this.db.get(key)
		} catch (err) {
			// If not found, return null
			if (err instanceof Error && err.name === 'not_found') {
				return null
			}

			// Unexpected error
			console.error(err)
			throw new Error('Error reading local cache data model')
		}

		// Fetch successful
		this.data[key] = data.value
		return data
	}

	/*
	 * Read a key or array of keys
	 */
	async read(keys: string[] | string): Promise<object[]> {
		if (typeof keys === 'string') {
			keys = [keys]
		}
		return Promise.all(
			keys.map(async key => {
				const response = await this.__read(key)
				return response?.value
			}),
		)
	}

	/*
	 * Reads all saved records out of PouchDB
	 */
	async readAll(): Promise<{ [key: string]: object }> {
		const docs = await this.db.allDocs()
		const keys = docs.rows.map(x => x.id)
		const values = await this.read(keys)
		return _.fromPairs(_.zip(keys, values))
	}

	/*
	 * Write some data to the key
	 */
	async write(key: string, data: any): Promise<PouchDB.Core.Response> {
		// Read asynchronously
		const rev = (await this.__read(key)) ?? { _rev: '1-' }

		// Build the record
		const record = {
			_id: key,
			_rev: String(1 + +rev._rev.split('-')[0]),
			value: data,
		}

		// Save it
		const resp = await this.db.put(record, { force: true })

		// Save to the unpacked data model
		this.data[key] = data

		// Modify the index (if not already)
		if (key !== 'index__') {
			const index = this.data.index__ ?? {}
			index[key] = null
			this.write('index__', index)
		}

		// Respond
		return resp
	}

	/*
	 * Close the DB
	 */
	async close(): Promise<void> {
		if (this.db == null) {
			return
		}
		await this.db.close()
	}

	/*
	 * Close and delete the DB
	 */
	async destroy(): Promise<void> {
		// Remove the database and the data, clearing cryptography

		// Null the data
		this.data = null

		// If there is no DB connection, resolve immediately
		if (this.db == null) {
			return
		}

		// Remove the encryption
		await (this.db as any).removeCrypto() // TODO - fix crypto-pouch types

		// Destroy the DB connection (as a promise)
		await this.db.destroy()
		this.db = null
	}

	/*
	 * Reset the database - clear all data
	 */
	async reset(): Promise<void> {
		await this.destroy()
		await this.connect()
	}
}
