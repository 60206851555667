import { BuildClass, Do, Maybe, clamp, fsmData, timer } from '../../universal'
import { $, React, _ } from '../lib'
import { J2rLoadingSpinner, J2rObject, j2r, reactRender } from './component-react'
import { J2rListComponent } from './component-react-list'
import { trionlineAjax } from './component-trionline'
import { newSystemFlyout } from './flyouts'

let facilitySwitcherData = null

export const updateFacilityUI = () => {
	switchFacilityNew()
}

export var switchFacilityNew = () => {
	// Get the size
	const sess = window.rootData?.Session
	const auth = sess?.Authority ?? +$('#var_auth').val() ?? -1
	const is_sn = sess && (auth >= 8 || sess.RealUser !== sess.User || sess.User < 100000)

	// Make the switcher flyout
	const flyout = newSystemFlyout({
		cl: 'facility-picker-2018-wrapper',
		size: Do(() => {
			if (is_sn) {
				return [720, 500]
			}
			return [360, 500]
		}),
	})

	// Timer promise
	const flyoutFinished = new Promise<void>(resolve => {
		timer(500, () => {
			resolve()
		})
	})

	// Build the component and send the data through immediately if it's
	// already loaded from opening the switcher since the last page reload
	reactRender<FacilitySwitcherUI>(
		flyout.Element,
		j2r({
			tag: FacilitySwitcherUI,
			flyout,
			flyoutFinishedPromise: flyoutFinished,
			showServers: is_sn,
		}),
	).then(cmpt => {
		// Set the component data if any available
		if (facilitySwitcherData != null) {
			cmpt.setData(facilitySwitcherData)

			// If it's an SN user, resize the flyout
			if ('isSN' in facilitySwitcherData && facilitySwitcherData.isSN) {
				flyout.ChangeSize({ width: 720, height: 500 })
			}
			return
		}
		if (window.rootData?.Switcher != null) {
			cmpt.setData(window.rootData.Switcher)
			return
		}

		// Load it async - only needed if it's not already cached
		trionlineAjax({
			url: '/api/switch/',
			yes: data => {
				facilitySwitcherData = JSON.parse(data)
				cmpt.setData(facilitySwitcherData)

				// If it's an SN user, resize the flyout
				if ('isSN' in facilitySwitcherData && facilitySwitcherData.isSN) {
					flyout.ChangeSize({ width: 720, height: 500 })
				}
			},
		})
	})
}

class FacilitySwitcherUI extends React.Component<
	{
		showServers?: boolean
		flyoutFinishedPromise: Promise<void>
		flyout: any
	},
	{
		// Input data
		init: boolean
		facilities: {
			ID: number
			Enabled: number
			Name: string
		}[]
		rosters: {
			Facility: number
			Name: string
			SubRoster: number
		}[]
		servers: {
			[key: string]: {
				ID: number
				Description: string
				FullName: string
				IsDev: number
				IsProd: number
				Subdomain: string
			}
		}
		// Selection data
		highlightedFacility: Maybe<number>
		selectedFacility: Maybe<number>
		highlightedSubroster: Maybe<number>
		selectedSubroster: Maybe<number>
		// Filtering data
		search: string
		sortKey: number
		sortReverse: boolean
		// Server selections
		isSN: boolean
		showServers: boolean
		currentServer: string
	}
> {
	list: React.RefObject<J2rListComponent>

	constructor(props) {
		super(props)
		this.list = React.createRef()

		// Determine if this is an SN user
		const auth = window.rootData?.Session?.Authority ?? $('#var_auth').val()
		const is_sn = +auth >= 8

		// Build the initial state
		this.state = {
			// Input data
			init: false,
			facilities: null,
			rosters: null,
			servers: null,
			// Selection data
			highlightedFacility: null,
			selectedFacility: null,
			highlightedSubroster: null,
			selectedSubroster: null,
			// Filtering data
			search: '',
			sortKey: 0,
			sortReverse: false,
			// Server selections
			isSN: is_sn,
			showServers: this.props.showServers,
			currentServer: Do(() => {
				const subdomain = location.host.replace(/trionline\.com\.au/g, '')
				return subdomain.replace(/\./g, '')
			}),
		}
	}

	setData(data) {
		// If the flyout is changing size, start that first
		if (data.isSN) {
			this.props.flyoutFinishedPromise.then(() => {
				this.setState({ showServers: true })
			})
		}

		// Update the state with the data from the server
		this.setState(
			{
				init: true,
				servers: data.servers,
				facilities: data.facilities.map(F => ({
					ID: F[0],
					Name: F[1],
					Enabled: F[2],
				})),
				rosters: data.rosters.map(F => ({
					Facility: F[0],
					SubRoster: F[1],
					Name: F[2],
				})),
				highlightedFacility: data.current[0],
				highlightedSubroster: data.current[1],
			},
			() => {
				// If there's only one facility option (pre-search), then just select
				// that without prompting the user to do so
				if (this.getFacilities().length === 1) {
					this.selectFacility()
					return
				}

				// We need to pick a facility - scroll the current one into view
				// Get the index of the selected item
				const index = Do(() => {
					let highlightedIndex = null
					_.forEach(this.getFacilities(), (F, i) => {
						if (this.state.highlightedFacility === F.ID) {
							highlightedIndex = i
							return false
						}
						return undefined
					})
					return highlightedIndex
				})

				// How many pixels down do we need to scroll
				// Clamp so it's centered, then scroll there
				let pixels = index * this.rowHeight()
				pixels = clamp(pixels - 140, 0, 10e9)
				this.list.current.listElement.current.scrollTop = pixels
			},
		)
	}

	override render() {
		return j2r(() => {
			if (!this.state.init) {
				return {
					tag: J2rLoadingSpinner,
					size: 'small',
				}
			} else if (this.state.selectedSubroster != null) {
				return {
					cl: 'cntr',
					style: { marginTop: '120px' },
					children: [
						{
							key: 'text',
							cl: 'error_msg_2',
							text: 'Switching...',
						},
						{
							tag: J2rLoadingSpinner,
							key: 'loading',
							size: 'small',
						},
					],
				}
			}
			return {
				cl: 'wrapper',
				key: 'wrapper',
				children: [
					{
						key: 'flyout-heading',
						cl: 'flyout-heading',
						text: 'Switch Facility',
					},
					this.buildFacilityPickerCombo(),
				],
			}
		})
	}

	buildFacilityPickerCombo() {
		return {
			cl: 'facility-picker-combo',
			key: 'facility-picker-combo',
			children: [
				this.buildFacilityPicker(),
				this.state.showServers ? this.buildServerPicker() : undefined,
			],
		}
	}

	buildServerPicker() {
		return {
			key: 'server-picker',
			cl: 'server-picker',
			children: Do(() => {
				// Start building the item array
				const items: J2rObject[] = [
					{
						key: 'heading-servers',
						cl: 'heading',
						text: 'Servers',
						children: [],
					},
				]

				// Group the servers by their type
				const headings = {
					1: 'Prod',
					2: 'Beta',
					3: 'Dev',
				}
				const server_groups = _.groupBy(this.state.servers, x => {
					if (x.IsProd) {
						return 1
					} else if (x.IsDev) {
						return 3
					}
					return 2
				})

				// Iterate over each group
				_.map(server_groups, (servers, k) => {
					// Skip if there are no matching servers in this group
					if (servers.length === 0) {
						return
					}

					// Build the item elements and add to the list
					items.push({
						cl: 'subheading',
						key: `subheading-${k}`,
						text: headings[k],
						children: [],
					})

					// Sort the server list by ID
					_.sortBy(servers, x => x.ID).forEach(x => {
						items.push({
							key: `server-${x.Subdomain}`,
							cl: BuildClass({
								server: true,
								selected: this.state.currentServer === x.Subdomain,
							}),
							children: [
								{
									tag: 'span',
									cl: 'fullname',
									key: 'fullname',
									text: x.FullName,
									title: x.FullName,
								},
								{
									tag: 'span',
									cl: 'subdomain',
									key: 'subdomain',
									text: x.Subdomain,
									title: x.Subdomain,
								},
								{
									tag: 'span',
									cl: 'desc',
									key: 'desc',
									text: x.Description,
									title: x.Description,
								},
							],
							onClick: () => {
								// Don't switch if it's the current server
								if (this.state.currentServer === x.Subdomain) {
									return
								}

								// Get the old/new URL patterns to swap
								const curr_host = Do(() => {
									if (this.state.currentServer === '') {
										return 'https://trionline.com.au'
									}
									return `https://${this.state.currentServer}.trionline.com.au`
								})
								const new_host = Do(() => {
									if (x.Subdomain) {
										return `https://${x.Subdomain}.trionline.com.au`
									}
									return 'https://trionline.com.au'
								})

								// Redirect to the new URL
								location.href = location.href.replace(curr_host, new_host)
							},
						})
					})
				})

				// Return the array of items
				return items
			}),
		}
	}

	buildFacilityPicker() {
		return {
			cl: 'facility-picker-2018',
			key: 'facility-picker-2018',
			children: [
				this.buildSearchBox(),
				this.state.selectedFacility != null
					? this.renderSubrosters()
					: this.renderFacilities(),
			],
		}
	}

	rowHeight() {
		if (window.size === 'mobile') {
			return 39
		}
		return 29
	}

	renderFacilities() {
		return {
			tag: J2rListComponent,
			ref: this.list,
			value: this.state.highlightedFacility,
			key: 'render-facilities',
			onUpdate: v => {
				this.setState({ highlightedFacility: v })
			},
			lazyRenderHeight: this.rowHeight(),
			headingRow: {
				cl: 'gridRow noselect heading',
				children: ['ID', 'Name'].map((txt, index) => ({
					tag: 'span',
					key: txt,
					text: txt,
					title: `${txt} column. Click to sort.`,
					onClick: () => {
						this.changeSort(index + 1)
					},
				})),
			},
			items: this.getFacilities().map(F => ({
				cl: BuildClass({
					facility: true,
					gridRow: true,
					disabled: F.Enabled === 0,
				}),
				value: F.ID,
				onClick: () => {
					this.selectFacility(F.ID)
				},
				children: [String(F.ID), F.Name].map(txt => ({
					tag: 'span',
					key: txt,
					title: txt,
					text: txt,
				})),
			})),
		}
	}

	renderSubrosters() {
		return {
			tag: J2rListComponent,
			value: this.state.highlightedSubroster,
			key: 'render-subrosters',
			onUpdate: v => {
				this.setState({ highlightedSubroster: v })
			},
			lazyRenderHeight: this.rowHeight(),
			headingRow: {
				cl: 'gridRow noselect heading',
				children: ['ID', 'Name'].map((txt, index) => ({
					tag: 'span',
					key: txt,
					text: txt,
					title: `${txt} column. Click to sort.`,
					onClick: () => {
						this.changeSort(index + 1)
					},
				})),
			},
			items: this.getSubrosters().map(R => ({
				cl: 'facility gridRow',
				value: R.SubRoster,
				onClick: () => {
					this.selectSubroster(R.SubRoster)
				},
				children: [String(R.SubRoster), R.Name].map(txt => ({
					tag: 'span',
					key: txt,
					title: txt,
					text: txt,
				})),
			})),
		}
	}

	changeSort(key) {
		this.setState(s => ({
			sortKey: key,
			sortReverse: key === s.sortKey && !s.sortReverse,
		}))
	}

	getFacilities() {
		return fsmData(this.state.facilities, {
			filter: F => {
				if (this.state.search !== '') {
					const txt = F.Name.toLowerCase()
					const bool_a = txt.indexOf(this.state.search) !== -1
					const bool_b = String(F.ID).indexOf(this.state.search) !== -1
					return bool_a || bool_b
				}
				return true
			},
			reverse: this.state.sortReverse,
			sort: F => {
				switch (this.state.sortKey) {
					case 1:
						return F.ID
					case 2:
						return F.Name
					default:
						var prefix = F.Enabled ? '' : 'zzzz '
						return `${prefix}${F.Name}`
				}
			},
		})
	}

	getSubrosters() {
		return fsmData(this.state.rosters, {
			filter: R => {
				if (this.state.selectedFacility !== R.Facility) {
					return false
				} else if (this.state.search !== '') {
					const txt = R.Name.toLowerCase()
					const bool_a = txt.indexOf(this.state.search) !== -1
					const bool_b = String(R.SubRoster).indexOf(this.state.search) !== -1
					return bool_a || bool_b
				}
				return true
			},
			reverse: this.state.sortReverse,
			sort: R => {
				switch (this.state.sortKey) {
					case 1:
						return R.SubRoster
					case 2:
						return R.Name
					default:
						return R.Name
				}
			},
		})
	}

	buildSearchBox() {
		return {
			tag: 'input',
			key: 'txtSearch',
			cl: 'tb2 txtSearch',
			placeholder: 'Filter...',
			autoFocus: window.size !== 'mobile',
			value: this.state.search,
			onBlur: e => e.target.focus(),
			onChange: e => {
				const v = e.target.value?.toLowerCase() ?? ''
				this.setState({ search: v }, () => {
					this.updateHighlightedFromSearch()
				})
			},
			onKeyDown: e => {
				e.stopPropagation()
				switch (e.keyCode) {
					case 38:
						this.moveSelection(-1)
						break
					case 40:
						this.moveSelection(+1)
						break
					case 13:
						this.pressEnter()
						break
					default:
						return
				}
				e.preventDefault()
			},
		}
	}

	moveSelection(dir) {
		// Get the current sort order and where the current selection falls
		let index, list
		if (!this.state.selectedFacility) {
			list = this.getFacilities().map(F => F.ID)
			index = list.indexOf(this.state.highlightedFacility)
		} else {
			list = this.getSubrosters().map(F => F.SubRoster)
			index = list.indexOf(this.state.highlightedSubroster)
		}

		// Increment the index based on direction and cap between valid values
		index += dir
		if (index < 0) {
			index = 0
		}
		if (index >= list.length) {
			index = list.length - 1
		}

		// Update the highlighted selection
		if (this.state.selectedFacility == null) {
			this.setState({ highlightedFacility: list[index] })
		} else {
			this.setState({ highlightedSubroster: list[index] })
		}
	}

	pressEnter() {
		if (this.state.selectedFacility == null) {
			this.selectFacility(this.state.highlightedFacility)
		} else {
			this.selectSubroster(this.state.highlightedSubroster)
		}
	}

	updateHighlightedFromSearch() {
		// Nulls the highlighted value if it's not part of the search subset
		if (this.state.selectedFacility == null) {
			const facilities = new Set(this.getFacilities().map(F => F.ID))
			if (!facilities.has(this.state.highlightedFacility)) {
				this.setState({ highlightedFacility: null })
			}
		} else {
			const subrosters = new Set(this.getSubrosters().map(F => F.SubRoster))
			if (!subrosters.has(this.state.highlightedSubroster)) {
				this.setState({ highlightedSubroster: null })
			}
		}
	}

	selectFacility(key?) {
		// Default to the first item in the list if nothing is defined
		key = key ?? this.getFacilities()[0].ID

		// Update the state
		this.setState(
			{
				selectedFacility: key,
				highlightedFacility: null,
				highlightedSubroster: this.state.highlightedSubroster ?? 0,
				search: '',
			},
			() => {
				// Check if there are any options for subrosters. If not, just end now
				if (this.getSubrosters().length <= 1) {
					this.selectSubroster()
				}
			},
		)
	}

	selectSubroster(key?) {
		// Default to the first item in the list if nothing is defined
		key = key ?? this.getSubrosters()[0]?.SubRoster ?? null

		// Update the state
		this.setState(
			{
				selectedSubroster: key,
				highlightedSubroster: null,
				search: '',
			},
			() => {
				this.finished()
			},
		)
	}

	finished() {
		// Run when the selection has been made
		this.props.flyout.Close()
		const k = null // TODO - re-add this maybe
		trionlineAjax({
			url: '/api/switch/',
			data: {
				d: JSON.stringify([
					this.state.selectedFacility,
					this.state.selectedSubroster,
					k,
				]),
			},
			no: () => {
				location.reload()
			},
			yes: () => {
				localStorage.currentRoster = null
				location.reload()
			},
		})
	}
}
