import { BuildClass, Maybe, TW_OVERFLOW_ELLIPSIS, fsmData } from '../../../../universal'
import { React, _ } from '../../../lib'
import { AddButton, Button, FormButton, FormButtonSet } from '../buttons'
import { Checkbox } from '../checkbox'
import { List } from '../list'
import { CJSX } from '../meta-types'
import { ResizeSplitter } from '../resize-split'
import { Textbox } from '../textbox'
import { Toolbar } from '../toolbar'
import { Action, actionAttemptDelete, actionAttemptSave, sendOnUpdate } from './actions'
import {
	GLOBAL_PARAM_ID,
	ReducerState,
	ReducerStateD,
	ReportJobInternal,
	ReportJobReportPending,
} from './types'
import { ReportJobGlobalParams } from './ui-globals'
import { ReportJobManagerOptions, StubMessage } from './ui-options'
import { hasReportJobChanged } from './ui-sidebar'

export const SingleReportJobManager = (props: {
	rs: ReducerStateD
	rj: Maybe<number>
}) => {
	const rj = props.rs.state.reportJobs[props.rj ?? 0]
	if (!rj) {
		return <StubMessage msg="Select a report job" />
	}
	return (
		<>
			<RJMToolbar rs={props.rs} />
			<div className="h-[calc(100%-116px)]">
				<ResizeSplitter
					value={props.rs.state.widthsInner}
					onUpdate={v => {
						props.rs.dispatch([Action.UpdateInnerWidths, v ?? null])
						sendOnUpdate(props.rs)
					}}
					panes={[
						{
							collapsible: false,
							defaultWidth: null,
							minWidth: null,
							maxWidth: null,
							flexWidth: 1,
							content: (
								<ReportJobManagerSidebar rs={props.rs} rj={props.rj} />
							),
						},
						{
							collapsible: false,
							defaultWidth: 290,
							minWidth: 260,
							maxWidth: 360,
							flexWidth: null,
							content:
								props.rs.state.selectedReport == GLOBAL_PARAM_ID ? (
									<ReportJobGlobalParams rs={props.rs} rj={props.rj} />
								) : (
									<ReportJobManagerOptions
										rs={props.rs}
										rj={props.rj}
										rpt={props.rs.state.selectedReport}
									/>
								),
						},
					]}
				/>
			</div>
			<FormButtonSet
				loading={props.rs.state.isLoading[props.rj ?? -1] ?? false}
				msg={props.rs.state.errorMessage[props.rj ?? -1] ?? ''}
			>
				<FormButton
					lbl="Save"
					onClick={() => {
						actionAttemptSave(props.rs, props.rj ?? -1)
					}}
				/>
				<FormButton
					lbl="Reset"
					onClick={() => {
						const original = props.rs.props.reportJobs[props.rj ?? -1]
						if (!original) {
							return
						}
						props.rs.updateState(s => ({
							errorMessage: {
								...s.errorMessage,
								[props.rj ?? -1]: '',
							},
							reportJobs: {
								...s.reportJobs,
								[props.rj ?? -1]: original,
							},
						}))
					}}
				/>
				<FormButton
					lbl="Delete"
					onClick={() => {
						actionAttemptDelete(props.rs, rj.ID)
					}}
				/>
			</FormButtonSet>
		</>
	)
}

const RJMToolbar = (props: { rs: ReducerStateD }) => {
	const rj = props.rs.state.reportJobs[props.rs.state.selectedReportJob ?? 0]
	if (!rj) {
		return <></>
	}
	const hasChanged = hasReportJobChanged(props.rs, rj.ID)
	return (
		<Toolbar
			lhs={
				<>
					<ToggleHiddenButton rs={props.rs} rj={rj} />
					<Textbox
						value={rj.Name}
						onUpdate={v => {
							props.rs.dispatch([
								Action.UpdateReportJob,
								rj.ID,
								() => ({ Name: v }),
							])
							sendOnUpdate(props.rs)
						}}
						maxLength={30}
						placeholder="Report job name..."
						className="w-[calc(100%-125px)]"
					/>
					<Checkbox
						lbl="Compact"
						title="Show a more compact view of the reports in this job"
						value={props.rs.state.compactView}
						onUpdate={v => {
							props.rs.updateState(() => ({ compactView: v }))
						}}
					/>
				</>
			}
			rhs={
				<>
					<Button
						type="ui5-borderless-24"
						lbl="Run"
						img="/static/img/i8/office-reports-run.svg"
						disabled={hasChanged}
						title={
							hasChanged
								? 'Save changes before running'
								: 'Opens the report job in the reports window, ready to run'
						}
						onClick={() => {
							if (rj.ID) {
								props.rs.props.openReportWindow(rj.ID)
							}
						}}
					/>
					<AddButton
						lbl="Add Report"
						onClick={() => {
							props.rs.dispatch([Action.AddReport, { rj: rj.ID }])
							props.rs.refs.list.current?.focus()
						}}
					/>
				</>
			}
			widthRHS={208}
		/>
	)
}

const ToggleHiddenButton = (props: { rs: ReducerStateD; rj: ReportJobInternal }) => (
	<button
		className="pt-[6px] inline-block opacity-60 hover:opacity-100"
		title={
			props.rj.Hidden == 1
				? 'Currently hidden, click to toggle as visible in the report list'
				: 'Currently visible in the report list, click to hide'
		}
		onClick={() => {
			props.rs.dispatch([
				Action.UpdateReportJob,
				props.rj.ID,
				() => ({ Hidden: props.rj.Hidden == 1 ? 0 : 1 }),
			])
			sendOnUpdate(props.rs)
		}}
	>
		<img
			className="w-5"
			src={
				props.rj.Hidden == 1
					? '/static/img/i8/material-outline-closed-eye.svg'
					: '/static/img/i8/material-outline-eye.svg'
			}
		/>
	</button>
)

const ReportJobManagerSidebar = (props: { rs: ReducerStateD; rj: Maybe<number> }) => {
	const rj = props.rs.state.reportJobs[props.rj ?? -1]
	const rjOriginal = props.rs.props.reportJobs[props.rj ?? -1]
	if (!rj || !rjOriginal) {
		return <StubMessage msg="Invalid report job selected" />
	}
	return (
		<List
			ref={props.rs.refs.list}
			className="h-full"
			value={props.rs.state.selectedReport}
			onUpdate={v => {
				props.rs.updateState(() => ({
					selectedReport: v,
				}))
				sendOnUpdate(props.rs)
			}}
			height={props.rs.state.compactView ? 28 : 48}
			multiple={false}
			rows={[
				{
					value: GLOBAL_PARAM_ID,
					height: 28,
					content: () => (
						<div
							className={BuildClass({
								'p-1 cursor-pointer h-7 leading-5': true,
								'hover:bg-[#ff9]':
									props.rs.state.selectedReport != GLOBAL_PARAM_ID,
							})}
						>
							Global Parameters
						</div>
					),
				},
				...fsmData(rj.Reports, {
					sort: [
						// Sort so all new reports are at the top
						x => (rjOriginal.Reports[x.ID] == null ? 0 : 1),
						// Then sort by original name so it doesn't change order until saved
						x => rjOriginal.Reports[x.ID]?.Name.toLowerCase() ?? x.ID,
					],
					map: rpt => ({
						value: rpt.ID,
						content: () => (
							<ReportJobManagerReportSidebarLine
								rs={props.rs}
								report={rpt}
							/>
						),
					}),
				}),
			]}
		/>
	)
}

const hasReportChanged = (rs: ReducerStateD, report: ReportJobReportPending) => {
	const original = rs.props.reportJobs[report.ReportJob]?.Reports[report.ID]
	return !_.isEqual(original, report)
}

const ReportJobManagerReportSidebarLine = (props: {
	rs: ReducerStateD
	report: ReportJobReportPending
}): React.JSX.Element => {
	const hasChanged = hasReportChanged(props.rs, props.report)
	return (
		<li
			className={BuildClass({
				'p-1 cursor-pointer leading-5': true,
				'list-none': true,
				'h-[48px]': !props.rs.state.compactView,
				'h-[28px]': props.rs.state.compactView,
				'hover:bg-[#ff9]': props.rs.state.selectedReport != props.report.ID,
			})}
		>
			<div className={TW_OVERFLOW_ELLIPSIS}>
				{_.compact([hasChanged ? '* ' : null, props.report.Name || 'Untitled'])
					.join(' ')
					.trim()}
			</div>
			<CJSX cond={!props.rs.state.compactView}>
				<div className="flex text-sm text-[#666]">
					<span className={`flex-1 ${TW_OVERFLOW_ELLIPSIS}`}>
						{getReportByKey(props.rs, props.report.Type)?.Title ?? 'No Type'}
					</span>
					<span
						className={BuildClass({
							'ml-2': true,
							'text-[hsl(10,50%,40%)]': props.report.ExportType == 1,
							'text-[hsl(150,60%,30%)]': props.report.ExportType == 2,
							'text-[hsl(270,90%,40%)]': props.report.ExportType == 3,
						})}
					>
						{
							{
								1: 'PDF',
								2: 'XLSX',
								3: 'CSV',
							}[props.report.ExportType]
						}
					</span>
					<span className="ml-2">·</span>
					<span className="ml-2">
						<button
							className={BuildClass({
								'scale-75 hover:scale-100 origin-center': true,
								// 'grayscale hover:grayscale-0': true,
								'saturate-50 hover:saturate-100': true,
								'opacity-50 hover:opacity-100': true,
								'transition-all duration-100': true,
								'h-4 leading-none align-text-top': true,
								'cursor-pointer': true,
							})}
							onClick={() => {
								props.rs.dispatch([
									Action.DeleteReport,
									{
										rj: props.report.ReportJob,
										report: props.report.ID,
									},
								])
							}}
						>
							<img
								src="/static/img/i8/w11-close.svg"
								className="w-4 h-4 inline align-baseline"
								alt="Remove"
								title="Remove report from job"
							/>
						</button>
					</span>
				</div>
			</CJSX>
		</li>
	)
}

export const getReportByKey = (rs: ReducerState, key: Maybe<string>) =>
	_.first(_.filter(rs.props.reportIndex, x => x.Key == key))
