import { BuildClass, Do, timer } from '../../universal'
import { React, _ } from '../lib'
import { J2rModalPortal, J2rObject, j2r, j2rtxt } from './component-react'
import { ConditionalObject } from './ui5'

type DropdownItem =
	| string
	| {
			label: string
			onClick?: Function
			items?: DropdownItem[]
	  }

// Dropdown menus component
export class J2rDropdownMenu extends React.Component<
	{
		label: string
		icon?: string
		onClick?: Function
		items?: DropdownItem[]
	},
	any // TODO: fix state type
> {
	constructor(props) {
		super(props)
		this.state = { hidden: false }
	}

	override render() {
		return j2r({
			key: 'top-level',
			cl: BuildClass({
				cmpt_dropdown: true,
				clickable: this.props.onClick != null,
			}),
			children: [
				{
					tag: 'span',
					key: 'top-level-inner',
					children: [
						ConditionalObject(this.props.icon != null, {
							tag: 'img',
							key: 'icon',
							src: this.props.icon,
						}),
						{
							tag: 'span',
							key: 'lbl',
							cl: 'lbl',
							text: this.props.label,
						},
						ConditionalObject(this.props.items != null, {
							tag: 'span',
							cl: 'caret',
							key: 'caret',
							text: '▼',
						}),
					],
					onClick: () => (this.props.onClick ?? _.noop)(),
				},
				ConditionalObject(this.props.items != null, () =>
					this.getChildren(this.props.items, this.props.label),
				),
			],
		})
	}

	getChildren(options, parent_lbl): J2rObject {
		return {
			key: 'dropdown-content',
			cl: 'dropdown-content',
			style: this.state.hidden ? { display: 'none' } : undefined,
			children: _.compact(
				_.map(options, (option, index) => {
					// Skip if no option found or if it's hidden
					if (option == null || option.hidden) {
						return null
					}

					// Skip if this is just a line rule
					if (typeof option === 'string' && option[0] === '-') {
						return {
							tag: 'hr',
							key: `sep-${index}`,
						}
					}

					// Return the object for this row
					return {
						key: `${parent_lbl}-child-${option.label}`,
						cl: BuildClass({
							'menu-item': true,
							'sub-items': option.items != null,
							disabled: option.onClick == null && option.items == null,
							'no-handler': option.onClick == null,
							hidden: this.state.hidden,
						}),
						children: Do(() => {
							const C: J2rObject[] = [
								{
									key: option.label,
									text: option.label,
									title: option.title,
									onClick:
										option.onClick == null
											? _.noop
											: () => {
													this.setState({ hidden: true })
													timer(() => {
														this.setState({ hidden: false })
													})
													;(option.onClick ?? _.noop)()
												},
								},
							]
							if (option.items != null) {
								C.push(this.getChildren(option.items, option.label))
							}
							return C
						}),
					}
				}),
			),
		}
	}
}

// An array of dropdown menus - collapses as required
export class J2rDropdownMenuSet extends React.Component<
	{
		cl?: string
		constrainWidth?: boolean
		items?: {
			key?: number
			label: string
			icon?: string
			onClick?: Function
			items?: DropdownItem[]
		}[]
		breakWidth?: number
	},
	any // TODO: fix state type
> {
	container: React.RefObject<HTMLDivElement>

	constructor(props) {
		super(props)
		this.container = React.createRef()
		this.state = {
			widths: null,
			expanded: false,
		}
	}

	override componentDidMount() {
		this.setState({ widths: this.getWidths() })
	}

	override componentDidUpdate() {
		const newWidths = this.getWidths()
		if (!_.isEqual(this.state.widths, newWidths)) {
			this.setState({ widths: newWidths })
		}
	}

	override render() {
		return j2r({
			ref: this.container,
			cl: BuildClass({
				[this.props.cl]: true,
				'mobile-ellipsis-menu': this.isOverflow(),
			}),
			children: _.flatten([
				this.buildModal(),

				this.isOverflow()
					? {
							tag: 'div',
							onClick: () => {
								this.setState({ expanded: true })
							},
							children: [
								{
									tag: 'img',
									key: 'inner',
									src: '/static/img/svg/ellipsis.svg',
								},
							],
						}
					: this.getVisibleItems().map((x, i) =>
							_.assign({ key: i }, x, { tag: J2rDropdownMenu }),
						),
			]),
		})
	}

	isOverflow() {
		return window.innerWidth <= (this.props.breakWidth ?? 768)
	}

	getVisibleItems() {
		if (this.props.constrainWidth) {
			const included = this.calculateVisibleItems()
			return this.props.items.slice(0, included)
		}
		return this.props.items
	}

	calculateVisibleItems() {
		// If widths haven't been calculated, show everything
		if (this.state.widths == null || this.container.current == null) {
			return this.props.items.length
		}

		// Get the amount of available width
		const availableWidth =
			this.container.current.getBoundingClientRect().width || 9999

		// Calculate the cumlative widths when each successive element is include
		let used_width = 0
		const cumulative_widths = _.map(this.props.items, (_x, i) => {
			used_width += this.state.widths[i]
			return used_width + 0
		})

		// Calculate how many elements can be shown before it exceeds what's available
		return _.size(_.filter(cumulative_widths, w => availableWidth - w > -1))
	}

	getWidths() {
		const widths = _.cloneDeep(this.state.widths ?? [])
		_.forEach(
			this.container.current?.children,
			(el, i) => (widths[i] = el.getBoundingClientRect().width),
		)
		return widths
	}

	buildModal(): J2rObject {
		return {
			tag: J2rModalPortal,
			key: 'modal',
			children: [
				{
					key: 'inner-1',
					cl: BuildClass({
						noselect: true,
						'menu-overflow-shade': true,
						open: this.state.expanded,
					}),
					onClick: () => {
						this.setState({ expanded: false })
					},
					children: [
						{
							key: 'inner-2',
							cl: 'menu-overflow-shade-inner',
							style: Do(() => {
								const top =
									this.container.current?.getBoundingClientRect()
										.bottom ?? 0
								return { top: `${top + 2}px` }
							}),
							children: [
								ConditionalObject(this.state.expanded, {
									tag: J2rDropdownMenuFlyout,
									key: 'inner-3',
									items: this.props.items,
									onClose: () => {
										this.setState({ expanded: false })
									},
								}),
							],
						},
					],
				},
			],
		}
	}
}

// A flyout version of the dropdown menu set
// This is designed for mobile devices
class J2rDropdownMenuFlyout extends React.Component<
	{
		items?: any[]
		onClose?: Function
	},
	any // TODO: fix state type
> {
	constructor(props) {
		super(props)
		this.state = { items: this.props.items }
	}

	override render() {
		return j2r({
			cl: 'menu',
			onClick: e => {
				e.stopPropagation()
				e.preventDefault()
			},
			children: _.map(this.state.items, (x, i) => ({
				key: x.key ?? i,
				cl: BuildClass({
					'menu-item': true,
					disabled: x.items == null && x.onClick == null,
				}),
				children: [
					{
						tag: 'span',
						key: 'icon-wrapper',
						cl: 'icon',
						children: [
							ConditionalObject(x.icon != null, {
								tag: 'img',
								key: 'icon',
								src: x.icon,
							}),
						],
					},
					{
						tag: 'span',
						cl: 'lbl',
						key: 'lbl',
						text: x.label,
					},
					ConditionalObject(x.items != null, {
						tag: 'span',
						cl: 'caret',
						key: 'caret',
						children: [j2rtxt('inner', '▼')],
					}),
				],
				onClick: () => {
					if (x.items != null) {
						this.setState({ items: x.items })
					} else if (x.onClick != null) {
						x.onClick()
						;(this.props.onClose ?? _.noop)()
					}
				},
			})),
		})
	}
}
