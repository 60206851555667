import { BuildClass, Maybe, ReportExportType, fsmData } from '../../../../universal'
import { React, _ } from '../../../lib'
import { Checkbox } from '../checkbox'
import { Combobox } from '../combobox'
import { LoadingSpinnerLarge } from '../loading'
import { ReportComponent, definedComponentsGeneric } from '../report-frame'
import { Textbox } from '../textbox'
import { Action, sendOnUpdate } from './actions'
import { ReducerStateD, ReportJobReportPending } from './types'
import { getReportByKey } from './ui-manager'

// Tailwind styles
const LABEL_CLASS = 'pt-[5px] pb-[10px] px-[10px]'
const SPAN_CLASS = 'block font-condensed font-light px-1 text-[#555]'

export const ReportJobManagerOptions = (props: {
	rs: ReducerStateD
	rj: Maybe<number>
	rpt: Maybe<number>
}) => {
	if (!props.rpt) {
		return <StubMessage msg="Select a report" />
	}
	const rj = props.rs.state.reportJobs[props.rj ?? -1]
	const rpt = rj?.Reports[props.rpt ?? 0]
	if (!rpt) {
		return <StubMessage msg="Invalid report" />
	}

	// Show a loading spinner while we wait for the options to be available
	if (!props.rs.state.cache) {
		return <LoadingSpinnerLarge />
	}

	// Get the option list for this report type
	// Check which export types are available
	const params = props.rs.state.cache.params?.[rpt.Type ?? '']
	const exports = props.rs.state.cache.exports?.[rpt.Type ?? ''] ?? []

	// Helper curried function to dispatch the update
	const updateState = (
		deltaFn: (s: ReportJobReportPending) => Partial<ReportJobReportPending>,
	) => {
		props.rs.dispatch([
			Action.UpdateReport,
			{
				rj: rj.ID,
				rpt: rpt.ID,
				update: deltaFn,
			},
		])
		sendOnUpdate(props.rs)
	}

	// Helper builder for radio buttons
	const buildRadioButton = (exportType: ReportExportType, lbl: string) => (
		<Checkbox
			className="mx-2"
			radioGroup="export-type"
			disabled={!exports.includes(exportType)}
			cbStyle="radio"
			lbl={lbl}
			value={rpt.ExportType == exportType}
			onUpdate={v => {
				if (v) {
					updateState(() => ({ ExportType: exportType }))
				}
			}}
		/>
	)

	// Render
	return (
		<div className="h-full overflow-y-scroll">
			{/* Base details */}
			<label
				className={BuildClass({
					block: true,
					[LABEL_CLASS]: true,
				})}
			>
				<span className={SPAN_CLASS}>Report Type</span>
				<Combobox<string, false>
					className="w-full min-w-full"
					value={rpt.Type}
					onUpdate={v => {
						const default_title = getReportByKey(props.rs, v)?.Title ?? ''
						const export_types =
							props.rs.state.cache?.exports?.[v ?? ''] ?? []
						updateState(s => ({
							Type: v,
							Name: s.Name || default_title,
							ExportType: _.first(export_types),
							Params: _.fromPairs(
								_.map(props.rs.state.cache?.params?.[v ?? ''], x => [
									x.Key,
									x.Default ??
										getComponentType(props.rs, x.Type).defVal?.() ??
										null,
								]),
							),
						}))
					}}
					options={_.compact(
						_.map(props.rs.props.reportGroups, grp => {
							const first = grp.Items[0]
							if (grp.Items.length > 1) {
								return {
									text: grp.Name,
									options: fsmData(grp.Items, {
										filter: R => !R.Unavailable,
										sort: R => R.ID,
										map: R => ({
											value: R.Key,
											text: R.Title,
										}),
									}),
								}
							} else if (first) {
								return {
									value: first.Key,
									text: first.Title,
								}
							}
							return null
						}),
					)}
				/>
			</label>
			<label
				className={BuildClass({
					block: true,
					[LABEL_CLASS]: true,
				})}
			>
				<span className={SPAN_CLASS}>Report Name</span>
				<Textbox
					className="w-full min-w-full"
					value={rpt.Name}
					onUpdate={v => {
						updateState(() => ({ Name: v }))
					}}
					maxLength={60}
				/>
			</label>
			<div className="w-full text-center">
				{buildRadioButton(1, 'PDF')}
				{buildRadioButton(2, 'XLSX')}
				{buildRadioButton(3, 'CSV')}
			</div>

			{/* Options */}
			<>
				{_.map(params, v => (
					<ReportJobManagerOption
						key={v.Key}
						rs={props.rs}
						rj={props.rj}
						rpt={props.rpt}
						optionType={v.Type}
						lbl={v.Label}
						value={rpt.Params[v.Key]}
						onUpdate={newVal => {
							updateState(s => ({
								Params: {
									...s.Params,
									[v.Key]: newVal,
								},
							}))
						}}
					/>
				))}
			</>
		</div>
	)
}

export const StubMessage = (props: { msg: string }) => (
	<div className="text-center text-neutral-600 pt-10 text-xl font-light">
		{props.msg}
	</div>
)

const ReportJobManagerOption = (props: {
	rs: ReducerStateD
	rj: Maybe<number>
	rpt: Maybe<number>
	optionType: string
	key: string
	lbl: string
	value: unknown
	onUpdate: (v: unknown) => void
}): React.JSX.Element => {
	const rs = props.rs

	// Get the component type
	const componentOriginal = getComponentType(rs, props.optionType)

	// Build the full JSX
	const component = React.cloneElement(componentOriginal.cmpt({ label: props.lbl }), {
		key: 'cmpt',
		className: BuildClass({
			[componentOriginal.className ?? '']: true,
			'max-w-full min-w-full': true,
		}),
		value: props.value,
		onUpdate: (v: unknown) => {
			const val: unknown = (componentOriginal.validate ?? _.identity)(v)
			props.onUpdate(val)
		},
	})

	// Final render
	const lblDisplay = props.optionType != 'TypeBool' ? props.lbl : ''
	return (
		<label
			className={BuildClass({
				block: true,
				[componentOriginal.paddingClass ?? LABEL_CLASS]: true,
				[componentOriginal.className ?? '']: true,
			})}
		>
			<div className="flex">
				<span
					className={BuildClass({
						[SPAN_CLASS]: true,
					})}
				>
					{lblDisplay}
				</span>
				<span></span>
			</div>
			{component}
		</label>
	)
}

const getComponentType = (rs: ReducerStateD, type: string): ReportComponent => {
	const cmpt = definedComponentsGeneric[type] ?? rs.props.getReportComponent(type)
	if (!cmpt) {
		return {
			defVal: () => null,
			cmpt: () => <div>Error loading option of type "{type}"</div>,
		}
	}
	return cmpt
}
