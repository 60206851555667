import { Do, timer } from '../../universal'
import { _ } from '../lib'
import { unmountReactOnElement } from './component-react'
import { Bindings } from './ui5'

// DEFINE A NEW FLYOUT FOR MORE MODERN STUFF #
// This is a lot more lightweight and leaves UI rendering to something else (React)
export type flyoutParams = Partial<flyoutArgs>
export const newSystemFlyout = (p: flyoutParams) => {
	const flyout = new NewSystemFlyoutInstance({
		size: p.size,
		callback: p.callback,
		coords: p.coords,
		nopadding: p.nopadding,
		cl: p.cl,
		anchorRight: p.anchorRight,
		closeCallback: p.closeCallback,
	})
	flyout.Build()
	return flyout
}

export type flyoutArgs = {
	cl: string
	size: [number, number]
	coords: [number, number]
	nopadding: boolean
	anchorRight: boolean
	callback: (flyout: NewSystemFlyoutInstance) => void
	closeCallback: () => void
}
export class NewSystemFlyoutInstance {
	params: flyoutArgs
	padding: { top: number; left: number; right: number; bottom: number }
	Element: HTMLDivElement
	Lock: HTMLDivElement

	constructor(param: flyoutArgs) {
		Bindings(this, [this.Close])

		// Input values
		this.params = {
			size: param.size ?? [null, null],
			coords: param.coords ?? [null, null],
			callback: param.callback ?? _.noop,
			nopadding: param.nopadding ?? false,
			cl: param.cl ?? '',
			anchorRight: param.anchorRight ?? false,
			closeCallback: param.closeCallback ?? _.noop,
		}

		// Define the padding required between the flyout and the edge of the window
		this.padding = Do(() => {
			if (this.params.nopadding) {
				return {
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
				}
			}
			return {
				top: 10,
				left: 5,
				right: 5,
				bottom: 20,
			}
		})
	}

	getSize(): [number, number] {
		let [w, h] = this.params.size
		if (w == null) {
			w = 640
		}
		if (h == null) {
			h = 99999
		}
		const maxWidth = window.innerWidth - (this.padding.left + this.padding.right)
		const maxHeight = window.innerHeight - (this.padding.top + this.padding.bottom)
		if (w > maxWidth) {
			w = maxWidth
		}
		if (h > maxHeight) {
			h = maxHeight
		}
		return [Math.floor(w), Math.floor(h)]
	}

	getCoords(size: [number, number]) {
		if (size == null) {
			size = this.getSize()
		}
		let [x, y] = this.params.coords
		if (x == null) {
			x = (window.innerWidth - size[0]) / 2
		}
		if (y == null) {
			y = (window.innerHeight - size[1]) / 4
		}
		const maxLeft = window.innerWidth - size[0] - this.padding.right
		const maxTop = window.innerHeight - size[1] - this.padding.bottom
		if (x < this.padding.left) {
			x = this.padding.left
		}
		if (y < this.padding.top) {
			y = this.padding.top
		}
		if (x > maxLeft) {
			x = maxLeft
		}
		if (y > maxTop) {
			y = maxTop
		}
		return [x, y].map(Math.floor)
	}

	getStyles() {
		// Normalise the size and coords
		const size = this.getSize()
		const coords = this.getCoords(size)

		// Get the amount to transform off-screen by - smallest possible
		// This is the height offset `offset[1]` plus the height `size[1]` plus the shadow
		const transform_offset = coords[1] + size[1] + 40

		// Return the HTML styles for the flyout element
		return {
			width: `${size[0]}px`,
			height: `${size[1]}px`,
			left: !this.params.anchorRight ? `${coords[0]}px` : undefined,
			right: this.params.anchorRight
				? `${window.innerWidth - coords[0] - size[0]}px`
				: undefined,
			top: `${coords[1]}px`,
			transform: `translateY(-${transform_offset}px`,
		}
	}

	Build() {
		// Build the element
		this.Element = Do(() => {
			const flyout = document.createElement('div')

			// Classes
			if (this.params.cl != null && this.params.cl) {
				flyout.classList.add(this.params.cl)
			}
			flyout.classList.add('to_floater')
			flyout.classList.add('newFlyout')

			// Main content container
			flyout.appendChild(
				Do(() => {
					const el = document.createElement('div')
					el.classList.add('floater_body')
					return el
				}),
			)

			// Apply the styles
			_.forEach(this.getStyles(), (v, k) => {
				flyout.style[k] = v
			})

			// Return the finished flyout
			return flyout
		})

		// Build the page lock - close the flyout on click
		this.Lock = Do(() => {
			const el = document.createElement('div')
			el.classList.add('toPageLockNew')
			el.classList.add('inactive')
			el.addEventListener('click', () => {
				this.Close()
			})
			return el
		})

		// Put the element and page locks in place
		document.body.appendChild(this.Lock)
		document.body.appendChild(this.Element)

		// Start the animation
		timer(10, () => {
			this.Lock.classList.remove('inactive')
			this.Element.style.transform = 'translateY(0)'

			// After animation execute the callback
			timer(170, () => {
				this.params.callback(this)
			})
		})
	}

	Close() {
		// Close the flyout
		if (this.Element != null && this.Element.style != null) {
			this.Element.style.transform = this.getStyles().transform
		}
		this.Lock?.classList?.add('inactive')

		// Remove the React component, if exists
		// Remove the lock and the element
		timer(170, () => {
			try {
				unmountReactOnElement(this.Element)
			} catch (error) {}
			this.Lock?.remove()
			this.Element?.remove()

			// Finally - run any close callback
			this.params.closeCallback()
		})
	}

	ChangeSize(sizeParams: {
		width?: number
		height?: number
		left?: number
		top?: number
	}) {
		// Update the instance variable for size and coords
		const { width, height, top, left } = sizeParams
		this.params.size = [width ?? this.params.size[0], height ?? this.params.size[1]]
		this.params.coords = [left ?? this.params.coords[0], top ?? this.params.coords[1]]

		// Re-apply the styles to the flyout, excluding transform and read-only properties
		const readOnlyProps = ['length', 'parentRule', 'cssText', 'transform']
		const styles = this.getStyles()

		if (this.Element?.style) {
			Object.entries(styles)
				.filter(([key]) => !readOnlyProps.includes(key))
				.forEach(([key, value]) => {
					;(this.Element.style as any)[key] = value
				})
		}
	}
}
