import { BuildClass, Do, clamp } from '../../universal'
import { React, _ } from '../lib'
import { J2rModalPortal, j2r } from './component-react'
import { moment } from './moment-wrapper'
import { Bindings, ConditionalObject } from './ui5'

/**
 * Datetime entry with a full React calendar widget
 * @deprecated use `Datebox`
 */
export class J2rDatetime extends React.Component<
	{
		value?: string
		valueFormat?: string // Defaults to YYYY-MM-DD, HH?:mm, or both
		onUpdate?: Function
		cl?: string
		disabled?: boolean
		includeDate?: boolean
		includeTime?: boolean
		tabIndex?: number
		autoFocus?: boolean
		cutOff1900?: number
		placeholder?: string
		defaultZoom?: number // 1 - 6
		displayFormat?: string // Overrides what format displays in text box
		disableArrowKeys?: boolean
		disableTextEntry?: boolean
		minYear?: number
		maxYear?: number
	},
	any // TODO: fix state type
> {
	static propTypes = {}

	static defaultProps = {
		minYear: 1900,
		maxYear: 2100,
	}
	element: React.RefObject<HTMLInputElement>

	constructor(props) {
		super(props)
		Bindings(this, [
			this.onClick,
			this.onKeyDown,
			this.onTextChanged,
			this.onTxtBlur,
			this.onTxtFocus,
			this.stopProp,
		])
		this.element = React.createRef()

		// Ensure the props are valid
		if (!(this.props.includeDate ?? true) && !this.props.includeTime) {
			console.error('J2rDatetime set to include neither date nor time')
		}

		// Initialise state
		// Parse the input value
		const value = this.parseValue(this.props.value)
		this.state = {
			// Form value state
			value,
			valueRaw: this.getValueRaw(value),
			isEditing: false,
			// Open/close state
			isOpen: false,
			isFocused: false,
			// Widget state
			zoomLevel: this.getDefaultZoom(),
			selectionPath: this.getSelectionPath(value),
		}
	}

	override componentDidUpdate(prevProps) {
		// If the passed value has been changed, reset our internal values
		if (prevProps.value !== this.props.value) {
			const value = this.parseValue(this.props.value)
			this.setState({
				value,
				isEditing: false,
				valueRaw: this.getValueRaw(value),
				selectionPath: this.getSelectionPath(value),
			})
		}
	}

	stopProp(e) {
		e.stopPropagation()
		e.preventDefault()
	}

	override render() {
		return j2r({
			cl: BuildClass({
				[this.props.cl]: true,
				tb2: true,
				'j2r-dt': true,
				unsaved: this.state.isEditing,
				focused: this.state.isFocused,
				disabled: this.props.disabled,
			}),
			children: [
				{
					tag: 'input',
					key: 'text-input',
					ref: this.element,
					cl: 'txt txtDate date',
					value: this.state.valueRaw,
					disabled: this.props.disabled,
					tabIndex: this.props.tabIndex,
					autoFocus: this.props.autoFocus,
					readOnly: this.props.disableTextEntry,
					placeholder: Do(() => {
						if (this.props.placeholder != null) {
							return this.props.placeholder
						} else if (!this.props.includeTime) {
							return 'dd/mm/yyyy'
						} else if (!(this.props.includeDate ?? true)) {
							return 'hh:mm'
						}
						return 'dd/mm/yyyy, hh:mm'
					}),
					onChange: this.onTextChanged,
					onFocus: this.onTxtFocus,
					onBlur: this.onTxtBlur,
					onKeyDown: this.onKeyDown,
					onClick: this.onClick,
				},
				{
					tag: J2rModalPortal,
					key: 'widget-modal',
					children: [this.buildModal()],
				},
			],
		})
	}

	buildModal() {
		return {
			key: 'datetime-widget',
			cl: BuildClass({
				'j2r-dt-widget': true,
				open: this.state.isOpen,
			}),
			children: ConditionalObject(this.state.isOpen, [
				this.buildWidgetToolbar(),
				this.buildWidgetGrid(),
			]),
			style: Do(() => {
				const el = this.element.current
				const box = el?.getBoundingClientRect() ?? {
					left: 0,
					bottom: 0,
				}
				return {
					top: `${box.bottom - 1}px`,
					left: `${box.left}px`,
				}
			}),
			onMouseDown: this.stopProp,
			onWheel: e => {
				if (e.deltaY < 0) {
					this.moveNav(-1)
				} else if (e.deltaY > 0) {
					this.moveNav(+1)
				}
			},
		}
	}

	buildWidgetToolbar() {
		return {
			cl: 'toolbar noselect',
			key: 'toolbar',
			children: [
				{
					key: 'lhs',
					cl: BuildClass({
						lhs: true,
						side: true,
						invisible: this.state.zoomLevel === 1,
					}),
					text: '↑',
					onMouseDown: this.stopProp,
					onClick: () => {
						this.moveNav(-1)
					},
				},
				{
					key: 'home',
					cl: 'home icon',
					title: 'Jump to today',
					children: [
						{
							tag: 'img',
							key: 'img',
							src: '/static/img/svg/home.svg',
						},
					],
					onMouseDown: this.stopProp,
					onClick: () => {
						this.setState({
							selectionPath: this.getSelectionPath(moment()),
							zoomLevel: (this.props.includeDate ?? true) ? 4 : 5,
						})
					},
				},
				{
					key: 'title',
					cl: BuildClass({
						title: true,
						'non-clickable': this.state.zoomLevel === 1,
					}),
					onMouseDown: this.stopProp,
					onClick: () => {
						this.moveNesting(-1)
					},
					text: Do(() => {
						const P = this.state.selectionPath
						const dt = moment({
							year: P[0] * 10 + P[1],
							month: P[2] - 1 ?? 1,
							day: P[3] ?? 1,
							hour: P[4] ?? 1,
						})
						switch (this.state.zoomLevel) {
							case 1:
								return '' // Top level
							case 2:
								return `${P[0]}0s` // Decade
							case 3:
								return dt.format('YYYY') // Year
							case 4:
								return dt.format('MMMM, YYYY') // Month
							case 5:
								return dt.format('DD/MM/YYYY') // Date
							case 6:
								var h = dt.format('HH')
								var d = dt.format('DD/MM/YY')
								return `${h}:xx, ${d}`
						}
						return ''
					}),
				},
				{
					key: 'target',
					cl: BuildClass({
						icon: true,
						target: true,
						nohover: this.state.value == null,
					}),
					title: 'Jump to current value',
					children: [
						{
							tag: 'img',
							key: 'img',
							src: '/static/img/svg/target.svg',
						},
					],
					onMouseDown: this.stopProp,
					onClick: () => {
						if (this.state.value != null) {
							this.setState({
								selectionPath: this.getSelectionPath(this.state.value),
							})
						}
					},
				},
				{
					key: 'rhs',
					cl: BuildClass({
						rhs: true,
						side: true,
						invisible: this.state.zoomLevel === 1,
					}),
					text: '↓',
					onMouseDown: this.stopProp,
					onClick: () => {
						this.moveNav(+1)
					},
				},
			],
		}
	}

	buildWidgetGrid() {
		return _.assign(
			{ key: 'grid' },
			Do(() => {
				switch (this.state.zoomLevel) {
					case 1:
						return this.buildWidgetGridDecades()
					case 2:
						return this.buildWidgetGridYears()
					case 3:
						return this.buildWidgetGridMonths()
					case 4:
						return this.buildWidgetGridDays()
					case 5:
						return this.buildWidgetGridHours()
					case 6:
						return this.buildWidgetGridMinutes()
				}
				return null
			}),
		)
	}

	buildGridOfOptions(param) {
		// Cache the current selection and today values
		const today = param.getID(moment())
		const current = param.getID(this.state.value)
		const groupID = (param.getGroupID ?? _.noop)(
			param.startDateGroup ?? param.startDate,
		)

		// Build a grid of options
		return {
			cl: 'calendar-view',
			children: _.flatten([
				// Build the heading row
				ConditionalObject(param.headingRow != null, () =>
					_.assign({}, param.headingRow, { key: 'heading' }),
				),

				// Build the content rows
				_.times(param.rowCount, y => ({
					key: y,
					cl: BuildClass({
						[param.cl]: true,
						'calendar-row': true,
						gridRow: true,
					}),
					children: _.times(param.colCount, x => {
						// Cache the cell date's moment object and identifier
						const dDate = param.getCellDate(param.startDate.clone(), x, y)
						const dID = param.getID(dDate)

						// Build the cell
						return {
							tag: 'span',
							key: x,
							cl: BuildClass({
								day: true,
								oog: groupID && groupID !== param.getGroupID(dDate),
								today: dID === today,
								current: dID === current,
							}),
							text: param.getCellText(dDate),
							title: (param.getCellTitle ?? param.getCellText)(dDate),
							onMouseDown: this.stopProp,
							onClick: e => {
								e.preventDefault()
								e.stopPropagation()
								;(param.onClick ?? _.noop)(dDate)
							},
						}
					}),
				})),
			]),
		}
	}

	buildWidgetGridDecades() {
		return this.buildGridOfOptions({
			startDate: moment({
				year: 1900,
				month: 0,
				date: 1,
			}),
			rowCount: 5,
			colCount: 4,
			getID: x => Math.floor((x?.year() ?? -1) / 10),
			getCellDate: (start, x, y) => start.add((y * 4 + x) * 10, 'year'),
			getCellText: x => {
				const decade = Math.floor(x.year() / 10)
				return `${decade}0s`
			},
			getGroupID: null,
			onClick: d => {
				this.setState(s => ({
					zoomLevel: s.zoomLevel + 1,
					selectionPath: Do(() => {
						const p = _.clone(s.selectionPath)
						p[0] = Math.floor(d.year() / 10)
						return p
					}),
				}))
				this.moveNav(0)
			},
		})
	}

	buildWidgetGridYears() {
		return this.buildGridOfOptions({
			startDate: moment({
				year: this.state.selectionPath[0] * 10,
				month: 0,
				date: 1,
			}),
			rowCount: 5,
			colCount: 4,
			getID: x => x?.year() ?? -1,
			getCellDate: (start, x, y) => start.add((y - 1) * 4 + x, 'year'),
			getCellText: x => x.format('YYYY'),
			getGroupID: x => Math.floor(x.year() / 10),
			onClick: d => {
				this.setState(s => ({
					zoomLevel: s.zoomLevel + 1,
					selectionPath: Do(() => {
						const p = _.clone(s.selectionPath)
						p[0] = Math.floor(d.year() / 10)
						p[1] = d.year() % 10
						return p
					}),
				}))
				this.moveNav(0)
			},
		})
	}

	buildWidgetGridMonths() {
		return this.buildGridOfOptions({
			startDate: moment({
				year: this.state.selectionPath[0] * 10 + this.state.selectionPath[1],
				month: 0,
				date: 1,
			}),
			rowCount: 5,
			colCount: 4,
			getID: x => x?.format('MM/YYYY') ?? '',
			getCellDate: (start, x, y) => start.add((y - 1) * 4 + x, 'month'),
			getCellText: x => x.format('MMM'),
			getCellTitle: x => x.format('MMMM, YYYY'),
			getGroupID: x => x.year(),
			onClick: d => {
				this.setState(s => ({
					zoomLevel: s.zoomLevel + 1,
					selectionPath: Do(() => {
						const p = _.clone(s.selectionPath)
						p[0] = Math.floor(d.year() / 10)
						p[1] = d.year() % 10
						p[2] = d.month() + 1
						return p
					}),
				}))
				this.moveNav(0)
			},
		})
	}

	buildWidgetGridDays() {
		return this.buildGridOfOptions({
			cl: 'days',
			headingRow: {
				cl: 'heading-row calendar-row gridRow days',
				children: ['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((txt, idx) => ({
					tag: 'span',
					key: idx,
					text: txt,
				})),
			},
			startDate: Do(() => {
				// Get the first Monday on or before the 1st of the month
				const date_ = moment({
					year: this.state.selectionPath[0] * 10 + this.state.selectionPath[1],
					month: this.state.selectionPath[2] - 1,
					date: 1,
				})
				if (!date_.isValid()) {
					console.error('Invalid date', date_)
					return moment()
				}
				while (date_.isValid() && date_.day() !== 1) {
					date_.add(-1, 'd')
				}
				return date_
			}),
			startDateGroup: moment({
				year: this.state.selectionPath[0] * 10 + this.state.selectionPath[1],
				month: this.state.selectionPath[2] - 1,
				date: 1,
			}),
			rowCount: 6,
			colCount: 7,
			getID: x => x?.format('DD/MM/YYYY') ?? '',
			getCellDate: (start, x, y) => start.add(y * 7 + x, 'd'),
			getCellText: x => x.date(),
			getCellTitle: x => x.format('dddd, DD/MM/YYYY'),
			getGroupID: x => x.format('MM/YYYY'),
			onClick: d => {
				// If time isn't included, we've found our final value
				if (!this.props.includeTime) {
					this.updateValue(d)
					this.setState({ isOpen: false })
					return
				}

				// Otherwise, update the path (like the higher-level ones) and zoom further
				this.setState(s => ({
					zoomLevel: s.zoomLevel + 1,
					selectionPath: Do(() => {
						const p = _.clone(s.selectionPath)
						p[0] = Math.floor(d.year() / 10)
						p[1] = d.year() % 10
						p[2] = d.month() + 1
						p[3] = d.date()
						return p
					}),
				}))
				this.moveNav(0)
			},
		})
	}

	buildCircleOfOptions(param: {
		cl: string
		key: string
		startDate: moment.Moment
		getID: (arg?: moment.Moment) => string
		getSegmentDT: (date: moment.Moment, delta: number) => moment.Moment
		segments: {
			value: number
			text: string
			style?: any
			onClick: (v: any) => void
		}[]
	}) {
		// Cache the current selection and today values
		const today = param.getID(moment())
		const current = param.getID(this.state.value)

		// Build the element
		return {
			key: param.key,
			cl: BuildClass({
				[param.cl]: true,
				circle: true,
			}),
			children: _.map(param.segments, (segment, index) => {
				// Cache the cell date's moment object and identifier
				const dDate = param.getSegmentDT(param.startDate.clone(), segment.value)
				const dID = param.getID(dDate)

				// Build the cell
				return {
					key: index,
					cl: BuildClass({
						segment: true,
						today: dID === today,
						current: dID === current,
					}),
					children: [
						{
							tag: 'span',
							cl: 'txt',
							key: 'txt',
							text: segment.text,
							style: {
								transform: Do(() => {
									const degs = (index * 360) / param.segments.length
									return `rotate(-${degs}deg)`
								}),
							},
						},
					],
					style: _.assign({}, segment.style, {
						transform: Do(() => {
							const degs = (index * 360) / param.segments.length
							const existing = segment.style?.transform ?? ''
							return `${existing} rotate(${degs}deg)`
						}),
					}),
					onMouseDown: this.stopProp,
					onClick: e => {
						e.preventDefault()
						e.stopPropagation()
						segment.onClick(segment.value)
					},
				}
			}),
		}
	}

	buildWidgetGridHours() {
		return {
			cl: 'hour-picker noselect',
			children: [
				// 00 - 11
				this.buildCircleOfOptions({
					cl: 'outer',
					key: 'outer',
					startDate: moment({
						year:
							this.state.selectionPath[0] * 10 +
							this.state.selectionPath[1],
						month: this.state.selectionPath[2] - 1,
						date: this.state.selectionPath[3],
						hour: 0,
						minute: 0,
					}),
					getID: x => x?.format('HH DD/MM/YYYY') ?? '',
					getSegmentDT: (start, delta) => start.add(delta, 'hours'),
					segments: _.times(12, x => ({
						value: x,
						text: x < 10 ? `0${x}` : String(x),
						onClick: v => {
							this.setState(s => ({
								zoomLevel: s.zoomLevel + 1,
								selectionPath: Do(() => {
									const p = _.clone(s.selectionPath)
									p[4] = v
									return p
								}),
							}))
							this.moveNav(0)
						},
					})),
				}),

				// 12 - 23
				this.buildCircleOfOptions({
					cl: 'inner',
					key: 'inner',
					startDate: moment({
						year:
							this.state.selectionPath[0] * 10 +
							this.state.selectionPath[1],
						month: this.state.selectionPath[2] - 1,
						date: this.state.selectionPath[3],
						hour: 0,
						minute: 0,
					}),
					getID: x => x?.format('HH DD/MM/YYYY') ?? '',
					getSegmentDT: (start, delta) => start.add(delta, 'hours'),
					segments: _.times(12, x => ({
						value: x + 12,
						text: String(x + 12),
						onClick: v => {
							this.setState(s => ({
								zoomLevel: s.zoomLevel + 1,
								selectionPath: Do(() => {
									const p = _.clone(s.selectionPath)
									p[4] = v
									return p
								}),
							}))
							this.moveNav(0)
						},
					})),
				}),

				// Dot for the middle
				{
					cl: 'dot',
					key: 'dot',
				},
			],
		}
	}

	buildWidgetGridMinutes() {
		return {
			cl: 'hour-picker noselect',
			children: [
				// Minutes
				this.buildCircleOfOptions({
					cl: 'outer',
					key: 'outer',
					startDate: moment({
						year:
							this.state.selectionPath[0] * 10 +
							this.state.selectionPath[1],
						month: this.state.selectionPath[2] - 1,
						date: this.state.selectionPath[3],
						hour: this.state.selectionPath[4],
						minute: 0,
					}),
					getID: x => {
						if (x == null) {
							return ''
						}
						const dt = x.format('DD/MM/YYYY HH') ?? ''
						const m = Math.floor(+x.format('mm') / 5)
						return `${dt}${m}`
					},
					getSegmentDT: (start, delta) => start.add(delta, 'minutes'),
					segments: _.times(12, x => ({
						value: x * 5,
						text: Do(() => {
							const v = String(x * 5)
							if (v.length < 2) {
								return `0${v}`
							}
							return v
						}),
						onClick: v => {
							// Add the second to the value
							this.setState(s => ({
								selectionPath: Do(() => {
									const p = _.clone(s.selectionPath)
									p[5] = v
									return p
								}),
							}))
							this.moveNav(0, null, () => {
								// We've found our final value
								this.updateValue(this.getValueFromSelectionPath())
								this.setState({ isOpen: false })
							})
						},
					})),
				}),

				// Dot for the middle
				{
					cl: 'dot',
					key: 'dot',
				},
			],
		}
	}

	updateValue(v) {
		this.setState(
			{
				value: v,
				isEditing: false,
				valueRaw: this.getValueRaw(v),
				selectionPath: this.getSelectionPath(v),
			},
			() =>
				(this.props.onUpdate ?? _.noop)(
					Do(() => {
						v = this.state.value
						if (v != null) {
							return v.format(this.getValueFormat())
						}
						return null
					}),
				),
		)
	}

	parseValue(txt) {
		if (!txt) {
			return null
		}
		const fmt = this.getValueFormat()
		const v = moment(txt, fmt)
		if (v.isValid()) {
			return v
		}
		console.error(
			`Could not parse given \`J2rDatetime\` value: '${txt}' with format '${fmt}'`,
		)
		return null
	}

	getValueFormat() {
		if (this.props.valueFormat != null) {
			return this.props.valueFormat
		} else if (!this.props.includeTime) {
			return 'YYYY-MM-DD'
		} else if (!(this.props.includeDate ?? true)) {
			return 'HH:mm'
		}
		return 'YYYY-MM-DD HH:mm:ss'
	}

	onClick() {
		this.setState({
			isOpen: true,
		})
	}

	onTxtFocus() {
		this.setState({
			isFocused: true,
			isOpen: true,
			zoomLevel: this.getDefaultZoom(),
		})
	}

	onTxtBlur() {
		// Close
		this.setState({
			isFocused: false,
			isOpen: false,
		})

		// If we were editing, check if there's something savable
		if (this.state.isEditing) {
			this.attemptToCommitEnteredValue()
		}
	}

	getValueRaw(v) {
		if (v == null) {
			return ''
		} else if (this.props.displayFormat != null) {
			return v.format(this.props.displayFormat)
		} else if (!this.props.includeTime) {
			return v.format('DD/MM/YYYY')
		} else if (!(this.props.includeDate ?? true)) {
			return v.format('HH:mm')
		}
		return v.format('DD/MM/YYYY, HH:mm')
	}

	onTextChanged(e) {
		const val = e.target.value

		// Change the textbox value, but flag that it's mid-edit
		this.setState({
			isEditing: true,
			valueRaw: val,
		})

		// Check if the entered value is meaningful yet
		// No value entered? That's null
		if (!val) {
			this.updateValue(null)
			return
		}

		// Get the expected text format
		const fmt = Do(() => {
			if (this.props.displayFormat != null) {
				return this.props.displayFormat
			} else if (!this.props.includeTime) {
				return 'DD/MM/YYYY'
			} else if (!(this.props.includeDate ?? true)) {
				return 'HH:mm'
			}
			return 'DD/MM/YYYY, HH:mm'
		})

		// Try parsing it in this form
		const attempted_value = moment(val, fmt, true)
		if (attempted_value.isValid()) {
			this.updateValue(attempted_value)
			return
		}

		// If we've come this far, there's no matching value
		// The `onUpdate` event hasn't fired and the internal value hasn't changed
	}

	attemptToCommitEnteredValue() {
		// Try to parse
		const v = this.attemptToParseEnteredValue()

		// If something was found, save it
		if (v !== false) {
			this.updateValue(v)
			return
		}

		// Otherwise, rollback changes
		this.setState(s => ({
			isEditing: false,
			valueRaw: this.getValueRaw(s.value),
		}))
	}

	attemptToParseEnteredValue() {
		let fmts
		const val = this.state.valueRaw
		let match = null

		// Base format hasn't been successful (handled in the `onTextChanged` event)
		// Try matching to other less-exact formats

		// Start with the simple case of a basic date-only value
		if (!this.props.includeTime) {
			fmts = [
				// No formatting
				'DDMMYYYY',
				'YYYYMMDD',
				'DDMMYY',
				'YYMMDD',
				// Slash/dash
				'DD/MM/YYYY',
				'YYYY-MM-DD',
				'D/M/YY',
				'D/M/Y',
				'YY-M-D',
				'Y-M-D',
				// Dots?
				'DD.MM.YYYY',
				'YYYY.MM.DD',
				'D.M.YY',
				'D.M.Y',
				'YY.M.D',
				'Y.M.D',
				// Final attempt without formatting or leading zeroes
				'DMY',
				'YMD',
			]
		}

		// Time only
		if (!(this.props.includeDate ?? true)) {
			fmts = [
				'HH:mm',
				'Hmm',
				'H.mm',
				'H:mm',
				'H-mm',
				'H mm',
				// With seconds?
				'HH:mm:ss',
				'Hmmss',
				'H.mm.ss',
				'H:mm:ss',
				'H-mm-ss',
				'H mm ss',
			]
		}

		// Compare formats against the entered string
		_.forEach(fmts, fmt => {
			const v = moment(val, fmt, true)
			if (v.isValid()) {
				match = v
				return false
			}
			return true
		})

		// Validate the year
		if (
			match != null &&
			(match.year() > this.props.maxYear || match.year() < this.props.minYear)
		) {
			match = false
		}

		// Return the match (false if nothing found)
		return match
	}

	onKeyDown(e) {
		const delta = e.ctrlKey || e.shiftKey ? 7 : 1
		switch (e.keyCode) {
			// Up / down
			case 38:
				this.stopProp(e)
				this.arrowNav(-delta)
				break
			case 40:
				this.stopProp(e)
				this.arrowNav(delta)
				break

			// Page up/down
			case 33:
				this.stopProp(e)
				this.arrowNav(-7 * delta)
				break
			case 34:
				this.arrowNav(7 * delta)
				break

			// Pressing enter
			case 13:
				this.attemptToCommitEnteredValue()
				this.setState({ isOpen: false })
				break

			// Pressing escape
			case 27:
				this.setState(s => ({
					isOpen: false,
					isEditing: false,
					valueRaw: this.getValueRaw(s.value),
				}))
				break

			// Exit function before `stopProp` - allows pass-through keyboard events
			default:
				return
		}

		// An action was taken - stop propagatino
		this.stopProp(e)
	}

	arrowNav(delta) {
		if (!this.props.disableArrowKeys) {
			this.moveNav(delta, this.getDefaultZoom() + 1, () => {
				this.updateValue(this.getValueFromSelectionPath())
			})
		}
	}

	getDefaultZoom() {
		// Zoom levels - at each level, each cell is a ...
		// 1 - decade
		// 2 - year
		// 3 - month
		// 4 - day (standard monthly view)
		// 5 - hours (only available with `includeTime` prop)
		// 6 - minutes (only available with `includeTime` prop)
		return (
			this.props.defaultZoom ??
			Do(() => {
				if (this.props.includeDate ?? true) {
					return 4
				}
				return 5
			})
		)
	}

	getSelectionPath(value = null) {
		value = value ?? this.state?.value ?? moment()
		return [
			Math.floor(value.year() / 10),
			value.year() % 10,
			value.month() + 1,
			value.date(),
			value.hour(),
			Math.round(value.minute() / 12),
		]
	}

	getValueFromSelectionPath(p = null) {
		p = p ?? this.state.selectionPath
		return moment({
			year: p[0] * 10 + p[1],
			month: p[2] - 1,
			date: p[3],
			hour: p[4],
			minute: p[5],
			second: 0,
		})
	}

	moveNav(delta, zoomLevel?, cb?) {
		const deltaFnWrapped = s => {
			// Get the zoom level if not overridden
			if (zoomLevel == null) {
				zoomLevel = s.zoomLevel
			}
			zoomLevel = clamp(zoomLevel, 1, 6)

			// Clone the existing path and increment by the delta
			const path = _.clone(s.selectionPath)
			path[zoomLevel - 2] += delta

			// Clamp values appropriately

			// Minutes to hours
			while (path[5] < 0) {
				path[5] += 60
				path[4] -= 1
			}
			while (path[5] > 59) {
				path[5] -= 60
				path[4] += 1
			}

			// Hours to days
			while (path[4] < 0) {
				path[4] += 24
				path[3] -= 1
			}
			while (path[4] > 23) {
				path[4] -= 24
				path[3] += 1
			}

			// Months to years
			while (path[2] < 1) {
				path[2] += 12
				path[1] -= 1
			}
			while (path[2] > 12) {
				path[2] -= 12
				path[1] += 1
			}

			// Days to months
			if (zoomLevel >= 4) {
				const d = moment({
					year: path[0] * 10 + path[1],
					month: path[2] - 1,
					date: 1,
				})
				d.add(path[3] - 1, 'd')
				path[0] = Math.floor(d.year() / 10)
				path[1] = d.year() % 10
				path[2] = d.month() + 1
				path[3] = d.date()
			}

			// Years
			while (path[1] < 0) {
				path[1] += 10
				path[0] -= 1
			}
			while (path[1] > 9) {
				path[1] -= 10
				path[0] += 1
			}

			// Decades - 1800 to 2200 only
			path[0] = clamp(path[0], 180, 220)

			// Update the state
			return { selectionPath: path }
		}

		// Execute with callback
		this.setState(deltaFnWrapped, cb)
	}

	moveNesting(delta) {
		this.setState(s => {
			let zoom = s.zoomLevel + delta
			zoom = clamp(zoom, 1, 6)
			return { zoomLevel: zoom }
		})
	}

	Focus() {
		// Focuses the component but doesn't open anything if not already
		const starting_is_open = this.state.isOpen
		this.element.current?.focus()
		if (!starting_is_open) {
			this.setState({ isOpen: starting_is_open })
		}
	}

	Select() {
		// Proxy-calls `.select()` on the input element
		this.element.current?.select()
	}
}
