import { BuildClass, Maybe, TW_OVERFLOW_ELLIPSIS, fsmData } from '../../../universal'
import { React, _ } from '../../lib'
import { List, ListInstance } from './list'
import { useStateSimple } from './meta-types'
import { stubListInstance } from './stubs'
import { TextSearched, searchText } from './text'

export type TreeListInstance<T extends string | number> = {
	focus: () => void
	select: () => void
	getElement: () => HTMLDivElement
	getList: () => ListInstance<T, false>
}

/** TreeList component - wrapped in a forwardRef before exporting */
const TreeListComponent = <T extends string | number>(
	props: TreeListProps<T>,
	ref: React.ForwardedRef<TreeListInstance<T>>,
): React.JSX.Element => {
	// Reference of the list
	const listRef = React.useRef<ListInstance<T, false>>(stubListInstance)

	// State - value and expanded set
	const [value, setValue] = useStateSimple<T | null>(props.value, props.onUpdate)
	const [expanded, setExpanded] = useStateSimple(props.expanded, props.onUpdateExpanded)

	// Toggle expansion helper function
	const toggleExpansion = React.useCallback(
		(v: T | null) => {
			if (v == null) {
				return
			} else if (expanded.includes(v)) {
				setExpanded(expanded.filter(x => x != v))
			} else {
				setExpanded(expanded.concat(v))
			}
		},
		[setExpanded, expanded],
	)

	// Get the flat version of the options
	const options = React.useMemo(
		() => getFlatOptions(props.items, expanded, props.searchText ?? null),
		[props.items, expanded, props.searchText],
	)

	// Navigation function - arrow left
	const navigateLeft = React.useCallback(() => {
		const selected = _.first(options.filter(x => x.value == value))
		if (selected == null) {
			return
		}
		// If this is an open group, close it
		// Otherwise, go to the parent of this item
		if (selected.isGroup && selected.isExpanded) {
			toggleExpansion(value)
		} else {
			setValue(selected.parent ?? value)
		}
	}, [options, toggleExpansion, setValue, value])

	// Navigation function - arrow right
	const navigateRight = React.useCallback(() => {
		const selected = _.first(options.filter(x => x.value == value))
		if (selected == null || !selected.isGroup) {
			return
		}
		// If this is a closed group, open it
		// If this is an open group, go to its first child
		if (!selected.isExpanded) {
			toggleExpansion(value)
		} else {
			setValue(selected.items[0] ?? value)
		}
	}, [options, toggleExpansion, setValue, value])

	// Keydown event
	const onKeyDown = React.useCallback(
		(
			ev: React.KeyboardEvent<HTMLInputElement>,
			defaultEvent: (ev: React.KeyboardEvent<HTMLInputElement>) => void,
		) => {
			// Run the default event from the component
			// This handles up/down arrows and other list key events
			defaultEvent(ev)

			// Custom event for processing left/right key events
			switch (ev.which) {
				case 37:
					navigateLeft()
					break
				case 39:
					navigateRight()
					break
				default:
					return
			}
			ev.preventDefault()
			ev.stopPropagation()
		},
		[navigateLeft, navigateRight],
	)

	// Reference - allows external components to focus this
	React.useImperativeHandle(ref, () => ({
		focus: () => {
			listRef.current?.focus()
		},
		select: () => {
			listRef.current?.focus()
		},
		getElement: () => listRef.current?.getElement(),
		getList: () => listRef.current,
	}))

	// Render the list
	return (
		<List<T, false>
			className={BuildClass({
				'ui5-tree-list h-full': true,
				[props.className ?? '']: true,
			})}
			style={props.style}
			ref={listRef}
			value={value}
			onUpdate={setValue}
			height={24}
			multiple={false}
			onKeyDown={onKeyDown}
			rows={options.map(x => ({
				value: x.value,
				selectable: props.selectableGroups || !x.isGroup,
				className: 'leading-6 hover:bg-yellow-100',
				content: () => (
					<label
						className={BuildClass({
							block: true,
							'font-bold': x.value == value,
							'cursor-pointer': x.value != value,
							[TW_OVERFLOW_ELLIPSIS]: true,
						})}
						style={{ paddingLeft: `${x.indent * 16}px` }}
						title={x.label}
						onClick={() => {
							if (x.isGroup) {
								toggleExpansion(x.value)
							}
						}}
					>
						<span className="inline-block w-4 align-top px-[5px]">
							{x.isGroup && (
								<img
									className={BuildClass({
										'inline w-[6px] align-middle': true,
										'-rotate-90': x.isGroup && !x.isExpanded,
									})}
									src="/static/img/svg/triangle-down.svg"
								/>
							)}
						</span>
						<span className="w-[calc(100%-16px)] align-top">
							<TextSearched
								needles={props.searchText ?? null}
								text={x.label}
								className="bg-[#ff0] font-bold text-black"
							/>
						</span>
					</label>
				),
			}))}
		/>
	)
}

const getFlatOptions = <T extends string | number>(
	items: TreeListItem<T>[],
	expanded: T[],
	search: string | null,
): TreeListItemInternal<T>[] => {
	// Start the array
	const options: TreeListItemInternal<T>[] = []
	const isSearching = search != null && search.length > 0

	// Helper function to add an item and its children - called recursively
	const addItems = (
		parents: T[],
		item: TreeListItem<T>,
		indent: number,
	): TreeListItemInternal<T> => {
		const internalItem: TreeListItemInternal<T> = {
			value: item.value,
			label: item.label,
			indent: indent,
			parent: _.last(parents),
			parents: parents,
			items: item.items?.map(x => x.value) ?? [],
			isGroup: item.items != null,
			// When searching, all items are expanded automatically
			isExpanded: expanded.includes(item.value) || isSearching,
			passedSearchFilter: searchText(item.label, search),
		}
		options.push(internalItem)
		if (internalItem.isGroup && internalItem.isExpanded) {
			_.forEach(item.items, x => {
				addItems(parents.concat(item.value), x, indent + 1)
			})
		}
		return internalItem
	}
	_.forEach(items, x => {
		addItems([], x, 0)
	})

	// Filter based on search
	// An item is shown if it passes the search or any of its descendants do
	const items_passed = new Set(
		_.uniq(
			_.flatten(
				fsmData(options, {
					filter: x => x.passedSearchFilter,
					map: x => x.parents.concat(x.value),
				}),
			),
		),
	)
	return options.filter(x => items_passed.has(x.value))
}

/** Props for the `TreeList` */
type TreeListProps<T extends string | number> = {
	value: T | null
	onUpdate: (value: T | null) => void
	className?: string
	searchText?: string
	style?: React.CSSProperties
	expanded: T[]
	onUpdateExpanded: (expanded: T[]) => void
	items: TreeListItem<T>[]
	selectableGroups?: boolean
}

/** Internally-used representation of the `TreeList` props */
type TreeListItemInternal<T extends string | number> = {
	value: T
	label: string
	indent: number
	parent: Maybe<T>
	parents: T[]
	items: T[]
	isGroup: boolean
	isExpanded: boolean
	passedSearchFilter: boolean
}

/**
 * Prop values for an item in a `TreeList` component
 * @param value The string/number ID for the row
 * @param label The text to display in the row
 * @param items Optional nested child rows
 */
export type TreeListItem<T extends string | number> = {
	value: T
	label: string
	items?: TreeListItem<T>[]
}

/**
 * A list component with collapsible nested groups
 * @param value The currently-selected row value
 * @param onUpdate Runs when value is updated
 * @param expanded The array of currently-expanded groups
 * @param onUpdateExpanded Runs when expanded groups update
 * @param items List of items (nested groups)
 * @param selectableGroups Whether group headings are selectable (default: no)
 */
export const TreeList = React.forwardRef(TreeListComponent)
