import { BuildClass, Do, fsmData } from '../../universal'
import { React, _ } from '../lib'
import { j2r } from './component-react'
import { Bindings } from './ui5'

// TODO: fix state type
/**
 * @deprecated Use `TabControl` in ui5
 */
export class J2rTabControl extends React.Component<
	{
		cl?: string
		selected?: number | string
		onUpdate?: Function
		position?: 'top' | 'bottom' | 'left' | 'right'
		disableFocusControl?: boolean
		disableAutoTabChange?: boolean
		toolbarBefore?: object
		toolbarAfter?: object
		tabs?: {
			index: number | string
			key?: string
			cl?: string
			label: string
			disabled?: boolean
			children?: Function | any[]
		}[]
	},
	{
		selected: number | string
		focused: boolean
	}
> {
	static defaultProps = { position: 'top' }

	hiddenInput: React.RefObject<HTMLInputElement>

	constructor(props) {
		super(props)
		this.hiddenInput = React.createRef()
		Bindings(this, [this.Focus])
		this.state = {
			selected: this.props.selected,
			focused: false,
		}
	}

	override componentDidUpdate(prevProps) {
		if (prevProps.selected !== this.props.selected) {
			this.setState({ selected: this.props.selected })
		}
	}

	override render() {
		return j2r({
			cl: BuildClass({
				[this.props.cl]: true,
				'j2r-tab-control-container': true,
				vertical:
					this.props.position === 'left' || this.props.position === 'right',
				right: this.props.position === 'right',
				bottom: this.props.position === 'bottom',
			}),
			children: Do(() => {
				if (this.props.position === 'top' || this.props.position === 'left') {
					return [
						this.buildFocusControler(),
						this.buildTabWrapper(),
						this.buildContent(),
					]
				}
				return [
					this.buildFocusControler(),
					this.buildContent(),
					this.buildTabWrapper(),
				]
			}),
		})
	}

	buildFocusControler() {
		return {
			cl: 'focus-controller',
			key: 'focus-controller',
			children: [
				{
					tag: 'input',
					key: 'hidden-input-inner',
					ref: this.hiddenInput,
					onFocus: () => {
						this.setState({ focused: true })
					},
					onBlur: () => {
						this.setState({ focused: false })
					},
					onKeyDown: e => {
						switch (e.keyCode) {
							case 37:
							case 38: // Left / Up
								this.moveSelection(-1)
								break
							case 39:
							case 40: // Right / Down
								this.moveSelection(+1)
								break
							default:
								return
						}
						e.preventDefault()
						e.stopPropagation()
					},
				},
			],
		}
	}

	buildTabWrapper() {
		return {
			key: 'tabs',
			cl: 'tab-labels-wrapper',
			children: [
				this.buildTabSibling('before'),
				{
					cl: 'tabs-wrapper-inner',
					key: 'tabs-wrapper-inner',
					children: [this.buildTabs()],
					onClick: this.Focus,
				},
				this.buildTabSibling('after'),
			],
		}
	}

	buildTabSibling(k) {
		return {
			cl: `tab-sibling tab-sibling-${k}`,
			key: `tab-${k}`,
			children: Do(() => {
				let el =
					k === 'before' ? this.props.toolbarBefore : this.props.toolbarAfter
				if (_.isFunction(el)) {
					el = el()
				}
				if (el == null) {
					return []
				}
				return [_.assign(el, { key: 'inner' })]
			}),
		}
	}

	buildTabs() {
		return {
			cl: 'tab-labels-chrome noselect',
			key: 'tabs',
			children: this.props.tabs.map(tab => ({
				key: tab.key ?? tab.label,
				cl: BuildClass({
					[tab.cl]: true,
					[tab.label.replace(/\s/g, '-').replace(/\./g, '').toLowerCase()]:
						true,
					'tab-label': true,
					selected: tab.index === this.state.selected,
					disabled: tab.disabled,
				}),
				children: [
					{
						cl: 'tab-box',
						key: 'box',
					},
					{
						cl: 'tab-text',
						key: 'label',
						text: tab.label,
						title: tab.label,
					},
				],
				onMouseDown: () => {
					if (!tab.disabled) {
						if (!this.props.disableAutoTabChange) {
							this.setState({ selected: tab.index })
						}
						;(this.props.onUpdate ?? _.noop)(tab.index)
					}
				},
			})),
		}
	}

	buildContent() {
		return {
			cl: 'tab-containers',
			key: 'containers',
			children: fsmData(this.props.tabs, {
				filter: tab => tab.index === this.state.selected,
				map: tab => ({
					key: tab.key ?? tab.label,
					cl: BuildClass({
						'tab-container': true,
						[tab.label.replace(/\s/g, '').replace(/\./g, '').toLowerCase()]:
							true,
						selected: tab.index === this.state.selected,
					}),
					children: Do(() => {
						if (tab.disabled) {
							return []
						} else if (_.isFunction(tab.children)) {
							return tab.children() ?? []
						}
						return tab.children ?? []
					}),
				}),
			}),
		}
	}

	Focus() {
		if (!this.props.disableFocusControl) {
			this.hiddenInput.current?.focus()
		}
	}

	moveSelection(d) {
		// Get the new index
		const min = _.min(this.props.tabs.map(x => x.index))
		const max = _.max(this.props.tabs.map(x => x.index))
		var newIndex = Do(() => {
			newIndex = this.state.selected + d
			if (newIndex < min) {
				newIndex = min
			} else if (newIndex > max) {
				newIndex = max
			}
			return newIndex
		})

		// Update the value and report back to the parent
		this.setState({ selected: newIndex })
		;(this.props.onUpdate ?? _.noop)(newIndex)
	}
}
