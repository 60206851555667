import { BuildClass, fsmData } from '../../../universal'
import { React, _ } from '../../lib'
import { useStateSync } from './meta-types'
import { stubSelect } from './stubs'

/** Valid parameters for HTML input elements */
type dropdownBaseProps = React.DetailedHTMLProps<
	React.SelectHTMLAttributes<HTMLSelectElement>,
	HTMLSelectElement
>

// Full component
const DropdownComponent = (
	props: {
		value: string | string[]
		onUpdate: (value: string | number | string[]) => void
		className?: string
	} & dropdownBaseProps,
	ref: React.ForwardedRef<HTMLSelectElement>,
) => {
	// Ref
	const refElement = React.useRef<HTMLSelectElement>(stubSelect)

	// Default the value to the serialized prop value
	// Sync state with props
	const [value, setValue] = React.useState(props.value)
	useStateSync({
		stateVal: value,
		propVal: props.value,
		setState: setValue,
		setProp: props.onUpdate,
	})

	// Helper function to get the current value(s)
	const getValue = () => {
		if (!props.multiple) {
			return refElement.current.value
		}
		const opts = refElement.current?.querySelectorAll('option')
		return fsmData(Array.from(opts), {
			filter: el => el.selected,
			map: el => el.value ?? '1',
		})
	}

	// Cache the change and blur functions
	const onChange = React.useCallback(() => {
		setValue(getValue())
	}, [])

	// Massage the options

	// Return the reference
	React.useImperativeHandle(ref, () => refElement.current)

	// Build the HTML input element with the custom class
	// Straight map the value state string in/out
	return (
		<select
			ref={refElement}
			className={BuildClass({
				'ui5 ui5-select': true,
				[props.className ?? '']: true,
			})}
			value={value}
			onChange={onChange}
			{..._.omit(props, ['value', 'onUpdate'])}
		/>
	)
}

/**
 * A very basic native `select` dropdown
 * Only use this instead of a Combobox if you need something very specific
 */
export const Dropdown = React.forwardRef(DropdownComponent)
