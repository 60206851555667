import { BuildClass } from '../../universal'
import { React, _ } from '../lib'
import { IRISResponseProgress, IRISResponseProgressSection } from './component-iris'
import { CJSX, LoadingSpinnerLarge, LoadingSpinnerSmall } from './ui5'

type LoadingSpinnerProgressProps = {
	cl?: string // TODO - use this
	type?: string // TODO - add possible types
	report?: IRISResponseProgress
}

export const LoadingSpinnerProgress = (props: LoadingSpinnerProgressProps) => {
	// TODO - add different type options
	// type: PropTypes.oneOf([
	// 	# Larger types that show the sections
	// 	'full', # A table with each nested result with its own loading (see reports)
	// 	# Percentage-based with no concept of sections
	// 	# 'percentage-inside' # A large loading spinner with the percentage inside
	// 	# 'percentage-outside' # A small loading spinner with a percentage beside it
	// 	# 'progress-bar' # Just a boring progress bar with no section context
	// ])

	const cache = {
		sectionPathsOrder: [] as string[],
		sectionPaths: {} as _.Dictionary<string[]>,
	}

	// Skip early if no progress report is available
	if (props.report == null) {
		return <LoadingSpinnerLarge />
	}

	// Build up a flat array of paths with parents
	const paths: string[][] = []

	// Recursive function to add nodes to the paths array
	const getSectionPath = (
		parents: string[],
		section: IRISResponseProgressSection,
	): void => {
		const this_path = parents.concat(section.Key)
		paths.push(this_path)
		section.Sections?.forEach(x => {
			getSectionPath(this_path, x)
		})
	}

	// Start scanning from the top-level
	props.report.Sections.forEach(x => {
		getSectionPath([], x)
	})

	// Map each by its leaf node key
	cache.sectionPathsOrder = paths.map(x => x[x.length - 1])
	cache.sectionPaths = _.mapKeys(paths, x => x[x.length - 1])

	const buildList = (sections: IRISResponseProgressSection[]) => (
		<ul className="list">
			{_.map(sections, x => (
				<li key={x.Key}>
					{/* Indicator status */}
					{buildIndicator(x.Key)}
					{/* Label */}
					<span>{x.Name}</span>
					{/* Any child sections */}
					{x.Sections ? buildList(x.Sections) : <></>}
				</li>
			))}
		</ul>
	)

	const buildIndicator = key => {
		// Determine what kind of section this is
		const is_current = props.report.Section === key
		const is_child_current = Array.from(
			cache.sectionPaths[props.report.Section],
		).includes(key)

		// Check if this key has been surpassed by the current key
		let has_passed = false
		if (!is_child_current && !is_current) {
			const selected_section = cache.sectionPathsOrder.indexOf(props.report.Section)
			const this_section = cache.sectionPathsOrder.indexOf(key)
			has_passed = this_section < selected_section
		}

		// Get the percentage as a number - `-1` for indeterminate
		let percentage = Math.round((props.report.Progress / props.report.Total) * 100)
		if (percentage === Infinity || isNaN(percentage)) {
			percentage = -1
		}

		// Build the element
		return (
			<span
				className={BuildClass({
					indicator: true,
					complete: has_passed,
				})}
			>
				{/* Spinner */}
				<span
					className={BuildClass({
						'sub-indicator spinner': true,
						semi: !is_current,
						hidden:
							!(is_current || is_child_current) ||
							(is_current && percentage !== -1),
					})}
				>
					<LoadingSpinnerSmall />
				</span>

				{/* Percentage */}
				<span
					className={BuildClass({
						'sub-indicator perc': true,
						hidden: percentage === -1 || !is_current,
					})}
				>
					{percentage}%
				</span>

				{/* Completion */}
				<span
					className={BuildClass({
						'sub-indicator completion': true,
						hidden: !has_passed || is_current || is_child_current,
					})}
				>
					✔
				</span>
			</span>
		)
	}

	// Render
	return (
		<div className="loading-progress-pane">
			<div
				className={BuildClass({
					'progress-details': true,
					hidden: props.report == null,
				})}
			>
				<CJSX cond={props.report?.Sections != null}>
					{buildList(props.report.Sections)}
				</CJSX>
			</div>
			<div
				className={BuildClass({
					'pre-progress': true,
					hidden: props.report != null,
				})}
			>
				<LoadingSpinnerLarge />
			</div>
		</div>
	)
}
