import { BuildClass, Maybe, TW_OVERFLOW_ELLIPSIS } from '../../../universal'
import { React, _ } from '../../lib'
import { uploadFileIris } from '../component-upload'
import { MatchRender, RSInstanceD, useRSInstance } from './meta-types'
import { stubInput } from './stubs'

export type FileUploadItem = {
	Filename: string
	Checksum: Maybe<string>
}

type FileUploadProps = {
	className?: string
	/** Whether this file uploader accepts multiple */
	multiple: boolean
	/** Stores the value of the file(s) uploaded and set */
	value: FileUploadItem[]
	/**
	 * Update event when the file list changes.
	 * The `isReady` flag is for when the files are uploaded and ready.
	 */
	onUpdate: (files: FileUploadItem[]) => void
	/** Placeholder text. If none given, defaults to "Click or drag files here" */
	placeholder?: string
	/** Height of the element */
	height?: number
}

type FileUploadState = {
	files: FileUploadItem[]
	isUploadingProgress: Maybe<number>
	isFocused: boolean
	isDraggingOver: boolean
}

type FileUploadRefs = {
	inputRef: React.RefObject<HTMLInputElement>
}

type ReducerState = RSInstanceD<FileUploadProps, FileUploadState, FileUploadRefs, object>

const getDefaultState = (p: FileUploadProps): FileUploadState => ({
	files: p.value ?? [],
	isUploadingProgress: null,
	isDraggingOver: false,
	isFocused: false,
})

export const FileUpload = (props: FileUploadProps): React.JSX.Element => {
	// Manage state
	const rs: ReducerState = useRSInstance({
		props,
		defaultState: getDefaultState,
		refs: {
			inputRef: React.useRef<HTMLInputElement>(stubInput),
		},
		actionToDelta: null,
	})
	console.log({ state: rs.state })

	// Render
	return (
		<div
			className={BuildClass({
				[rs.props.className ?? '']: true,
				'relative h-full font-light text-[#888]': true,
				'border-2 border-dashed rounded-md': true,
				'cursor-pointer': true,
				'bg-[#ffe] hover:bg-[#ffb]':
					!rs.state.isDraggingOver && !rs.state.isFocused,
				'bg-[#ffb]': rs.state.isFocused && !rs.state.isDraggingOver,
				'bg-[#ff9]': rs.state.isDraggingOver,
				'border-[#ccc] hover:border-[#aaa]':
					!rs.state.isDraggingOver && !rs.state.isFocused,
				'border-[#00f]': rs.state.isFocused && !rs.state.isDraggingOver,
				'border-[#000]': rs.state.isDraggingOver,
			})}
			style={{ height: rs.props.height }}
			onDragEnter={ev => {
				ev.preventDefault()
				rs.updateState({ isDraggingOver: true })
			}}
			onDragOver={ev => {
				ev.preventDefault()
				rs.updateState({ isDraggingOver: true })
			}}
			onDragLeave={ev => {
				ev.preventDefault()
				rs.updateState({ isDraggingOver: false })
			}}
			onDrop={ev => {
				ev.preventDefault()
				rs.updateState({ isDraggingOver: false })
				uploadFiles(rs, ev.dataTransfer.files)
			}}
		>
			<input
				className="pointer-events-none w-0 h-0 overflow-hidden"
				type="file"
				ref={rs.refs.inputRef}
				multiple={rs.props.multiple}
				onFocus={() => {
					rs.updateState({ isFocused: true })
				}}
				onBlur={() => {
					rs.updateState({ isFocused: false })
				}}
				onChange={async ev => {
					uploadFiles(rs, ev.target.files)
					ev.target.value = ''
				}}
			/>
			<span className="absolute inline-block top-1/2 w-full translate-y-[-50%] text-center">
				<MatchRender
					check={[
						// Uploading progress indicator
						{
							when: rs.state.isUploadingProgress !== null,
							then: <>Uploading {rs.state.isUploadingProgress}%</>,
						},
						// Shown while dragging a file over
						{
							when: rs.state.isDraggingOver,
							then: <>Drop to upload</>,
						},
						// Show the currently-uploaded files that are selected
						{
							when: rs.state.files.length > 0,
							then: (
								<>
									{rs.state.files.map(file => (
										<AttachedFileLine
											key={file.Checksum}
											file={file}
											onRemove={() => {
												const files = rs.state.files.filter(
													f => f.Checksum !== file.Checksum,
												)
												rs.updateState({ files: files })
												rs.props.onUpdate(files)
											}}
										/>
									))}
								</>
							),
						},
						// Default placeholder
						{
							when: true,
							then: (
								<>{rs.props.placeholder ?? 'Click or drag files here'}</>
							),
						},
					]}
				/>
			</span>
		</div>
	)
}

const AttachedFileLine = (props: {
	file: FileUploadItem
	onRemove: () => void
}): React.JSX.Element => {
	const [isHovered, setIsHovered] = React.useState(false)
	return (
		<div
			className={BuildClass({
				'flex items-center m-1 rounded-sm': true,
				'bg-red-200': isHovered,
				'hover:bg-[#9df]': !isHovered,
			})}
		>
			{/* Filename */}
			<span
				className={BuildClass({
					[TW_OVERFLOW_ELLIPSIS]: true,
					'flex-grow align-baseline': true,
					'leading-6 font-normal': true,
					'text-neutral-800': true,
				})}
			>
				{props.file.Filename}
			</span>
			{/* Delete icon */}
			<span
				className={BuildClass({
					'flex-shrink-0 align-baseline': true,
					'w-6 h-6 p-1 ml-1': true,
					'border border-transparent rounded-sm': true,
					'cursor-pointer hover:border-[#f00] hover:bg-red-300': true,
				})}
				onMouseEnter={() => {
					setIsHovered(true)
				}}
				onMouseLeave={() => {
					setIsHovered(false)
				}}
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					props.onRemove()
				}}
			>
				<img
					className="w-4 h-4"
					src="/static/img/i8/w11-minus.svg"
					alt="Remove attachment"
				/>
			</span>
		</div>
	)
}

const uploadFiles = async (
	rs: ReducerState,
	fileList: FileList | null,
): Promise<FileUploadItem[]> => {
	console.log('Pre-starting upload')
	// Set progress to 0 since we're now uploading
	rs.updateState({ isUploadingProgress: 0 })
	const files = Array.from(fileList ?? [])
	console.log({ files })

	// Track collective progress so we can report a global average
	// This could potentially weight file sizes but it's probably not a big deal
	const progress = _.times(files.length, () => 0)
	console.log({ progress })

	// Build the promise array for uploading each file
	const uploadedFiles = await Promise.all(
		files.map(
			async (file, index) =>
				new Promise<FileUploadItem>(async resolve => {
					console.log('Starting upload')
					const uploader = uploadFileIris({
						fileObj: file,
						// Track the progress event to update the global average
						onProgress: e => {
							progress[index] = e.percentage
							const perc = Math.floor(_.sum(progress) / files.length)
							rs.updateState({ isUploadingProgress: perc })
						},
						// Unpack the checksum and filename to resolve promise
						onComplete: e => {
							console.log(`Completed: ${e.checksum}`)
							resolve({
								Filename: e.file.name,
								Checksum: e.checksum,
							})
						},
					})
					uploader()
				}),
		),
	)
	console.log('Completed')

	// Update the selected file state and clear the uploading progress
	console.log({ uploadedFiles })
	rs.updateState({
		files: uploadedFiles,
		isUploadingProgress: null,
	})
	rs.props.onUpdate(uploadedFiles)
	return uploadedFiles
}
