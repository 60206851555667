import { BuildClass, Do, Maybe, fsmData } from '../../universal'
import { React, _ } from '../lib'
import { J2rCheckbox, J2rHelp, J2rObject, j2r, j2rtxt } from './component-react'
import { J2rLCIObject, J2rListComponent } from './component-react-list'
import { ConditionalObject, ContextMenuItem, setContextMenu } from './ui5'

/** @deprecated Use `ListGrid` component in ui5 */
export type J2rListGridGroup<T> = {
	ID: any
	Name: string
	Records: T[]
}

/** @deprecated Use `ListGrid` component in ui5 */
export type J2rListGridField<T> = {
	key?: string // Key of the record - defaults to the lbl
	lbl: string // Label to show in the heading row
	lblLong?: string // Longer version of label to show in context menus
	lblDesc?: string // Label tooltip to show in the heading row
	// Component attributes
	cl?: (record: T) => Maybe<string> // Adds a custom class to each cell in the column
	text?: (record: T) => string | number // Gets text from value - this is searchable
	searchable?: boolean
	display?: (record: T) => J2rObject // Gets custom rendered j2r object (overides `text`)
	tooltip?: (record: T) => string // Gets tooltip text
	sortVal?: (record: T, isRev?: boolean) => any // Converts a value to a sortable value
	sortReverseDefault?: boolean // Whether the default sort is descending
	sortable?: boolean // Whether this field can be sorted (generally implied)
	tooltipBubble?: boolean // Whether a question mark tooltip hint is shown
	onClick?: (record: T, e?: React.MouseEvent) => void // Pass-through on-click handler for the cell
	// Colouring by value options
	colorVal?: (record: T) => number // Gets a value suitable for numeric colour scales
	colorScale?: {
		// Hue / saturation / lightness (default shown in comment)
		low?: {
			h?: number // 0 (deg)
			s?: number // 100 (%)
			l?: number
		} // 75 (%)
		high?: {
			h?: number // 120 (deg)
			s?: number // 100 (%)
			l?: number
		}
	} // 70 (%)
	// Grouping-specific field attributes
	groupVal?: (record: T) => any // Turns record into grouping ID (when grouped)
	groupName?: (groupID: any, record: T) => string // Turns grouping ID into group name (when grouped)
	groupSort?: (group: J2rListGridGroup<T>) => any // Turns grouping ID into sort index (when grouped)
	// Filtering by value - options shown shown even if not found in current list
	filterValues?: () => any[]
}

/** @deprecated Use `ListGrid` component in ui5 */
export type J2rListGridView = {
	colorBy?: string
	// Sorting
	sortKey?: string
	sortRev?: boolean
	// Filtering
	hiddenFields?: any[]
	columnFilters?: object
	searchText?: string
	// Grouping
	groupingField?: string
	groupingFieldRev?: boolean
}
type J2rListGridProps<T, V, M> = {
	cl?: string
	clRow?: (record: Maybe<T>) => string // Takes in a record and returns its custom classes
	tooltipRow?: (record: Maybe<T>) => string // Takes in a record and returns its custom tooltip
	pk: (record: T) => V
	selectable?: (record: T) => boolean // Whether a row can be selected (defaults to true)
	onContextMenu?: (record: T, e: React.MouseEvent) => void
	defaultSortingKey?: string
	defaultSortingRev?: boolean
	data: T[]
	searchText?: string // Overrules the one in the `view` model - legacy
	fields?: J2rListGridField<T>[]
	// Grouping options
	grouping?: {
		key?: (record: T) => any // Takes in a row record, gives a grouping ID
		name?: (groupID: any, record: T) => string // Takes in a grouping ID, gives a title string
		sort?: (group: J2rListGridGroup<T>) => any // Takes grouping obj (ID, Name, Records)
		reverse?: boolean
		gaps?: boolean
	} // Whether an empty row sits above each new group
	// Selection state management
	value?: M extends true ? V[] : V
	onUpdate?: (value: M extends true ? V[] : V, view: J2rListGridView) => void
	multiple?: M
	multipleDefault?: boolean
	readOnly?: boolean
	checkboxSelection?: boolean
	// View stetings - sort / group / filters / colour etc.
	// Leave as null for the reset default
	view?: J2rListGridView
	// Extra options
	lazyRenderHeight?: number | ((record: T) => number)
	lazyThrottleMs?: number
	sortable?: boolean
	onClick?: (record: T, e?: any, def_ev?: any) => void // Passes in the record associated with the clicked row
	style?: any
	sortableItems?: any // Drag and drop manual sorting
	disableFocusControl?: boolean
}

/**
 * The read-only version of a `J2rEditableGrid`
 * Aims to replace every instance of using a `J2rListComponent` to show a grid
 * Supports searching, sorting column headers, and custom cell components
 * @deprecated Use `ListGrid` from ui5 instead
 */
export class J2rListGrid<T, V, M extends boolean = false> extends React.Component<
	J2rListGridProps<T, V, M>,
	any
> {
	// State management #

	static defaultProps = {
		cl: null,
		pk: x => x.ID,
		defaultSortingRev: false,
		readOnly: false,
		sortable: true,
		searchText: '',
	}

	listCmpt: React.RefObject<J2rListComponent>
	colorScale: any

	constructor(props) {
		super(props)
		this.onUpdate = this.onUpdate.bind(this)

		// Validate combinations of properties
		if (this.props.sortable && this.props.defaultSortingKey == null) {
			console.error('No default sorting key defined')
		}
		if (this.props.checkboxSelection && !this.props.multiple) {
			console.error('Cannot have checkbox selection without multi-select')
		}
		if (this.props.checkboxSelection && !this.props.multipleDefault) {
			console.error('Cannot have checkbox selection without multi-select default')
		}

		// References
		this.listCmpt = React.createRef()

		// Initial state
		this.state = {
			sortKey: this.props.view?.sortKey ?? this.props.defaultSortingKey,
			sortRev: this.props.view?.sortRev ?? this.props.defaultSortingRev,
			hiddenFields: this.props.view?.hiddenFields ?? [],
			grouping: null,
			columnFilters: this.props.view?.columnFilters ?? {},
			groupingField: this.props.view?.groupingField ?? null, // Only set when doing an internal grouping
			groupingFieldRev: this.props.view?.groupingFieldRev ?? null, // Only set when doing an internal grouping
			colorBy: this.props.view?.colorBy,
			searchText: this.props.searchText ?? this.props.view?.searchText ?? '',
			value:
				this.props.value ??
				Do(() => {
					if (this.props.multiple) {
						return []
					}
					return null
				}),
		}
		_.assign(
			this.state,
			this.getGroupingDelta({
				groupingField: this.state.groupingField,
				groupingFieldRev: this.state.groupingFieldRev,
			}),
		)
	}

	override componentDidUpdate(prevProps?) {
		// Helper function to update internal state to match props if props have changed
		const checkUpdateProp = (key_prop, key_state, def?) => {
			if (!_.isEqual(this.props[key_prop], prevProps[key_prop])) {
				const val = this.props[key_prop] ?? def
				if (!_.isEqual(val, this.state[key_state])) {
					this.setState({ [key_state]: val })
				}
			}
		}

		const checUpdatePropView = (key_prop, key_state, def?) => {
			if (!_.isEqual(this.props.view?.[key_prop], prevProps.view?.[key_prop])) {
				const val = this.props.view?.[key_prop] ?? def
				if (!_.isEqual(val, this.state[key_state])) {
					this.setState({ [key_state]: val })
				}
			}
		}

		// Check if the props have updated in a way that should impact the internal state
		checkUpdateProp('value', 'value')
		checkUpdateProp('defaultSortingKey', 'sortKey')
		checkUpdateProp('defaultSortingRev', 'sortRev')

		// Check if the view props have updated
		checUpdatePropView('sortKey', 'sortKey')
		checUpdatePropView('sortRev', 'sortRev')
		checUpdatePropView('hiddenFields', 'hiddenFields', [])
		checUpdatePropView('columnFilters', 'columnFilters', {})
		checUpdatePropView('groupingField', 'groupingField', null)
		checUpdatePropView('groupingFieldRev', 'groupingFieldRev', null)
		checUpdatePropView('colorBy', 'colorBy', null)

		// Search text can be in both
		if (prevProps.searchText !== this.props.searchText) {
			this.setState({ searchText: this.props.searchText })
		}
		if (prevProps.view?.searchText !== this.props.view?.searchText) {
			this.setState({ searchText: this.props.searchText })
		}
	}

	updateState(delta) {
		this.setState(delta, () => {
			this.onUpdate()
		})
	}

	onUpdate() {
		const fn = this.props.onUpdate ?? _.noop
		fn(this.state.value, {
			sortKey: this.state.sortKey,
			sortRev: this.state.sortRev,
			hiddenFields: this.state.hiddenFields,
			columnFilters: this.state.columnFilters,
			groupingField: this.state.groupingField,
			groupingFieldRev: this.state.groupingFieldRev,
			colorBy: this.state.colorBy,
		})
	}

	// Builders #

	override render() {
		return j2r({
			tag: J2rListComponent,
			value: this.state.value,
			ref: this.listCmpt,
			onUpdate: v => {
				this.updateState({ value: v })
			},
			multiple: this.props.multiple,
			multipleDefault: this.props.multipleDefault,
			readOnly: this.props.readOnly,
			lazyRenderHeight: this.props.lazyRenderHeight,
			lazyThrottleMs: this.props.lazyThrottleMs,
			cl: BuildClass({
				[this.props.cl]: true,
				j2rlistgrid: true,
				'checkbox-selection': this.props.checkboxSelection,
			}),
			style: this.props.style,
			headingRow: this.buildHeadingRow(),
			items: this.buildItems(),
			sortable: this.props.sortableItems,
			disableFocusControl: this.props.disableFocusControl,
		})
	}

	buildHeadingRow() {
		return {
			cl: BuildClass({
				'gridRow heading ': true,
				[(this.props.clRow ?? (() => null))(null)]: true,
			}),
			children: _.compact(
				_.flatten([
					// Checkbox as the first field, if doing checkbox selection
					ConditionalObject(this.props.checkboxSelection, () => ({
						tag: 'span',
						key: 'checkbox-selection',
						cl: 'checkbox-selection noselect',
						children: [
							{
								tag: J2rCheckbox,
								key: 'checkbox',
								triggerClick: true,
								value: Do(() => {
									const values = _.sortBy(
										this.props.data.map(x => this.props.pk(x)),
									)
									return _.isEqual(values, _.sortBy(this.state.value))
								}),
								onClick: e => e.stopPropagation(),
								onUpdate: v => {
									const delta = s => ({
										value: Do(() => {
											if (v) {
												return s.value.concat(
													this.props.data.map(x =>
														this.props.pk(x),
													),
												)
											}
											return []
										}),
									})
									this.updateState(delta)
								},
							},
						],
					})),

					// Fields
					fsmData(this.props.fields, {
						filter: F => !this.state.hiddenFields.includes(F.key ?? F.lbl),
						map: F => this.buildHeadingCell(F),
					}),
				] as J2rObject[]),
			),
		}
	}

	buildHeadingCell(F: J2rListGridField<T>) {
		// Cache some values first
		const key = F.key ?? F.lbl
		let lblDesc = F.lblDesc ?? F.lbl
		let { lbl } = F
		const sortable =
			this.props.sortable &&
			(F.sortVal != null || F.text != null) &&
			(F.sortable ?? true)

		// Adjust the labels if it's filtered
		if (this.state.columnFilters[key] != null) {
			lblDesc += ' (filtered by value)'
			lbl += '*'
		}

		// Build the heading cell field
		return {
			tag: 'span',
			key,

			// Class includes the field name, sorting stuff, and help indicator
			cl: BuildClass({
				// Field-specific class for column identification
				[(key ?? '').toLowerCase().replace(/[^a-z0-9]/g, '')]: true,

				// Base styles
				'heading-cell-inner': true, // All heading cells have this
				[F.cl?.(null)]: true, // Custom row class
				'help-indicator': F.tooltipBubble, // For help tooltips
				filtered: this.state.columnFilters[key] != null,

				// Sorting styles
				nosort: !sortable,
				sorted: sortable && key === this.state.sortKey,
				'sort-asc': sortable && key === this.state.sortKey && !this.state.sortRev,
				'sort-dsc': sortable && key === this.state.sortKey && this.state.sortRev,

				// Grouping styles
				grouped: key === this.state.groupingField,
				'group-asc':
					key === this.state.groupingField && !this.state.grouping.reverse,
				'group-dsc':
					key === this.state.groupingField && this.state.grouping.reverse,
			}),

			// Text label (or children if using a tooltip bubble)
			text: !F.tooltipBubble ? lbl : undefined,
			title: !F.tooltipBubble ? lblDesc : undefined,
			children: ConditionalObject(F.tooltipBubble, [
				{
					key: 'lbl',
					text: lbl,
					title: lblDesc,
				},
				{
					tag: J2rHelp,
					key: 'help-tooltip',
					title: lblDesc,
				},
			]),

			// Click event only applies if it's sortable or is the current grouping key
			onClick: Do(() => {
				// Grouped by this - reverse the asc/desc order
				if (key === this.state.groupingField) {
					return () => {
						this.updateState(s => ({
							grouping: _.assign({}, s.grouping, {
								reverse: !s.grouping.reverse,
							}),
						}))
					}
					// Sorting
				} else if (sortable) {
					return () => {
						this.updateState(s => ({
							sortKey: key,
							sortRev: Do(() => {
								if (s.sortKey === key) {
									return !s.sortRev
								}
								return F.sortReverseDefault ?? false
							}),
						}))
					}
					// No on-click handler
				}
				return undefined
			}),

			// On middle mouse down - activate grouping
			// If already grouping by this column, ungroup
			onMouseDown: e => {
				if (e.button === 1) {
					this.updateState(s =>
						this.getGroupingDelta(
							Do(() => {
								if (key === s.groupingField) {
									return {
										groupingField: null,
										groupingFieldRev: null,
									}
								}
								return {
									groupingField: key,
									groupingFieldRev: false,
								}
							}),
						),
					)
					return
				}
			},

			// Context menu
			onContextMenu: e => {
				e.preventDefault()
				this.headingCellContextMenu(e, F)
			},
		}
	}

	buildItems() {
		// Cache the colour scale information into @color
		this.cacheColorScale()

		// Iterate over each group
		const groups = this.getGroupedRecords()
		const grouping = this.state.grouping ?? this.props.grouping
		return _.compact(
			_.flattenDeep(
				_.map(groups, group => {
					// Calculate what's happening with the headings
					const heading_gap =
						(groups.length > 1 || group.ID != null) && (grouping.gaps ?? true)
					const heading_row =
						(groups.length > 1 || group.ID != null) && group.Name != null
					let total_rows = group.Records.length
					total_rows += heading_gap ? 1 : 0
					total_rows += heading_row ? 1 : 0

					// Build the rows for this group
					return [
						// Build the heading for the group - only if there are groups and gaps wanted
						Do(() => {
							if (heading_gap) {
								return {
									lazyRenderHeight:
										group.ID === groups[0].ID ? 0 : null,
									getItem: () => ({
										value: `listgrid-group-gap-${group.ID}`,
										selectable: false,
										cl: BuildClass({
											gridRow: true,
											'listgrid-group-heading-gap': true,
											'no-select-highlight': true,
											'hidden-grouping-row':
												group.ID === groups[0].ID,
											[this.props.clRow?.(null)]: true,
										}),
										children: [
											{
												tag: 'span',
												key: 'stub',
												dangerouslySetInnerHTML: {
													__html: '&nbsp;',
												},
											},
										],
									}),
								}

								// Otherwise add a hidden row
							} else if (heading_row) {
								return {
									lazyRenderHeight: 0,
									getItem: () => ({
										value: `listgrid-group-mod21-${group.ID}`,
										selectable: false,
										cl: 'gridRow hidden-grouping-row',
									}),
								}
							}
							return undefined
						}),

						// Heading row for the group
						heading_row ? () => this.buildGroupHeadingRow(group) : undefined,

						// Apply an independent sort process to each group
						// Build the group items as if it's its own grid
						this.buildItemsInnerGroup(group.Records),

						// Add an empty row to ensure the group has an even number
						// This ensures the row highlights are consistent between groups
						ConditionalObject(
							heading_row && groups.length > 1 && total_rows % 2 != 0,
							() => ({
								lazyRenderHeight: 0,
								getItem: () => ({
									value: `listgrid-group-mod22-${group.ID}`,
									selectable: false,
									cl: 'gridRow hidden-grouping-row',
								}),
							}),
						),
					] as any[]
				}),
			),
		)
	}

	buildItemsInnerGroup(records: T[]) {
		// Get the sorting function
		const sortingFunction = Do(() => {
			// If sorting disabled...
			if (!this.props.sortable) {
				return () => 1
			}

			// Get the sorting key
			const { sortKey } = this.state

			// Loop through the fields and find which sorting function to use
			let sortFn = null
			_.forEach(this.props.fields, F => {
				const key = F.key ?? F.lbl
				if (key === sortKey) {
					const fn = F.sortVal ?? F.text
					sortFn = fn != null ? fn : record => record[key]
					return false
				}
				return true
			})
			if (sortFn != null) {
				return sortFn
			}

			// No sorting key found
			console.warn('No sorting value found for key: ', this.state.sortKey)
			return () => 1
		})

		// Iterate the records
		return fsmData(records, {
			// Sorting
			sort: record => sortingFunction(record, this.state.sortRev),
			reverse: this.state.sortRev && this.props.sortable,
			// Build rows
			map: x => {
				// Either return a basic function, or add the lazy render height
				if (_.isFunction(this.props.lazyRenderHeight)) {
					return {
						lazyRenderHeight: this.props.lazyRenderHeight(x),
						getItem: () => this.buildItem(x),
					}
				}
				return () => this.buildItem(x)
			},
		})
	}

	buildGroupHeadingRow(group) {
		return {
			value: `listgrid-group-${group.ID}`,
			selectable: false,
			cl: BuildClass({
				gridRow: true,
				'listgrid-group-heading': true,
				'no-select-highlight': true,
				[(this.props.clRow ?? (() => ''))(null)]: true,
			}),
			title: this.props.tooltipRow?.(null),
			children: _.compact(
				_.flatten([
					// TODO - implement `@props.checkboxSelection` for group headings
					// TODO - add collapsing button to show/hide the group
					{
						tag: 'span',
						key: 'title',
						children: [j2rtxt('txt', group.Name)],
						title: `${group.Name} - ${group.Records.length}`,
					},
				]),
			),
		}
	}

	buildItem(record): J2rLCIObject {
		const selectable = (this.props.selectable ?? (() => true))(record)
		return {
			value: this.props.pk(record),
			selectable: selectable,
			cl: BuildClass({
				gridRow: true,
				[(this.props.clRow ?? (() => null))(record)]: true,
			}),
			title: this.props.tooltipRow?.(record),
			children: _.compact(
				_.flatten([
					// Checkbox as the first field, if doing checkbox selection
					ConditionalObject(this.props.checkboxSelection, () => ({
						tag: 'span',
						key: 'checkbox-selection',
						cl: 'checkbox-selection',
						children: [
							ConditionalObject(selectable, {
								tag: J2rCheckbox,
								key: 'checkbox',
								readOnly: true,
								value: _.includes(
									this.state.value,
									this.props.pk(record),
								),
							}),
						],
					})),

					// Fields
					fsmData(this.props.fields, {
						filter: F => !this.state.hiddenFields.includes(F.key ?? F.lbl),
						map: F => ({
							tag: 'span',
							key: F.key ?? F.lbl,
							style: this.buildColorStyle(F, record),
							cl: BuildClass({
								[(F.key ?? F.lbl ?? '')
									.toLowerCase()
									.replace(/[^a-z0-9]/g, '')]: true,
								[F.cl?.(record)]: F.cl != null,
							}),
							children: this.buildCell(F, record),
							title: Do(() => {
								if (F.tooltip != null) {
									return F.tooltip(record)
								}
								return record[F.key ?? F.lbl]
							}),
							onClick: Do(() => {
								if (F.onClick != null) {
									return e => {
										F.onClick(record, e)
									}
								}
								return undefined
							}),
						}),
					}),
				] as J2rObject[]),
			),
			onClick: Do(() => {
				if (this.props.onClick != null) {
					return (e, def_ev) => {
						this.props.onClick(record, e, def_ev)
					}
				}
				return undefined
			}),
			onContextMenu: (v, e) => {
				this.props.onContextMenu?.(v, e)
			},
		}
	}

	buildColorStyle(field, record) {
		// Return null if not colouring by this field
		if (
			this.state.colorBy == null ||
			this.state.colorBy !== (field.key ?? field.lbl)
		) {
			return null
		}

		// Get the value for this cell - no background if it's non-numeric
		let val = (field.colorVal ?? field.sortVal ?? field.text ?? _.noop)(record)
		if (val == null || isNaN(+val)) {
			return null
		}
		val = +val

		// Get the colour style if colouring by this field and the proportion of the value
		const scale = this.colorScale[1] - this.colorScale[0]
		const proportion = (val - this.colorScale[0]) / scale // domain [0, 1]

		// Get the HSL scale for the field - fill in defaults of a red/green shift
		let hsl_high = field.colorScale?.high ?? {}
		let hsl_low = field.colorScale?.low ?? {}
		hsl_low = {
			h: hsl_low.h ?? 0,
			s: hsl_low.s ?? 100,
			l: hsl_low.v ?? 75,
		}
		hsl_high = {
			h: hsl_high.h ?? 120,
			s: hsl_high.s ?? 100,
			l: hsl_high.v ?? 70,
		}

		// Calculate the style on the spectrum and return
		const h = proportion * (hsl_high.h - hsl_low.h) + hsl_low.h
		const s = proportion * (hsl_high.s - hsl_low.s) + hsl_low.s
		const l = proportion * (hsl_high.l - hsl_low.l) + hsl_low.l
		return { background: `hsl(${h},${s}%,${l}%)` }
	}

	buildCell(field, record) {
		// If it's a custom component, just render that
		// This doesn't show highlights on search result matches though
		if (field.display) {
			return [_.assign(field.display(record), { key: 'cmpt' })]
		}

		// Generate the text
		// If no `text` function is defined, just show the raw field name cast as a string
		let text = null
		text = field.text != null ? field.text(record) : record[field.key ?? field.lbl]
		text = String(text)

		// If there's no search, just exit here with the text
		if (!this.props.searchText) {
			return [
				{
					tag: 'span',
					key: 'text',
					text,
				},
			]
		}

		// Get the start index of the search match
		// If there's no match, exit here with the text (same as above)
		const startIndex = text.toLowerCase().indexOf(this.props.searchText.toLowerCase())
		if (startIndex === -1) {
			return [
				{
					tag: 'span',
					key: 'text',
					text,
				},
			]
		}

		// Split the string into pre-match, match, and post-match
		const endIndex = startIndex + this.props.searchText.length
		const parts = [
			text.substring(0, startIndex),
			text.substring(startIndex, endIndex),
			text.substring(endIndex, text.length),
		]
		return [
			{
				tag: 'span',
				key: 'text-pre',
				text: parts[0],
			},
			{
				tag: 'span',
				key: 'text-mid',
				cl: 'highlight',
				text: parts[1],
			},
			{
				tag: 'span',
				key: 'text-post',
				text: parts[2],
			},
		]
	}

	// Context menu #

	headingCellContextMenu(ev, field) {
		setContextMenu({
			position: {
				x: ev.clientX,
				y: ev.clientY,
			},
			items: _.compact([
				// Reset to the default options
				{
					label: 'Reset view',
					icon: '/static/img/i8/material-outline-clear-filters.svg',
					onClick: () => {
						this.updateState({
							grouping: null,
							groupingField: null,
							groupingFieldRev: null,
							hiddenFields: [],
							columnFilters: {},
							sortKey: this.props.defaultSortingKey,
							sortRev: this.props.defaultSortingRev,
							colorBy: null,
						})
					},
				},

				// Copy the unfiltered table to the clipboard
				{
					label: 'Copy as text',
					icon: '/static/img/i8/material-outline-copy.svg',
					onClick: () => {
						const lines = _.map(this.getFullTable(), x => x.join('\t'))
						navigator.clipboard.writeText(lines.join('\n'))
					},
				},

				// Sorting options
				this.props.sortable ? '---' : undefined,
				{
					label: 'Sort ascending',
					shortcut: 'LMB',
					shortcutDesc: 'Left mouse button',
					icon: '/static/img/i8/material-outline-ascending-sorting.svg',
					hidden: !this.props.sortable,
					onClick: () => {
						this.updateState({
							sortKey: field.key ?? field.lbl,
							sortRev: false,
						})
					},
				},
				{
					label: 'Sort descending',
					icon: '/static/img/i8/material-outline-descending-sorting.svg',
					hidden: !this.props.sortable,
					onClick: () => {
						this.updateState({
							sortKey: field.key ?? field.lbl,
							sortRev: true,
						})
					},
				},
				this.buildContextMenuSortBy(),

				// Grouping options
				'---',
				{
					label: 'Group ascending',
					shortcut: 'MMB',
					shortcutDesc: 'Middle mouse button',
					icon: '/static/img/i8/material-outline-ascending-sorting.svg',
					onClick: () => {
						this.updateState(
							this.getGroupingDelta({
								groupingField: field.key ?? field.lbl,
								groupingFieldRev: false,
							}),
						)
					},
				},
				{
					label: 'Group descending',
					icon: '/static/img/i8/material-outline-descending-sorting.svg',
					onClick: () => {
						this.updateState(
							this.getGroupingDelta({
								groupingField: field.key ?? field.lbl,
								groupingFieldRev: true,
							}),
						)
					},
				},
				this.buildContextMenuGroupBy(),

				// Filtering by value
				'---',
				this.buildContextMenuFilterByValue(field),

				// Column toggling
				{
					label: 'Hide column',
					icon: '/static/img/i8/material-outline-closed-eye.svg',
					onClick: () => {
						this.updateState(s => ({
							hiddenFields: s.hiddenFields.concat(field.key ?? field.lbl),
						}))
					},
				},
				{
					label: 'Toggle columns...',
					icon: '/static/img/i8/material-outline-delete-column.svg',
					items: _.flatten([
						{
							label: 'Show All',
							dontClose: true,
							icon: () => {
								if (this.state.hiddenFields.length === 0) {
									return '/static/img/i8/material-outline-eye.svg'
								}
								return ''
							},
							onClick: () => {
								this.updateState(s => ({
									hiddenFields: Do(() => {
										if (s.hiddenFields.length === 0) {
											return this.props.fields.map(
												F => F.key ?? F.lbl,
											)
										}
										return []
									}),
								}))
							},
						},
						'---',
						fsmData(this.props.fields, {
							map: F => ({
								label: F.lblLong ?? F.lbl,
								labelDesc: F.lblDesc ?? F.lbl,
								icon: () => {
									if (
										this.state.hiddenFields.includes(F.key ?? F.lbl)
									) {
										return null
									}
									return '/static/img/i8/material-outline-eye.svg'
								},
								dontClose: true,
								onClick: () => {
									this.updateState(s => ({
										hiddenFields: Do(() => {
											if (s.hiddenFields.includes(F.key ?? F.lbl)) {
												return s.hiddenFields.filter(
													x => (F.key ?? F.lbl) !== x,
												)
											}
											return s.hiddenFields.concat(F.key ?? F.lbl)
										}),
									}))
								},
							}),
						}),
					] as J2rObject[]),
				},

				// Colour by value
				'---',
				{
					label: 'Colour by value',
					icon: '/static/img/i8/material-outline-paint-palette.svg',
					onClick: () => {
						this.updateState(s => {
							const k = field.key ?? field.lbl
							if (s.colorBy === k) {
								return { colorBy: null }
							}
							return { colorBy: k }
						})
					},
				},
			] as ContextMenuItem[]),
		})
	}

	buildContextMenuSortBy() {
		return {
			label: 'Sort By...',
			icon: '/static/img/i8/material-outline-sort.svg',
			hidden: !this.props.sortable,
			items: fsmData(this.props.fields, {
				filter: F =>
					(F.sortVal != null || F.text != null) && (F.sortable ?? true),
				map: F => ({
					label: F.lblLong ?? F.lbl,
					labelDesc: F.lblDesc ?? F.lbl,
					dontClose: true,
					icon: () => {
						if (this.state.sortKey !== (F.key ?? F.lbl)) {
							return null
						} else if (this.state.sortRev) {
							return '/static/img/i8/material-outline-descending-sorting.svg'
						}
						return '/static/img/i8/material-outline-ascending-sorting.svg'
					},
					onClick: () => {
						this.updateState(s => ({
							sortKey: F.key ?? F.lbl,
							sortRev: Do(() => {
								if (s.sortKey === (F.key ?? F.lbl)) {
									return !s.sortRev
								}
								return F.sortReverseDefault ?? false
							}),
						}))
					},
				}),
			}),
		}
	}

	buildContextMenuGroupBy() {
		return {
			label: 'Group by...',
			icon: '/static/img/i8/material-outline-group-objects.svg',
			items: _.flatten([
				// Group by none
				{
					label: 'None',
					dontClose: true,
					icon: () => {
						if (this.state.groupingField != null) {
							return null
						}
						if (this.props.grouping == null && this.state.grouping != null) {
							return null
						}
						if (this.props.grouping != null && this.state.grouping == null) {
							return null
						}
						return '/static/img/i8/material-outline-checkmark.svg'
					},
					onClick: () => {
						if (this.props.grouping == null) {
							this.updateState(
								this.getGroupingDelta({
									groupingField: null,
									groupingFieldRev: null,
									grouping: null,
								}),
							)
						} else {
							// Override default grouping with no grouping
							this.updateState(
								this.getGroupingDelta({
									groupingField: null,
									groupingFieldRev: null,
									grouping: {
										key: () => 1,
										name: () => null,
										sort: () => 1,
										gaps: false,
									},
								}),
							)
						}
					},
				},

				// Group by default - only if there is a default grouping
				{
					label: 'Default',
					hidden: this.props.grouping == null,
					icon: () => {
						if (this.state.grouping == null) {
							return '/static/img/i8/material-outline-checkmark.svg'
						}
						return ''
					},
					dontClose: true,
					onClick: () => {
						this.updateState(
							this.getGroupingDelta({
								groupingField: null,
								groupingFieldRev: null,
								grouping: null,
							}),
						)
					},
				},

				// Group by each field
				'---',
				fsmData(this.props.fields, {
					filter: F => (F.groupVal ?? F.text) != null,
					map: F => ({
						label: F.lblLong ?? F.lbl,
						labelDesc: F.lblDesc ?? F.lbl,
						icon: () => {
							if (this.state.groupingField !== (F.key ?? F.lbl)) {
								return null
							} else if (this.state.grouping.reverse) {
								return '/static/img/i8/material-outline-descending-sorting.svg'
							}
							return '/static/img/i8/material-outline-ascending-sorting.svg'
						},
						dontClose: true,
						onClick: () => {
							this.updateState(
								this.getGroupingDelta({
									groupingField: F.key ?? F.lbl,
									groupingFieldRev: Do(() => {
										if (
											this.state.groupingField !== (F.key ?? F.lbl)
										) {
											return false
										}
										return !this.state.groupingFieldRev
									}),
								}),
							)
						},
					}),
				}),
			] as J2rObject[]),
		}
	}

	buildContextMenuFilterByValue(field) {
		return {
			label: 'Filter column',
			icon: '/static/img/i8/material-outline-filter.svg',
			hidden: field.text == null,
			items: () => {
				// Add the extra custom values defined on the field
				// This is handy if you know the values can come from a set and allow tthe user
				// to pre-filter them even though they're not in the current list
				// For example - filtering a task list by task state to ignore "Awaiting Resource"
				// even if that task state doesn't appear in the current list - for the future
				const values_fixed: any[] = field.filterValues?.() ?? []

				// Get all unique values for this column, sorted
				let values_found = _.uniq(
					fsmData(this.props.data, {
						sort: x => (field.sortVal ?? field.text)?.(x) ?? null,
						map: x => field.text?.(x) ?? null,
					}),
				)
				values_found = _.filter(values_found, x => !values_fixed.includes(x))

				// Join the two types of values together
				const values = _.uniq(_.concat(values_found, values_fixed))

				// Get the list of existing filters
				let existing = this.state.columnFilters[field.key ?? field.lbl] ?? null

				// Build the context menu items
				const tick = '/static/img/i8/material-outline-checkmark.svg'
				return _.flatten([
					// Select all/none
					{
						label: 'Select All',
						icon: existing == null ? tick : undefined,
						dontClose: true,
						onClick: () => {
							this.updateState(s => {
								const c = _.clone(s.columnFilters)
								const k = field.key ?? field.lbl
								c[k] = c[k] != null ? null : []
								return { columnFilters: c }
							})
						},
					},
					'---',
					// All possible values
					_.map(values, v => ({
						label: String(v),
						icon: existing == null || existing.includes(v) ? tick : undefined,
						dontClose: true,
						onClick: () => {
							this.updateState(s => {
								const c = _.clone(s.columnFilters)
								existing = c[field.key ?? field.lbl]
								if (existing == null) {
									existing = values.filter(x => x !== v)
								} else if (existing.includes(v)) {
									existing = existing.filter(x => x !== v)
								} else {
									existing = existing.concat(v)
								}
								if (_.difference(values, existing).length === 0) {
									existing = null
								}
								c[field.key ?? field.lbl] = existing
								return { columnFilters: c }
							})
						},
					})),
				])
			},
		}
	}

	// Helpers #

	getGroupedRecords() {
		// Get the grouping data - fallback to props if no state given
		const grouping = this.state.grouping ?? this.props.grouping ?? {}

		// Filter the records
		const records = this.getFilteredRecords()

		// Run each record through the grouping function to divide into groups
		const groups = _.groupBy(records, x =>
			JSON.stringify((grouping.key ?? _.noop)(x) ?? null),
		)

		// Helper function to get a group name from its ID
		const getGroupObj = k => {
			const items = groups[JSON.stringify(k)]
			return {
				ID: k,
				Name: grouping.name?.(k, items) ?? null,
				Records: items,
			}
		}

		// Get the group keys and sort them
		let group_keys = _.keys(groups).map(x => JSON.parse(x))
		group_keys = _.sortBy(group_keys, gk => {
			const fn = grouping.sort ?? (x => x.Name)
			return fn(getGroupObj(gk))
		})

		// Reverse if desired
		if (grouping.reverse) {
			_.reverse(group_keys)
		}

		// Return the array of groups
		return _.map(group_keys, x => getGroupObj(x))
	}

	getFilteredRecords(searchText?) {
		if (searchText == null) {
			searchText = this.props.searchText
		}

		// Filter based on search text, if applicable
		const filterSearchText = Do(() => {
			// No search text? Not filtering by search
			if (!searchText) {
				return () => true
			}

			// Get all searchable text generation functions - to lowercase
			const textFns = fsmData(this.props.fields, {
				filter: F => F.text != null && (F.searchable ?? true),
				map: F => x => String(F.text(x) ?? '').toLowerCase(),
			})

			// Build a search filter function
			const search_text = searchText.toLowerCase()
			return record => {
				let found = false
				_.forEach(textFns, fn => {
					if (fn(record).indexOf(search_text) !== -1) {
						found = true
						return false
					}
					return true
				})
				return found
			}
		})

		// Filter based on values
		const filterColumnValues = Do(() => {
			// Get all columns with value filters
			const cols = fsmData(this.props.fields, {
				filter: F => {
					const values = this.state.columnFilters[F.key ?? F.lbl]
					return values != null && F.text != null
				},
				map: F => ({
					getValue: record => F.text?.(record) ?? null,
					values: this.state.columnFilters[F.key ?? F.lbl],
				}),
			})

			// If no values are filtered, make a faster filter function
			if (cols.length === 0) {
				return () => true
			}

			// Build value filter function
			return record => {
				let failed = false
				_.forEach(cols, x => {
					const needle = x.getValue(record)
					if (!x.values.includes(needle)) {
						failed = true
						return false
					}
					return true
				})
				return !failed
			}
		})

		// Filter the raw data record properties to get the filtered records
		return _.filter(this.props.data, record => {
			// Check if it fails the search filter
			if (!filterSearchText(record)) {
				return false
			}

			// Check if it fails the field value filters
			if (!filterColumnValues(record)) {
				return false
			}

			// All tests passed
			return true
		})
	}

	getFullTable() {
		// Get the heading
		let heading_row = _.compact(this.buildHeadingRow()?.children ?? []).map(
			(x: any) => x.text,
		)
		if (heading_row == null) {
			heading_row = []
		}

		// Get the content values
		let rows = this.buildItems().map((x: any) => {
			const rowFn = x?.getItem != null ? x.getItem : x
			const row = _.isFunction(rowFn) ? rowFn() : rowFn
			return (row?.children ?? []).map(x => x?.children?.[0]?.text ?? null)
		})
		if (rows == null) {
			rows = []
		}

		// Return them joined together
		return _.concat([heading_row], rows)
	}

	getElement() {
		return this.listCmpt.current?.listElement.current
	}

	getGroupingDelta({ groupingField, groupingFieldRev, grouping }: any) {
		return {
			groupingField,
			groupingFieldRev,
			grouping:
				grouping ??
				Do(() => {
					// Get the field for the `groupingField`
					const F = fsmData(this.props.fields, {
						filter: F => groupingField === (F.key ?? F.lbl),
						takeFirst: true,
					})

					// No grouping field? Nothing to group
					if (F == null) {
						return null
					}

					// Get the grouping key function
					// Nothing found? Trying to group by an ungroupable field
					const keyFn = F.groupVal ?? F.sortVal ?? F.text
					if (keyFn == null) {
						return {
							key: () => 1,
							name: () => null,
							sort: () => 1,
							gaps: false,
						}
					}

					// Build the four-grouping function array
					return {
						key: keyFn,
						name: F.groupName ?? ((_k, x) => F.text(x[0])),
						sort: F.groupSort ?? (x => (F.sortVal ?? F.text)(x.Records[0])),
						reverse: groupingFieldRev,
					}
				}),
		}
	}

	cacheColorScale() {
		// Get the scale range for the colours for colouring by

		// If there's no colouring option selected, exit early setting it to null
		if (this.state.colorBy == null) {
			this.colorScale = [null, null]
			return
		}

		// Get the field function
		const fn = fsmData(this.props.fields, {
			filter: F => this.state.colorBy === (F.key ?? F.lbl),
			map: F => F?.colorVal ?? F?.sortVal ?? F?.text ?? _.noop,
			takeFirst: true,
		})

		// Get the values for each record
		const vals = _.map(this.props.data, x => +fn(x) || null)

		// Cache the min and max values
		this.colorScale = [_.min(vals), _.max(vals)]
	}
}
