import { React } from '../../../lib'
import { ReportFrameComponent } from './frame'

export { loadParametersGeneric } from './frame'
export { definedComponentsGeneric } from './options'
export type {
	Report,
	ReportComponent,
	ReportFrameInstance,
	ReportFrameSerialisedState,
	ReportGroup,
	ReportOptions,
} from './types'
export const ReportFrame = React.forwardRef(ReportFrameComponent)
