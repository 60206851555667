import { _ } from '../../lib'

/** Wraps a value in an array as required */
export const getInternalValue = <T extends any>(propVal: T | T[]): T[] => {
	if (_.isArray(propVal)) {
		return propVal
	} else if (propVal == null) {
		return []
	}
	return [propVal]
}

/** Utility function. Calculates selection based on click/shift/existing */
export const getValuesAfterClick = <T extends string | number>(
	values: T[],
	selected: T[],
	clickedItem: T,
	modifier: 1 | 2 | 3,
): T[] => {
	// No modifier - just overwrite selection
	if (modifier == 1) {
		return [clickedItem]
	}

	// Ctrl clicking
	if (modifier == 2) {
		let value = null
		value = selected.includes(clickedItem)
			? selected.filter(x => x != clickedItem)
			: selected.concat(clickedItem)
		return value
	}

	// Shift clicking needs to get everything between
	let latest_index = -1
	if (selected.length >= 1) {
		latest_index = values.indexOf(_.last(selected) as T)
	}
	const new_index = values.indexOf(clickedItem)
	const iterator = new_index > latest_index ? 1 : -1
	const new_indices = _.range(latest_index, new_index + iterator, iterator)
	const value = _.uniq(
		_.concat(
			selected,
			new_indices.map(x => values[x] as T),
		),
	)
	return value
}

/**
 * Stores a block of deltas that can be batched together
 * @param delta The number of rows to move
 * @param shift Whether the shift key was held (different group)
 */
export type PendingDelta = {
	delta: number
	shift: boolean
}

/**
 * Resolves sequential move and select keyboard event actions
 * Batches and runs them together all at once, then clears the queue
 */
export const resolvePendingDeltas = <T extends any>(
	pendingDeltas: PendingDelta[],
	value: T[],
	values: T[],
	isMulti: boolean,
) => {
	// Exit early if there is nothing to resolve
	if (pendingDeltas.length == 0) {
		return undefined
	}

	// Group sequential deltas with the same shift status
	const deltasGrouped: PendingDelta[] = []
	_.forEach(pendingDeltas, delta => {
		const last = deltasGrouped.slice(-1)[0]
		if (last?.shift == delta.shift) {
			last.delta += delta.delta
		} else {
			deltasGrouped.push(delta)
		}
	})

	// Run the correct function for each group based on shift status
	let selected_items = value
	_.forEach(deltasGrouped, delta => {
		selected_items = delta.shift
			? selectDelta(selected_items, delta.delta, isMulti, values)
			: moveDelta(selected_items, delta.delta, values)
	})

	// Return the new selected items
	return selected_items
}

/** Move the selection in a direction but the shift key */
const selectDelta = <T extends any>(
	selected_items: T[],
	delta: number,
	multiple: boolean,
	values: T[],
): T[] => {
	// Exit immediately if not multiple
	if (!multiple) {
		return selected_items
	}

	// Get the selectable values
	const selectedSet = new Set(selected_items)

	// Get the indices to select
	let latest_index = -1
	if (selected_items.length >= 1) {
		latest_index = values.indexOf(_.last(selected_items) as T)
	}
	const new_index = latest_index + delta
	const iterator = delta / Math.abs(delta) // 1 or -1
	const new_indices = _.range(latest_index, new_index + iterator, iterator).filter(
		i => i >= 0 && i < values.length,
	)

	// If nothing behind this move is selected, we just pop some off the end
	// This only applies if more than one is already selected
	if (selectedSet.size > 1) {
		let index = latest_index - iterator
		let has_any_prior = false
		while (index >= 0 && index <= values.length) {
			if (selectedSet.has(values[index] as T)) {
				has_any_prior = true
				break
			}
			index -= iterator
		}
		if (!has_any_prior) {
			index = new_index - iterator
			while (index >= 0 && index <= values.length) {
				selectedSet.delete(values[index] as T)
				index -= iterator
			}
			return Array.from(selectedSet)
		}
	}

	// Everything in the new indices is added to the selection in order
	new_indices.map(x => values[x] as T).forEach(x => selectedSet.add(x))

	// Everything after this is forcibly removed
	let index = new_index + iterator
	while (index >= 0 && index <= values.length) {
		selectedSet.delete(values[index] as T)
		index += iterator
	}

	// Update the value
	return Array.from(selectedSet)
}

/** Move the selection in a direction - generally based on keyboard input */
const moveDelta = <T extends any>(
	selected_items: T[],
	delta: number,
	values: T[],
): T[] => {
	// Get the new index
	let latest_index = -1
	if (selected_items.length >= 1) {
		latest_index = values.indexOf(_.last(selected_items) as T)
	}
	let new_index = latest_index + delta

	// Validate the index
	if (new_index < 0) {
		new_index = 0
	} else if (new_index >= values.length) {
		new_index = values.length - 1
	}

	// Update the value
	return [values[new_index] as T]
}
