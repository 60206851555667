import { BuildClass, Do, Maybe, clamp, fsmData, timer } from '../../universal'
import { React, _ } from '../lib'
import { getScrollbarWidth } from './component-main'
import { J2rObject, j2r } from './component-react'
import { Bindings } from './ui5'

declare let Sortable: any

// Types for list component items

/** @deprecated Use List component in ui5 */
export type J2rLCIObject = {
	children?: any[]
	cl?: string
	tag?: any
	text?: string
	title?: string
	value: any
	selectable?: boolean // Cannot be selected
	onClick?: Function
	onContextMenu?: Function
	[others: string]: any
}
type J2rLCIObjectFn = J2rLCIObject | (() => J2rLCIObject)
type J2rLCIWrapped = {
	lazyRenderHeight: number // Row override height
	getItem: () => J2rLCIObject
}
type J2rListComponentItem = J2rLCIObjectFn | J2rLCIWrapped

type J2rListComponentProps = {
	cl?: string
	onKeyDown?: Function
	headingRow?: {
		key?: string
		children?: J2rObject[]
		cl?: string
		tag?: any
		text?: string
		title?: string
		style?: { [key: string]: string }
	}
	items?: J2rListComponentItem[]
	sortable?: object
	lazyRenderHeight?: number | Function
	lazyThrottleMs?: number
	lazyRenderViewportHeightOverride?: number
	multiple?: boolean
	multipleDefault?: boolean
	onUpdate?: Function
	value?: any
	readOnly?: boolean
	disableFocusControl?: boolean
	style?: object
}

/** @deprecated Use List component in ui5 */
export class J2rListComponent extends React.Component<J2rListComponentProps, any> {
	listElement: React.RefObject<HTMLDivElement>
	hiddenInput: React.RefObject<HTMLInputElement>
	moveSelectionPending: number
	cachedRows: any
	cachedRowValues: any
	init: boolean
	lastRowCount: number
	sortableObj: any

	constructor(props: J2rListComponentProps) {
		super(props)
		Bindings(this, [
			this.contextMenuHandler,
			this.evBlur,
			this.evFocus,
			this.evKeydown,
			this.Focus,
			this.moveSelectionInner,
		])
		this.listElement = React.createRef()
		this.hiddenInput = React.createRef()
		this.moveSelectionPending = 0
		this.cachedRows = null
		this.cachedRowValues = null
		this.init = false
		this.lastRowCount = -1 // Track when row count changes for lazy render warnings
		this.state = {
			value: props.value,
			scrollPosition: 0,
			focused: false,
		}
	}

	override componentDidUpdate(prevProps: J2rListComponentProps) {
		// Update state if the prop value has changed
		if (prevProps.value !== this.props.value) {
			this.setState({ value: this.props.value })
		}

		// Clear the cache if the items are updated
		if (!_.isEqual(prevProps.items, this.props.items)) {
			this.cachedRowValues = null
			this.cachedRows = null
			this.setState({})
		}
	}

	override componentDidMount() {
		this.init = true

		// Create a throttled function to update the scroll position state
		const fn = (v: Maybe<number>) => {
			if (v != null) {
				this.setState({ scrollPosition: v })
			}
		}
		const timeout = this.props.lazyThrottleMs ?? 50
		const dbfn = _.throttle(fn, timeout, {
			leading: true,
			trailing: true,
		})

		// Create the event to update the scroll position
		this.listElement.current?.addEventListener('scroll', () => {
			dbfn(this.listElement.current?.scrollTop)
		})

		// Mount the sortable
		if (this.props.sortable) {
			const params_default = { direction: 'vertical' }
			const params = _.assign({}, params_default, this.props.sortable)
			this.sortableObj = Sortable.create(this.listElement.current, params)
		}

		// Now that it's mounted, if there is a pre-selected value (props), scroll to it
		// No highlight when scrolling to it
		if (this.state.value != null) {
			this.scrollToItem(null, 0)
		}
	}

	override componentWillUnmount() {
		this.init = false
		this.sortableObj?.destroy()
	}

	onUpdate() {
		;(this.props.onUpdate ?? _.noop)(this.state.value)
	}

	override render() {
		return j2r({
			cl: BuildClass({
				j2rlist: true,
				[this.props.cl ?? '']: true,
			}),
			style: this.props.style,
			children: _.compact([
				this.buildHeadingRow(),
				this.buildContentRows(),
				!this.props.disableFocusControl ? this.buildHiddenInput() : undefined,
			]),
			onClick: this.Focus,
		})
	}

	buildHeadingRow() {
		const item = this.props.headingRow
		if (!item) {
			return null
		}
		return _.assign({}, item, {
			key: item.key ?? 'heading',
			cl: BuildClass({
				[item.cl ?? '']: true,
				item: true,
			}),
			style: _.assign({}, item.style ?? {}, {
				paddingRight: `${getScrollbarWidth()}px`,
			}),
			title: item.title ?? item.text,
		})
	}

	buildContentRows() {
		return {
			cl: 'rows',
			key: 'row-list',
			ref: this.listElement,
			children: Do(() => {
				const rows = this.calculateRenderedRows(this.getRows())
				return rows.map(x => x())
			}),
		}
	}

	buildHiddenInput() {
		return {
			cl: 'hidden-input',
			key: 'hidden-input',
			children: [
				{
					tag: 'input',
					readOnly: true,
					key: 'hidden-input-inner',
					ref: this.hiddenInput,
					onFocus: this.evFocus,
					onBlur: this.evBlur,
					onKeyDown: this.evKeydown,
				},
			],
		}
	}

	evFocus() {
		this.setState({ focused: true })
	}

	evBlur() {
		this.setState({ focused: false })
	}

	evKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
		// Default event
		const defEv = (e: React.KeyboardEvent<HTMLInputElement>) => {
			switch (e.keyCode) {
				case 33:
					this.moveSelection(-12, e.shiftKey)
					break
				case 34:
					this.moveSelection(+12, e.shiftKey)
					break
				case 38:
					this.moveSelection(-1, e.shiftKey)
					break
				case 40:
					this.moveSelection(+1, e.shiftKey)
					break
				default:
					return
			}
			e.preventDefault()
			e.stopPropagation()
		}

		// Allow an override keydown event
		if (this.props.onKeyDown != null) {
			this.props.onKeyDown(e, defEv)
		} else {
			defEv(e)
		}
	}

	Focus() {
		if (!this.props.disableFocusControl) {
			this.hiddenInput.current?.focus()
		}
	}

	getRows() {
		// Return from cache if available
		if (this.cachedRows != null) {
			return this.cachedRows
		}

		// Get the function that when executed will return the row element
		const getItem = (itemRaw: J2rLCIObjectFn) => () => {
			// If the rows are functions, execute them to get the row. This
			// delayed execution being saved until after the lazy render filter
			// is applied improves performance on large lists significantly
			const item = _.isFunction(itemRaw) ? itemRaw() : itemRaw

			// Merge the supplied item into the other properties to create the
			// full row element that will be shown on screen
			return _.assign({}, _.omit(item, ['selectable', 'value']), {
				key: item.value,
				'data-key': item.value,
				cl: BuildClass({
					[item.cl ?? '']: true,
					item: true,
					selected:
						(!(this.props.multiple ?? false) &&
							item.value === this.state.value) ||
						((this.props.multiple ?? false) &&
							_.includes(this.state.value, item.value)),
				}),
				title: item.title ?? item.text,
				onContextMenu: this.contextMenuHandler(item),
				onClick: Do(() => {
					// Build the default click event
					let default_event = (e: React.MouseEvent) => {
						// Update the selection state and report to higher components
						const [ctrlKey, shiftKey] = [e.ctrlKey, e.shiftKey]
						const deltaFn = s =>
							this.clickHandler(s, item.value, ctrlKey, shiftKey)
						this.setState(deltaFn, () => {
							this.onUpdate()
						})
					}

					// Default event is overridden to nothing if it's not selectable
					if (!(item.selectable ?? true)) {
						default_event = _.noop
					}

					// If a custom on-click event is given, pass the default through
					// This allows click-overrides to use the default as well
					if (item.onClick != null) {
						return e => item.onClick(e, default_event)
					}
					return default_event
				}),
			})
		}

		// Returns the array of functions that when executed generate the full row
		// Added to the top level is the lazy rendering height that is used
		// We can inspect the lazily-rendered height without needing to unpack the rest
		const rows = this.props.items.map(x => {
			if ('getItem' in x) {
				return {
					rowFn: getItem(x.getItem),
					lazyRenderHeight: x.lazyRenderHeight,
				}
			}
			return { rowFn: getItem(x) }
		})

		// Cache and return
		this.cachedRows = rows
		return rows
	}

	contextMenuHandler(item) {
		// If there's no context menu handler, ignore
		if (item.onContextMenu == null) {
			return undefined
		}

		// Return the function handler to be run when the context menu click takes place
		return e => {
			e.persist()
			// If this item isn't one of the selected ones, change the selection
			if (this.props.multiple && !this.state.value.includes(item.value)) {
				this.setState({ value: [item.value] }, () => {
					item.onContextMenu(this.state.value, e)
					this.onUpdate()
				})
			} else if (!this.props.multiple && item.value !== this.state.value) {
				this.setState({ value: item.value }, () => {
					item.onContextMenu(this.state.value, e)
					this.onUpdate()
				})
			} else {
				item.onContextMenu(this.state.value, e)
			}
		}
	}

	clickHandler(state, clickedValue, ctrlKey, shiftKey) {
		// Returns the state change delta for a click event - handles multi-selects

		// Focus the hidden input
		timer(() => {
			this.Focus()
		})

		// If read-only - do nothing
		if (this.props.readOnly) {
			return {}
		}

		// Return the set state delta
		return {
			value: Do(() => {
				// Get the current value array, with fallback
				const curr = state.value ?? (state.multiple ? [] : null)

				// Handle the simple non-multiple case first - just change the selection ID
				if (!this.props.multiple) {
					return clickedValue
				}

				// If it's not a multi-select, we either deselect, or switch
				// Deselecting only happens if it's the only item selected
				const multi_select = this.props.multipleDefault || ctrlKey || shiftKey
				if (!multi_select) {
					if (_.includes(curr, clickedValue) && curr.length === 1) {
						return []
					}
					return [clickedValue]
				}

				// If it's a ctrl-click (or default multi), concat the item or filter it out
				// Shift-clicking is treated like a ctrl-click if nothing is selected
				if (!shiftKey || curr.length === 0) {
					if (curr.includes(clickedValue)) {
						return curr.filter(x => x !== clickedValue)
					}
					return curr.concat(clickedValue)
				}

				// If it's a shift-click, it's trickier
				// Look at every item between the last selected and the clicked one
				const items = this.getRowValues()
				const clickedIndex = items.indexOf(clickedValue)
				const lastIndex = items.indexOf(curr[curr.length - 1])
				const diff = clickedIndex > lastIndex ? 1 : -1
				const inBetween = _.range(lastIndex, clickedIndex + diff, diff).map(
					i => items[i],
				)

				// If all of them are currently selected, de-select them all
				// If any are unselected, select all of them
				const allSelected = _.every(_.map(inBetween, x => curr.includes(x)))
				if (allSelected) {
					return curr.filter(x => !inBetween.includes(x))
				}
				return _.uniq(curr.concat(inBetween))
			}),
		}
	}

	getRowValues() {
		// Return from cache if available
		if (this.cachedRowValues != null) {
			return this.cachedRowValues
		}

		// Get the selectable items
		const items = _.map(this.props.items, x => {
			if ('getItem' in x) {
				return x.getItem()
			} else if (_.isFunction(x)) {
				return x()
			}
			return x
		})

		// Get the values
		const values = fsmData(items, {
			filter: x => x.selectable ?? true,
			map: x => x.value,
		})

		// Cache and return
		this.cachedRowValues = values
		return values
	}

	calculateRenderedRows(rows) {
		// If no lazy rendering height is supplied, just pass the rows through
		let client_height, height, scroll_height
		if (this.props.lazyRenderHeight == null) {
			return rows.map(x => x.rowFn)
		}

		// Evaluate the function if the lazy render height is dynamic
		const LRH = Do(() => {
			if (_.isFunction(this.props.lazyRenderHeight)) {
				return this.props.lazyRenderHeight()
			}
			return this.props.lazyRenderHeight
		})

		// Magic number - how many rows outside of the viewport will be rendered for buffer
		const BUFFER_HEIGHT = 80
		const ARBITRARY_HEIGHT = window.innerHeight

		// Get the viewport of the list at the current position/height
		if (this.props.lazyRenderViewportHeightOverride != null) {
			height = this.props.lazyRenderViewportHeightOverride
		} else if (this.listElement.current != null) {
			const rects = this.listElement.current.getClientRects()
			height = rects[0]?.height ?? ARBITRARY_HEIGHT
			client_height = height
			scroll_height = this.listElement.current?.scrollHeight ?? 0
		} else {
			height = ARBITRARY_HEIGHT // Arbitrary, ensures rendering sensible amount to start
		}
		const scrollRange = [
			this.state.scrollPosition,
			this.state.scrollPosition + height,
		]

		// Workout height cut-offs relative to the current scroll pos
		const top_cutoff_h = clamp(scrollRange[0] - BUFFER_HEIGHT, 0, null)
		const bot_cutoff_h = clamp(scrollRange[1] + BUFFER_HEIGHT, 0, null)

		// Get the array of heights
		const heights = _.map(rows, x => x.lazyRenderHeight ?? LRH)

		// Get the items, and work out which ones are going to be rendered
		// Loop over each height, to determine cut-off indices
		let full_height = 0
		let top_cutoff_i = null // To calculate
		let bot_cutoff_i = null // To calculate
		_.map(heights, (height, i) => {
			// Calculate the new full height
			const new_full_height = full_height + height

			// If still looking for the top index and adding this row exceeds
			// the top cutoff, we've found the top cutoff index
			if (top_cutoff_i == null && new_full_height > top_cutoff_h) {
				top_cutoff_i = i
				// If the amount hidden up top is odd, we'll include an extra one
				// This helps avoid the even/odd highlights flipping around since we'll
				// always lose rendered rows in pairs
				if (top_cutoff_i % 2 === 1) {
					top_cutoff_i -= 1
				}
			}

			// If the full height has gone past the bottom height cut-off, we can stop
			if (bot_cutoff_i == null && full_height > bot_cutoff_h) {
				bot_cutoff_i = i
			}

			// Update the new full height and the last row height
			full_height = new_full_height
		})

		// If no bot cutoff found, it's the maximum
		if (bot_cutoff_i == null) {
			bot_cutoff_i = rows.length
		}

		// Check for issues
		if (full_height !== _.sum(heights)) {
			console.warn(
				`Mismatches heights: ${full_height}, ${_.sum(heights)}`,
				this.listElement.current,
			)
		}
		if (
			rows.length === this.lastRowCount &&
			scroll_height !== client_height &&
			scroll_height &&
			full_height !== scroll_height
		) {
			console.warn(
				`Mismatches in DOM heights: ${full_height} vs ${scroll_height}`,
				this.listElement.current,
			)
		}
		this.lastRowCount = rows.length

		// Get the heights of each of the three sections based on the index
		let top_height = 0 // To sum
		let bot_height = 0 // To sum
		_.map(heights, (h, i) => {
			if (i < top_cutoff_i) {
				top_height += h
			} else if (i >= bot_cutoff_i) {
				bot_height += h
			}
		})

		// Slice a subset of the items being shown
		// Create the array of rows being rendered
		rows = rows.slice(top_cutoff_i, bot_cutoff_i)
		rows = rows.map(x => x.rowFn)

		// Add the spacer rows on the top and bottom
		rows.unshift(() => ({
			key: 'spacer-above',
			cl: 'spacer',
			style: { height: `${top_height}px` },
		}))
		rows.push(() => ({
			key: 'spacer-below',
			cl: 'spacer',
			style: { height: `${bot_height}px` },
		}))

		// Return the rendered rows
		return rows
	}

	selectAll() {
		// Warn if not a multi-select list
		if (!this.props.multiple) {
			console.warn('Called `selectAll` on non-multi-select list - not allowed')
			return
		}

		// Get the array of all values in the list
		const values = this.getRowValues()

		// Update the state and execute callback
		this.setState({ value: values }, () => {
			this.onUpdate()
		})
	}

	updateScrollPosition(offset) {
		if (this.listElement.current != null) {
			this.listElement.current.scrollTop = offset
		}
		this.setState({ scrollPosition: offset })
	}

	scrollToItem(keys?, highlight_duration?, only_if_needed?) {
		// If the key is not defined, default to the current selected value
		// If the key is not an array, normalise it into one
		// With the key as an array, it'll scroll to the first item in the list
		if (highlight_duration == null) {
			highlight_duration = 800
		}
		if (only_if_needed == null) {
			only_if_needed = false
		}
		if (keys == null) {
			keys = this.state.value
		}
		if (keys == null) {
			return
		}
		if (keys.constructor !== Array) {
			keys = [keys]
		}

		// Get the index of the first item in the list
		let selected_index = null
		let key = null
		const rows = this.getRows()
		let row = null
		_.forEach(rows, (rowObj, index) => {
			row = rowObj.rowFn()
			if (_.includes(keys, row.key)) {
				selected_index = index
				key = row.key
				return false
			}
			return true
		})

		// Get the height position within the list of the selected index
		const heights = Do(() => {
			// No lazy rendering means we can just inspect the row in the DOM directly
			if (this.props.lazyRenderHeight == null) {
				const el = this.listElement.current.children[
					selected_index
				] as HTMLDivElement
				const elRect = el?.getBoundingClientRect() ?? {
					top: 0,
					height: 0,
				}
				const parentRect = el?.offsetParent.getBoundingClientRect() ?? {
					top: 0,
				}
				return {
					found: el != null,
					offsetHeight: elRect.top - parentRect.top,
					itemHeight: elRect.height,
				}
			}

			// There is lazy rendering set up, get its offset height from that
			// Evaluate the function if the lazy render height is dynamic
			const LRH = Do(() => {
				if (_.isFunction(this.props.lazyRenderHeight)) {
					return this.props.lazyRenderHeight()
				}
				return this.props.lazyRenderHeight
			})

			// Return the offset information
			return {
				found: selected_index != null,
				itemHeight: row?.lazyRenderHeight ?? LRH,
				// Loop over every row above the selected index and calculate its height
				// Get the total height of all items above the row we're highlighting
				offsetHeight: Do(() => {
					let offset = 0
					_.forEach(rows, (x, i) => {
						if (i >= selected_index) {
							return false
						}
						offset += x.lazyRenderHeight ?? LRH
						return true
					})
					return offset
				}),
			}
		})

		// If no offset height is found, do nothing
		if (!heights.found) {
			return
		}

		// Get the height of the list container so we can scroll to the middle
		const container = this.listElement.current
		const rect = container.getBoundingClientRect()
		const container_height = rect?.height ?? 600
		const offset_current_top = container.scrollTop
		const offset_current_bottom = offset_current_top + container_height

		// Get the offset position of the top and bottom - both of which should be visible
		const offset_top = heights.offsetHeight
		const offset_bottom = heights.offsetHeight + heights.itemHeight

		// Check if scrolling is needed to make this visible
		if (offset_top < offset_current_top || offset_bottom > offset_current_bottom) {
			// Only scrolling the smallest amount that's needed to bring it into view
			if (only_if_needed) {
				// Keep this amount on the opposite side so it's not hugging the top/bottom
				const padding_when_scroll_needed = 10

				// Scroll up
				if (offset_top < offset_current_top) {
					this.updateScrollPosition(offset_top - padding_when_scroll_needed)
					// Scroll down
				} else if (offset_bottom > offset_current_bottom) {
					this.updateScrollPosition(
						offset_bottom - container_height + padding_when_scroll_needed,
					)
				}

				// Otherwise, scroll so it's in the middle-ish
			} else {
				// Get the centre offset of the item (as opposed to the offset at top)
				// Offset it so that the selected item is in the top third of the container
				const offset_midpoint = Math.floor((offset_top + offset_bottom) / 2)
				let offset_from_top = Math.floor(offset_midpoint - container_height / 3)
				const max_offset = container?.scrollHeight - container?.clientHeight
				offset_from_top = clamp(offset_from_top, 0, max_offset)

				// Scroll to this offset height
				this.updateScrollPosition(offset_from_top)
			}
		}

		// Highlight the item if requested
		if (highlight_duration) {
			timer(() => {
				// Get the element and the current background/transition styles
				const list = this.listElement.current
				const el = list.querySelector(`[data-key='${key}']`) as HTMLDivElement
				const oldBG = el?.style.backgroundColor
				const oldTrans = el?.style.transition

				// Set the transition and on timeout set the background colour
				const anim = 200
				if (el != null) {
					el.style.transition = `background-color ${anim}ms`
					el.classList.add('highlighting')
				}
				timer(() => {
					if (el != null) {
						el.style.backgroundColor = '#ffb'
					}

					// After the animation duration, set the colour back and remove the transition
					timer(highlight_duration + anim, () => {
						if (el != null) {
							el.style.backgroundColor = oldBG
						}
						timer(anim, () => {
							if (el != null) {
								el.style.transition = oldTrans
								el.classList.remove('highlighting')
							}
						})
					})
				})
			})
		}
	}

	moveSelection(delta: number, _shift: boolean = null) {
		// TODO - use shift for something
		this.moveSelectionPending += delta
		requestAnimationFrame(this.moveSelectionInner)
	}

	moveDeltaGetValue(delta: number, currentValue?) {
		let curentValue
		if (currentValue == null) {
			currentValue = this.state.value
		}

		// TODO - use the shift key modifier for multi-selects
		if (this.props.multiple) {
			if (currentValue.length > 1) {
				console.warn(
					'Arrow key selection on multi-select lists not yet supported',
				)
				return currentValue
			}
			curentValue = currentValue[0] ?? null
		} else {
			curentValue = currentValue ?? null
		}

		// Index the items and get the item +d from the current selection
		// Ignore items with `selectable` explicitly set to false
		const items = this.getRowValues()

		// Get the current and new index
		const currentIndex = items.indexOf(curentValue)
		let newIndex = currentIndex + delta

		// Validate the index
		if (newIndex < 0) {
			newIndex = 0
		} else if (newIndex > items.length - 1) {
			newIndex = items.length - 1
		}

		// If the index hasn't changed, return null to indicate no change needed
		if (newIndex === currentIndex) {
			return null
		}

		// If the index has changed, get the new value and return it
		let v = items[newIndex]
		if (this.props.multiple) {
			v = [v]
		}
		return v
	}

	moveSelectionInner() {
		// Reset the pending delta
		const delta = this.moveSelectionPending
		this.moveSelectionPending = 0

		// If no delta (they cancel out) - exit early
		// Also exit early if the list component has unmounted
		if (delta === 0 || !this.init) {
			return
		}

		// Get the new value. Exit early if no change needed
		const value = this.moveDeltaGetValue(delta)
		if (value == null) {
			return
		}

		// Update the value and scroll to its position (as little as possible)
		this.scrollToItem(value, 0, true)
		this.setState({ value }, () => {
			this.onUpdate()
		})
	}
}
