import { BuildClass, Do, fsmData, timer } from '../../universal'
import { React, _ } from '../lib'
import { J2rButton } from './component-buttons'
import { J2rComboBox } from './component-combobox'
import { J2rDatetime } from './component-date-picker'
import { validateTime } from './component-main'
import {
	J2rHelp,
	J2rLoadingSpinner,
	J2rModalPortal,
	J2rObject,
	J2rText,
	j2r,
} from './component-react'
import { J2rListComponent } from './component-react-list'
import { moment } from './moment-wrapper'
import { Bindings, ConditionalObject } from './ui5'

export type J2rEditableGridSaveDelta<T> = {
	appended: Partial<J2rEditableGridRecord<T>>[]
	updated: { [key: string]: Partial<T> }
	deleted: any[]
	isChanged: boolean
}

export type J2rEditableGridField = {
	key: string // Key of the record
	lbl: string // Label to show in the heading row
	lblDesc?: string // Label tooltip to show in the heading row
	readonly?: boolean // Whether the field is uneditable
	def?: Function // Returns the default value of the field - used for new records
	tooltipBubble?: boolean // Whether a question mark tooltip hint is shown
	cl?: string
	readOnlyFunc?: Function
	onClick?: Function // Custom on-click function for the header
	cmpt?: {
		component?: any // Returns a React component that allows editing (j2r format)
		renderer?: Function // Turns value into rendered text (optional)
		sortVal?: Function // Turns value into sortable version of value (optional)
		fixer?: Function // Turns value into corrected value (optional)
		validator?: Function // Checks if (corrected) value is valid (optional)
		clickThrough?: boolean // Whether clicks are passed-through
		def?: Function
		onFocus?: Function
		onChange?: Function
	}
}

type J2rEditableGridProps<T> = {
	pk: (x: T) => number | string
	cl?: string
	onUpdate?: (update: {
		data: J2rEditableGridRecord<T>[]
		delta: J2rEditableGridSaveDelta<T>
	}) => void
	onBeforeUpdate?: Function
	onBeforeDelete?: Function
	onClick?: (update: { selectedRow: any; selectedCol: any; selectedValue: any }) => void
	onKeyPress?: (ev: React.KeyboardEvent) => void
	defaultSortingKey?: string
	defaultSortingReverse?: boolean
	onSave?: (
		delta: J2rEditableGridSaveDelta<T>,
		callback: (sucessOrError: string | true) => void,
	) => void
	recordValidation?: (
		record: J2rEditableGridRecord<T>,
		key: string,
	) => J2rEditableGridRecord<T> | void
	data: T[] // Array of data records
	fields: J2rEditableGridField[] // A fallback default value function (component-level)
	// Extra options
	lazyRenderHeight?: number
	appendable?: boolean
	deletable?: boolean
	sortable?: boolean
	selectable?: boolean
	editable?: boolean // TODO - change this to just mean `mutable`
	increment?: number // Used for forcing a re-render by providing a changed key
	buttons?: {
		// Used for adding extra buttons to the grid
		label: string
		title?: string
		onClick?: Function
	}[]
}
type J2rEditableGridState<T> = {
	isLoading: boolean
	isSuccess: boolean
	message: string
	error: string
	selectedRow: number
	selectedCol: string
	selectedValue: any
	sortingKey: string
	sortingFunction: Function
	sortingReverse: boolean
	data: J2rEditableGridRecord<T>[]
}
export type J2rEditableGridRecord<T extends Record<string, any>> = {
	__id: number
	__appended: boolean
	__deleted: boolean
} & T

// TODO: fix state type
export class J2rEditableGrid<T> extends React.Component<
	J2rEditableGridProps<T>,
	J2rEditableGridState<T>
> {
	static defaultProps = {
		cl: null,
		pk: x => x.ID,
		onUpdate: _.noop,
		onBeforeUpdate: null,
		onBeforeDelete: null,
		onClick: null,
		onKeyPress: null,
		appendable: false,
		deletable: false,
		sortable: true,
		selectable: false,
		editable: true,
	}

	static Components = {
		// Text
		Text: (obj: any = {}) => ({
			component: () => ({
				tag: J2rText,
				readOnly: obj.readOnly,
				maxLength: obj.maxLength,
				placeholder: obj.placeholder,
				fixer: obj.fixer,
				onFocus: obj.onFocus ?? _.noop,
				onChange: obj.onChange ?? _.noop,
			}),
			renderer: (v, r) => {
				if (obj.renderer) {
					const val = obj.renderer(v, r)
					if (typeof val == 'object') {
						return val
					}
					return String(val)
				}
				return String(v ?? '')
			},
			validator: v => {
				const length = v?.length || 0
				const [min, max] = [obj.minLength ?? 0, obj.maxLength ?? 1e99]
				if (!(min <= length && length <= max)) {
					return false
				}
				if (obj.numeric && v != null && isNaN(+v)) {
					return false
				}
				return true
			},
			fixer: v => {
				v = String(v ?? '')
				if (obj.trim) {
					v = v.trim()
				}
				v = (obj.fixer ?? _.identity)(v)
				if (isNaN(v)) {
					return v || null
				}
				return v ?? null
			},
			sortVal: obj.sortVal ?? (v => String(v ?? '').toLowerCase()),
		}),

		// Time textbox
		TextTime: (obj: any = {}) => ({
			component: () => ({
				tag: J2rText,
				maxLength: 5,
				placeholder: obj.placeholder ?? 'HH:MM',
				readOnly: obj.readOnly ? obj.readOnly : undefined,
				onFocus: obj.onFocus ?? _.noop,
			}),
			renderer: (v, r) => {
				if (obj.renderer) {
					return String(obj.renderer(v, r))
				}
				return String(v ?? '')
			},
			validator: v => {
				const length = v?.length || 0
				const [min, max] = [obj.minLength ?? 0, obj.maxLength ?? 1e99]
				return min <= length && length <= max
			},
			fixer: v => (obj.fixer ?? validateTime)(v),
			sortVal: v => v ?? '',
		}),

		// Checkbox
		Checkbox: (obj: any = {}) => ({
			component: () => ({
				tag: J2rGridCheckbox,
				isCross: !(obj.tick ?? true),
			}),
			renderer: v => ({
				cl: 'cntr',
				text: Do(() => {
					if (!v) {
						return ''
					} else if (obj.tick ?? true) {
						return '✔'
					}
					return '✘'
				}),
			}),
			fixer: v => Boolean(v),
			clickThrough: true,
		}),

		// Currency
		Currency: () => ({
			renderer: v => {
				if (typeof v === 'string') {
					return v
				}
				return v?.toFixed(2) ?? ''
			},
			fixer: v => {
				const vn = +v
				if (!v) {
					return null
				} else if (isNaN(vn)) {
					return String(v)
				}
				return vn
			},
			validator: v => typeof v !== 'string',
		}),

		// Dropdown
		Dropdown: (
			options: any[],
			obj: { onFocus?: Function; onChange?: Function; validator?: Function } = {},
			nullable: boolean = false,
		) => {
			// Get all option values
			const opts = _.flattenDeep([
				fsmData(options, {
					filter: x => x.options != null,
					map: x => x.options ?? [],
				}),
				fsmData(options, {
					filter: x => x.value != null,
					map: x => x ?? [],
				}),
			])
			if (nullable) {
				opts.unshift({ value: '', text: '' })
			}

			// Map option IDs to option strings to optimise sorting
			const optMap = _.fromPairs(_.map(opts, x => [x.value, x.text]))

			// Return the builder component
			return {
				component: () => ({
					tag: J2rComboBox,
					options,
					onFocus: obj.onFocus ?? _.noop,
					onChange: obj.onChange ?? _.noop,
					nullable: nullable ? '' : null,
				}),
				fixer: v => v || '',
				def: () => '',
				validator: v => {
					if (v == null && nullable) {
						v = ''
					}
					if (obj.validator) {
						return obj.validator(v, opts)
					}
					return _.size(opts.filter(x => x.value === v)) > 0
				},
				renderer: v => optMap[v] ?? '',
				sortVal: v => optMap[v] ?? '',
			}
		},

		// Dropdown with null option
		DropdownNullable: (
			options: any[],
			obj: {
				onFocus?: Function
				validator?: Function
				renderer?: Function
				onChange?: Function
			} = {},
		) => J2rEditableGrid.Components.Dropdown(options, obj, true),

		// Date
		Date: (obj: any = {}) => ({
			component: () => ({ tag: J2rDatetime }),
			renderer: v => {
				if (!v) {
					return ''
				} else if (obj.format) {
					return moment(v).format(obj.format)
				} else if (obj.long) {
					return moment(v).format('DD/MM/YYYY')
				}
				return moment(v).format('DD/MM/YY')
			},
		}),
		// Color
		Color: (obj: any = {}) => ({
			component: () => ({
				tag: J2rGridColor,
				readonly: obj.readonly,
			}),
			renderer: v => ({
				cl: 'j2r-color-picker',
				style: { backgroundColor: `#${v}` },
			}),
			validator: v => v == null || v?.length === 6,
			clickThrough: true,
		}),
	}

	mounted: boolean

	constructor(props) {
		super(props)
		this.mounted = false
		Bindings(this, [
			this.buildButtons,
			this.buildCell,
			this.buildList,
			this.changeSelectedValue,
			this.checkAppendable,
			this.clearSelection,
			this.commitCurrentWork,
			this.deleteRecord,
			this.getDelta,
			this.getResetData,
			this.getRowsOrdered,
			this.handleKeyPress,
			this.isChanged,
			this.isRecordEmpty,
			this.moveSelection,
			this.onSave,
			this.onUpdate,
			this.resetForm,
			this.selectCell,
			this.updateCellValue,
			this.updateSortOrder,
		])
		this.state = this.initState(props)
	}

	override componentDidMount() {
		this.mounted = true
	}

	override componentWillUnmount() {
		this.mounted = false
	}

	override render() {
		return j2r({
			cl: BuildClass({
				j2rgrid: true,
				[this.props.cl]: true,
			}),
			children: [this.buildList(), this.buildButtons(), this.buildResponse()],
		})
	}

	initState(props): J2rEditableGridState<T> {
		// Set the initial state
		let state = {
			isLoading: false,
			isSuccess: false,
			message: '',
			error: '',
			selectedRow: null,
			selectedCol: null,
			selectedValue: null,
			sortingKey: null,
			sortingFunction: _.identity,
			sortingReverse: null,
			data: null,
		}
		// Copy the input data array into the edited state array
		if (props.sortable && props.defaultSortingKey) {
			state = _.assign(state, this.updateSortOrder(state, props.defaultSortingKey))
			if (props.defaultSortingReverse != null) {
				state.sortingReverse = props.defaultSortingReverse
			}
		}
		state.data = this.getResetData(props)
		return state
	}

	getResetData(props: J2rEditableGridProps<T>) {
		// Add extra hidden properties
		const data = fsmData(props.data, {
			map: (record, index) => {
				const defaults = {
					__id: index,
					__deleted: false,
					__appended: false,
				}
				return _.assign({}, defaults, record)
			},
		})
		// Confirm that fields have values in each record
		_.forEach(data, (record, index) =>
			_.forEach(props.fields, field => {
				if (!_.has(record, field.key)) {
					console.error(`Record #${index} is missing field ${field.key}`)
				}
			}),
		)
		// If this is appendable, add an empty record at the bottom
		return this.checkAppendable({ data: data }, props)
	}

	override componentDidUpdate(prevProps) {
		// Get the JSON string version of the old and new properties
		// Do a custom JSON replacer that gets the string code of all functions
		// to ensure that changes of components are detected without triggering
		// when the components are regenerated from custom functions
		const to_json = x =>
			JSON.stringify(x, (_k, v) => {
				if (typeof v === 'function') {
					return v.toString()
				}
				return v
			})
		const o = to_json(prevProps)
		const n = to_json(this.props)

		// If they are not equal, reset the state
		if (!_.isEqual(o, n)) {
			this.setState(this.initState(this.props))
		}
	}

	checkAppendable(
		state: Partial<J2rEditableGridState<T>>,
		props: J2rEditableGridProps<T>,
	): J2rEditableGridRecord<T>[] {
		// Skip early if not appendable
		if (!props.appendable) {
			return state.data
		}

		// Check if there's already an empty record at the end
		const is_empty = this.isRecordEmpty(
			fsmData(state.data, {
				filter: x => !x.__deleted,
				sort: x => x.__id,
				reverse: true,
				takeFirst: true,
			}),
		)

		// If the last record is already empty, make no change to data
		if (is_empty) {
			return state.data
		}

		// An empty record needs to be added
		const new_record_partial = {} as T
		_.forEach(props.fields, field => {
			new_record_partial[field.key] = (field.def ?? field?.cmpt?.def ?? _.noop)()
		})
		const new_record: J2rEditableGridRecord<T> = {
			...new_record_partial,
			__id: (state.data[state.data.length - 1]?.__id ?? -1) + 1,
			__deleted: false,
			__appended: true,
		}

		// Add the new record and return the new array of data
		const data = state.data.concat(new_record)
		return data
	}

	isRecordEmpty(record: T, props: J2rEditableGridProps<T> = this.props) {
		// Null input means likely no records exist at all
		if (record == null) {
			return false
		}

		// Check if it's empty
		let is_empty = true
		_.forEach(props.fields, field => {
			const def_val = (field.def ?? field.cmpt?.def ?? _.noop)()
			if (record[field.key] !== def_val) {
				is_empty = false
				return false
			}
			return true
		})
		return is_empty
	}

	isRecordValid(record) {
		// Empty records are always fine
		if (this.isRecordEmpty(record)) {
			return true
		}

		// Check if any field's validator value returns false
		let invalid = false
		_.forEach(this.props.fields, field => {
			const is_valid = (field.cmpt?.validator ?? _.stubTrue)(record[field.key])
			if (!is_valid) {
				invalid = true
				return false
			}
			return true
		})

		// Return whether all were valid
		return !invalid
	}

	isRecordChanged(record) {
		// Empty records are unchanged
		if (this.isRecordEmpty(record)) {
			return false
		}

		// Compare the field keys only on the records
		const keys = this.props.fields.map(f => f.key)
		let old_record = this.props.data[record.__id] as Partial<T>
		old_record = _.pick(old_record, keys)
		const new_record = _.pick(record, keys)

		// Return whether they're unequal
		const result = !_.isEqual(old_record, new_record)
		return result
	}

	isRecordDisabled(record) {
		return record.disabled ?? false
	}

	buildList(): J2rObject {
		return {
			tag: J2rListComponent,
			key: 'list',
			value: null,
			disableFocusControl: true,
			lazyRenderHeight: this.props.lazyRenderHeight,

			// Build the heading row
			headingRow: {
				cl: 'gridRow noselect heading',
				children: _.flatten([
					// Edited indicator
					ConditionalObject(this.props.editable, {
						key: 'edited-indicator',
						cl: 'edited-indicator',
					}),

					// Header cells
					this.props.fields.map(field => ({
						tag: 'span',
						cl: BuildClass({
							[field.cl]: true,
							[field.key.toLowerCase().replace(/([^a-z0-9])/g, '')]: true,
							'heading-cell': true,
							'help-indicator': field.tooltipBubble,
						}),
						key: field.key,
						title: !field.tooltipBubble ? field.lblDesc : undefined,
						children: [
							{
								key: 'heading-cell-inner',
								title: field.tooltipBubble ? field.lblDesc : undefined,
								cl: BuildClass({
									'txt heading-cell-inner': true,
									sorted:
										field.key === this.state.sortingKey &&
										this.props.sortable,
									'sort-asc':
										field.key === this.state.sortingKey &&
										this.props.sortable &&
										!this.state.sortingReverse,
									'sort-dsc':
										field.key === this.state.sortingKey &&
										this.props.sortable &&
										this.state.sortingReverse,
								}),
								text: field.lbl,
							},
							ConditionalObject(field.tooltipBubble, {
								tag: J2rHelp,
								key: 'help-tooltip',
								title: field.lblDesc,
							}),
						],
						onClick: () => {
							this.setState(s => {
								if (field.onClick != null) {
									// Execute custom on-click function
									return field?.onClick(this.state) ?? {}
								} else if (this.props.sortable) {
									// Update the sorting key when clicking the headings
									return this.updateSortOrder(s, field.key)
								}
								return {}
							})
						},
					})),

					// Space for the delete column
					ConditionalObject(this.props.deletable, {
						key: 'delete-record',
						cl: 'delete-record heading',
					}),
				] as J2rObject[]),
			},

			// Build the item array
			// Build each row as an array of cells
			items: this.getRowsOrdered().map((record, indexY) => ({
				value: this.state.data.indexOf(record),
				cl: BuildClass({
					gridRow: true,
					empty: this.isRecordEmpty(record),
					changed: this.isRecordChanged(record),
					disabled: this.isRecordDisabled(record),
				}),
				children: _.flatten([
					// Edited indicator
					ConditionalObject(this.props.editable, {
						key: 'edited-indicator',
						cl: 'edited-indicator',
					}),

					// Content cells
					this.props.fields.map((field, indexX) =>
						this.buildCell(record, field, indexY, indexX),
					),

					// Delete cell
					ConditionalObject(this.props.deletable, {
						tag: 'span',
						key: 'delete-record',
						cl: 'delete-record',
						children: [
							{
								tag: 'img',
								key: 'delete-inner',
								// src: '/static/img/svg/cross.svg'
								src: '/static/img/cross.png',
							},
						],
						onClick: () => {
							let canDelete = true
							if (
								this.props.onBeforeDelete &&
								!this.props.onBeforeDelete(record)
							) {
								canDelete = false
							}
							if (canDelete) {
								this.deleteRecord(record.__id)
							}
						},
					}),
				] as J2rObject[]),
			})),
		}
	}

	buildButtons() {
		if (this.props.onSave != null) {
			return {
				key: 'buttons',
				cl: 'cntr buttons',
				children: _.flatten([
					// Save button
					{
						tag: J2rButton,
						type: 'submit',
						key: 'save',
						label: 'Save',
						title: 'Save',
						onClick: this.onSave,
					},
					// Reset form
					ConditionalObject(this.props.editable, {
						tag: J2rButton,
						type: 'standard',
						key: 'reset',
						label: 'Reset',
						title: 'Reset',
						onClick: this.resetForm,
					}),
					// Restore deleted rows
					ConditionalObject(
						_.size(_.filter(this.state.data, R => R?.__deleted)) > 0,
						{
							tag: J2rButton,
							type: 'standard',
							key: 'restore-deleted',
							label: 'Restore',
							title: 'Restores deleted records without resetting other changes',
							onClick: () => {
								this.setState(
									{
										message: 'Restored deleted records',
										data: this.state.data.map(R => {
											R = _.clone(R)
											R.__deleted = false
											return R
										}),
									},
									() =>
										timer(2000, () => {
											if (this.mounted) {
												this.setState({ message: '' })
											}
										}),
								)
							},
						},
					),
					// Add in any extra buttons defined by the user
					ConditionalObject(this.props.buttons != null, () =>
						_.map(this.props.buttons, button => ({
							tag: J2rButton,
							type: 'standard',
							key: button.label,
							label: button.label,
							title: button.title ?? button.label ?? '',
							onClick: () => {
								this.selectCell(null, null, () => {
									this.setState(s => button.onClick(s) ?? {})
								})
							},
						})),
					),
				]),
			}
		}
		return null
	}

	buildResponse() {
		if (this.props.editable) {
			return {
				key: 'response',
				cl: 'response-label',
				children: [
					this.state.isLoading
						? {
								tag: J2rLoadingSpinner,
								key: 'loading',
								size: 'small',
							}
						: this.state.error
							? String(this.state.error)
							: this.state.message
								? {
										key: 'non-error',
										cl: 'non-error',
										text: this.state.message,
									}
								: {
										cl: 'stub',
										key: 'nothing',
										text: '-',
									},
				],
			}
		}
		return null
	}

	deleteRecord(index) {
		const fn = s => {
			const data = _.clone(s.data)
			data[index] = _.assign({}, s.data[index], { __deleted: true })
			return { data }
		}
		this.setState(fn, () => {
			const delta = s => ({ data: this.checkAppendable(s, this.props) })
			this.setState(delta, this.onUpdate)
		})
	}

	getRowsOrdered() {
		let records = fsmData(this.state.data, {
			filter: R => R != null && !R.__deleted,
			reverse: this.state.sortingReverse,
			sort: R => {
				if (this.props.sortable) {
					return this.state.sortingFunction(R[this.state.sortingKey])
				}
				return 1
			},
		})
		records = _.sortBy(records, R => R.__appended)
		return _.sortBy(records, R => this.isRecordEmpty(R))
	}

	buildCell(record, field, indexY, indexX) {
		// Check whether this field is the selected one
		const isSelected =
			this.state.selectedRow === record.__id && this.state.selectedCol === field.key

		// Check whether the value has been changed since the original data input
		const isChanged = Do(() => {
			if (record.__appended) {
				return false
			}
			return !_.isEqual(
				record[field.key],
				this.props.data[record.__id]?.[field.key],
			)
		})

		// Check whether the value is valid
		const isValid = (field.cmpt?.validator ?? _.stubTrue)(record[field.key])

		// Only send through the selected value if it's relevant to this cell
		const selectedValue = isSelected ? this.state.selectedValue : null

		// Return the object
		return {
			tag: J2rEditableGridCell,
			// Reference info
			key: field.key,
			field,
			record,
			tabIndex: 1 + indexY * this.props.fields.length + indexX,
			// Props and values
			isEditable: this.props.editable,
			isSelected,
			isChanged,
			isValid,
			selectedValue,
			cl: field.cl,
			// Proxy methods
			selectCell: this.selectCell,
			commitCurrentWork: this.commitCurrentWork,
			changeValue: this.changeSelectedValue,
			handleKeyPress: this.handleKeyPress,
		}
	}

	changeSelectedValue(v, cb) {
		this.setState({ selectedValue: v }, () => {
			;(cb ?? _.noop)(v)
		})
	}

	updateCellValue(row_index, field_key, value, cb) {
		// Skip if there is no selection
		if (row_index == null || field_key == null) {
			;(cb ?? _.noop)()
			return
		}

		// Get the fixing function that will convert the value to its
		// more sanitised version
		const field = fsmData(this.props.fields, {
			filter: x => x.key === field_key,
			takeFirst: true,
		})
		const fixer_function = field.cmpt?.fixer ?? _.identity

		// If the field is readonly, don't update its value
		if (field.readonly) {
			;(cb ?? _.noop)()
			return
		}

		// Apply fixing function and save to the data model
		value = fixer_function(value)
		const update = s => {
			// Create the new record, with the updated cell
			let record = _.assign({}, s.data[row_index], { [field_key]: value })

			// Run the record validation function - if it detects a change is
			// required to the record, make that now
			const delta = (this.props.recordValidation ?? _.noop)(record, field_key)
			if (delta != null) {
				record = _.assign({}, record, delta)
			}

			// Update the data with the updated record, including validations
			return { data: _.assign([], s.data, { [row_index]: record }) }
		}

		// Apply the update, and check if empty rows are needed at the end
		let canUpdate = true
		if (
			this.props.onBeforeUpdate != null &&
			!this.props.onBeforeUpdate({
				rowID: row_index,
				field: field_key,
				value: value,
				data: this.getData(),
			})
		) {
			canUpdate = false
		}
		if (canUpdate) {
			this.setState(update, () => {
				this.setState(
					s => ({ data: this.checkAppendable(s, this.props) }),
					() => {
						if (
							this.state.data[this.state.selectedRow]?.[
								this.state.selectedCol
							] !== fixer_function(this.state.selectedValue)
						) {
							this.updateCellValue(
								this.state.selectedRow,
								this.state.selectedCol,
								this.state.selectedValue,
								cb,
							)
						} else {
							;(cb ?? _.noop)()
							this.onUpdate()
						}
					},
				)
			})
		}
	}

	onUpdate() {
		if (this.props.onUpdate != null) {
			this.props.onUpdate({
				data: this.getData(),
				delta: this.getDelta(),
			})
		}
	}

	handleKeyPress(e) {
		if (this.props.onKeyPress) {
			this.props.onKeyPress(e)
			return
		}
		switch (e.keyCode) {
			// Arrow keys - only up/down for now to avoid interrupting text input
			// when 37 then @moveSelection(0, -1)
			// when 39 then @moveSelection(0, 1)
			case 38:
				this.moveSelection(-1, 0)
				break
			case 40:
				this.moveSelection(1, 0)
				break
			// Tab - either right or left depending on shift
			case 9:
				if (e.shiftKey) {
					this.moveSelection(0, -1)
				} else {
					this.moveSelection(0, 1)
				}
				break
			// Enter - move down
			case 13:
				this.moveSelection(1, 0)
				break
			// Escape - cancel selection
			case 27:
				this.selectCell(null, null)
				break
			// Insert - move to last row
			case 45:
				this.selectCell(this.state.data.length - 1, this.state.selectedCol)
				break
			default:
				return
		}
		e.stopPropagation()
		e.preventDefault()
	}

	updateSortOrder(
		current_state,
		key: string,
	): {
		sortingKey: string
		sortingReverse: boolean
		sortingFunction: Function
	} {
		const cs = current_state
		const field = fsmData(this.props.fields, {
			filter: F => F.key === key,
			takeFirst: true,
		})
		return {
			sortingKey: key,
			sortingReverse: cs?.sortingKey === key && !cs?.sortingReverse,
			sortingFunction: field.cmpt?.sortVal ?? _.identity,
		}
	}

	selectCell(row_index, column_field, cb?) {
		// Save the current selected value
		this.updateCellValue(
			this.state.selectedRow,
			this.state.selectedCol,
			this.state.selectedValue,
			() => {
				// Update the selection
				const update = {
					selectedRow: row_index,
					selectedCol: column_field,
					selectedValue: this.state.data[row_index]?.[column_field] ?? null,
				}
				this.setState(update, () => {
					this.props.onClick?.(update)
					;(cb ?? _.noop)()
				})
			},
		)
	}

	moveSelection(dy, dx) {
		// Get the current coordinates
		const fields = this.props.fields.map(F => F.key)
		let cx = fields.indexOf(this.state.selectedCol)
		let cy = this.state.selectedRow

		// Translate the Y coordinate to take into account the current sort order
		const mapping = {}
		const mapping_reverse = {}
		this.getRowsOrdered().forEach((record, index) => {
			mapping[record.__id] = index
			mapping_reverse[index] = record.__id
		})
		cy = mapping[cy]

		// Modify the coordinates
		cx += dx
		cy += dy

		// Handle overflow
		while (cx < 0 && cy > 0) {
			cx += this.props.fields.length
			cy -= 1
		}
		if (cx < 0) {
			cx = 0
		}
		while (cx >= this.props.fields.length && cy < this.state.data.length - 1) {
			cx -= this.props.fields.length
			cy += 1
		}
		if (cx >= this.props.fields.length) {
			cx = this.props.fields.length - 1
		}
		if (cy < 0) {
			cy = 0
		}
		if (cy >= this.state.data.length) {
			cy = this.state.data.length - 1
		}

		// Translate the row back to the sort-independent index
		cy = mapping_reverse[cy]

		// Set the new focus
		this.selectCell(cy, this.props.fields[cx].key)
	}

	clearSelection(cb) {
		this.selectCell(null, null, cb)
	}

	getData() {
		// _.filter @state.data,
		// 	filter: (x) =>
		// 		if x.__deleted
		// 			false
		// 		else if x.__appended and @isRecordEmpty(x)
		// 			false
		// 		else
		// 			true
		return this.state.data
	}

	getDelta(): J2rEditableGridSaveDelta<T> {
		// Track the three types of differences
		const deleted_records: any[] = []
		const appended_records: Partial<J2rEditableGridRecord<T>>[] = []
		const updated_records: { [key: string]: Partial<T> } = {}

		// Get the array of records with only changed fields from each present
		// Unchanged rows are represented as an empty object in the array
		const record_count = _.size(this.state.data)
		_.range(record_count).forEach(index => {
			// Get the PK for this record
			const record_old = this.props.data[index]
			const record_new = this.state.data[index]
			const pk = this.props.pk(record_new)

			// Skip if the record was deleted - store its PK in the array
			if (record_new.__deleted) {
				if (!record_new.__appended) {
					deleted_records.push(pk)
				}
				return
			}

			// Skip if the old record doesn't exist - store as a new record
			if (record_old == null) {
				if (!this.isRecordEmpty(record_new)) {
					const keys = _.map(this.props.fields, f => f.key)
					appended_records.push(_.pick(record_new, keys))
				}
				return
			}

			// Loop over every field and save all changed values
			const diff_record: Partial<T> = {}
			this.props.fields.forEach(field => {
				const vold = record_old[field.key]
				const vnew = record_new[field.key]
				if (vold !== vnew) {
					diff_record[field.key] = vnew
				}
			})
			// If anything has changed, store as an updated record
			if (_.size(diff_record) > 0) {
				updated_records[pk] = diff_record
			}
		})

		// Return the difference model
		const diff_count = _.sum([
			appended_records.length,
			_.size(updated_records),
			deleted_records.length,
		])
		return {
			appended: appended_records,
			updated: updated_records,
			deleted: deleted_records,
			isChanged: diff_count > 0,
		}
	}

	isChanged() {
		return this.getDelta().isChanged
	}

	resetForm() {
		this.clearSelection(() => {
			this.setState(
				{
					isLoading: false,
					isSuccess: false,
					message: 'Grid Reset',
					error: '',
					data: this.getResetData(this.props),
				},
				() => {
					const delta = s => ({
						selectedRow: s.data[s.selectedRow]?.__id ?? null,
						selectedCol: s.data[s.selectedRow] != null ? s.selectedCol : null,
						selectedValue: s.data[s.selectedRow]?.[s.selectedCol] ?? null,
					})

					this.setState(delta, this.onUpdate)
					timer(2000, () => {
						if (this.mounted) {
							this.setState({ message: '' })
						}
					})
				},
			)
		})
	}

	commitCurrentWork(cb) {
		// Skip if unmounted (this can be called after a delay)
		if (!this.mounted) {
			return
		}

		// Save the current selected value
		this.updateCellValue(
			this.state.selectedRow,
			this.state.selectedCol,
			this.state.selectedValue,
			cb,
		)
	}

	onSave() {
		this.setState({
			isLoading: true,
			isSuccess: false,
		})
		this.commitCurrentWork(() => {
			const delta = this.getDelta()
			if (!delta.isChanged) {
				this.setState(
					{
						isLoading: false,
						isSuccess: false,
						message: 'Nothing has changed',
						error: '',
					},
					() =>
						timer(2000, () => {
							if (this.mounted) {
								this.setState({ message: '' })
							}
						}),
				)
			} else {
				this.props.onSave(delta, cb_response => {
					if (cb_response === true) {
						timer(() => {
							if (this.mounted) {
								this.clearSelection(() => {
									this.setState(
										{
											isLoading: false,
											isSuccess: true,
											message: 'Successfully Saved!',
											error: '',
											data: this.getResetData(this.props),
										},
										() =>
											timer(2000, () => {
												if (this.mounted) {
													this.setState({
														message: '',
														isSuccess: false,
													})
												}
											}),
									)
								})
							}
						})
					} else {
						this.setState({
							isLoading: false,
							isSuccess: false,
							message: '',
							error: cb_response,
						})
					}
				})
			}
		})
	}
}

export class J2rEditableGridCell extends React.Component<
	{
		cl?: string
		isSelected?: boolean
		field?: any
		isEditable?: boolean
		record?: any
		tabIndex?: number
		isChanged?: boolean
		isValid?: boolean
		handleKeyPress?: any
		selectCell?: Function
		selectedValue?: any
		changeValue?: Function
		commitCurrentWork?: Function
		autoFocus?: boolean
	},
	any // TODO: fix state type
> {
	selectedComponent: React.RefObject<any>
	mounted: boolean

	constructor(props) {
		super(props)
		this.selectedComponent = React.createRef()
		this.mounted = false
	}

	override shouldComponentUpdate(nextProps) {
		const prevProps = this.props

		// Get the combined set of all keys
		const ko = _.keys(prevProps)
		const kn = _.keys(nextProps)
		const keys = _.uniq(_.concat(ko, kn))

		// Exclude `field` and `record` for now - they require more nuance
		// If anything is different, we need to update
		const diffs = _.filter(
			keys,
			k => prevProps[k] !== nextProps[k] && !['field', 'record'].includes(k),
		)
		if (diffs.length > 0) {
			return true
		}

		// At this point, we may not need to update
		// We need to check the two special props though
		if (!_.isEqual(prevProps.field, nextProps.field)) {
			return true
		}
		if (!_.isEqual(prevProps.record, nextProps.record)) {
			return true
		}

		// Can't find anything else different, so no update required
		return false
	}

	override componentDidMount() {
		this.mounted = true
	}

	override componentWillUnmount() {
		this.mounted = false
	}

	override render() {
		return j2r(
			Do(() => {
				if (!this.props.isSelected || !this.props.isEditable) {
					return this.buildDisplay()
				}
				const is_ro =
					this.props.field.readOnlyFunc?.(this.props.record) ??
					this.props.field.readonly
				if (is_ro) {
					return this.buildDisplay()
				}
				return this.buildEditable()
			}),
		)
	}

	buildDisplay() {
		return {
			tag: 'span',
			key: this.props.field.key,
			tabIndex: this.props.tabIndex,
			cl: BuildClass({
				[this.props.cl]: true,
				[this.props.field.key.toLowerCase().replace(/([^a-z0-9])/g, '')]: true,
				gridCell: true,
				focused: this.props.isSelected,
				changed: this.props.isChanged,
				invalid: !this.props.isValid,
			}),
			children: [
				Do(() => {
					const renderer = this.props.field.cmpt?.renderer ?? _.identity
					const value = this.props.record[this.props.field.key]
					const rendered = renderer(value, this.props.record)
					if (
						rendered == null ||
						_.includes(['string', 'number'], typeof rendered)
					) {
						return {
							key: 'inner',
							cl: 'txt',
							text: String(rendered),
						}
					}
					return _.assign({}, rendered, {
						key: 'inner',
						cl: `txt ${rendered.cl ?? ''}`,
					})
				}),
			],
			onKeyDown: this.props.handleKeyPress,
			onClick: () =>
				this.props.selectCell(this.props.record.__id, this.props.field.key, () =>
					// Pass-through mouse clicks to some components
					{
						this.clickPassThrough()
					},
				),
		}
	}

	buildEditable() {
		return {
			tag: 'span',
			key: this.props.field.key,
			tabIndex: this.props.tabIndex,
			cl: BuildClass({
				[this.props.cl]: true,
				[this.props.field.key.toLowerCase().replace(/([^a-z0-9])/g, '')]: true,
				gridCell: true,
				editing: true,
				focused: this.props.isSelected,
			}),
			children: [
				Do(() => {
					let cmpt = (
						this.props.field.cmpt?.component ?? (() => ({ tag: J2rText }))
					)(this)
					cmpt = _.assign({}, cmpt, {
						key: 'editable-cmpt',
						ref: this.selectedComponent,
						autoFocus: true,
						value: this.props.selectedValue,
						onUpdate: v => {
							v = cmpt.tag === J2rComboBox ? v.value : v
							const cb = cmpt.onChange ?? _.noop
							this.props.changeValue(v, cb)
						},
					})
					return cmpt
				}),
			],
			onKeyDown: this.props.handleKeyPress,
		}
	}

	clickPassThrough() {
		// Exit early if no pass-through
		if (!this.props.field.cmpt?.clickThrough) {
			return
		}

		timer(() => {
			if (!this.mounted) {
				return
			}

			// Get the child component element on which to to trigger a simulated click
			const el = this.selectedComponent.current.element?.current

			// Either warn about the element not being there, or dispatch the event
			if (el == null) {
				console.warn(
					'Cannot proxy-call `clickThrough` on a component without an `element` reference',
				)
			} else {
				el.dispatchEvent(
					new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: true,
					}),
				)
			}

			// Save the changes of the click immediately (intended for checkboxes)
			this.props.commitCurrentWork()
		})
	}
}

export class J2rGridColor extends React.Component<
	{
		value?: string
		onUpdate?: Function
		autoFocus?: boolean
		readonly?: boolean
	},
	any // TODO: fix state type
> {
	static palette = [
		// Grid of colours - 4x4
		['#4444FF', '#FF6666', '#F61C1C', '#CCCC00'],
		['#00CC33', '#EC407A', '#AB47BC', '#7E57C2'],
		['#5C6BC0', '#FF7043', '#29B6F6', '#26A69A'],
		['#66BB6A', '#D4E157', '#FFA726', '#8D6E63'],
	]

	element: React.RefObject<HTMLInputElement>

	constructor(props) {
		super(props)
		this.element = React.createRef()
		this.state = {
			focused: false,
			closed: false,
		}
	}

	override render() {
		return j2r({
			cl: BuildClass({
				'j2r-color-picker': true,
				focused: this.state.focused && !this.state.closed,
			}),
			children: [
				this.buildInput(),
				{
					tag: J2rModalPortal,
					key: 'picker',
					children: [this.buildPicker()],
				},
			],
		})
	}

	buildInput() {
		const input = {
			tag: 'input',
			key: 'input',
			ref: this.element,
			cl: 'color-picker',
			readOnly: true,
			autoFocus: this.props.autoFocus,
			value: this.props.value ?? '',
			style: { backgroundColor: this.getColorValue() },
		}
		if (!this.props.readonly) {
			return _.assign(input, {
				onFocus: () => {
					this.setState({
						focused: true,
						closed: false,
					})
				},
				onClick: () => {
					this.setState({
						closed: false,
					})
				},
				onBlur: () => {
					this.setState({
						focused: false,
					})
				},
			})
		}
		return input
	}

	buildPicker() {
		return {
			cl: BuildClass({
				'color-picker-modal': true,
				closed: this.state.closed || !this.state.focused,
			}),
			key: 'color-picker-modal',
			style: Do(() => {
				const B = this.element.current?.getBoundingClientRect() ?? {
					left: 0,
					right: 0,
					bottom: 0,
				}
				const x = (B.left + B.right) / 2
				const y = B.bottom
				return {
					left: `${x - 134}px`,
					top: `${y + 1}px`,
				}
			}),
			children: Do(() => {
				if (this.state.focused && !this.state.closed) {
					return this.buildPalettePicker()
				}
				return []
			}),
		}
	}

	buildPalettePicker() {
		return J2rGridColor.palette.map((x, p) => ({
			cl: 'col-row',
			key: p,
			children: x.map((x, q) => ({
				cl: 'cell',
				key: q,
				style: { background: x },
				onMouseDown: e => {
					e.stopPropagation()
					e.preventDefault()
				},
				onClick: e => {
					e.stopPropagation()
					e.preventDefault()
					;(this.props.onUpdate ?? _.noop)(this.stripHash(x))
					this.setState({ closed: true })
				},
			})),
		}))
	}

	stripHash(c) {
		if (!c) {
			return null
		} else if (c.startsWith('#')) {
			return c.substring(1)
		}
		return c
	}

	getColorValue() {
		let col = this.props.value ?? ''
		if (col && !col.startsWith('#')) {
			col = `#${col}`
		}
		if (col && col.length !== 7) {
			console.warn(`Invalid colour: '${col}'`)
		}
		return col || null
	}
}

export class J2rGridCheckbox extends React.Component<
	{
		value?: boolean
		isCross?: boolean
		onUpdate?: Function
	},
	any // TODO: fix state type
> {
	element: React.RefObject<HTMLDivElement>

	constructor(props) {
		super(props)
		Bindings(this, [this.toggle])
		this.element = React.createRef()
		this.state = {
			checked: props.value,
			isCross: props.isCross ?? false,
		}
	}

	override componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({ checked: Boolean(this.props.value) })
		}
	}

	override render() {
		return j2r({
			ref: this.element,
			cl: BuildClass({
				'checkbox-renderer': true,
				checked: this.state.checked,
			}),
			text: Do(() => {
				if (!this.state.checked) {
					return ''
				} else if (this.state.isCross) {
					return '✘'
				}
				return '✔'
			}),
			onClick: this.toggle,
			onKeyPress: e => {
				if (e.keyCode === 13) {
					this.toggle()
				}
			},
		})
	}

	toggle() {
		const new_value = !this.state.checked
		this.setState({ checked: new_value }, () =>
			(this.props.onUpdate ?? _.noop)(new_value),
		)
	}
}
