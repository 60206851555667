import { BuildClass, clamp } from '../../../universal'
import { React, _ } from '../../lib'
import { CJSX } from './meta-types'
import { Modal } from './modal'
import { stubDiv, stubInput } from './stubs'
import { TextboxNumeric } from './textbox'

type sliderProps = {
	value: number
	onUpdate: (value: number) => void
	min: number
	max: number
	step?: number
	showValueText?: boolean
	hideTooltip?: boolean
	disabled?: boolean
}

type sliderInstance = {
	getElement: () => HTMLDivElement
	getInput: () => HTMLInputElement
	focus: () => void
}

const RangeSliderComponent = (
	props: sliderProps,
	ref: React.ForwardedRef<sliderInstance>,
) => {
	// Refs
	const refOuter = React.useRef(stubDiv)
	const refTrack = React.useRef(stubDiv)
	const refInput = React.useRef(stubInput)

	// Cache the rectangle data for the track
	const cacheRect = React.useRef<DOMRect>()

	// Track whether dragging is currently taking place
	const refDragging = React.useRef<boolean>(false)
	const [__, setDragging] = React.useState<boolean>(false)

	// Track focus
	const [focused, setFocused] = React.useState<boolean>(false)

	// Verify the value and return a clamped one with correct step
	const filterValueStep = React.useCallback(
		(value: number) => {
			value = clamp(value, props.min, props.max)
			if (!props.step) {
				return value
			}
			if (value == props.max) {
				return props.max
			}
			const steps = Math.round((value - props.min) / props.step)
			return props.min + steps * props.step
		},
		[props.min, props.step, props.max],
	)

	// Get the percentage from the value and range
	// Track the internal percentage state for faster responses and update throttling
	// When the prop value changes, reset the percentage
	const getPerc = React.useCallback(
		(value: number) => {
			const perc = (value - props.min) / (props.max - props.min)
			return clamp(perc, 0, 1)
		},
		[props.min, props.max],
	)
	const [perc, setPerc] = React.useState(() => getPerc(props.value))
	React.useEffect(() => {
		setPerc(getPerc(props.value))
	}, [props.value, props.min, props.max])

	// Helper function to broadcast updates to the value
	const setValue = React.useCallback(
		(value: number) => {
			if (props.value != value) {
				props.onUpdate(value)
			}
		},
		[props.value, props.onUpdate],
	)

	// Track the size of the track to do faster percentage calculations
	React.useEffect(() => {
		const observer = new ResizeObserver(() => {
			cacheRect.current = refTrack.current.getBoundingClientRect()
		})
		observer.observe(refTrack.current)
		return () => {
			observer.disconnect()
		}
	}, [refTrack.current])

	// Helper function to set the value based on mouse position
	const setDraggedValue = React.useCallback(
		(coordX: number) => {
			const rect = cacheRect.current
			if (!rect) {
				return
			}
			const posX = clamp(coordX, rect.left, rect.right)
			const percentage = (posX - rect.left) / rect.width
			const valueRaw = props.min + (props.max - props.min) * percentage
			const value = filterValueStep(valueRaw)
			setValue(value)
			setPerc(getPerc(value))
		},
		[cacheRect, props.min, props.max, setValue, setPerc, filterValueStep],
	)

	// Create a global mouse up event for the range
	const setDraggingOff = React.useCallback(() => {
		if (refDragging.current) {
			refDragging.current = false
			setDragging(false)
			const value = props.min + (props.max - props.min) * perc
			setValue(filterValueStep(value))
		}
	}, [perc, refDragging.current, props.min, props.max])
	React.useEffect(() => {
		document.addEventListener('pointerup', setDraggingOff)
		return () => {
			document.removeEventListener('pointerup', setDraggingOff)
		}
	}, [setDraggingOff])

	// Create a global mouse move event for the range
	const dragMove = React.useCallback(
		(ev: PointerEvent) => {
			if (refDragging.current) {
				setDraggedValue(ev.clientX)
			}
		},
		[refDragging.current, setDraggedValue],
	)
	React.useEffect(() => {
		if (refDragging.current) {
			document.addEventListener('pointermove', dragMove)
			return () => {
				document.removeEventListener('pointermove', dragMove)
			}
		}
		return _.noop
	}, [dragMove, refDragging.current])

	// Event function to update the text
	const onUpdateText = React.useCallback(
		(value: number) => {
			setValue(filterValueStep(value))
		},
		[setValue, filterValueStep],
	)

	// Event function - drag start
	const evDragStart = React.useCallback(
		(ev: React.PointerEvent) => {
			cacheRect.current = refTrack.current.getBoundingClientRect()
			refDragging.current = true
			refInput.current?.focus()
			setDragging(true)
			setDraggedValue(ev.clientX)
			ev.preventDefault()
			ev.stopPropagation()
		},
		[refDragging.current, setDraggedValue],
	)

	// Helper function - move range value by some number of steps
	// If no step is defined, move 1%
	const moveStep = React.useCallback(
		(steps: number) => {
			let value: number
			if (props.step) {
				value = props.value + steps * props.step
			} else {
				const percentage = perc + 0.01 * steps
				value = props.min + (props.max - props.min) * percentage
			}
			value = filterValueStep(value)
			setValue(value)
			setPerc(getPerc(value))
		},
		[
			props.step,
			props.value,
			perc,
			props.min,
			props.max,
			filterValueStep,
			setValue,
			setPerc,
			getPerc,
		],
	)

	// Event function - keyboard navigation
	const evKeyPress = React.useCallback(
		(e: React.KeyboardEvent) => {
			const multiplier = 1 * (e.shiftKey ? 5 : 1) * (e.ctrlKey ? 2 : 1)
			if (e.which == 37) {
				moveStep(-multiplier)
			} else if (e.which == 39) {
				moveStep(+multiplier)
			}
		},
		[moveStep],
	)

	// Focus events
	const setFocusedOn = React.useCallback(() => {
		setFocused(true)
	}, [setFocused])
	const setFocusedOff = React.useCallback(() => {
		setFocused(false)
	}, [setFocused])

	// Instance
	React.useImperativeHandle(ref, () => ({
		getElement: () => refOuter.current,
		getInput: () => refInput.current,
		focus: () => {
			refInput.current.focus()
		},
	}))

	// Render
	return (
		<div className="ui5 ui5-range" ref={refOuter}>
			<CJSX cond={props.showValueText ?? false}>
				<TextboxNumeric
					type="text"
					className="text"
					value={props.value}
					onUpdate={onUpdateText}
				/>
			</CJSX>
			<input
				ref={refInput}
				className="focus-control"
				readOnly={true}
				onFocus={setFocusedOn}
				onBlur={setFocusedOff}
				onKeyDown={evKeyPress}
			/>
			<div
				className={BuildClass({
					'range-outer': true,
					dragging: refDragging.current,
					focused: focused,
				})}
				onPointerDown={evDragStart}
			>
				<div
					ref={refTrack}
					className={BuildClass({
						track: true,
						disabled: props.disabled ?? false,
					})}
				></div>
				<div className="thumb" style={{ left: `calc(${perc * 100}% - 14px)` }}>
					<CJSX cond={!props.hideTooltip}>
						<div className="tooltip">{props.value}</div>
					</CJSX>
					<div className="thumb-inner" />
				</div>
			</div>
			<CJSX cond={refDragging.current}>
				<Modal>
					<div className="ui5-range-dropzone" />
				</Modal>
			</CJSX>
		</div>
	)
}

export const RangeSlider = React.forwardRef(RangeSliderComponent)
