(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lib_libs"));
	else if(typeof define === 'function' && define.amd)
		define(["lib_libs"], factory);
	else if(typeof exports === 'object')
		exports["components"] = factory(require("lib_libs"));
	else
		root["components"] = factory(root["lib_libs"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__lib__) => {
return 