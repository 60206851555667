import { Do, fsmData } from '../../universal'
import { $, _ } from '../lib'

export var j2h_count = 0
const j2h_regexp = /gridRow/

// Converts JObjects to DOM elements
/**
 * @deprecated This is only for use with pre-React layouts
 */
export var j2h = (
	obj: any = {},
	modelObj?,
	skipNormalisation = false,
	recursive = true,
	topEl?,
) => {
	let $elem
	j2h_count += 1

	// If object is null, return an empty span
	if (obj === null) {
		const sub_obj = { tag: 'span', text: '' }
		console.warn('Null object given to `j2h`', topEl)
		return j2h(sub_obj, modelObj, skipNormalisation, recursive, topEl)
	}

	// If no top element is defined, it is this one on an empty topEl object
	topEl = topEl ?? {
		objs: Do(() => {
			if (obj.constructor === Array) {
				return obj
			}
			return [obj]
		}),
		mapping: new Map(),
	}

	// If this is a list, we want to apply the function to every sub-item
	if (obj.constructor === Array) {
		const _j2hConvertArray = sub_obj =>
			j2h(sub_obj, modelObj, skipNormalisation, recursive, topEl)

		return _.map(obj, _j2hConvertArray)
	}

	// Normalise the object
	if (!skipNormalisation) {
		obj = j2hNormalise(obj)
	}

	// Create element, and add class (if exists). If `gridRow` is in the
	// class, we will add `gridCell` to all children elements
	const element = document.createElement(obj.tag ?? 'div')

	// Check if this is a `gridRow` - any children will be given `gridCell` class
	const is_grid_row = j2h_regexp.test(obj.class)

	// Apply the class (if exists)
	if (obj.class != null) {
		element.className = obj.class
	}

	// Apply attributes
	const _j2hApplyAttribute = (value, key) => {
		if (value !== undefined) {
			element.setAttribute(key, value)
		}
	}
	_.forEach(obj.attr, _j2hApplyAttribute)

	// Apply data-set
	const _j2hApplyDataAttribute = (value, key) => {
		if (value !== undefined) {
			element.setAttribute(`data-${key}`, value)
		}
	}
	_.forEach(obj.data, _j2hApplyDataAttribute)

	// Apply value
	if (obj.value != null) {
		element.value = obj.value
	}

	// Fill direct text/html content (if there is content)
	// Otherwise, fill children elements (if exists)
	const _j2hAppendChildNodes = () => {
		// Plain text node
		if (obj.text != null) {
			element.textContent = obj.text

			// Append children
		} else if (obj.children != null && recursive) {
			_.forEach(obj.children, child => {
				// Fetch the child element recursively and append to the DOM
				const childElem = j2h(child, modelObj, skipNormalisation, true, topEl)
				// Add the `gridCell` class if required
				if (is_grid_row) {
					childElem.className += ' gridCell'
				}
				element.appendChild(childElem)
			})
			return

			// Raw HTML node (not recommended but available)
		} else if (obj.html != null) {
			element.innerHTML = obj.html
		}
	}
	_j2hAppendChildNodes()

	// Event listeners
	// `click` attribute is an event
	// `init` attribute is not technically an event
	// Iterate and add the listeners, passing the original event and the JQuery element
	if (_.size(obj.ev) > 0) {
		$elem = $(element)
		const _j2hApplyEventListener = (func, key) => $elem.on(key, e => func(e, $elem))
		_.forEach(obj.ev, _j2hApplyEventListener)
	}

	// If this has a model name, set the value to the existing value for that
	// model's key. Also add an on input/change event listener to update the
	// model with the new value
	const _j2hApplyModelEvents = () => {
		if (!obj.model) {
			return
		}

		// Ensure there is a model to save to
		if (modelObj == null) {
			throw Error('No object model defined for this J2H set')
		}

		// Check if this is an input (editable) element, or just a static item
		const non_input = !_.includes(['select', 'input', 'textarea'], obj.tag)

		// Backwards compatible string model
		const defSerialize = modelVal => modelVal
		const defDeserialize = formVal => formVal
		if (typeof obj.model === 'string') {
			obj.model = { field: obj.model }
		}
		if (obj.model.serialize == null) {
			obj.model.serialize = defSerialize
		}
		if (obj.model.deserialize == null) {
			obj.model.deserialize = defDeserialize
		}

		// Set the value of the element from the model
		let modelVal = modelObj[obj.model.field]
		modelVal = obj.model.serialize(modelVal, element, modelObj)

		// Put the model key in the DOM element data
		element.dataset.model = obj.model.field

		// Set the text content of a static non-editable element
		if (non_input) {
			element.textContent = modelVal
			return
		}
		// else if not modelVal? and obj.value
		// 	modelObj[obj.model] = obj.value

		// At this point, it's a dynamic form element

		// Normalise checkbox values
		if (obj.attr?.type === 'checkbox') {
			element.checked = !!modelVal

			// Fix issue with setting value of select to null before it's on page
		} else if (obj.tag === 'select' && modelVal == null) {
			element.selectedIndex = -1

			// Set the straight value for the rest
		} else {
			element.value = modelVal
		}

		// Trigger change events for the element
		if (
			(obj.ev?.change != null || obj.ev?.input != null) &&
			!obj.skipInitValidation
		) {
			$elem = $(element)
			;(obj.ev?.change ?? _.noop)({}, $elem)
			;(obj.ev?.input ?? _.noop)({}, $elem)
		}

		// Add event listeners to update the model
		const updateModel = e => {
			// Get the element of this event
			const elem = e.currentTarget

			// Get the value (based on whether it's a checkbox or not
			let value = Do(() => {
				if (obj.attr?.type === 'checkbox') {
					return elem.checked
				} else if (obj.tag === 'select' && _.has(obj.attr, 'multiple')) {
					return fsmData<any>(elem.options, {
						filter: x => x.selected,
						map: x => x.value ?? x.text,
					})
				}
				return elem.value
			})

			// Run deserialize function and update data model
			value = obj.model.deserialize(value, element, modelObj)
			modelObj[obj.model.field] = value

			// Look for elements (from top level) listening to this field
			let listeners = []
			_.forEach(
				topEl.objs.forEach(topObj => {
					const q = `[data-model='${obj.model.field}']`
					_.forEach(topObj.el.querySelectorAll(q), x => {
						listeners.push(x)
					})
				}),
			)

			// Filter out the one we just read the update from
			// Loop over each listener, fetch its original J2H object, and update
			// its value mapping through its serialize
			listeners = listeners.filter(el => el !== obj.el)
			listeners.forEach(el => {
				// Get the listener object's serialized value
				const listenObj = topEl.mapping.get(el)
				if (listenObj == null) {
					return
				}
				const serializedValue = listenObj.model.serialize(value)

				// Update the value on screen in the listener element
				if (_.includes(['input', 'textarea', 'select'], el.tag)) {
					el.value = serializedValue
				} else {
					el.textContent = serializedValue
				}
			})
		}

		// Apply the event handler
		$(element).on('input change', e => {
			updateModel(e)
		})
	}

	// Apply all model events (function above)
	_j2hApplyModelEvents()

	// Run the `init` event now that the element is fully initialised
	if (obj.init != null) {
		obj.init(element)
	}

	// Add the element to the original object
	// And return the element object up the stack
	obj.el = element
	topEl.mapping.set(obj.el, obj)
	return element
}

// Normalises a JObject for conversion or comparison
var j2hNormalise = (obj: any = {}) => {
	// If a string is passed, it will be an element with that text in it
	if (typeof obj !== 'object') {
		obj = { tag: 'span', text: obj }
	}

	// Otherwise, element tag defaults to a generic `div` if not given
	// Tag must also be normalised to lowercase
	obj.tag = obj.tag != null ? obj.tag.toLowerCase() : 'div'

	// These object nodes must exist
	{
		if (obj.class == null) {
			obj.class = ''
		}
		if (obj.attr == null) {
			obj.attr = {}
		}
		if (obj.data == null) {
			obj.data = {}
		}
		if (obj.ev == null) {
			obj.ev = {}
		}
	}

	// `key` is a dataset item, `click` is an event, `init` is not a DOM event
	{
		if (obj.key != null) {
			obj.data.key = obj.key
			delete obj.key
		}
		if (obj.click != null) {
			obj.ev.click = obj.click
			delete obj.click
		}
		if (obj.ev.init != null) {
			obj.init = obj.ev.init
			delete obj.ev.init
		}
	}

	// These attributes do not need to be nested
	{
		if (obj.attr.value !== undefined) {
			obj.value = obj.value
			delete obj.attr.value
		}
		if (obj.title != null) {
			obj.attr.title = obj.title
			delete obj.title
		}
		if (obj.ID != null) {
			obj.attr.id = obj.ID
			delete obj.ID
		}
		if (obj.id != null) {
			obj.attr.id = obj.id
			delete obj.id
		}
		if (obj.attr.ID != null) {
			obj.attr.id = obj.attr.ID
			delete obj.attr.ID
		}
	}

	// Attributes and data must all be strings
	{
		_.forEach(obj.data, (v, k) => {
			obj.data[k] = String(v)
		})
		_.forEach(obj.attr, (v, k) => {
			if (v === undefined) {
				delete obj.attr[k]
			} else {
				obj.attr[k] = String(v)
			}
		})
	}

	return obj
}

// Normalises a JObject recursively - can prep an entire tree
const j2hNormaliseRecursive = (obj: any = {}) => {
	obj = j2hNormalise(obj)
	if (obj.children != null) {
		_.forEach(obj.children, (child, index) => {
			obj.children[index] = j2hNormaliseRecursive(child)
		})
	}
	return obj
}

// Converts an existing DOM tree into a JObject
const j2hReverse = elem => {
	// Base object
	const jobj = {
		tag: elem.tagName.toLowerCase(),
		class: '',
		attr: {},
		data: {},
		ev: {},
		children: null,
		text: null,
	}

	// Attributes
	_.forEach(elem.attributes, node => {
		let [key, value] = [node.name, node.value]
		if (key === 'id') {
			key = 'ID'
		}
		if (key === 'class') {
			jobj.class = value
		} else if (key.substring(0, 5) === 'data-') {
			jobj.data[key.substring(5)] = value
		} else {
			jobj.attr[key] = value
		}
	})

	// Events
	_.forEach(($ as any)._data(elem).events, (fns, ev) => {
		jobj.ev[ev] =
			fns.length === 1
				? fns[0]
				: (e, $elem) => {
						_.forEach(fns, fn => {
							fn(e, $elem)
						})
					}
	})

	// Children
	if (elem.hasChildNodes()) {
		jobj.children = []
		const raw_children = elem.children
		_.forEach(_.range(raw_children.length), x => {
			jobj.children.push(j2hReverse(raw_children.item(x)))
		})

		// Or just plain text
	} else {
		jobj.text = elem.textContent
	}

	// Return the JObject
	return jobj
}

// Rebuilds a J2H object and all its children
/**
 * @deprecated This is only for use with pre-React layouts
 */
export const j2hRebuild = rootObj => {
	const element = rootObj.el
	const newElement = j2h(rootObj, null, true)
	element.insertAdjacentElement('afterend', newElement)
	element.remove()
	return newElement
}
