import { React } from '../../../lib'
import { useRSInstance } from '../meta-types'
import { loadParametersGeneric } from '../report-frame'
import { ResizeSplitter } from '../resize-split'
import { stubListInstance } from '../stubs'
import {
	Action,
	Payload,
	getDefaultState,
	getPartialStateFromAction,
	sendOnUpdate,
} from './actions'
import { RJMProps, RJMRefs, RJMState, ReducerStateD } from './types'
import { SingleReportJobManager } from './ui-manager'
import { RJMSidebar } from './ui-sidebar'

export const ReportJobManager = (props: RJMProps) => {
	// Reducer
	const rs = useRSInstance<RJMProps, RJMState, RJMRefs, Payload>({
		props: props,
		refs: {
			list: React.useRef(stubListInstance),
		},
		defaultState: getDefaultState,
		actionToDelta: getPartialStateFromAction,
	})

	// Handle loading the parameters
	React.useEffect(() => {
		loadParameters(rs).catch(err => {
			console.error(err)
		})
	}, [props.loadData.v3, props.loadData.v4, rs])

	// Render
	return (
		<ResizeSplitter
			className="tailwind-wrapper"
			value={rs.state.widthsOuter ?? undefined}
			onUpdate={v => {
				rs.dispatch([Action.UpdateOuterWidths, v ?? null])
				sendOnUpdate(rs)
			}}
			panes={[
				{
					collapsible: false,
					minWidth: 140,
					maxWidth: 360,
					defaultWidth: 200,
					flexWidth: null,
					content: <RJMSidebar rs={rs} />,
				},
				{
					collapsible: false,
					minWidth: null,
					maxWidth: null,
					defaultWidth: null,
					flexWidth: 1,
					content: (
						<SingleReportJobManager rs={rs} rj={rs.state.selectedReportJob} />
					),
				},
			]}
		/>
	)
}

const loadParameters = async (rs: ReducerStateD) => {
	const cacheCombined = await loadParametersGeneric(rs.props.loadData)
	rs.updateState(() => ({ cache: cacheCombined }))
}
