// Ideas for this:
// - https://ux.stackexchange.com/questions/103487/create-new-rows-for-datagrids
// - https://www.mobilespoon.net/2019/11/design-ui-tables-20-rules-guide.html
// eslint-disable-next-line no-secrets/no-secrets
// - https://uxdesign.cc/designing-tables-for-reusability-490a3760533
// - https://medium.com/design-with-figma/the-ultimate-guide-to-designing-data-tables-7db29713a85a
//
// TODO:
// - Multi-select checkboxes
// - Unnecessary borders?
// - Reordering columns
// - Resizing columns
// - Action icon(s) at end
// - Copy to clipboard (listgrid) but paste back in (can edit in Excel)

import {
	BuildClass,
	Do,
	Maybe,
	Sleep,
	fsmData,
	guid,
	runPromisesSequentially,
} from '../../../universal'
import { React, _ } from '../../lib'
import { Button, FormButton, FormButtonSet } from './buttons'
import { CellComponent, checkReadOnly } from './editable-cells'
import {
	ListGrid,
	ListGridField,
	ListGridGroup,
	ListGridInstance,
	ListGridView,
	filterVal,
	groupID,
	groupingState,
	sortableVal,
} from './list-grid'
import {
	CJSX,
	ConditionalObject,
	Focusable,
	RSInstance,
	RSInstanceD,
	useEffectCompare,
	useRSInstance,
	useStateSync,
} from './meta-types'
import { stubDiv, stubFocusable, stubInput, stubListGrid } from './stubs'
import { Textbox } from './textbox'
import { Toolbar } from './toolbar'

export type CellValidation = {
	req: (value: any) => boolean // Maps value to a bool - requirement
	msg: string // Error message if bool is false
}

export type GridField<T extends Dict, V> = {
	// Column information
	key: string // Key of the record
	lbl: string // Label to show in the heading row
	lblLong?: string // Longer version of label to show in context menus
	lblDesc?: string // Label tooltip to show in the heading row
	tooltipBubble?: boolean // Whether a question mark tooltip hint is shown
	hideIndicators?: boolean // Whether to hide the change indicators (mostly for static calculated fields)
	// Grouping-specific field attributes
	groupVal?: (record: T) => groupID // Turns record into grouping ID (when grouped)
	groupName?: (groupID: groupID, record: T) => string // Turns grouping ID into group name (when grouped)
	groupSort?: (group: ListGridGroup<T>) => sortableVal // Turns grouping ID into sort index (when grouped)
	// Filtering by value - options shown shown even if not found in current list
	filterValues?: () => filterVal[]
	// Editable component fields
	cell: CellComponent<T, V>
	// TODO - this needs to be a record `PartialDefined<T>`
	value: (record: T) => V // Gets text from value - this is searchable
	onUpdate: (value: V, record: T) => void // Function taking value + record, mutating record (no return)
	validation?: CellValidation[]
	disabled?: boolean // Allows disabling a field based on a condition without using `ConditionalObject` breaking type infererence
	// Overrides for `cell` properties that can use the row object
	className?: (record: Maybe<T>) => Maybe<string> // Adds a custom class to each cell in the column
	text?: (record: T, isRev?: boolean) => string
	sortVal?: (value: T, isRev?: boolean) => sortableVal
	readOnly?: boolean | ((record: T) => boolean)
	// Sorting
	sortReverseDefault?: boolean // Whether the default sort is descending
	sortable?: boolean // Whether this field can be sorted (generally implied)
	// Extras from listgrid
	colorVal?: (record: T) => number
	colorRange?: { low: number; high: number }
}

type Dict = { [key: string]: any }

type gridSeed = { index: number; circle: number }

export type GridSaveDelta<T extends Dict, V extends string | number> = {
	data: T[]
	appended: T[]
	appendedIDs: string[]
	updated: {
		[key: string | number]: Partial<T>
	}
	deleted: V[]
	isChanged: boolean
}

type PartialDefined<T> = {
	[P in keyof T]: T[P] | null
}

type EditableGridView = ListGridView & { searchText?: string }

/** Points to a specific cell in the grid using the PK of the record with field string key */
export type CellCoordinates<V extends string | number> = {
	pk: string | V
	field: string
}

type GridProps<T extends Dict, V extends string | number> = {
	/** Adds a custom DOM class name */
	className?: string
	/** Takes in a record and returns its custom classes */
	classRow?: (record: Maybe<T>) => string
	/** Takes in a record and returns its custom tooltip */
	tooltipRow?: (record: Maybe<T>) => string
	/** Function to map a data record to the primary key (usually `x => x.ID`) */
	pk: (record: T) => V
	/** Source data */
	data: T[]
	/** Filter the visible rows based on something external - only applies to original records */
	filter?: (record: T) => boolean
	/** Current data state - only needed if it's being controlled by a parent */
	value?: EGRecord<T, V>[]
	/** Runs when the data is updated - only needed if it's being controlled by a parent */
	onUpdate?: (value: EGRecord<T, V>[]) => void
	/** List of fields/columns to show */
	fields: GridField<T, any>[]
	/** Grouping options */
	grouping?: groupingState<T>
	/** Sort / group / filters / colour etc. Leave as null for the reset default */
	view?: EditableGridView
	/** Runs when the view is updated */
	onUpdateView?: (value: EditableGridView) => void
	/** The currently-focused cell coordinates (record PK + field key) */
	focusedCell?: Maybe<CellCoordinates<V>>
	/** Event when the currently-focused cell updates */
	onUpdateFocusedCell?: (value: Maybe<CellCoordinates<V>>) => void
	/** The default column to sort */
	defaultSortingKey: string
	/** Whether the default column sort starts reversed */
	defaultSortingReverse?: boolean
	/** Lazy render height override - defaults to 28 */
	height?: (record: T) => number
	/** Whether the grid is sortable (default: true) */
	sortable?: boolean
	/**
	 * Whether sorting happens when values update or defers until user re-sorts.
	 * @default true
	 */
	deferSorting?: boolean
	/** Override CSS styles */
	style?: { [key: string]: string }
	/** Whether the toolbar is shown above the grid (default: true) */
	showToolbar?: boolean
	/** Whether the grid can be edited. Overrides appendable/deletable */
	readOnly?: boolean
	/** If the grid is appendable, we need a function that generates blank records */
	appendable?: () => PartialDefined<T>
	/** Whether rows can be deleted from the grid */
	deletable?: boolean
	/** Runs when clicking the save button, a callback function is run once done */
	onSave?: (
		delta: GridSaveDelta<T, V>,
		resolve: (result: true | string) => void,
	) => void
}

export type EGRecord<T, V> = {
	pk: string | V
	blankIndex: number
	deleted: boolean
	original: Maybe<T>
	viewing: T
	current: T
}

type gridData<T extends Dict, V extends string | number> = {
	[key: string | number]: EGRecord<T, V>
}

type GridState<T extends Dict, V extends string | number> = {
	data: gridData<T, V>
	view: EditableGridView
	focusedCell: Maybe<CellCoordinates<V>>
	message: string
	msgColor: string
	isLoading: boolean
	showTick: boolean
}

type cellRefsType = {
	[pk: string | number]: {
		[field: string]: React.MutableRefObject<Focusable<Element>>
	}
}

type GridRefs<T extends Dict, V extends string | number> = {
	cellRefs: React.MutableRefObject<cellRefsType>
	seed: React.MutableRefObject<gridSeed>
	wrapper: React.MutableRefObject<HTMLDivElement>
	listGrid: React.MutableRefObject<ListGridInstance<EGRecord<T, V>, false>>
}

type ReducerStateD<T extends Dict, V extends string | number> = RSInstanceD<
	GridProps<T, V>,
	GridState<T, V>,
	GridRefs<T, V>,
	Payload<T, V>
>
type ReducerState<T extends Dict, V extends string | number> = RSInstance<
	GridProps<T, V>,
	GridState<T, V>,
	GridRefs<T, V>
>

export type EditableGridInstance<T extends Dict, V extends string | number> = {
	getElement: () => HTMLDivElement
	listGrid: () => ListGridInstance<EGRecord<T, V>, false>
	getDefaultDataState: () => gridData<T, V>
	getChangeDelta: () => GridSaveDelta<T, V>
	isChanged: () => boolean
	reset: () => void
	save: () => Promise<void>
	focusNewRow: () => void
	recordCount: { filtered: number; total: number }
}

const getDefaultState = <T extends Dict, V extends string | number>(
	props: GridProps<T, V>,
	seed: React.MutableRefObject<gridSeed>,
): GridState<T, V> => ({
	data: getDefaultDataState(props, seed),
	view: props.view ?? {},
	focusedCell: null,
	message: '',
	msgColor: '',
	isLoading: false,
	showTick: false,
})

const EditableGridComponent = <T extends Dict, V extends string | number>(
	props: GridProps<T, V>,
	ref: React.ForwardedRef<EditableGridInstance<T, V>>,
) => {
	const seed = React.useRef<gridSeed>({ index: 1, circle: 0.0 })

	// Reducer
	const rs = useRSInstance<
		GridProps<T, V>,
		GridState<T, V>,
		GridRefs<T, V>,
		Payload<T, V>
	>({
		props: props,
		refs: {
			seed: seed,
			wrapper: React.useRef<HTMLDivElement>(stubDiv),
			listGrid: React.useRef<ListGridInstance<EGRecord<T, V>, false>>(stubListGrid),
			cellRefs: React.useRef<cellRefsType>({}),
		},
		defaultState: React.useCallback(p => getDefaultState(p, seed), []),
		actionToDelta: getPartialStateFromAction,
	})

	// If the data props change, reset the data
	useEffectCompare(() => {
		rs.refs.seed.current = { index: 1, circle: 0.0 }
		rs.dispatch([Action.UpdateData, getDefaultDataState(rs.props, rs.refs.seed)])
	}, rs.props.data)

	// Sync state.data with props.value
	useStateSync({
		stateVal: rs.state.data,
		propVal: rs.props.value,
		setState: data => {
			rs.dispatch([
				Action.UpdateData,
				getDefaultDataState(rs.props, rs.refs.seed, data),
			])
		},
		setProp: value => {
			rs.props.onUpdate?.(_.values(value))
		},
	})

	// Sync focused cell
	useStateSync({
		stateVal: rs.state.focusedCell,
		propVal: rs.props.focusedCell,
		setState: v => {
			rs.dispatch([Action.UpdateFocusedCell, v])
		},
		setProp: v => {
			rs.props.onUpdateFocusedCell?.(v)
		},
	})

	// Search box update
	const updateSearchBoxText = React.useCallback(
		(v: string) => {
			changeSearchText(rs, v)
		},
		[rs],
	)

	// Get the number of visible records from the inner-most list component
	// const listCmpt = listGrid.current.getList()
	const rCount = {
		filtered:
			rs.refs.listGrid.current
				?.getFilteredRecords(rs.state.view?.searchText ?? '')
				.filter(x => !x.deleted && x.original != null).length ?? 0,
		total: _.filter(rs.state.data, x => !x.deleted && x.original != null).length,
	}

	// Sync state for the view - only if the view is given
	const onUpdateView = React.useCallback(
		(v: ListGridView) => {
			rs.dispatch([
				Action.UpdateView,
				{ view: { ...v, searchText: rs.state.view.searchText } },
			])
		},
		[rs],
	)
	useStateSync<EditableGridView, EditableGridView>({
		propVal: rs.props.view ?? {},
		stateVal: rs.state.view,
		setProp: v => rs.props.onUpdateView?.(v),
		setState: v => {
			onUpdateView(v)
		},
		disabled: !rs.props.view && !rs.props.onUpdateView,
	})

	// Function to focus empty row - when "add record" is clicked
	const focusEmptyRowWrapper = React.useCallback(() => {
		focusEmptyRow(rs).catch(err => {
			console.error('Error focusing empty row', err)
		})
	}, [rs])

	// Generate all of the missing empty ref objects
	{
		const refs = rs.refs.cellRefs.current
		_.forEach(rs.state.data, row => {
			if (!_.has(refs, row.pk)) {
				refs[row.pk] = {}
			}
			const item = refs[row.pk] as Maybe<{ [field: string]: unknown }>
			_.forEach(rs.props.fields, field => {
				if (item && !item[field.key]) {
					item[field.key] = {
						current: stubFocusable(stubInput),
					}
				}
			})
		})
	}

	// Get the instance
	React.useImperativeHandle(ref, () => ({
		getElement: () => rs.refs.wrapper.current,
		listGrid: () => rs.refs.listGrid.current,
		getDefaultDataState: () => getDefaultDataState(rs.props, rs.refs.seed),
		getChangeDelta: () => getChangeDeltaInner(rs),
		isChanged: () => getChangeDeltaInner(rs).isChanged,
		reset: () => {
			onReset(rs)
		},
		save: async () => onSave(rs),
		focusNewRow: () => {
			focusEmptyRowWrapper()
		},
		recordCount: rCount,
	}))

	// Render
	return (
		<div
			ref={rs.refs.wrapper}
			className={BuildClass({
				'ui5 ui5-editable-grid': true,
				readonly: props.readOnly ?? false,
				[props.className ?? '']: true,
				noToolbar: !(props.showToolbar ?? true),
				noSaveButtons: !props.onSave,
			})}
		>
			<CJSX cond={props.showToolbar ?? true}>
				<Toolbar
					lhs={<></>}
					rhs={
						<>
							<div className="record-count">
								{rCount.filtered == rCount.total
									? `${rCount.total} records`
									: `Filtered to show ${rCount.filtered} / ${rCount.total} records`}
							</div>
							<div className="search-wrapper">
								<img src="/static/img/svg/search.svg" />
								<Textbox
									className="search"
									placeholder="Search..."
									type="search"
									value={rs.state.view?.searchText ?? ''}
									onUpdate={updateSearchBoxText}
								/>
							</div>
							<CJSX cond={rs.props.appendable != null}>
								<Button
									className="add-button"
									type="borderless"
									title="Focuses the empty row in the grid to add a new record"
									onClick={focusEmptyRowWrapper}
								>
									<img src="/static/img/svg/plus-black.svg" />
									Add Record
								</Button>
							</CJSX>
						</>
					}
					widthLHS={0}
				/>
			</CJSX>
			<ListGrid<EGRecord<T, V>, string | V, false>
				ref={rs.refs.listGrid}
				data={_.filter(
					rs.state.data,
					x =>
						!x.deleted &&
						(!x.original || !props.filter || props.filter(x.original)),
				)}
				pk={x => x.pk}
				// Disable selections
				disableFocusControl={true}
				disableClickHandlers={true}
				multiple={false}
				value={null}
				onUpdate={() => {}}
				readOnly={true}
				// Views
				searchText={rs.state.view?.searchText}
				view={_.omit(rs.state.view, ['searchText'])}
				onUpdateView={onUpdateView}
				grouping={Do(() => {
					const grp = props.grouping
					return grp == null
						? undefined
						: {
								key: x => grp.key(x.viewing),
								name: (gID, x) => grp.name(gID, x.viewing),
								sort: group =>
									grp.sort({
										ID: group.ID as unknown,
										Name: group.Name,
										Records: group.Records.map(x => x.viewing),
									}),
								reverse: grp.reverse,
								gaps: grp.gaps,
							}
				})}
				defaultSortingKey={props.defaultSortingKey}
				defaultSortingReverse={props.defaultSortingReverse}
				// Layout
				classRow={
					props.classRow
						? x => props.classRow?.(x?.viewing ?? null) ?? ''
						: undefined
				}
				tooltipRow={
					props.tooltipRow
						? x => props.tooltipRow?.(x?.viewing ?? null) ?? ''
						: undefined
				}
				height={v => props.height?.(v.viewing) ?? 28}
				sortable={props.sortable}
				style={props.style}
				// Handle key events
				onKeyDown={(
					ev: React.KeyboardEvent<HTMLInputElement>,
					defaultEvent: (ev: React.KeyboardEvent<HTMLInputElement>) => void,
				) => {
					console.log({ ev, defaultEvent })
				}}
				// Define fields
				fields={_.compact([
					// Change indicator
					ConditionalObject(!(props.readOnly ?? false), () => ({
						key: 'change-indicator',
						lbl: '',
						lblLong: '[Changed]',
						lblDesc:
							'Colour-coded based on whether the record has changed (green), has errors (red), or is unchanged (grey)',
						className: () => 'noselect',
						tooltip: x => getIndicatorRow(rs, x).message,
						text: () => '',
						sortable: false,
						searchable: false,
						display: x => <div className={getIndicatorRow(rs, x).type} />,
					})),

					// Main fields
					...fsmData(props.fields, {
						map: F => buildField(rs, F),
					}),

					// Delete button
					ConditionalObject(!(props.readOnly ?? false), () => ({
						key: 'delete-reset-button',
						lbl: '',
						lblLong: '[Delete/Reset]',
						lblDesc: 'Delete / reset buttons',
						className: () => 'noselect',
						text: () => '',
						searchable: false,
						sortable: false,
						tooltip: x => {
							switch (checkRecordDeletableResetable(rs, x)) {
								case 'delete':
									return 'Delete this record'
								case 'reset':
									return 'Reset this record'
								default:
									return ''
							}
						},
						display: x => {
							switch (checkRecordDeletableResetable(rs, x)) {
								case 'delete':
									return (
										<img
											className="delete-icon"
											src="/static/img/cross.png"
										/>
									)
								case 'reset':
									return (
										<img
											className="reset-icon"
											src="/static/img/undo.png"
										/>
									)
								default:
									return <></>
							}
						},
						onClick: x => {
							switch (checkRecordDeletableResetable(rs, x)) {
								case 'delete':
									actionDelete(rs, x)
									break
								case 'reset':
									actionResetRow(rs, x)
									break
								default:
									break
							}
						},
					})),
				] as ListGridField<EGRecord<T, V>>[])}
			/>
			<CJSX cond={Boolean(props.onSave)}>
				<FormButtonSet
					loading={rs.state.isLoading}
					msg={rs.state.message}
					tick={rs.state.showTick}
				>
					<FormButton
						lbl="Save"
						onClick={() => {
							onSave(rs).catch(err => {
								rs.dispatch([
									Action.UpdateFormState,
									{
										message: String(err),
										isLoading: false,
										showTick: false,
									},
								])
							})
						}}
					/>
					<FormButton
						lbl="Reset"
						onClick={() => {
							onReset(rs)
						}}
					/>
				</FormButtonSet>
			</CJSX>
		</div>
	)
}

// HELPERS ###############################################################################

// Helper function - get default data state when init or reset
const getDefaultDataState = <T extends Dict, V extends string | number>(
	props: GridProps<T, V>,
	seed: React.MutableRefObject<gridSeed>,
	dataOverride?: EGRecord<T, V>[],
): gridData<T, V> => {
	const data = _.fromPairs(
		dataOverride
			? dataOverride.map(x => [x.pk, x])
			: props.data.map(x => [
					props.pk(x),
					{
						pk: props.pk(x),
						blankIndex: 0,
						original: x,
						viewing: x,
						current: x,
						deleted: false,
					} as EGRecord<T, V>,
				]),
	)
	return addEmptyRows(data, props.appendable, seed) as gridData<T, V>
}

// Build the field
const buildField = <T extends Dict, V extends string | number>(
	rs: ReducerStateD<T, V>,
	F: GridField<T, any>,
): ListGridField<EGRecord<T, V>> => ({
	key: F.key,
	disabled: F.disabled ?? false,
	className: x =>
		BuildClass({
			[F.className?.(x?.current) ?? '']: true,
			[x == null ? '' : (F.cell.className?.(F.value(x.current)) ?? '')]: true,
			'cell-outer': true,
			[x ? `indicator-${getIndicatorCell(rs, F, x).type}` : '']: true,
		}),
	tooltip: x => {
		const indicator = getIndicatorCell(rs, F, x)
		if (indicator.type == 'red') {
			return indicator.message
		}
		return ''
	},
	// Build the component
	text: x => (F.text ? F.text(x.current) : (F.cell.text?.(F.value(x.current)) ?? '')),
	display: x => {
		// Build the component, passing in the value and `onUpdate` props
		const ref = rs.refs.cellRefs.current[x.pk][F.key]
		if (!ref) {
			console.error('Missing ref for', F.key, x)
			return <></>
		}
		return F.cell.cmpt({
			record: x?.current,
			value: F.value(x?.current) as unknown,
			ref: ref,
			searchText: rs.state.view?.searchText || null,
			readOnly: rs.props.readOnly || checkReadOnly(F?.readOnly, x?.current),
			onUpdate: (value, commit?: boolean) => {
				// If this is read-only, this is an error - it should not get this far
				const isReadOnly =
					rs.props.readOnly ||
					(_.isFunction(F.cell.readOnly)
						? F.cell.readOnly(x.current)
						: F.cell.readOnly)
				if (isReadOnly) {
					console.error('Read-only component triggered update', F.key)
					rs.dispatch([Action.UpdateData, rs.state.data])
					return
				}

				// Get new data record
				const newVal = (F.cell.proxyValue ?? _.identity)(value) as unknown
				const currRecord = { ...x.current }
				F.onUpdate(newVal, currRecord)

				// Update state, inserting in this new record as `current`
				// Only commit if we're not deferring sorting
				const updateViewing = commit && !(rs.props.deferSorting ?? true)
				rs.dispatch([
					Action.UpdateRecord,
					x.pk,
					{
						...x,
						current: { ...currRecord },
						viewing: updateViewing ? { ...currRecord } : x.viewing,
					},
				])
				// console.log('Updating', F, x)
			},
			onFocus: () => {
				rs.dispatch([Action.UpdateFocusedCell, { pk: x.pk, field: F.key }])
			},
			onBlur: () => {
				rs.dispatch([Action.UpdateFocusedCell, null])
			},
			onCommit: () => {
				// TODO - track this
				// This updates `viewing` model (sort etc) - doesn't update per-keystroke
				// Only do this if we're not deferring sorting
				if (!(rs.props.deferSorting ?? true)) {
					rs.dispatch([
						Action.UpdateRecord,
						x.pk,
						{
							...x,
							viewing: x.current,
						},
					])
				}
				// console.log('Committing', F, x)
			},
		})
	},

	// Labels
	lbl: F.lbl,
	lblLong: F.lblLong,
	lblDesc: F.lblDesc,
	tooltipBubble: F.tooltipBubble,

	// Sorting
	preSort: Do(() => {
		// Helper function to ensure that blank rows go at the bottom by inverting if
		// the `isRev` flag is true
		const blank_record = rs.props.appendable?.()
		return (x: EGRecord<T, V>, isRev: Maybe<boolean>): [boolean, number] => {
			const is_blank = x.original == null && _.isEqual(x.viewing, blank_record)
			return [isRev ? !is_blank : is_blank, is_blank ? x.blankIndex : 0]
		}
	}),
	sortVal: Do(() => {
		// 1st priority - sorting function on the field
		if (F.sortVal) {
			return x => F.sortVal?.(x.viewing)
		}

		// 2nd - a sorting value on the cell type (generally pre-packaged)
		if (F.cell.sortVal) {
			return x => F.cell.sortVal?.(F.value(x.viewing))
		}

		// 3rd - just use the value itself (identity sort function)
		if (F.value) {
			return x => F.value(x.viewing)
		}

		// Nothing to go off - just use whatever, keeping blanks at the bottom
		return () => null
	}),
	sortReverseDefault: F.sortReverseDefault,
	sortable: F.sortable,

	// Grouping
	groupVal: Do(() => {
		const fn = F.groupVal
		if (fn != null) {
			return x => fn(x.viewing)
		}
		return undefined
	}),
	groupName: Do(() => {
		const fn = F.groupName
		if (fn != null) {
			return (gID, record) => fn(gID, record.viewing)
		}
		return undefined
	}),
	groupSort: Do(() => {
		const fn = F.groupSort
		if (fn != null) {
			return group =>
				fn({
					ID: group.ID as unknown,
					Name: group.Name,
					Records: group.Records.map(x => x.viewing),
				})
		}
		return undefined
	}),

	// Filtering
	filterValues: F.filterValues,

	// Extras
	colorVal: F.colorVal ? x => F.colorVal?.(x.current) ?? 0 : undefined,
	colorRange: F.colorRange,
})

export const getEGChangeDelta = <T extends Dict, V extends string | number>(
	current: EGRecord<T, V>[],
	newRecord?: () => PartialDefined<T>,
): GridSaveDelta<T, V> => {
	// Identify a new record
	const nr = newRecord?.()
	const isEmpty = (x: EGRecord<T, V>) => !x.original && _.isEqual(x.current, nr)

	// Get changed values - this part is a little trickier
	const changed = fsmData(current, {
		filter: x => x.original && !_.isEqual(x.original, x.current),
		map: x => {
			// Only the keys that have actually changed
			const keys: (keyof T)[] = _.uniq([
				..._.keys(x.original),
				..._.keys(x.current),
			])
			const is_changed_inner = fsmData(keys, {
				filter: k => !x.original || !_.isEqual(x.original[k], x.current[k]),
				map: k => [k, x.current[k]] as [keyof T, T[keyof T]],
			})
			return [x.pk, _.fromPairs(is_changed_inner)] as [V, Partial<T>]
		},
	})

	// Get the appended items - those without an original
	const appended = fsmData(current, {
		filter: x => !x.original && !isEmpty(x) && !x.deleted,
		map: x => [String(x.pk), x.current] as const,
	})

	// Delete items are marked as such
	const deleted = fsmData(current, {
		filter: x => x.deleted && x.original,
		map: x => x.pk as V,
	})

	// Build the full delta object
	return {
		data: fsmData(current, {
			filter: x => !isEmpty(x),
			map: x => x.current,
		}),
		appended: appended.map(x => x[1]),
		appendedIDs: appended.map(x => x[0]),
		updated: _.fromPairs(changed) as { [key: string | number]: Partial<T> },
		deleted: deleted,
		isChanged: changed.length > 0 || deleted.length > 0 || appended.length > 0,
	}
}

const getChangeDeltaInner = <T extends Dict, V extends string | number>(
	rs: ReducerStateD<T, V>,
): GridSaveDelta<T, V> => getEGChangeDelta(_.values(rs.state.data), rs.props.appendable)

// Helper to heck whether a record can be deleted and/or reset
const checkRecordDeletableResetable = <T extends Dict, V extends string | number>(
	rs: ReducerStateD<T, V>,
	x: EGRecord<T, V>,
): Maybe<'delete' | 'reset'> => {
	const nr = rs.props.appendable?.()
	const existing = x.original != null
	const changed = !_.isEqual(x.original, x.current)
	// Existing rows can be deleted (if deletable) only if unchanged, otherwise reset
	if (existing) {
		if (!changed && rs.props.deletable) {
			return 'delete'
		} else if (!changed) {
			return null
		}
		return 'reset'
		// New rows can always be deleted, but show nothing for the empty ones
	}
	if (_.isEqual(nr, x.current)) {
		return null
	}
	return 'delete'
}

// Helper function to get the indicator for a row
// Used for the tooltip and DOM class name
const getIndicatorRow = <T extends Dict, V extends string | number>(
	rs: ReducerStateD<T, V>,
	rec: EGRecord<T, V>,
): { type: 'white' | 'red' | 'grey' | 'green'; message: string } => {
	// Check if the record is blank
	const blank = rec.original == null && _.isEqual(rec.current, rs.props.appendable?.())

	// Get all possible validation errors in the record
	const errors = () =>
		_.flatten(
			fsmData(rs.props.fields, {
				filter: x => !x.disabled,
				map: field => getErrorsInCell(field, rec),
			}),
		)

	// Get the indicator type with tooltip
	if (blank) {
		return {
			type: 'white',
			message: 'Empty row',
		}
	} else if (errors().length > 0) {
		return {
			type: 'red',
			message: errors().join(', '),
		}
	} else if (_.isEqual(rec.current, rec.original)) {
		return {
			type: 'grey',
			message: 'Record unchanged',
		}
	}
	return {
		type: 'green',
		message: 'Record changed',
	}
}

// Helper function to get the indicator for a cell
const getIndicatorCell = <T extends Dict, V extends string | number>(
	rs: ReducerStateD<T, V>,
	field: GridField<T, any>,
	rec: EGRecord<T, V>,
) => {
	// Check if the record is blank
	const blank_record = rs.props.appendable?.() as unknown as Maybe<PartialDefined<T>>
	const blank = rec.original == null && _.isEqual(rec.current, blank_record)

	// Get all possible validation errors in the record
	const errors = getErrorsInCell(field, rec)

	// Get the indicator type with tooltip
	const curVal: unknown = field.value(rec.current)
	const type = () => {
		if (field.hideIndicators ?? false) {
			return 'white'
		} else if (
			blank &&
			blank_record &&
			_.isEqual(curVal, field.value(blank_record as T))
		) {
			return 'white'
		} else if (errors.length > 0) {
			return 'red'
		} else if (blank_record && _.isEqual(curVal, field.value(blank_record as T))) {
			return 'grey'
		} else if (rec.original && _.isEqual(curVal, field.value(rec.original))) {
			return 'grey'
		}
		return 'green'
	}
	return {
		type: type(),
		message: errors.join(', '),
	}
}

const getErrorsInCell = <T extends Dict, V extends string | number>(
	field: GridField<T, any>,
	rec: EGRecord<T, V>,
) => {
	const value: unknown = field.value(rec.current)
	return fsmData([...(field.validation ?? []), ...(field.cell.validation ?? [])], {
		filter: x => x && !x.req(value),
		map: x => `${field.lbl}: ${x.msg}`,
	})
}

// Action to edit the search text in the built-in toolbar
const changeSearchText = <T extends Dict, V extends string | number>(
	rs: ReducerStateD<T, V>,
	searchText: string,
) => {
	rs.dispatch([Action.UpdateSearchText, { searchText: searchText }])
}

// Action to update the view - also commits all `current` values to `viewing`
const updateView = <T extends Dict, V extends string | number>(
	rs: ReducerState<T, V>,
	view: EditableGridView,
): Partial<GridState<T, V>> => ({
	data: _.mapValues(rs.state.data, x => ({
		...x,
		viewing: x.current,
	})),
	view: view,
})

// Row action - reset
const actionResetRow = <T extends Dict, V extends string | number>(
	rs: ReducerStateD<T, V>,
	record: EGRecord<T, V>,
) => {
	// Set current row back to original
	if (record.original) {
		rs.dispatch([
			Action.UpdateRecord,
			record.pk,
			{
				...record,
				viewing: record.original,
				current: record.original,
				deleted: false,
			},
		])
	} else {
		// Delete if this was a new row
		rs.dispatch([Action.DeleteRecord, { pk: record.pk }])
	}

	// Reset the form state
	rs.dispatch([
		Action.UpdateFormState,
		{ message: '', isLoading: false, showTick: false },
	])
}

const focusEmptyRow = async <T extends Dict, V extends string | number>(
	rs: ReducerState<T, V>,
) => {
	// Exit immediately if this grid isn't appendable
	if (!rs.props.appendable) {
		return
	}

	// Find the first blank record
	const blank = rs.props.appendable()
	const record = _.first(
		_.filter(
			rs.state.data,
			x => !x.deleted && x.original == null && _.isEqual(x.current, blank),
		),
	)

	// Exit early if the new blank record row not found
	if (!record) {
		console.warn('Could not find newly-created row to focus')
		return
	}

	// Force the list component to scroll to this record
	rs.refs.listGrid.current?.getList().updateScrollPosition([String(record.pk)])

	// Wait for the lazy rendering to update so that the empty row is now visible
	const tryToSelect = () => {
		const recordRefs = rs.refs.cellRefs.current[record.pk]
		if (!recordRefs) {
			return false
		}

		// Get the first editable field key
		const cell = fsmData(rs.props.fields, {
			filter: f => recordRefs[f.key]?.current?.focus != null,
			map: f => recordRefs[f?.key ?? '']?.current,
			takeFirst: true,
		})

		if (cell) {
			cell.select()
			return true
		}
		return false
	}

	// Keep trying in 50ms intervals to select the empty row once it has rendered
	let done = false
	await runPromisesSequentially(
		_.range(20).map(() => async () => {
			if (done) {
				return
			}
			const succeeded = tryToSelect()
			if (succeeded) {
				done = true
			}
			await Sleep(50)
		}),
	)
}

// Row action - delete
const actionDelete = <T extends Dict, V extends string | number>(
	rs: ReducerStateD<T, V>,
	record: EGRecord<T, V>,
) => {
	// Exit early if this record cannot be deleted
	if (!rs.props.deletable && record.original != null) {
		return
	}

	// Mark it as deleted
	rs.dispatch([
		Action.UpdateRecord,
		record.pk,
		{
			...record,
			deleted: true,
		},
	])
	rs.dispatch([
		Action.UpdateFormState,
		{ message: '', isLoading: false, showTick: false },
	])
}

// Reset event
const onReset = <T extends Dict, V extends string | number>(rs: ReducerStateD<T, V>) => {
	rs.refs.seed.current = { index: 1, circle: 0.0 }
	rs.dispatch([Action.UpdateData, getDefaultDataState(rs.props, rs.refs.seed)])
	rs.dispatch([
		Action.UpdateFormState,
		{ message: '', isLoading: false, showTick: false },
	])
}

// Save event stub
const onSave = async <T extends Dict, V extends string | number>(
	rs: ReducerStateD<T, V>,
) => {
	// Exit early if there is no save handler
	if (!rs.props.onSave) {
		console.error('No `onSave` property, but triggered the save event')
		return
	}

	// Get the delta - if nothing has changed, just say so
	const delta = getChangeDeltaInner(rs)
	if (!delta.isChanged) {
		rs.dispatch([
			Action.UpdateFormState,
			{ message: 'Nothing to update', isLoading: false, showTick: false },
		])
		setTimeout(() => {
			rs.dispatch([
				Action.UpdateFormState,
				{ message: '', isLoading: false, showTick: false },
			])
		}, 500)
		return
	}

	// Set the form to loading state
	rs.dispatch([
		Action.UpdateFormState,
		{ message: '', isLoading: true, showTick: false },
	])

	// Run the user-defined save handler
	const result = await new Promise<string | true>(resolve => {
		rs.props.onSave?.(delta, resolve)
	})

	// Error - show message
	if (result !== true) {
		rs.dispatch([
			Action.UpdateFormState,
			{ message: result, isLoading: false, showTick: false },
		])
		return
	}

	// Success - show a tick for a while
	rs.dispatch([
		Action.UpdateFormState,
		{ message: '', isLoading: false, showTick: true },
	])
	setTimeout(() => {
		rs.dispatch([
			Action.UpdateFormState,
			{ message: '', isLoading: false, showTick: false },
		])
	}, 500)
}

// Helper to add empty rows to the bottom
const addEmptyRows = <T extends Dict, V extends string | number>(
	data: gridData<T, V>,
	appendable: Maybe<() => T>,
	seed: React.MutableRefObject<gridSeed>,
): gridData<T, V> => {
	// Exit immediately if this grid isn't appendable
	if (!appendable) {
		return data
	}

	// Get a new blank record and see if any existing records match
	const blank_record = appendable()
	const blank_records = _.filter(
		data,
		x => !x.deleted && x.original == null && _.isEqual(x.current, blank_record),
	)

	// Case 1/3: exactly one blank record - everything is fine
	if (blank_records.length === 1) {
		return data
	}

	// Case 2/3: more than one - remove the extras
	if (blank_records.length > 1) {
		const keys_to_remove = blank_records.slice(1).map(x => x.pk)
		return _.omit(data, keys_to_remove)
	}

	// Case 3/3: no blanks - add one
	const pk = gridGuid(seed)
	return {
		...data,
		[pk]: {
			pk: pk,
			blankIndex: seed.current.index,
			deleted: false,
			original: null,
			viewing: blank_record,
			current: blank_record,
		},
	}
}

const gridGuid = (seed: React.MutableRefObject<gridSeed>): string => {
	seed.current = {
		circle: (seed.current.circle + Math.PI) % 1,
		index: seed.current.index + 1,
	}
	return guid(seed.current.circle)
}

// ACTION INDEX ##########################################################################

enum Action {
	UpdateState,
	UpdateData,
	UpdateRecord,
	UpdateFormState,
	DeleteRecord,
	UpdateSearchText,
	UpdateView,
	UpdateFocusedCell,
}

type Payload<T extends Dict, V extends string | number> =
	| [Action.UpdateState, (state: GridState<T, V>) => Partial<GridState<T, V>>]
	| [Action.UpdateData, gridData<T, V>]
	| [Action.UpdateRecord, string | V, EGRecord<T, V>]
	| [Action.UpdateFormState, { message: string; isLoading: boolean; showTick: boolean }]
	| [Action.DeleteRecord, { pk: string | V }]
	| [Action.UpdateSearchText, { searchText: string }]
	| [Action.UpdateView, { view: EditableGridView }]
	| [Action.UpdateFocusedCell, Maybe<{ pk: string | V; field: string }>]

const getPartialStateFromAction = <T extends Dict, V extends string | number>(
	rs: ReducerState<T, V>,
	p: Payload<T, V>,
): Maybe<Partial<GridState<T, V>>> => {
	switch (p[0]) {
		// Raw update state action
		case Action.UpdateState:
			return p[1](rs.state)

		// Update the data, checking the empty row status
		case Action.UpdateData:
			const data = addEmptyRows(p[1], rs.props.appendable, rs.refs.seed)
			return { data: data as gridData<T, V> }

		// `UpdateRecord` proxy-calls the `UpdateData` action
		case Action.UpdateRecord:
			return getPartialStateFromAction(rs, [
				Action.UpdateData,
				{ ...rs.state.data, [p[1]]: p[2] },
			])

		// `DeleteRecord` proxy-calls as well
		case Action.DeleteRecord:
			return getPartialStateFromAction(rs, [
				Action.UpdateData,
				_.pickBy(rs.state.data, x => x.pk == p[1].pk),
			])

		// Set the current form state
		case Action.UpdateFormState:
			return {
				message: p[1].message,
				isLoading: p[1].isLoading,
				showTick: p[1].showTick,
			}

		// Set the search text
		case Action.UpdateSearchText:
			return {
				view: {
					...rs.state.view,
					searchText: p[1].searchText,
				},
			}

		// Update the view
		case Action.UpdateView:
			return updateView(rs, p[1].view)

		// Update the focused cell
		case Action.UpdateFocusedCell:
			return {
				focusedCell: p[1] ? { pk: p[1].pk, field: p[1].field } : null,
			}
	}
}

// FINAL EXPORTS #########################################################################

export const EditableGrid = React.forwardRef(EditableGridComponent)
