import { Maybe, ReportJob, TW_OVERFLOW_ELLIPSIS } from '../../../../universal'
import { React, _ } from '../../../lib'
import { AddButton } from '../buttons'
import { ListGrid } from '../list-grid'
import { SearchBox } from '../textbox'
import { Toolbar } from '../toolbar'
import { actionAddReportJob, sendOnUpdate } from './actions'
import { ReducerStateD, ReportJobInternal } from './types'

export const RJMSidebar = (props: { rs: ReducerStateD }) => {
	const hasHidden = _.some(props.rs.state.reportJobs, x => x.Hidden == 1)
	return (
		<>
			<Toolbar
				widthRHS={80}
				lhs={
					<SearchBox
						className="w-full"
						value={props.rs.state.searchText}
						onUpdate={v => {
							props.rs.updateState(() => ({ searchText: v }))
						}}
						// TODO - add arrow linking to the sidebar list as a ref
						// onArrowDelta={v => {
					/>
				}
				rhs={
					<>
						<AddButton
							lbl="New"
							title="Add a new report job"
							onClick={() => {
								actionAddReportJob(props.rs)
							}}
						/>
					</>
				}
			/>
			<ListGrid
				value={props.rs.state.selectedReportJob ?? null}
				onUpdate={v => {
					props.rs.updateState(() => ({
						selectedReportJob: v,
						selectedReport: null,
					}))
					sendOnUpdate(props.rs)
				}}
				data={_.values(props.rs.state.reportJobs)}
				pk={x => x.ID}
				defaultSortingKey="Name"
				multiple={false}
				view={{
					groupingField: hasHidden ? 'Hidden' : undefined,
					hiddenFields: ['Hidden'],
				}}
				className="!h-[calc(100%-40px)]"
				classCell={() => TW_OVERFLOW_ELLIPSIS}
				searchText={props.rs.state.searchText}
				fields={[
					{
						key: 'Name',
						lbl: 'Name',
						sortVal: x => x.Name,
						text: x =>
							_.compact([
								hasReportJobChanged(props.rs, x.ID) ? '* ' : null,
								x.Name,
								x.Hidden == 1 ? '(hidden)' : null,
							])
								.join(' ')
								.trim(),
					},
					{
						key: 'Hidden',
						lbl: 'Hidden?',
						sortVal: x => x.Hidden,
						groupVal: x => x.Hidden,
						groupName: x => (x == 1 ? 'Hidden' : 'Active'),
						text: () => '',
						width: { init: 0, flex: 0 },
					},
					{
						key: 'count',
						lbl: '#',
						lblLong: 'Report Count',
						lblDesc: 'Number of reports in the report job',
						className: () => 'text-right',
						width: {
							init: 28,
						},
						text: x => _.size(x.Reports),
					},
				]}
			/>
		</>
	)
}

export const hasReportJobChanged = (rs: ReducerStateD, key: number): boolean => {
	const original = rs.props.reportJobs[key]
	const current = rs.state.reportJobs[key]
	return hasReportJobChangedInner(original, current)
}

const hasReportJobChangedInner = (
	prop: Maybe<ReportJob>,
	state: Maybe<ReportJobInternal>,
): boolean => {
	if (!prop || !state) {
		return true
	}
	if (prop.Name != state.Name) {
		return true
	}
	if (prop.Hidden != state.Hidden) {
		return true
	}
	if (!_.isEqual(prop.BaseParams, state.BaseParams)) {
		return true
	}
	if (!_.isEqual(prop.Reports, state.Reports)) {
		return true
	}
	return false
}
